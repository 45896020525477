import { comma, uncomma } from "utils/utils";
import Select from "./Select";
import styled from "styled-components";
const StyledInput = styled.div`
  display: flex;
  & .input-wrap {
    width: 329px;
    & .budget-replace {
      color: rgb(153, 153, 153);
    }
  }
`;
const OfferInput = ({
  amount,
  handleAmount,
  payMethod,
  handlePayMethod,
  color,
}) => {
  return (
    <StyledInput className="input type2">
      <Select
        className={["project-request"]}
        color={color}
        width={"150px"}
        option={[
          {
            id: "SALARY",
            name: "월급",
          },
          {
            id: "WAGE",
            name: "주급",
          },
          {
            id: "REWARD",
            name: "프로젝트 완료 후",
          },
        ]}
        selected={payMethod}
        setSelect={(value) => {
          handlePayMethod(value);
        }}
      />
      <div className="input-number input-wrap" style={{ marginLeft: "10px" }}>
        {Boolean(amount) && <p className="budget-replace">{amount}만 원</p>}
        <input
          type="text"
          className="input-text type2 color-b"
          placeholder="희망 금액을 입력하세요."
          value={amount || ""}
          onChange={(e) => {
            if (e.target.value < -1) return;
            const value = uncomma(e.target.value);
            if (value < 0) return;
            handleAmount(comma(value));
          }}
        />
        <button
          type="button"
          className="btn up"
          onClick={() => {
            const value = Number(uncomma(amount));
            if (value < 0) return;
            handleAmount(comma(value + 1));
          }}
        />
        <button
          type="button"
          className="btn down"
          onClick={() => {
            if (amount <= 0) return;
            const value = Number(uncomma(amount));
            if (value < 0) return;
            handleAmount(comma(value - 1) !== "0" ? comma(value - 1) : "");
          }}
        />
      </div>
    </StyledInput>
  );
};

export default OfferInput;
