import { createSlice } from "@reduxjs/toolkit";

const initialPartnerData = {
  career: null,
  workMethod: null,
  introduceSimple: null,
  introduceDetails: null,
  file: null,
  fileName: null,
  bailiwick: {},
  skillRetention: {},
  interestedAiTask: {},
  framework: {},
  careerInfoList: [],
  educationInfoList: [],
  businessInfo: {
    identificationNo: null,
    name: "",
    file: null,
    fileName: null,
    scale: "",
    location: "",
    locationDetails: "",
  },
};

export const userSlice = createSlice({
  name: "partner",
  initialState: { partnerData: initialPartnerData },
  reducers: {
    setPartnerData: (state, action) => {
      state.partnerData = action.payload;
    },
    setPartnerBusinessInfo: (state, action) => {
      console.log(action);
      state.partnerData.businessInfo = action.payload;
    },
  },
});

export const { setPartnerData, setPartnerBusinessInfo } = userSlice.actions;
