import React from "react";
import styled from "styled-components";
import { interestedAiTaskData } from "utils/data";
import useAuth from "hooks/useAuth";
import useViewCheck from "hooks/useViewCheck";

import Ibusiness from "assets/images/icon/icon_business.svg";
import Icareer from "assets/images/icon/icon_career.svg";
import Ilocation from "assets/images/icon/icon_location.svg";
import Itype from "assets/images/icon/icon_type.svg";
import IGpt from "assets/images/icon/icon_gpt.svg";
import IAi from "assets/images/icon/icon_ai.svg";
import IReference from "assets/images/icon/icon_reference.svg";
import IFrofile from "assets/images/icon/icon_profile.svg";
import heartLine from "assets/images/icon/heart_line.svg";
import heartFill from "assets/images/icon/heart_fill.svg";

const ArticleBox = ({ title, children }) => {
  return (
    <StyledArticleBox>
      <label>{title}</label>
      {children}
    </StyledArticleBox>
  );
};

// 프로필 특징 뱃지
const ProfileBadges = ({ badges }) => {
  return (
    <div className="badges">
      {badges.gpt && (
        <span className="badge skills">
          <img src={IGpt} alt="chatGPT" title="Chat GPT 모델 개발 경험" />
          Chat GPT 모델 개발 경험
        </span>
      )}
      {badges.ai && (
        <span className="badge skills">
          <img src={IAi} alt="Productive AI" title="생성형 AI 모델 개발 경험" />
          생성형 AI 모델 개발 경험
        </span>
      )}
      {badges.career?.length > 0 && (
        <span className="badge reference">
          <img src={IReference} alt="Reference" title="레퍼런스 보유" />
          레퍼런스 보유
        </span>
      )}
    </div>
  );
};

const ProfileBox = ({
  user,
  name,
  partnerType,
  imgSrc,
  simple,
  detail,
  businessInfo,
  skills,
  badges,
  heroesLike,
  heroesDibsChange,
}) => {
  const { isMobile } = useViewCheck();
  const { isLoggedin, userInfo } = useAuth();

  const colors = ["#CCFF00", "#FFBC27", "#BAEEFE", "#FFB2B2", "#D8BAFF"];
  const replaceScaleText = (type) => {
    switch (type) {
      case "UNDER_10":
        return "0 ~ 10명 미만";
      case "UNDER_50":
        return "10명 ~ 50명 미만";
      case "UNDER_100":
        return "50 ~ 100명 미만";
      case "UNDER_200":
        return "100 ~ 200명 미만";
      case "UNDER_500":
        return "200 ~ 500명 미만";
      case "OVER_1000":
        return "1000명 이상";
      default:
        break;
    }
  };
  const changeBadgeArray = (data) => {
    if (!data) return;
    const dataTask = Object.entries(data)
      .filter(([key, value]) => typeof value === "boolean" && value === true)
      .map(([key]) => key);
    return dataTask;
  };

  const toCamelCase = (str) =>
    str.toLowerCase().replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  const findAiTaskByResponseValue = (responseValue) => {
    const camelCaseId = toCamelCase(responseValue);
    return interestedAiTaskData.find((task) => task.id === camelCaseId)?.name;
  };

  return (
    <section>
      <StyledTitle>
        <div className="img-box">
          {/* 프로필 이미지 */}
          <img
            src={imgSrc ? imgSrc : IFrofile}
            alt="사용자 사진"
            title="사용자 사진"
          />
          {/* 프로필 파트너 뱃지 */}
          <span
            className={`badge ${
              partnerType === "ROLE_HEROS_PERSONAL" ? "blue" : "primary"
            }`}
          >
            {partnerType === "ROLE_HEROS_PERSONAL"
              ? "개인 파트너"
              : "기업 파트너"}
          </span>
        </div>
        <div className="title-box">
          <div className="title-line" style={{ alignItems: "flex-start" }}>
            {/* 프로필 이름 */}
            <div
              className="d-flex"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              {!isMobile && <ProfileBadges badges={badges} />}
              <h1>{name}</h1>
            </div>

            {isLoggedin && user !== userInfo.SEQ && (
              <button
                type="button"
                className={`btn btn-like ${heroesLike?.DIBS ? "active" : ""}`}
                onClick={heroesDibsChange}
              >
                <img
                  alt="like"
                  className={heroesLike?.DIBS ? "heartFill" : "heartLine"}
                  src={heroesLike?.DIBS ? heartFill : heartLine}
                />
              </button>
            )}
          </div>
          {/* 프로필 심플설명 */}
          <p>{simple}</p>
          {isMobile && <ProfileBadges badges={badges} />}
        </div>
      </StyledTitle>
      <StyledInfomation>
        <ArticleBox title="파트너 소개">
          <article>
            <pre> {detail} </pre>
          </article>
        </ArticleBox>
        <ArticleBox title="파트너 정보">
          <article style={{ paddingBottom: "8px" }}>
            {(businessInfo.career !== undefined ||
              businessInfo.career !== null) && (
              <div className="info-input">
                <img src={Icareer} alt="경력_아이콘" title="경력" />
                <label>
                  {partnerType === "ROLE_HEROS_PERSONAL" ? "경력" : "업력"}
                </label>
                <span>{businessInfo.career}년</span>
              </div>
            )}
            {businessInfo.workMethod !== null &&
              businessInfo.workMethod?.length > 0 && (
                <div className="info-input">
                  <img src={Itype} alt="근무형태_아이콘" title="근무형태" />
                  <label>선호 근무 형태</label>
                  {businessInfo.workMethod === "BOTH" && (
                    <span>상주, 원격 모두 가능</span>
                  )}
                  {businessInfo.workMethod === "RESIDENT" && (
                    <span>상주 가능</span>
                  )}
                  {businessInfo.workMethod === "REMOTE" && (
                    <span>원격 가능</span>
                  )}
                </div>
              )}
            {partnerType !== "ROLE_HEROS_PERSONAL" && (
              <>
                {businessInfo.location !== null &&
                  businessInfo.location?.length > 0 && (
                    <div className="info-input">
                      <img src={Ilocation} alt="소재지_아이콘" title="소재지" />
                      <label>소재지</label>
                      <span>
                        {businessInfo.location} {businessInfo.locationDetails}
                      </span>
                    </div>
                  )}
                {businessInfo.scale !== null &&
                  businessInfo.scale?.length > 0 && (
                    <div className="info-input">
                      <img src={Ibusiness} alt="규모_아이콘" title="규모" />
                      <label>규모</label>
                      <span>{replaceScaleText(businessInfo.scale)}</span>
                    </div>
                  )}
              </>
            )}
          </article>
        </ArticleBox>
        {changeBadgeArray(skills).length > 0 && (
          <ArticleBox title="보유한 기술">
            <article className="flex-wrap">
              {changeBadgeArray(skills).map((item, idx) => (
                <span
                  key={idx}
                  className="badge m5 badge-md purple"
                  style={{
                    backgroundColor: colors[idx % colors.length],
                  }}
                >
                  {findAiTaskByResponseValue(item)}
                </span>
              ))}
            </article>
          </ArticleBox>
        )}
      </StyledInfomation>
    </section>
  );
};

export default ProfileBox;

const StyledTitle = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: inline-flex;
  align-items: center;
  & .img-box {
    margin-right: 20px;
    img {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    span.badge {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: 12.5px;
      font-size: 12px;
      padding: 0 10px;
      width: 75px;
    }
  }
  & .title-box {
    width: calc(100% - 120px);
    & .title-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      line-height: 24px;
      margin-bottom: 6px;
      & .btn-like {
        background-image: none;

        & img {
          transition: opacity 0.3s ease-in-out;
        }
        .heartFill {
          opacity: 1;
        }

        .heartLine {
          opacity: 0.4;
        }
      }
    }
    h1 {
      margin-right: 6px;
      font-size: 18px;
      font-weight: 500;
      line-height: 132.778%;
      width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @media (max-width: 660px) {
      & h1 {
        width: 190px;
      }
    }
    .badges {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6px;

      span.badge {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        height: 24px;
        padding: 0 10px;
        & img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
        &.skills {
          border-radius: 5px;
          background: var(--sub-u-purple01, #f4ecff);
        }
        &.reference {
          border-radius: 5px;
          background: var(--primary-p-green01, #cf0);
        }
      }
    }
    p {
      color: var(--mono-m-black01, #333);

      /* Title4 600 */
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.9px; /* 132.778% */
      max-width: 620px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 660px) {
    & .title-box {
      & .badges {
        margin-top: 6px;
      }
    }
  }
`;

const StyledInfomation = styled.div``;

const StyledArticleBox = styled.div`
  margin-top: 20px;
  label {
    color: var(--mono-m-black01, #333);

    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3px; /* 133.125% */
  }
  article {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 15.5px 18px;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    color: var(--mono-m-black01, #333);
    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.6px; /* 132.857% */
    &.flex-wrap {
      display: flex;
      padding: 15px 18px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 6px;
      align-self: stretch;
      flex-wrap: wrap;
    }
    & .info-input {
      display: flex;
      align-items: center;
      width: 50%;

      /* Body 500 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18.6px; /* 132.857% */
      margin-bottom: 10px;
      label {
        color: var(--mono-m-gray01, #999);
        font-size: 14px;
        margin-right: 6px;
        line-height: 19px;
      }
      span {
        line-height: 19px;
      }
      img {
        margin-right: 6px;
      }
      @media (max-width: 860px) {
        width: 100%;
      }
    }
    & .badge {
      border-radius: 10px;
    }
    & pre {
      white-space: pre-wrap;
    }
  }
`;
