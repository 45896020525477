import React from 'react';
import useModal from 'hooks/useModal';

const ImageViewModal = ({ img }) => {
	const { closeModal } = useModal();

	return (
		<div className="modal show">
			<div
				className="modal-dim"
				onClick={() => { closeModal() }}
			></div>
			<div className="modal-con only-image">
				<img src={img} alt="" />
			</div>
		</div>
	)
}

export default ImageViewModal