import React from "react";
import styled from "styled-components";
import IBannerNew from "assets/images/icon/icon_banner_new.svg";
import IBannerAi from "assets/images/icon/icon_banner_ai.svg";
import IProfile from "assets/images/icon/icon_profile.svg";
import { useNavigate } from "react-router-dom";

const BannerCard = ({ item, type, ...restProps }) => {
  const navigate = useNavigate();
  return (
    <StyledBannerCard {...restProps}>
      <Title>
        <img
          src={type === "new" ? IBannerNew : IBannerAi}
          alt="파트너 뱃지"
          title="파트너 뱃지"
        />
        <span>
          <strong>{type === "new" ? "최근 가입" : "생성형 AI"}</strong>
          {type === "new" ? " 파트너" : " 전문 파트너"}
        </span>
      </Title>
      <Content>
        {item.map((data, idx) => (
          <div
            key={idx}
            className="item"
            onClick={() => {
              navigate(`/heroes/${data.USER_SEQ}`);
            }}
          >
            <img src={data.PROFILE_IMG ? data.PROFILE_IMG : IProfile} />
            <div>
              <div className="title">
                {data.USER_NICKNAME ? data.USER_NICKNAME : data.USER_NAME}
              </div>
              {data.HEROES_TYPE === "BUSINESS" && (
                <div className="badge business">기업 파트너</div>
              )}
              {data.HEROES_TYPE === "PERSONAL" && (
                <div className="badge personal">개인 파트너</div>
              )}
            </div>
          </div>
        ))}
      </Content>
    </StyledBannerCard>
  );
};

export default BannerCard;

const StyledBannerCard = styled.section`
  width: ${(props) => (props.isMobile ? "calc(100vw - 50px)" : "260px")};
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid var(--mono-m-gray03-stroke, #eee);
  margin-bottom: ${(props) => (props.isMobile ? "20px" : "0")};
`;
const Title = styled.h1`
  gap: 4px;
  color: var(--mono-m-black01, #333);
  display: inline-flex;
  align-items: center;
  /* Title5 600 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px;
  margin-bottom: 22px;
  & strong {
    color: var(--primary-p-blue00, #0a7aff);
    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3px; /* 133.125% */
  }
`;
const Content = styled.div`
  & .item {
    display: inline-flex;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    & img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    & div {
      width: 135px;
      .title {
        color: var(--mono-m-black01, #333);
        padding-bottom: 2px;

        /* Body 500 */
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.6px; /* 132.857% */
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .badge {
        color: var(--mono-m-black00, #000);

        /* Caption 400 */
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.9px; /* 132.5% */
        display: flex;
        padding: 4px 10px;
        align-items: center;
        width: 75px;
        &.business {
          border-radius: 5px;
          background: var(--primary-p-green00, #00ff85);
        }
        &.personal {
          border-radius: 5px;
          background: var(--sub-u-blue01, #43f4ff);
        }
      }
    }
  }
`;
