import React, { useState } from "react";
import useModal from "hooks/useModal";
import { userContact } from "services/heros";
import styled from "styled-components";
const PartnerContactModal = ({ id, callback }) => {
  const { openModal, closeModal } = useModal();
  const [contents, setDetails] = useState("");
  const submitHandler = () => {
    userContact(id, contents, (res) => {
      if (res.code === 0) {
        openModal({
          type: "messageModal",
          contents: {
            title: "파트너 컨텍트 요청 완료",
            message:
              "파트너 컨텍트 요청이 완료되었어요.\n담당 매니저를 통해 곧 연락 드릴게요.",
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
              callback();
            },
          },
        });
      } else {
        openModal({
          type: "messageModal",
          contents: {
            title: "요청에 실패했습니다",
            message:
              "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      }
    });
  };

  return (
    <StyledForm>
      <div className="modal show">
        <div className="modal-dim"></div>
        <div className="modal-con message">
          <div className="text">
            <p className="strong">파트너 컨택하기</p>
          </div>
          <div className="checkbox-list mt-10">
            <textarea
              className="input-textarea black"
              placeholder={
                "파트너 컨택을 통해 가장 알고 싶은 내용을 간단히 남겨주세요."
              }
              maxLength={1000}
              value={contents}
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
          </div>
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-half btn-grey"
              onClick={() => closeModal()}
            >
              돌아가기
            </button>
            <button
              type="button"
              className="btn btn-full btn-primary"
              onClick={() => {
                submitHandler();
              }}
            >
              {"요청하기"}
            </button>
          </div>
        </div>
      </div>
    </StyledForm>
  );
};

export default PartnerContactModal;

const StyledForm = styled.div`
  .modal-con {
    display: flex;
    width: 650px;
    min-width: unset;
    padding: 65px 20px;
    height: 387px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    & .strong {
      margin: 0;
    }
    @media (max-width: 860px) {
      padding: 33px 32px;
      margin: 0 18px;
      border-radius: 5px;
      min-width: unset;
      width: 90%;
      & .checkbox-list {
        width: 100%;
      }
      & textarea {
        width: 100%;
      }
    }
  }
  align-items: center;
  & textarea {
    width: 480px;
    height: 130px;
  }
  & .btn-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;
    margin: 0;
    & .btn-half {
      width: 94px;
      height: 50px;
      margin: 0;
    }
    & .btn-full {
      width: 200px;
      height: 50px;
      margin: 0;
    }
  }
`;
