import React, { createContext, useState, useEffect } from 'react';

export let ViewCheckContext = createContext();

const ViewCheckProvider = ({ children }) => {
	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => { // cleanup 
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	const handleResize = () => {
		if (window.innerWidth > 860) {
			setMobile(false)
		} else {
			setMobile(true);
		}
	}
	let value = { isMobile };

	return <ViewCheckContext.Provider value={value}>{children}</ViewCheckContext.Provider>;
}

export default ViewCheckProvider;