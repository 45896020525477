import React from 'react'
import { useNavigate } from 'react-router-dom'
import useModal from 'hooks/useModal';

const NotSignedup = () => {
	const navigate = useNavigate();
	const { openModal } = useModal();
	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<div className="section-tit lg">
						가입된 이메일 계정이 없어요.<br />
						회원가입 후<br className="m-block" /> 서비스를 이용해 주세요.
					</div>
					<div className="col-2">
						<button type="button" className="btn btn-half btn-black type2 mt-30 m-w-100" onClick={() => openModal({ type: 'loginModal' })}>회원가입 하기</button>
						<button type="button" className="btn btn-half btn-l-grey type2 mt-30 m-w-100" onClick={() => navigate('/idinquiry/snsauth')}>이메일 다시 찾기</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotSignedup