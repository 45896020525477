import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SkillBadge from "./SkillBadge";
const AiSkills = ({
  partnerData,
  interestedAiTask,
  setInterestedAiTask,
  options,
  isMobile,
  ...restProps
}) => {
  const scrollRef = useRef();
  const handleScroll = (event) => {
    event.stopPropagation();
    // You can add additional logic or remove the alert as needed
  };
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
      const specificDiv = scrollRef.current;
      if (specificDiv) {
        specificDiv.scrollLeft += event.deltaY;
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);
  return (
    <StyledWrapper>
      <ScrollContainer
        ref={scrollRef}
        onWheel={handleScroll}
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        {options.map((item, idx) => (
          <SkillBadge
            isMobile={isMobile}
            key={`${item.id}_${idx}`}
            index={idx}
            className={"input-checkbox"}
            checkedArray={interestedAiTask}
            setCheckArray={setInterestedAiTask}
            badgeData={item}
          />
        ))}
      </ScrollContainer>
    </StyledWrapper>
  );
};

export default React.memo(AiSkills);

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
  padding: 11px 18px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  /* Style for WebKit browsers (Chrome, Safari) */
`;
const ScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    padding: 0;
    height: 0; /* 가로축 스크롤바 폭 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* Firefox 스크롤 바 스타일 */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  & {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  /* 하단 여백을 스크롤 바 높이 만큼 빼주기 */
  padding-bottom: calc(10px * -1);
`;
