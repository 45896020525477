import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import {
  getHerosDetail,
  userHerosDibsChange,
  getClientCount,
} from "services/heros";
import MetaTag from "MetaTag";

import useViewCheck from "hooks/useViewCheck";
import { projectOfferList } from "services/projectManagement";
import ProfileBox from "./components/profileBox";
import ReferenceBox from "./components/ReferenceBox";
import KeywordBox from "./components/KeywordBox";
import styled from "styled-components";
import RecomendBox from "./components/RecomendBox";
import FloatBox from "./components/FloatBox";
import IFrofile from "assets/images/icon/icon_profile.svg";
import { breakString } from "utils/utils";

const LeftSection = styled.section`
  margin-right: 28px;
  width: 100%;

  & .recommend-title {
    color: var(--primary-p-purple00, #5712eb);

    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3px; /* 133.125% */
  }
`;

const RightSection = styled.section`
  width: 260px;

  & div.btn-wrap {
    position: sticky;
    top: 100px;
    margin: 0;
    & .d-flex {
      &.mobile {
        flex-direction: row;
      }
      flex-direction: column;
    }
  }
  & button {
    width: 260px;
    height: 50px;
    gap: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 6px;
    &.btn-white {
      border-radius: 5px;
      border: 1px solid var(--mono-m-gray03-stroke, #eee);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 660px) {
    padding-top: 25px;
    width: 100%;
  }
`;

const Divider = styled.div`
  border-top: 1px solid #eee;
  width: 100%;
  color: #eeeeee;
  margin: 40px 0;
`;

const StyledFile = styled.article`
  margin-top: 20px;

  & label {
    color: var(--mono-m-black01, #333);

    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3008px; /* 133.125% */
  }
  & button {
    margin-top: 10px;
  }
`;

const NHeroesDetail = () => {
  const navigate = useNavigate();
  const { isLoggedin, userInfo } = useAuth();
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [count, setCount] = useState(null);
  const [isOfferAvailable, setOfferAvailable] = useState(false);
  const [reviewPage, setReviewPage] = useState(1);
  const [clientProject, setClientProject] = useState([]);

  useEffect(() => {
    if (!Number.isInteger(Number(id))) {
      navigate("/heroes");
      return;
    }

    getData();
    getProjectOfferList();
    getCount();
  }, [id, reviewPage]);

  useEffect(() => {
    getClientProject();
  }, [userInfo]);

  const getClientProject = useCallback(() => {
    if (userInfo.SEQ === null) {
      return;
    }
    projectOfferList(userInfo.SEQ, (res) => {
      setClientProject(res.response);
    });
  }, [userInfo.SEQ]);

  const getData = useCallback(() => {
    const params = {
      seq: id,
      page: reviewPage,
    };
    getHerosDetail(id, params, (res) => {
      if (res.code !== 0) {
        navigate("/heroes");
      }
      setData(res.response);
    });
  }, [id, reviewPage]);

  const getCount = () => {
    getClientCount(id, (res) => {
      if (res.response.length === 0) {
        setCount(null);
      } else {
        setCount(res.response);
      }
    });
  };

  const getProjectOfferList = () => {
    projectOfferList(id, (res) => {
      if (res.response.length === 0) {
        setOfferAvailable(false);
      } else {
        setOfferAvailable(true);
      }
    });
  };

  return (
    <div className="container">
      <MetaTag
        title={
          data?.INTRODUCE_SIMPLE
            ? data.INTRODUCE_SIMPLE
            : "파트너 | AI 히어로즈"
        }
        desc={
          data?.INTRODUCE_DETAILS
            ? breakString(data.INTRODUCE_DETAILS, 80)
            : "AI 히어로즈의 검증된 파트너를 만나보세요. 신뢰할 수 있는 파트너와 함께 성공적인 프로젝트를 진행하세요."
        }
        imgSrc={data?.PROFILE_IMG ? data.PROFILE_IMG : IFrofile}
        robot={true}
      />
      <div className="section">
        <button
          onClick={() => {
            navigate("/heroes");
          }}
          type="button"
          className="btn top-back"
        >
          리스트로 돌아가기
        </button>
        {data && (
          <div className="project-detail">
            <LeftSection>
              <div className="profile-info">
                {/* 프로필박스 */}
                <section style={{ width: "100%" }}>
                  <ProfileBox
                    user={data.USER}
                    name={data.NICKNAME}
                    partnerType={data.ROLE}
                    imgSrc={data.PROFILE_IMG}
                    simple={data.INTRODUCE_SIMPLE}
                    detail={data.INTRODUCE_DETAILS}
                    businessInfo={{
                      career: data.CAREER,
                      workMethod: data.WORK_METHOD,
                      location: data.BUSINESS_INFO?.LOCATION,
                      locationDetails: data.BUSINESS_INFO?.LOCATION_DETAILS,
                      scale: data.BUSINESS_INFO?.SCALE,
                    }}
                    badges={{
                      gpt: data.INTERESTED_AI_TASK?.CHAT_GPT,
                      ai: data.INTERESTED_AI_TASK?.GENERATIVE_AI,
                      career: data.CAREER_LIST,
                    }}
                    skills={data.INTERESTED_AI_TASK}
                    heroesLike={data.heroesLike}
                    heroesDibsChange={() => {
                      userHerosDibsChange(id, () => getData());
                    }}
                  />
                  <ReferenceBox
                    role={data.ROLE}
                    careerList={data.CAREER_LIST}
                    eduList={data.EDUCATION_LIST}
                  />
                  {data.FILE && (
                    <StyledFile>
                      <label>포트폴리오</label>
                      <button
                        type="button"
                        style={!data.INTRODUCE_DETAILS ? { marginTop: 0 } : {}}
                        className="btn btn-full btn-primary btn-down"
                        onClick={() => {
                          data.FILE !== null && window.open(data.FILE);
                        }}
                      >
                        {data.FILE && data.FILE_NAME
                          ? data.FILE_NAME
                          : "포트폴리오 다운로드"}
                      </button>
                    </StyledFile>
                  )}
                </section>
              </div>
              {/* 상세 정보가 있을 때 나오게 된다. */}
              <div>
                <KeywordBox data={data} />
              </div>
              <Divider />

              <div>
                <label className="recommend-title">
                  다른 파트너도 함께 살펴보세요.
                </label>
                <RecomendBox
                  data={data.recommend}
                  isMobile={isMobile}
                  targetSkill={data.INTERESTED_AI_TASK}
                />
              </div>
            </LeftSection>
            <RightSection>
              <div className="btn-wrap">
                <FloatBox count={count} isSelf={data.USER === userInfo.SEQ}>
                  <div className={isMobile ? "d-flex mobile" : "d-flex"}>
                    {data.USER === userInfo.SEQ ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-black type2"
                        onClick={() => {
                          navigate("/mypage/profile");
                        }}
                      >
                        프로필 수정하기
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary type2"
                          onClick={() => {
                            openModal({
                              type: "partnerContactModal",
                              contents: {
                                id,
                                callback: () => {
                                  closeModal();
                                },
                              },
                            });
                          }}
                        >
                          파트너 컨택하기
                        </button>
                        {clientProject.length !== 0 && (
                          <button
                            type="button"
                            className="btn btn-lg btn-black type2"
                            onClick={() => {
                              openModal({
                                type: "projectSuggestionModal",
                                contents: {
                                  id,
                                  heroesName: data.NAME,
                                  callback: () => {
                                    getProjectOfferList();
                                    closeModal();
                                  },
                                },
                              });
                            }}
                            disabled={!isOfferAvailable}
                          >
                            프로젝트 제안하기
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </FloatBox>
              </div>
            </RightSection>
          </div>
        )}
      </div>
    </div>
  );
};

export default NHeroesDetail;
