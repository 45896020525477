import React, { useEffect, useState } from "react";
import { authBusinessInfo, authProfile } from "services/user";
import Select from "components/common/Select";

import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import {
  aiProjectModify,
  aiProjectRequest,
  aiProjectRequestSimple,
} from "services/aiproject";
import { comma, dateFormat, fileNameFormat, uncomma } from "utils/utils";
import {
  aiIndustryData,
  aiSkillData,
  interestedAiTaskData,
  frameworkData,
} from "utils/data";
import { useLocation, useNavigate } from "react-router-dom";
import useModal from "hooks/useModal";
import { getParameterByName } from "utils/utils";
import { userProjectDetail } from "services/projectManagement";
import useViewCheck from "hooks/useViewCheck";
import { fileAttach } from "utils/storage";
import styled from "styled-components";

const AiProjectRequest = () => {
  const today = new Date();
  const threeDaysLater = new Date(today.setDate(today.getDate() + 3));
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const [formSimplify, setFormSimplify] = useState(true);

  const [editSeq, setEditSeq] = useState(null);
  const [baseInfoForm, setBaseInfoForm] = useState({
    managerName: null,
    managerEmail: null,
    managerPhone: null,
    managerNickname: null,
  });
  const [projectPeriodType, setProjectPeriodType] = useState("W");
  const [projectInfoForm, setProjectInfoForm] = useState({
    type: "BUSINESS", // PERSONAL, BUSINESS
    businessIdentificationNo: null,
    businessRegistrationImg: null,
    registrationFileName: null,
    projectName: "",
    projectPeriod: "",
    periodNegotiable: false,
    budgetMin: null,
    budgetMax: null,
    payMethod: null, // SALARY, WAGE, REWARD
    payNegotiable: false,
    workMethod: null, //BOTH, REMOTE, RESIDENT
    closeDate: null,
    haveData: false,
    aiIndustry: [],
    aiSkill: [],
  });
  const [businessNumCheckDone, setBusinessNumCheckDone] = useState(false);
  const [descriptionForm, setDescriptionForm] = useState({
    projectDetails: "",
    projectFile: null,
    projectFileName: null,
  });
  const [smartTagForm, setSmartTagForm] = useState({
    interestedAiTask: [],
    framework: [],
  });
  const [businessNumError, setBusinessNumError] = useState(false);
  const [budgetMinError, setBudgetMinError] = useState(false);
  const [budgetMaxError, setBudgetMaxError] = useState(false);
  const [budgetMINMaxError, setBudgetMINMaxError] = useState(false);

  const [allIpuntDone, setAllIpuntDone] = useState(false);

  useEffect(() => {
    const editProjectSEQ = getParameterByName(location.search, "edit");
    authProfile((res) => {
      const { NAME, USERNAME, PHONE, NICKNAME } = res.response;
      setBaseInfoForm({
        managerName: NAME,
        managerEmail: USERNAME,
        managerPhone: PHONE,
        managerNickname: NICKNAME,
      });
      if (editProjectSEQ) {
        userProjectDetail(editProjectSEQ, (res) => {
          const editData = res.response;

          const tempAiIndustryArr = [];
          for (let i = 0; i < Object.keys(editData.AI_INDUSTRY).length; i++) {
            if (editData.AI_INDUSTRY[Object.keys(editData.AI_INDUSTRY)[i]]) {
              tempAiIndustryArr.push(
                Object.keys(editData.AI_INDUSTRY)[i].toLocaleLowerCase()
              );
            }
          }
          const tempAiSkillArr = [];
          for (let i = 0; i < Object.keys(editData.AI_SKILL).length; i++) {
            if (editData.AI_SKILL[Object.keys(editData.AI_SKILL)[i]]) {
              if (Object.keys(editData.AI_SKILL)[i] === "BIG_DATA") {
                tempAiSkillArr.push("bigData");
              }
              tempAiSkillArr.push(
                Object.keys(editData.AI_SKILL)[i].toLocaleLowerCase()
              );
            }
          }
          setEditSeq(editProjectSEQ);

          setProjectInfoForm({
            type: editData.PROJECT_TYPE,
            businessIdentificationNo: editData.BUSINESS_IDENTIFICATION_NO,
            businessRegistrationImg: editData.BUSINESS_REGISTRATION_IMG,
            registrationFileName: editData.REGISTRATION_FILE_NAME,
            projectName: editData.PROJECT_NAME,
            projectPeriod: editData.PROJECT_PERIOD,
            periodNegotiable: editData.PERIOD_NEGOTIABLE,
            budgetMin: comma(editData.BUDGET_MIN),
            budgetMax: comma(editData.BUDGET_MAX),
            payMethod: editData.PAY_METHOD,
            payNegotiable: editData.PAY_NEGOTIABLE,
            workMethod: editData.WORK_METHOD,
            closeDate: new Date(editData.CLOSE_DATE),
            haveData: editData.HAVE_DATA,
            aiIndustry: tempAiIndustryArr,
            aiSkill: tempAiSkillArr,
          });

          setDescriptionForm({
            projectDetails: editData.PROJECT_DETAILS,
            projectFile: editData.PROJECT_FILE,
            projectFileName: editData.PROJECT_FILE_NAME,
          });
          setSmartTagForm({
            interestedAiTask: [],
            framework: [],
          });
        });
      } else {
        if (
          res.response.ROLE === "ROLE_HEROS_BUSINESS" ||
          res.response.ROLE === "ROLE_BUSINESS_USER"
        ) {
          authBusinessInfo((res) => {
            if (res.code !== 0) return;
            setBusinessNumCheckDone(true);
            setProjectInfoForm({
              ...projectInfoForm,
              businessIdentificationNo: res.response.IDENTIFICATION_NO,
              businessRegistrationImg: res.response.FILE_NAME,
              registrationFileName: res.response.FILE_NAME,
            });
          });
        }
      }
    });
  }, [location]);

  useEffect(() => {
    if (formSimplify) {
      setAllIpuntDone(true);
      return;
    }
    if (
      projectInfoForm.type === "BUSINESS" &&
      (!projectInfoForm.businessIdentificationNo ||
        !projectInfoForm.businessRegistrationImg)
    ) {
      setAllIpuntDone(false);
      return;
    }

    if (!projectInfoForm.periodNegotiable && !projectInfoForm.projectPeriod) {
      setAllIpuntDone(false);
      return;
    }

    if (
      !projectInfoForm.payNegotiable &&
      !projectInfoForm.payMethod &&
      !projectInfoForm.budgetMin &&
      !projectInfoForm.budgetMax
    ) {
      setAllIpuntDone(false);
      return;
    }

    if (
      projectInfoForm.projectName &&
      projectInfoForm.workMethod &&
      projectInfoForm.closeDate &&
      projectInfoForm.aiIndustry.length &&
      projectInfoForm.aiSkill.length
    ) {
      setAllIpuntDone(true);
    } else {
      setAllIpuntDone(false);
    }
  }, [formSimplify, projectInfoForm]);

  useEffect(() => {
    authBusinessInfo((res) => {
      if (res.code !== 0) return;
      setBusinessNumCheckDone(true);
      setProjectInfoForm({
        ...projectInfoForm,
        businessIdentificationNo: res.response.IDENTIFICATION_NO,
        businessRegistrationImg: res.response.FILE_NAME,
        registrationFileName: res.response.FILE_NAME,
      });
    });
  }, []);

  const aiIndTecHandler = (key, id) => {
    let tempArr = [...projectInfoForm[key]];
    if (tempArr.includes(id)) {
      tempArr = tempArr.filter((a) => {
        return a !== id;
      });
    } else {
      tempArr.push(id);
    }

    setProjectInfoForm({
      ...projectInfoForm,
      [key]: tempArr,
    });
  };

  const smartTagFormHandler = (key, id) => {
    let tempArr = [...smartTagForm[key]];
    if (tempArr.includes(id)) {
      tempArr = tempArr.filter((a) => {
        return a !== id;
      });
    } else {
      tempArr.push(id);
    }

    setSmartTagForm({
      ...smartTagForm,
      [key]: tempArr,
    });
  };

  const businessDocAttach = (e) => {
    if (e.target.files[0].size > 5242880) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "businessDoc/", (downloadURL, fileName) => {
      setProjectInfoForm({
        ...projectInfoForm,
        businessRegistrationImg: downloadURL,
        registrationFileName: fileName,
      });
    });
  };

  const projectDocAttach = (e) => {
    if (e.target.files[0].size > 5242880) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "projectDoc/", (downloadURL, fileName) => {
      setDescriptionForm({
        ...descriptionForm,
        projectFile: downloadURL,
        projectFileName: fileName,
      });
    });
  };

  const onProjectRequest = () => {
    let tempinterestedAiTask = {};
    let tempframework = {};
    interestedAiTaskData.forEach((item) => {
      tempinterestedAiTask = {
        ...tempinterestedAiTask,
        [item.id]: smartTagForm.interestedAiTask.includes(item.id),
      };
    });
    frameworkData.forEach((item) => {
      tempframework = {
        ...tempframework,
        [item.id]: smartTagForm.framework.includes(item.id),
      };
    });
    if (formSimplify) {
      const simpleReqBody = {
        ...baseInfoForm,
        ...descriptionForm,
        interestedAiTask: tempinterestedAiTask,
        framework: tempframework,
      };

      aiProjectRequestSimple(simpleReqBody, (res) => {
        if (res.code === 0) {
          openModal({
            type: "messageModal",
            contents: {
              title: "의뢰 완료",
              message: `작성된 프로젝트의 내용과 스마트 매칭에 대하여 안내 메일을 보내드렸어요.\n프로젝트 등록은 내부 검토 후 담당 매니저가 연락드릴게요.`,
              confirmText: "확인",
              confirmCallback: () => {
                navigate("/");
                closeModal();
              },
            },
          });
        } else {
          openModal({
            type: "messageModal",
            contents: {
              title: "요청에 실패했습니다",
              message:
                "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
              confirmText: "확인",
              confirmCallback: () => {
                closeModal();
              },
            },
          });
        }
      });
      return;
    }

    let tempAiIndustry = {};
    let tempAiSkill = {};

    aiIndustryData.forEach((item) => {
      tempAiIndustry = {
        ...tempAiIndustry,
        [item.id]: projectInfoForm.aiIndustry.includes(item.id),
      };
    });
    aiSkillData.forEach((item) => {
      tempAiSkill = {
        ...tempAiSkill,
        [item.id]: projectInfoForm.aiSkill.includes(item.id),
      };
    });

    let projectPeriod = projectInfoForm.projectPeriod;
    if (projectPeriod && projectPeriodType === "M") {
      projectPeriod = projectInfoForm.projectPeriod * 4;
    }

    let body = {
      ...baseInfoForm,
      ...projectInfoForm,
      ...descriptionForm,
      budgetMin: uncomma(projectInfoForm.budgetMin),
      budgetMax: uncomma(projectInfoForm.budgetMax),
      projectPeriod,
      aiIndustry: tempAiIndustry,
      aiSkill: tempAiSkill,
      interestedAiTask: tempinterestedAiTask,
      framework: tempframework,
      closeDate: dateFormat(projectInfoForm.closeDate),
    };

    if (projectInfoForm.periodNegotiable) {
      body.projectPeriod = 0;
    }

    if (projectInfoForm.payNegotiable) {
      body.payMethod = 0;
      body.budgetMin = 0;
      body.budgetMax = 0;
    }

    if (projectInfoForm.type === "PERSONAL") {
      delete body.businessIdentificationNo;
      delete body.businessRegistrationImg;
    }

    if (!editSeq) {
      aiProjectRequest(body, (res) => {
        if (res.code === 0) {
          openModal({
            type: "messageModal",
            contents: {
              title: "의뢰 완료",
              message: `작성된 프로젝트의 내용과 스마트 매칭에 대하여 안내 메일을 보내드렸어요.\n프로젝트 등록은 내부 검토 후 담당 매니저가 연락드릴게요.`,
              confirmText: "확인",
              confirmCallback: () => {
                navigate("/");
                closeModal();
              },
            },
          });
        } else {
          openModal({
            type: "messageModal",
            contents: {
              title: "요청에 실패했습니다",
              message:
                "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
              confirmText: "확인",
              confirmCallback: () => {
                closeModal();
              },
            },
          });
        }
      });
    } else {
      aiProjectModify(editSeq, body, (res) => {
        if (res.code === 0) {
          openModal({
            type: "messageModal",
            contents: {
              title: "수정 완료",
              message: `수정된 프로젝트의 내용과 스마트 매칭에 대하여 안내 메일을 보내드렸어요.\n프로젝트 수정은 내부 검토 후 담당 매니저가 연락드릴게요.`,
              confirmText: "확인",
              confirmCallback: () => {
                navigate("/");
                closeModal();
              },
            },
          });
        } else {
          openModal({
            type: "messageModal",
            contents: {
              title: "요청에 실패했습니다",
              message:
                "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
              confirmText: "확인",
              confirmCallback: () => {
                closeModal();
              },
            },
          });
        }
      });
    }
  };

  return (
    <>
      {/* 상단 의뢰 안내문 */}
      <div className="request-top">
        <div className="content-wrapper">
          <strong>프로젝트 의뢰</strong>
          <p>
            의뢰서 작성에 어려움이 있다면 간단하게 <br className="m-block" />
            메모를 남겨주세요, 담당 매니저가 도와드릴게요.
            <br />
            만약 구체적인 계획이 있으면 <br className="m-block" />
            프로젝트 정식 의뢰를 해 주세요.
          </p>

          <label className="input-checkbox">
            <input
              type="checkbox"
              checked={formSimplify}
              onChange={(e) => setFormSimplify(e.target.checked)}
            />
            <span className="text" style={{ fontWeight: "700" }}>
              {formSimplify
                ? "프로젝트 정식 의뢰하기"
                : "프로젝트 간편 의뢰하기"}
            </span>
            <span className="box"></span>
          </label>
        </div>
      </div>
      {/* 상단 의뢰 안내문 */}

      <div className="container">
        <div className="section">
          {/* 기본 정보 */}
          <div className="section-tit">기본 정보</div>
          <div className="col-2 m-none">
            <div>
              <div className="input-wrap mt-20">
                <label className="input-label">담당자</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerName || ""}
                  readOnly
                />
              </div>
              <div className="input-wrap mt-30">
                <label className="input-label">연락처</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerPhone || ""}
                  readOnly
                />
              </div>
            </div>
            <div className="ml-20">
              <div className="input-wrap mt-20">
                <label className="input-label">이메일</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerEmail || ""}
                  readOnly
                />
              </div>
              <div className="input-wrap mt-30">
                <label className="input-label">닉네임</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerNickname || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col-2 m-block">
            <div className="m-row">
              <div className="input-wrap col-sm">
                <label className="input-label">담당자</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerName || ""}
                  readOnly
                />
              </div>
              <div className="input-wrap">
                <label className="input-label">이메일</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerEmail || ""}
                  readOnly
                />
              </div>
            </div>
            <div className="ml-20">
              <div className="input-wrap">
                <label className="input-label">연락처</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerPhone || ""}
                  readOnly
                />
              </div>
              <div className="input-wrap">
                <label className="input-label">닉네임</label>
                <input
                  type="text"
                  className="input-text"
                  value={baseInfoForm.managerNickname || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
          {/* 기본 정보 */}

          {/* 프로젝트 정보 */}
          <div className="section-tit mt-100">프로젝트 정보</div>
          {formSimplify ? (
            <>
              {/* 간편 의뢰하기 */}
              <div className="input-wrap w-100">
                <label className="input-label mt-20">간단 설명</label>
                <textarea
                  className="input-textarea"
                  placeholder={`프로젝트 의뢰 전, 궁금한 점에 대해 자유롭게 남겨주세요.

당장 정식으로 의뢰할 프로젝트가 아니어도 좋아요! 
담당 매니저가 확인 후 빠르게 답변 드릴게요.
`}
                  rows="20"
                  value={descriptionForm.projectDetails || ""}
                  onChange={(e) => {
                    setDescriptionForm({
                      ...descriptionForm,
                      projectDetails: e.target.value,
                    });
                  }}
                  maxLength={1500}
                ></textarea>
              </div>
              <label className="input-file mt-10">
                <input
                  type="file"
                  onChange={(e) => {
                    projectDocAttach(e);
                  }}
                />
                <span
                  className={`input-text center ${
                    descriptionForm.projectFile ? "correct" : ""
                  }`}
                >
                  {descriptionForm.projectFile
                    ? fileNameFormat(descriptionForm.projectFileName)
                    : "프로젝트 파악에 도움이되는 기획안 or 제안서 등을 첨부해 주세요. "}
                </span>
              </label>
              {!descriptionForm.projectFile && (
                <p className="input-guide grey center">
                  zip, jpg, png, pdf, hwp, MS Office, 최대 5MB 첨부 가능
                </p>
              )}
              {/* 간편 의뢰하기 */}
            </>
          ) : (
            <>
              {/* 정식 의뢰하기 */}
              <div className="col-2 mt-20">
                <div>
                  <div className="input-wrap">
                    <label className="input-label">
                      규모 <Required>*</Required>
                    </label>
                    <div className="m-row">
                      <label className="input-radio">
                        <input
                          type="checkbox"
                          value="BUSINESS"
                          checked={projectInfoForm.type === "BUSINESS"}
                          onChange={(e) => {
                            authBusinessInfo((res) => {
                              if (res.code !== 0) {
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  type: e.target.value,
                                  businessIdentificationNo: null,
                                  businessRegistrationImg: null,
                                });
                              } else {
                                setBusinessNumCheckDone(true);
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  type: e.target.value,
                                  businessIdentificationNo:
                                    res.response.IDENTIFICATION_NO,
                                  businessRegistrationImg:
                                    res.response.FILE_NAME,
                                  registrationFileName: res.response.FILE_NAME,
                                });
                              }
                            });
                          }}
                        />
                        <span className="box">기업(사업자) 프로젝트</span>
                      </label>
                      <label
                        className="input-radio m-block"
                        style={{
                          marginLeft: 4,
                        }}
                      >
                        <input
                          type="checkbox"
                          value="PERSONAL"
                          checked={projectInfoForm.type === "PERSONAL"}
                          onChange={(e) => {
                            setProjectInfoForm({
                              ...projectInfoForm,
                              type: e.target.value,
                            });
                          }}
                        />
                        <span className="box">개인 프로젝트</span>
                      </label>
                    </div>
                    {projectInfoForm.type === "BUSINESS" && (
                      <div
                        className="col-2"
                        style={isMobile ? { marginTop: 14 } : { marginTop: 10 }}
                      >
                        <div className="flex16">
                          <input
                            type="text"
                            className={`input-text ${
                              businessNumError ? "error" : ""
                            }`}
                            placeholder="사업자 번호를 입력해 주세요."
                            value={
                              projectInfoForm.businessIdentificationNo || ""
                            }
                            onChange={(e) => {
                              setProjectInfoForm({
                                ...projectInfoForm,
                                businessIdentificationNo: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              setBusinessNumError(false);
                              const regex = /^[0-9]+$/;
                              if (
                                e.target.value.length > 0 &&
                                !regex.test(e.target.value)
                              ) {
                                setBusinessNumError(true);
                                return;
                              }
                            }}
                            maxLength={10}
                          />
                          {businessNumError && (
                            <p className="input-guide error">
                              올바른 사업자 번호가 아니에요.
                            </p>
                          )}
                        </div>
                        <div
                          className="ml-10"
                          style={isMobile ? { marginTop: 14 } : {}}
                        >
                          <label className="input-file">
                            <input
                              type="file"
                              onChange={(e) => businessDocAttach(e)}
                            />
                            <span
                              className={`input-text center ${
                                projectInfoForm.businessRegistrationImg
                                  ? "correct"
                                  : ""
                              }`}
                            >
                              {projectInfoForm.businessRegistrationImg
                                ? fileNameFormat(
                                    projectInfoForm.registrationFileName
                                  )
                                : "사업자등록증 첨부"}
                            </span>
                          </label>
                          {!projectInfoForm.businessRegistrationImg && (
                            <p className="input-guide grey">
                              &#40;jpg, png, pdf 최대 5MB 첨부 가능&#41;
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ml-20 m-none">
                  <div className="input-wrap">
                    <label className="input-label">&nbsp;</label>
                    <label className="input-radio">
                      <input
                        type="checkbox"
                        value="PERSONAL"
                        checked={projectInfoForm.type === "PERSONAL"}
                        onChange={(e) => {
                          setProjectInfoForm({
                            ...projectInfoForm,
                            type: e.target.value,
                          });
                        }}
                      />
                      <span className="box">개인 프로젝트</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-2 mt-30">
                <div>
                  <div className="col-2">
                    <div className="input-wrap flex16">
                      <label
                        className="input-label"
                        style={isMobile ? { marginTop: 30 } : {}}
                      >
                        프로젝트명 <Required>*</Required>
                      </label>
                      <input
                        type="text"
                        className="input-text type2"
                        placeholder="프로젝트 이름을 입력해 주세요."
                        maxLength={30}
                        value={projectInfoForm.projectName || ""}
                        onChange={(e) => {
                          setProjectInfoForm({
                            ...projectInfoForm,
                            projectName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="ml-10">
                      <div className="input-wrap">
                        <div className="input-switch--wrap right-top">
                          <span className="text">협의하기</span>
                          <label className="input-switch">
                            <input
                              type="checkbox"
                              checked={projectInfoForm.periodNegotiable}
                              onChange={(e) => {
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  periodNegotiable: e.target.checked,
                                  projectPeriod: null,
                                });
                                setProjectPeriodType("W");
                              }}
                            />
                            <span className="switch"></span>
                          </label>
                        </div>
                        <label
                          className="input-label"
                          style={isMobile ? { marginTop: 19 } : {}}
                        >
                          기간 <Required>*</Required>
                        </label>
                        <div className="col-2 m-row">
                          <div>
                            <Select
                              className={["project-request"]}
                              option={[
                                { id: "M", name: "개월" },
                                { id: "W", name: "주" },
                              ]}
                              selected={projectPeriodType}
                              setSelect={setProjectPeriodType}
                              disabled={projectInfoForm.periodNegotiable}
                            />
                          </div>
                          <div className="ml-10">
                            <div className="input-number">
                              <input
                                type="number"
                                className="input-text"
                                placeholder="기간"
                                value={projectInfoForm.projectPeriod || ""}
                                disabled={projectInfoForm.periodNegotiable}
                                onChange={(e) => {
                                  setProjectInfoForm({
                                    ...projectInfoForm,
                                    projectPeriod: e.target.value,
                                  });
                                }}
                              />
                              <button
                                onClick={() => {
                                  setProjectInfoForm({
                                    ...projectInfoForm,
                                    projectPeriod:
                                      ++projectInfoForm.projectPeriod,
                                  });
                                }}
                                type="button"
                                className="btn up"
                              />
                              <button
                                onClick={() => {
                                  if (projectInfoForm.projectPeriod <= 0)
                                    return;
                                  setProjectInfoForm({
                                    ...projectInfoForm,
                                    projectPeriod:
                                      --projectInfoForm.projectPeriod,
                                  });
                                }}
                                type="button"
                                className="btn down"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 mt-30">
                    <div className="input-wrap flex16">
                      <label
                        className="input-label type3"
                        style={isMobile ? { marginTop: 19 } : {}}
                      >
                        예산 및 급여 방식 <Required>*</Required>
                        <span
                          className="guide"
                          style={isMobile ? { marginTop: 4 } : {}}
                        >
                          VAT를 제외한 금액으로 책정해 주세요.
                        </span>
                      </label>
                      {isMobile && (
                        <div className="input-switch--wrap right-top">
                          <span className="text">제안받기</span>
                          <label className="input-switch">
                            <input
                              type="checkbox"
                              checked={projectInfoForm.payNegotiable}
                              onChange={(e) => {
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  payNegotiable: e.target.checked,
                                  payMethod: null,
                                  budgetMin: null,
                                  budgetMax: null,
                                });
                              }}
                            />
                            <span className="switch" />
                          </label>
                        </div>
                      )}
                      <div className="col-2 m-row">
                        <div>
                          <div className="input-number">
                            {Boolean(projectInfoForm.budgetMin) && (
                              <p className="budget-replace" onClick={() => {}}>
                                {projectInfoForm.budgetMin}만 원
                              </p>
                            )}
                            <input
                              type="text"
                              className={`input-text ${
                                budgetMinError ? "error" : ""
                              }`}
                              placeholder="최소 금액"
                              value={projectInfoForm.budgetMin || ""}
                              maxLength={7}
                              disabled={projectInfoForm.payNegotiable}
                              onChange={(e) => {
                                const value = uncomma(e.target.value);
                                if (value > 100000) {
                                  setBudgetMinError(true);
                                } else {
                                  setBudgetMinError(false);
                                }
                                if (value < 0) return;
                                setBudgetMINMaxError(false);
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMin: comma(value),
                                });
                              }}
                              onBlur={() => {
                                if (
                                  Number(uncomma(projectInfoForm.budgetMin)) >
                                  100000
                                ) {
                                  setBudgetMinError(true);
                                } else {
                                  setBudgetMinError(false);
                                }
                                if (
                                  Number(
                                    uncomma(projectInfoForm.budgetMax) <= 0
                                  )
                                )
                                  return;
                                if (
                                  uncomma(projectInfoForm.budgetMin) >
                                  uncomma(projectInfoForm.budgetMax)
                                ) {
                                  setBudgetMINMaxError(true);
                                }
                              }}
                            />
                            <button
                              tabIndex="-1"
                              type="button"
                              className="btn up"
                              onClick={() => {
                                if (projectInfoForm.payNegotiable) return;
                                const value = Number(
                                  uncomma(projectInfoForm.budgetMin)
                                );
                                if (value + 1 > 100000) return;
                                if (value < 0) return;
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMin: comma(value + 1),
                                });
                                if (!projectInfoForm.budgetMax) {
                                  setBudgetMINMaxError(false);
                                  return;
                                }
                                if (
                                  value - 1 >
                                  uncomma(projectInfoForm.budgetMax)
                                ) {
                                  setBudgetMINMaxError(true);
                                } else {
                                  setBudgetMINMaxError(false);
                                }
                              }}
                            />
                            <button
                              tabIndex="-1"
                              type="button"
                              className="btn down"
                              onClick={() => {
                                if (projectInfoForm.payNegotiable) return;
                                const value = Number(
                                  uncomma(projectInfoForm.budgetMin)
                                );
                                if (value <= 0) return;
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMin:
                                    comma(value - 1) !== "0"
                                      ? comma(value - 1)
                                      : "",
                                });
                                if (!projectInfoForm.budgetMax) {
                                  setBudgetMINMaxError(false);
                                  return;
                                }
                                if (
                                  value - 1 >
                                  uncomma(projectInfoForm.budgetMax)
                                ) {
                                  setBudgetMINMaxError(true);
                                } else {
                                  setBudgetMINMaxError(false);
                                }
                              }}
                            />
                          </div>
                          {budgetMinError && (
                            <p className="input-guide error">
                              최대 금액 100,000만 원
                            </p>
                          )}
                        </div>
                        <div className="text">~</div>
                        <div>
                          <div className="input-number">
                            {Boolean(projectInfoForm.budgetMax) && (
                              <p className="budget-replace" onClick={() => {}}>
                                {projectInfoForm.budgetMax}만 원
                              </p>
                            )}
                            <input
                              type="text"
                              className={`input-text ${
                                budgetMaxError || budgetMINMaxError
                                  ? "error"
                                  : ""
                              }`}
                              placeholder="최대 금액"
                              value={projectInfoForm.budgetMax || ""}
                              maxLength={7}
                              disabled={projectInfoForm.payNegotiable}
                              onChange={(e) => {
                                const value = uncomma(e.target.value);
                                if (value > 100000) {
                                  setBudgetMaxError(true);
                                } else {
                                  setBudgetMaxError(false);
                                }
                                if (value < 0) return;
                                setBudgetMINMaxError(false);
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMax: comma(value),
                                });
                              }}
                              onBlur={() => {
                                if (
                                  Number(uncomma(projectInfoForm.budgetMax)) >
                                  100000
                                ) {
                                  setBudgetMaxError(true);
                                } else {
                                  setBudgetMaxError(false);
                                }
                                if (
                                  Number(
                                    uncomma(projectInfoForm.budgetMax) <= 0
                                  )
                                )
                                  return;
                                if (
                                  Number(uncomma(projectInfoForm.budgetMin)) >
                                  Number(uncomma(projectInfoForm.budgetMax))
                                ) {
                                  setBudgetMINMaxError(true);
                                }
                              }}
                            />
                            <button
                              tabIndex="-1"
                              type="button"
                              className="btn up"
                              onClick={() => {
                                if (projectInfoForm.payNegotiable) return;
                                const value = Number(
                                  uncomma(projectInfoForm.budgetMax)
                                );
                                if (value < 0) return;
                                if (value + 1 > 100000) return;
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMax: comma(value + 1),
                                });
                                if (!projectInfoForm.budgetMin) {
                                  setBudgetMINMaxError(false);
                                  return;
                                }
                                if (
                                  uncomma(projectInfoForm.budgetMin) >
                                  value + 1
                                ) {
                                  setBudgetMINMaxError(true);
                                } else {
                                  setBudgetMINMaxError(false);
                                }
                              }}
                            />
                            <button
                              tabIndex="-1"
                              type="button"
                              className="btn down"
                              onClick={() => {
                                if (projectInfoForm.payNegotiable) return;
                                const value = Number(
                                  uncomma(projectInfoForm.budgetMax)
                                );
                                if (value <= 0) return;
                                if (value - 1 > 100000) {
                                  setBudgetMaxError(true);
                                } else {
                                  setBudgetMaxError(false);
                                }
                                setProjectInfoForm({
                                  ...projectInfoForm,
                                  budgetMax:
                                    comma(value - 1) !== "0"
                                      ? comma(value - 1)
                                      : "",
                                });
                                if (!projectInfoForm.budgetMin) {
                                  setBudgetMINMaxError(false);
                                  return;
                                }
                                if (
                                  uncomma(projectInfoForm.budgetMin) >
                                  value + 1
                                ) {
                                  setBudgetMINMaxError(true);
                                } else {
                                  setBudgetMINMaxError(false);
                                }
                              }}
                            />
                          </div>
                          {budgetMaxError && (
                            <p className="input-guide error">
                              최대 금액 100,000만 원
                            </p>
                          )}
                          {budgetMINMaxError && (
                            <p className="input-guide error">
                              금액을 확인해 주세요
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="ml-10">
                      <div className="input-wrap">
                        {!isMobile && (
                          <div className="input-switch--wrap right-top">
                            <span className="text">제안받기</span>
                            <label className="input-switch">
                              <input
                                type="checkbox"
                                checked={projectInfoForm.payNegotiable}
                                onChange={(e) => {
                                  setProjectInfoForm({
                                    ...projectInfoForm,
                                    payNegotiable: e.target.checked,
                                    payMethod: null,
                                    budgetMin: null,
                                    budgetMax: null,
                                  });
                                }}
                              />
                              <span className="switch" />
                            </label>
                          </div>
                        )}
                        <label
                          className="input-label"
                          style={isMobile ? {} : { height: 16 }}
                        ></label>
                        <Select
                          disabled={projectInfoForm.payNegotiable}
                          className={["project-request"]}
                          option={[
                            { id: null, name: "지급 방식 선택" },
                            { id: "SALARY", name: "월급" },
                            { id: "WAGE", name: "주급" },
                            { id: "REWARD", name: "프로젝트 완료 후" },
                          ]}
                          selected={projectInfoForm.payMethod}
                          setSelect={(value) => {
                            setProjectInfoForm({
                              ...projectInfoForm,
                              payMethod: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-2 mt-30">
                    <div className="input-wrap">
                      <label className="input-label">
                        진행 방식(근무 형태) <Required>*</Required>
                      </label>
                      <Select
                        className={["project-request"]}
                        option={[
                          { id: null, name: "진행 방식을 선택해 주세요." },
                          { id: "BOTH", name: "상주, 원격 모두" },
                          { id: "RESIDENT", name: "상주 근무" },
                          { id: "REMOTE", name: "원격 근무" },
                        ]}
                        selected={projectInfoForm.workMethod}
                        setSelect={(value) => {
                          setProjectInfoForm({
                            ...projectInfoForm,
                            workMethod: value,
                          });
                        }}
                      />
                    </div>
                    <div className="ml-10">
                      <div className="input-wrap">
                        <label className="input-label">
                          의뢰 마감일 <Required>*</Required>
                        </label>
                        <DatePicker
                          selected={projectInfoForm.closeDate}
                          onChange={(date) => {
                            setProjectInfoForm({
                              ...projectInfoForm,
                              closeDate: date,
                            });
                          }}
                          minDate={threeDaysLater}
                          dateFormat="yyyy-MM-dd"
                          dateFormatCalendar="yyyy년 MM월"
                          locale={ko}
                          customInput={
                            <button
                              type="button"
                              className="input-text input-date"
                            >
                              {dateFormat(projectInfoForm?.closeDate) ||
                                "의뢰 마감일을 선택해 주세요."}
                            </button>
                          }
                          placeholderText={"의뢰 마감일을 선택해 주세요."}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="input-wrap d-flex mt-30"
                    style={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    <label className="input-label">
                      데이터 보유 현황 <Required>*</Required>
                    </label>
                    <div
                      className="type2"
                      style={{ marginTop: "-5px", marginLeft: "20px" }}
                    >
                      <label className="input-checkbox type2">
                        <input
                          type="radio"
                          name="radio2"
                          checked={projectInfoForm.haveData}
                          onChange={(e) =>
                            setProjectInfoForm({
                              ...projectInfoForm,
                              haveData: e.target.checked && true,
                            })
                          }
                        />
                        <span className="box"></span>
                        <span className="text">보유하고 있음</span>
                      </label>
                      <label className="input-checkbox type2">
                        <input
                          type="radio"
                          name="radio2"
                          checked={projectInfoForm.haveData === false}
                          onChange={(e) =>
                            setProjectInfoForm({
                              ...projectInfoForm,
                              haveData: e.target.checked && false,
                            })
                          }
                        />
                        <span className="box"></span>
                        <span className="text">보유중인 데이터 없음</span>
                      </label>
                    </div>
                  </div>
                  <div className="input-wrap mt-30">
                    <label
                      className="input-label type2"
                      style={
                        isMobile ? { marginTop: 30, marginBottom: 16 } : {}
                      }
                    >
                      산업 분야 <Required>*</Required>
                      <span className="guide">
                        최소 1개 이상의 전문 분야를 선택해 주세요.
                      </span>
                    </label>
                    <div className="input-checkbox--wrap">
                      {aiIndustryData.map((item, i) => (
                        <label
                          className="input-checkbox"
                          key={`input-checkbox-aiInd__${i}`}
                        >
                          <input
                            type="checkbox"
                            checked={projectInfoForm.aiIndustry.includes(
                              item.id
                            )}
                            onChange={() => {
                              aiIndTecHandler("aiIndustry", item.id);
                            }}
                            style={{ display: "none" }}
                          />
                          <span className="label">{item.name}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="input-wrap mt-30">
                    <label
                      className="input-label type2"
                      style={isMobile ? { marginTop: 18 } : {}}
                    >
                      기술 분야 <Required>*</Required>
                      <span className="guide">
                        최소 1개 이상의 프로젝트 관련 분야를 선택해 주세요.
                      </span>
                    </label>
                    <div className="input-checkbox--wrap">
                      {aiSkillData.map((item, i) => (
                        <label
                          className="input-checkbox"
                          key={`input-checkbox-aiTec__${i}`}
                        >
                          <input
                            type="checkbox"
                            checked={projectInfoForm.aiSkill.includes(item.id)}
                            onChange={() => {
                              aiIndTecHandler("aiSkill", item.id);
                            }}
                            style={{ display: "none" }}
                          />
                          <span className="label">{item.name}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="ml-20">
                  <div className="input-wrap">
                    <label
                      className="input-label"
                      style={isMobile ? { marginTop: 18 } : {}}
                    >
                      상세 설명
                    </label>
                    <textarea
                      className="input-textarea"
                      placeholder={`프로젝트에 대해 상세히 설명해 주세요.
작성이 어려우실 경우 아래 기본 양식을 참고해 주세요!

<기본 양식>
1. 프로젝트 간단 소개

2. 현재까지 프로젝트 진행사항

3. 세부적인 업무 범위 (*모델 정확도 포함)

4. 데이터 상태(*규모, 가공 유무 등) 

5. 파트너에게 바라는 점`}
                      rows="20"
                      value={descriptionForm.projectDetails || ""}
                      onChange={(e) => {
                        setDescriptionForm({
                          ...descriptionForm,
                          projectDetails: e.target.value,
                        });
                      }}
                      maxLength={1500}
                    ></textarea>
                  </div>
                  <label
                    className="input-file"
                    style={isMobile ? { marginTop: 16 } : { marginTop: 10 }}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        projectDocAttach(e);
                      }}
                    />
                    <span
                      className={`input-text center ${
                        descriptionForm.projectFile ? "correct" : ""
                      }`}
                    >
                      {descriptionForm.projectFile
                        ? fileNameFormat(descriptionForm.projectFileName)
                        : "프로젝트 파악에 도움이되는 기획안 or 제안서 등을 첨부해 주세요. "}
                    </span>
                  </label>
                  {!descriptionForm.projectFile && (
                    <p
                      className={`input-guide grey ${isMobile ? "" : "center"}`}
                    >
                      zip, jpg, png, pdf, hwp, MS Office, 최대 5MB 첨부 가능
                    </p>
                  )}
                </div>
              </div>
              {/* 정식 의뢰하기 */}
            </>
          )}
          {/* 프로젝트 정보 */}

          {/* 기술, 프레임워크 키워드 */}
          <div className="section-tit mt-100">스마트 매칭을 높여주는 정보</div>
          <div className="col-2 mt-20">
            <div style={{ marginRight: 20 }}>
              <div className="input-wrap">
                <label
                  className="input-label"
                  style={isMobile ? { marginTop: 16, marginBottom: 16 } : {}}
                >
                  관심 태스크 및 기술
                </label>
                <div className="input-checkbox--wrap">
                  {interestedAiTaskData.map((item, i) => (
                    <label
                      className="input-checkbox"
                      key={`input-checkbox-aitask__${i}`}
                    >
                      <input
                        type="checkbox"
                        checked={smartTagForm.interestedAiTask.includes(
                          item.id
                        )}
                        onChange={() => {
                          smartTagFormHandler("interestedAiTask", item.id);
                        }}
                        style={{ display: "none" }}
                      />
                      <span className="label type2">{item.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="input-wrap">
                <label
                  className="input-label"
                  style={isMobile ? { marginTop: 18, marginBottom: 16 } : {}}
                >
                  프로그래밍 프레임워크
                </label>
                <div className="input-checkbox--wrap">
                  {frameworkData.map((item, i) => (
                    <label
                      className="input-checkbox"
                      key={`input-checkbox-aitask__${i}`}
                    >
                      <input
                        type="checkbox"
                        checked={smartTagForm.framework.includes(item.id)}
                        onChange={() => {
                          smartTagFormHandler("framework", item.id);
                        }}
                        style={{ display: "none" }}
                      />
                      <span className="label type2">{item.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* 기술, 프레임워크 키워드 */}

          <div
            className="btn-wrap"
            style={isMobile ? { marginTop: 28 } : { marginTop: 100 }}
          >
            <button
              type="button"
              className="btn btn-lg btn-black type2"
              disabled={!allIpuntDone}
              onClick={() => {
                onProjectRequest();
              }}
            >
              프로젝트 의뢰하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiProjectRequest;

const Required = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #df2e47;
`;
