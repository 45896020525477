import React, { useEffect, useState } from 'react';
import useModal from 'hooks/useModal';
import { aiProjectAReport, aiProjectQuestionReport } from 'services/aiproject';
import { topicDetailReport, topicDetailReplyReport } from 'services/topic';

const ReportModal = ({ reportType, id }) => {
	const { openModal, closeModal } = useModal();
	const [reasonRadio, setReasonRadio] = useState('');
	const [reasonText, setReasonText] = useState('');

	useEffect(() => {
		if (reasonRadio === 'ETC') return;
		setReasonText('')
	}, [reasonRadio])

	const reportPost = () => {
		if (!reasonRadio) return;
		if (reasonRadio === 'ETC' && !reasonText.length) return;

		const body = {
			reason: reasonRadio,
			etcDetails: reasonText
		}
		if (reportType === 'project_post_q') {
			aiProjectQuestionReport(id, body, (res) => {
				if (res.code === 0) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 완료',
							message: '신고 처리가 완료 되었어요.\n내부 검토 후 가이드 위반이 확인되면 삭제 처리될 예정이에요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else if (res.code === 400) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 실패',
							message: '한 게시물 당 신고는 한 번만 가능해요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else {
					openModal({
						type: 'messageModal',
						contents: {
							title: '요청에 실패했습니다',
							message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							},
						}
					});
				}
			})
		}

		if (reportType === 'project_post_a') {
			aiProjectAReport(id, body, (res) => {
				if (res.code === 0) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 완료',
							message: '신고 처리가 완료 되었어요.\n내부 검토 후 가이드 위반이 확인되면 삭제 처리될 예정이에요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else if (res.code === 400) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 실패',
							message: '한 게시물 당 신고는 한 번만 가능해요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else {
					openModal({
						type: 'messageModal',
						contents: {
							title: '요청에 실패했습니다',
							message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							},
						}
					});
				}
			})
		}


		if (reportType === 'topic_post_r') {
			topicDetailReplyReport(id, body, (res) => {
				if (res.code === 0) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 완료',
							message: '신고 처리가 완료 되었어요.\n내부 검토 후 가이드 위반이 확인되면 삭제 처리될 예정이에요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else if (res.code === 400) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 실패',
							message: '한 게시물 당 신고는 한 번만 가능해요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else {
					openModal({
						type: 'messageModal',
						contents: {
							title: '요청에 실패했습니다',
							message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							},
						}
					});
				}
			})
		}

		if (reportType === 'topic_post') {
			topicDetailReport(id, body, (res) => {
				if (res.code === 0) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 완료',
							message: '신고 처리가 완료 되었어요.\n내부 검토 후 가이드 위반이 확인되면 삭제 처리될 예정이에요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else if (res.code === 400) {
					openModal({
						type: 'messageModal',
						contents: {
							title: '신고 실패',
							message: '한 게시물 당 신고는 한 번만 가능해요.',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							}
						}
					});
				} else {
					openModal({
						type: 'messageModal',
						contents: {
							title: '요청에 실패했습니다',
							message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
							confirmText: '확인',
							confirmCallback: () => {
								closeModal();
							},
						}
					});
				}
			})
		}

	}

	return (
		<div className="modal show">
			<div className="modal-dim"></div>
			<div className="modal-con bg-black" >
				<div className="modal-tit left">게시물 신고</div>
				<div className="checkbox-list">
					<label className="input-checkbox">
						<input type="radio" name="report" value="BLAME" checked={reasonRadio === "BLAME"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">비방/욕설</span>
					</label>
					<label className="input-checkbox">
						<input type="radio" name="report" value="FAKE" checked={reasonRadio === "FAKE"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">허위사실</span>
					</label>
					<label className="input-checkbox">
						<input type="radio" name="report" value="PRIVACY_LEAK" checked={reasonRadio === "PRIVACY_LEAK"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">개인정보 노출</span>
					</label>
					<label className="input-checkbox">
						<input type="radio" name="report" value="LEWD" checked={reasonRadio === "LEWD"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">음란성</span>
					</label>
					<label className="input-checkbox">
						<input type="radio" name="report" value="SPAM" checked={reasonRadio === "SPAM"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">게시글 도배</span>
					</label>
					<label className="input-checkbox">
						<input type="radio" name="report" value="AD" checked={reasonRadio === "AD"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">부적절한 홍보</span>
					</label>
					<label className="input-checkbox" >
						<input type="radio" name="report" value="ETC" checked={reasonRadio === "ETC"} onChange={(e) => setReasonRadio(e.target.value)} />
						<span className="box"></span>
						<span className="text">기타</span>
					</label>
					<textarea className="input-textarea" placeholder="내용을 입력해 주세요." value={reasonText} onChange={(e) => setReasonText(e.target.value)} disabled={reasonRadio !== 'ETC'}></textarea>
				</div>
				<div className="btn-wrap" >
					<button onClick={() => reportPost()} type="button" className="btn btn-half btn-primary">신고하기</button>
					<button onClick={() => closeModal()} type="button" className="btn btn-half btn-grey">돌아가기</button>
				</div>
			</div>
		</div>
	)
}

export default ReportModal