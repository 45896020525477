import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
const CannonicalTag = ({ title, desc, imgSrc }) => {
  const [currentURL, setUrl] = useState(window.location.href);
  const [src, setSrc] = useState(imgSrc);
  useEffect(() => {
    setUrl(currentURL);
    setSrc(imgSrc);
    console.log(currentURL, imgSrc);
  }, [currentURL, imgSrc]);
  return (
    <Helmet>
      <link rel="canonical" href={currentURL}></link>
      <meta property="og:image" content={src} />
      <meta property="og:url" content={currentURL} />
      <meta name="twitter:image" content={src} />
    </Helmet>
  );
};

export default CannonicalTag;
