import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { dateFormatType2NoTime, highlightIncludedText } from 'utils/utils';

const Search = ({ data }) => {
	const navigate = useNavigate();

	const searchCategoryName = (category, dataName) => {
		if (dataName === '공지사항') {
			switch (category) {
				case 'NOTICE':
					return '공지'
				case 'UPDATE':
					return '업데이트'
				case 'EVENT':
					return '이벤트'
				case 'ETC':
					return '기타'
				default:
					return;
			}
		} else if (dataName === 'FAQ') {
			switch (category) {
				case 'CLIENT':
					return '클라이언트'
				case 'HEROES':
					return '히어로즈'
				case 'PROJECT':
					return '프로젝트'
				case 'MEETING':
					return '미팅'
				case 'CONTRACT':
					return '계약'
				case 'FEE':
					return '요금'
				default:
					return;
			}
		} else if (dataName === 'AI TALK') {
			switch (category) {
				case 'FREE':
					return 'AI 기술 · 트렌드';
				case 'STUDY':
					return 'AI 스터디 · 강의';
				case 'INDUSTRY':
					return 'AI 커리어 · 이직';
				case 'ARCHIVE':
					return 'AI 기술 · 트렌드';
				default:
					return '';
			}
		} else if (dataName === 'AI 블로그') {
			switch (category) {
				case 'INTERVIEW':
					return '인터뷰';
				case 'TREND':
					return '트렌드';
				case 'VIDEO':
					return '동영상';
				case 'IMAGE':
					return '이미지';
				case 'SOUND':
					return '음성';
				case 'TEXT':
					return '텍스트';
				case 'BIG_DATA':
					return '빅데이터';
				case 'ETC':
					return '기타';
				case 'NOTICE':
					return '공지';
				default:
					return '';
			}
		}
	}

	return (
		<>
			{data?.total ?
				<>
					<div className="community-search--guide">
						총 <span className="txt-c--sub">{data.total}건</span>의 검색결과를 찾았어요.
					</div>
					<div className="community-search">
						{
							Boolean(data.data.length) && data.data.map((item) => (
								<React.Fragment key={`search-list-${item.dataName}`}>
									<div className="community-search--tit">
										<span>{item.dataName} <span className="txt-c--sub">{item.total}건</span></span>
										<button type="button" className="btn" onClick={() => navigate(item.url)}>더 보기 &gt;</button>
									</div>
									<ul className="list">
										{item.list.map((listItem) => (
											<li className="list-item" key={`search-list-item-${listItem.SEQ}`}
												onClick={() => {
													navigate(item.url + '/' + listItem.SEQ);
												}}>
												<div className="left">{searchCategoryName(listItem.CATEGORY, item.dataName)}</div>
												<div className="center">
													<div className="top">{highlightIncludedText(listItem.TITLE, item.keyword)} {(item.dataName !== '공지사항' && item.dataName !== 'FAQ') && `(${listItem.TOTAL_REPLY || 0})`}</div>
													<div className="bottom">{highlightIncludedText(listItem.CONTENTS, item.keyword)}</div>
												</div>
												<div className="right">{dateFormatType2NoTime(listItem.CREATED_AT)}</div>
											</li>
										))}
									</ul>
								</React.Fragment>

							))}
					</div>
				</>
				:
				<div className="community-search--none">
					검색 결과를 찾지 못했어요. <br className="m-block" />다른 단어로 검색해 보시겠어요?
					<p>
						철자나 맞춤법에 오류가 있는지 확인해 주세요. <br />
						여러 단어로 나누거나 보다 일반적인 단어로 검색해 주세요.
					</p>
				</div>
			}
		</>
	)
}

export default Search