import api from "api";

export const getHerosList = (params, body, callback) => {
  api
    .post("/heros/view/list", body, {
      params,
    })
    .then((res) => {
      callback(res);
    });
};

export const getHerosPowerOnList = (callback) => {
  api.get("/heros/power-on").then((res) => {
    callback(res);
  });
};

export const getHerosDetail = (seq, params, callback) => {
  api.get(`/heros/view/${seq}`, { params }).then((res) => {
    callback(res);
  });
};

export const userHerosDibsChange = (heros, callback) => {
  api
    .post(`/heros-dibs/auth/change`, null, { params: { heros } })
    .then((res) => {
      callback(res);
    });
};

export const getHerosDibsList = (callback) => {
  api.get(`/heros-dibs/auth/view/list`).then((res) => {
    callback(res);
  });
};
// 신규 히어로즈 지원 API
export const partnerApply = (roleType, type, body, callback) => {
  api
    .post("/heros/auth/apply", body, {
      params: { changeUserRoleType: roleType, isOnlyDefault: type },
    })
    .then((res) => {
      callback(res);
    });
};

export const getClientCount = (seq, callback) => {
  api.get(`/heros/auth/hits/${seq}`).then((res) => {
    callback(res);
  });
};

export const userContact = (seq, body, callback) => {
  api.put(`/heros/auth/contact/${seq}?contents=${body}`).then((res) => {
    callback(res);
  });
};

export const getBannerList = (type, limit, callback) => {
  api
    .get(`/heros/banners?heroesBannerType=${type}&limit=${limit}`)
    .then((res) => {
      callback(res);
    });
};

export const userAddInfoCheck = (callback) => {
  api.get(`/heros/auth/add-info/check`).then((res) => callback(res));
};

export const userAddInfo = (body, callback) => {
  api.post(`/heros/auth/add-info`, body).then((res) => callback(res));
};
