import React from "react";
import CareerCard from "./CareerCard";
import EducationCard from "./EducationCard";
import styled from "styled-components";
const ArticleBox = ({ title, children }) => {
  return (
    <StyledArticleBox>
      <label>{title}</label>
      {children}
    </StyledArticleBox>
  );
};

const referenceBox = ({ careerList, eduList, role, ...restProps }) => {
  const filteredCareerList = careerList?.filter(
    (item) => item.COMPANY_NAME.length !== 0 && item.PERFORMANCE.length !== 0
  );
  const filteredEduList = eduList?.filter(
    (item) => item.SCHOOL_NAME.length !== 0 && item.EDUCATION_INFO.length !== 0
  );

  return (
    <>
      {careerList && (
        <ArticleBox
          title={
            role === "ROLE_HEROS_PERSONAL"
              ? "경력・프로젝트 레퍼런스"
              : "프로젝트 레퍼런스"
          }
        >
          <div style={{ marginTop: "10px" }}>
            {filteredCareerList.map((card, idx) => (
              <div key={`${card.seq}_${idx}`}>
                <CareerCard
                  isFirst={idx === 0}
                  seq={card.seq}
                  data={card}
                  handleCareerDelete={() => console.log(card.seq)}
                  handleCareerData={() => console.log("ss")}
                />
              </div>
            ))}
          </div>
        </ArticleBox>
      )}
      {eduList && (
        <ArticleBox title="학력 ">
          {filteredEduList.map((card, idx) => (
            <div key={`${card.seq}_${idx}`}>
              <EducationCard
                isFirst={idx === 0}
                seq={card.seq}
                data={card}
                handleEduDelete={() => console.log(card.seq)}
                handleEduData={() => console.log("ss")}
              />
            </div>
          ))}
        </ArticleBox>
      )}
    </>
  );
};

export default referenceBox;
const StyledArticleBox = styled.div`
  margin-top: 20px;
  label {
    color: var(--mono-m-black01, #333);
    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3px; /* 133.125% */
  }
  article {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 15.5px 18px;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    color: var(--mono-m-black01, #333);
    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.6px; /* 132.857% */
    &.flex-wrap {
      display: flex;
      padding: 15px 18px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 6px;
      align-self: stretch;
      flex-wrap: wrap;
    }
    & .info-input {
      display: flex;
      align-items: center;
      width: 50%;
      /* Body 500 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18.6px; /* 132.857% */
      label {
        color: var(--mono-m-gray01, #999);
        font-size: 14px;
        margin-right: 6px;
      }
      span {
      }
      img {
        margin-right: 6px;
      }
    }
  }
`;
