import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSolutionList,
  getSolutionVIPList,
  userSolutionPurchase,
} from "services/solution";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";

import MultiSelect from "components/common/MultiSelect";
import Pagination from "components/common/Pagination";
import useViewCheck from "hooks/useViewCheck";

const orderList = [
  {
    id: 1,
    name: "최신순",
  },
  {
    id: 2,
    name: "낮은 가격 순",
  },
  {
    id: 3,
    name: "높은 가격 순",
  },
  {
    id: 4,
    name: "판매량 순",
  },
  {
    id: 5,
    name: "평점 높은 순",
  },
  {
    id: 6,
    name: "후기 많은 순",
  },
];

const aiIndOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "교육",
    apiName: "education",
    state: true,
  },
  {
    name: "서비스",
    apiName: "service",
    state: true,
  },
  {
    name: "제조, 화학, 에너지",
    apiName: "manufacturing",
    state: true,
  },

  {
    name: "자동차",
    apiName: "vehicle",
    state: true,
  },
  {
    name: "판매, 유통",
    apiName: "sales",
    state: true,
  },
  {
    name: "미디어, 엔터테인먼트",
    apiName: "media",
    state: true,
  },
  {
    name: "관광, 스포츠, 레저",
    apiName: "tour",
    state: true,
  },
  {
    name: "부동산, 건설, 건축",
    apiName: "construct",
    state: true,
  },
  {
    name: "금융",
    apiName: "finance",
    state: true,
  },
  {
    name: "의료, 제약, 바이오, 헬스케어",
    apiName: "medical",
    state: true,
  },
  {
    name: "IT, 통신",
    apiName: "it",
    state: true,
  },
  {
    name: "정부, 기관",
    apiName: "government",
    state: true,
  },
  {
    name: "식품",
    apiName: "food",
    state: true,
  },
  {
    name: "농업",
    apiName: "agriculture",
    state: true,
  },
  {
    name: "게임",
    apiName: "game",
    state: true,
  },
  {
    name: "기타",
    apiName: "etc",
    state: true,
  },
];

const aiTecOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "영상",
    apiName: "video",
    state: true,
  },
  {
    name: "이미지",
    apiName: "image",
    state: true,
  },
  {
    name: "음성",
    apiName: "sound",
    state: true,
  },
  {
    name: "텍스트",
    apiName: "text",
    state: true,
  },
  {
    name: "빅데이터",
    apiName: "bigData",
    state: true,
  },
  {
    name: "융합",
    apiName: "fusion",
    state: true,
  },
  {
    name: "기타",
    apiName: "etc",
    state: true,
  },
];

const typeOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "API",
    apiName: "api",
    state: true,
  },
  {
    name: "SDK",
    apiName: "sdk",
    state: true,
  },
  {
    name: "ON_PREMISE",
    apiName: "onPremise",
    state: true,
  },
  {
    name: "SI",
    apiName: "si",
    state: true,
  },
  {
    name: "SAAS",
    apiName: "saas",
    state: true,
  },
];

const SolutionStore = () => {
  const navigate = useNavigate();
  const { userInfo, isLoggedin } = useAuth();
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();

  const [selectInd, setSelectInd] = useState(aiIndOptions.map((a) => a.name));
  const [selectTec, setSelectTec] = useState(aiTecOptions.map((a) => a.name));
  const [selectType, setSelectType] = useState(typeOptions.map((a) => a.name));
  const [order, setOrder] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchKeywordInput, setSearchKeywordInput] = useState("");
  const [solutionList, setSolutionList] = useState([]);
  const [solutionVIPList, setSolutionVIPList] = useState([]);
  const [solutionTotal, setsolutionTotal] = useState(0);
  const [sortHot, setSortHot] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);

  useEffect(() => {
    getSolutionVIPList((res) => {
      setSolutionVIPList(res.response);
    });
  }, []);
  useEffect(() => {
    getList();
  }, [page, searchKeyword, selectInd, selectTec, selectType, sortHot]);

  const getList = useCallback(() => {
    const params = {
      page,
    };
    const body = {
      keyword: searchKeyword || null,
      conditions: {},
    };

    if (sortHot) {
      params.hot = true;
    }

    let tempAiSkill = {};
    aiTecOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectTec.includes("전체")) {
        tempAiSkill = {
          ...tempAiSkill,
          [item.apiName]: false,
        };
      } else {
        tempAiSkill = {
          ...tempAiSkill,
          [item.apiName]: selectTec.includes(item.name),
        };
      }
    });
    let tempAiInd = {};
    aiIndOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectInd.includes("전체")) {
        tempAiInd = {
          ...tempAiInd,
          [item.apiName]: false,
        };
      } else {
        tempAiInd = {
          ...tempAiInd,
          [item.apiName]: selectInd.includes(item.name),
        };
      }
    });
    typeOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectType.includes("전체")) {
        body[item.apiName] = false;
      } else {
        body[item.apiName] = selectType.includes(item.name);
      }
    });

    body.conditions.aiSkill = tempAiSkill;
    body.conditions.aiIndustry = tempAiInd;

    getSolutionList(params, body, (res) => {
      setSolutionList(res.response.list);
      setsolutionTotal(res.response.total);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
  }, [page, searchKeyword, selectInd, selectTec, selectType, sortHot]);

  const solutionApply = () => {
    if (userInfo.ROLE === "ROLE_HEROS_BUSINESS") {
      openModal({
        type: "consultModal",
        contents: {
          type: "solution", // consulting, consultant, solution
        },
      });
    } else {
      if (isLoggedin) {
        openModal({
          type: "messageModal",
          contents: {
            title: "솔루션 등록 요청 실패",
            message: "기업 파트너만 등록 요청을 할 수 있습니다",
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      } else {
        openModal({
          type: "loginModal",
          // contents: { // optional
          // 	message: ''
          // }
        });
      }
    }
  };

  return (
    <div className="container">
      <div className="section" style={{ paddingBottom: 10 }}>
        <div className="store-top">
          <div className="top-btn m-none">
            <button
              type="button"
              className="btn btn-md btn-direct"
              onClick={() => {
                solutionApply();
              }}
            >
              솔루션 등록 요청하기<span className="arrow wh"></span>
            </button>
          </div>
          <div className="list-top">VIP 파트너 솔루션</div>
          <ul className="thumb-list type2">
            {solutionVIPList?.map((item, i) => {
              if (i >= 3) return;
              return (
                <li
                  className="thumb-list--item"
                  onClick={() => navigate(`${item.SEQ}`)}
                  key={`thumb-list--item-solution-vip-${item.SEQ}`}
                >
                  <div className="image-wrap">
                    <img src={item.THUMBNAIL} alt="" />
                  </div>
                  <div className="text-wrap">
                    <div className="top">
                      {item.TITLE}
                      <span
                        className={`badge badge-sm ${
                          item.SELLER_TYPE === "PERSONAL" ? "blue" : "primary"
                        }`}
                      >
                        {item.SELLER_TYPE === "PERSONAL" ? "개인" : "기업"}
                      </span>
                    </div>
                    <div className="tit">{item.TEAM_NAME}</div>
                    {/* <div className="rate">
										<button type="button" className="btn btn-star active" readOnly></button>
										<button type="button" className="btn btn-star active" readOnly></button>
										<button type="button" className="btn btn-star active" readOnly></button>
										<button type="button" className="btn btn-star active" readOnly></button>
										<button type="button" className="btn btn-star half" readOnly></button>
										<span className="count">4.1 (990)</span>
									</div> */}
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="center">
            AI 히어로즈는 솔루션 구매 시
            <p className="accent">5%를 할인해 드려요!</p>
          </div>
        </div>
        <div className="m-block btn-wrap">
          <button
            type="button"
            className="btn btn-full btn-black type2 link mt-20"
            onClick={() => {
              solutionApply();
            }}
          >
            솔루션 등록 요청하기<span className="arrow wh"></span>
          </button>
        </div>
        <div className="search-top mt-60">
          <div className="input">
            <div className="left type-col2">
              <MultiSelect
                width={184}
                value="TASK"
                options={aiTecOptions}
                selected={selectTec}
                setSelect={setSelectTec}
                zIndex={2}
              />
              <MultiSelect
                width={184}
                value="개발 형태"
                options={typeOptions}
                selected={selectType}
                setSelect={setSelectType}
                zIndex={1}
              />
              <MultiSelect
                width={219}
                value="산업 분야"
                options={aiIndOptions}
                selected={selectInd}
                setSelect={setSelectInd}
              />
            </div>
            <div className="right m-mt-14">
              <div className="input-wrap search">
                <button type="button" className="btn btn-search"></button>
                <input
                  type="text"
                  className="input-text"
                  placeholder="솔루션 상품명을 검색해 주세요."
                  value={searchKeywordInput}
                  onChange={(e) => setSearchKeywordInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setSearchKeyword(e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="search-top">
          <div className="count basic">
            전체 {solutionTotal}개
            <div className="right sort-btn">
              <button
                type="button"
                className={`btn ${!sortHot ? "active" : ""}`}
                onClick={() => {
                  setSortHot(false);
                }}
              >
                최신 순
              </button>
              <button
                type="button"
                className={`btn ${sortHot ? "active" : ""}`}
                onClick={() => {
                  setSortHot(true);
                }}
              >
                판매량 순
              </button>
            </div>
          </div>
        </div>
        {solutionList.length ? (
          <ul className="store-list">
            {solutionList?.map((item) => {
              const countArr = [
                "VIDEO",
                "IMAGE",
                "SOUND",
                "TEXT",
                "BIG_DATA",
                "FUSION",
                "ETC",
              ];
              let optionsCnt = 0;
              for (let i = 0; i < countArr.length; i++) {
                if (item[countArr[i]]) {
                  optionsCnt++;
                }
              }
              return (
                <li
                  className="store-list--item"
                  onClick={() => navigate(`${item.SEQ}`)}
                  key={`thumb-list--item-solution-item-${item.SEQ}`}
                >
                  <div className="img-wrap">
                    <img src={item.THUMBNAIL} alt="" />
                  </div>
                  <div className="txt-wrap">
                    {/* {Boolean(item.NEW) && (
                      <div className="top">
                        <span className="badge badge-sm black type2">NEW</span>
                      </div>
                    )} */}
                    <div className="name">{item.TEAM_NAME}</div>
                    <div className="tit">{item.TITLE}</div>
                    {/* <div className="rate">
									<button type="button" className="btn btn-star active" readonly=""></button>
									<button type="button" className="btn btn-star active" readonly=""></button>
									<button type="button" className="btn btn-star active" readonly=""></button>
									<button type="button" className="btclassName= active" readonly=""></button>
									<button type="button" className="btn btn-star half" readonly=""></button>
									<span className="count">(4.1)</span>
								</div> */}
                    <div className="bottom">
                      {item.VIDEO && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          동영상
                        </span>
                      )}
                      {item.IMAGE && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          이미지
                        </span>
                      )}
                      {item.SOUND && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          음성
                        </span>
                      )}
                      {item.TEXT && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          텍스트
                        </span>
                      )}
                      {item.BIG_DATA && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          빅데이터
                        </span>
                      )}
                      {item.FUSION && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          융합
                        </span>
                      )}
                      {item.ETC && (
                        <span
                          className={`badge badge-md ${
                            isMobile ? "badge-hide-m" : ""
                          } primary2`}
                        >
                          기타
                        </span>
                      )}

                      {isMobile && optionsCnt > 2 && (
                        <span className="text">+{optionsCnt - 2}</span>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="search-none">
            <div className="icon"></div>
            <p className="strong">검색된 상품이 없어요.</p>
            <p>다른 솔루션 상품들도 둘러보시겠어요?</p>
            <button
              type="button"
              className="btn btn-lg btn-black type2"
              onClick={() => {
                setSearchKeyword("");
              }}
            >
              다른 상품 보러 가기
            </button>
          </div>
        )}
        {paginationData && (
          <Pagination
            paginationData={paginationData}
            page={page}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default SolutionStore;
