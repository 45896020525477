import React, { useState, useRef, useEffect, forwardRef } from "react";
import styled from "styled-components";
import arrow_down from "assets/images/icon/arrow_bottom_fill.svg";
import arrow_up from "assets/images/icon/arrow_top_fill.svg";
import Ibusiness from "assets/images/icon/icon_business.svg";
import Icareer from "assets/images/icon/icon_career.svg";
import Ilocation from "assets/images/icon/icon_location.svg";
import Itype from "assets/images/icon/icon_type.svg";

const LabelInput = forwardRef(
  (
    {
      type = "text",
      className,
      label = "라벨명",
      isLabeled = true,
      icon = "type",
      isIcon = false,
      placeholder = "플레이스홀더",
      value,
      defaultValue,
      inputArr = [],
      onChange,
      selectable = false,
      readOnly = false,
      disabled = false,
      handleEvent,
      countType,
      toggleOpt = false,
      handleToggle,
      minValue = 0,
      maxLength,
      errorMsg,
      mobile,
      ...restProps
    },
    ref
  ) => {
    if (value === undefined || value === null) value = "";
    const [number, setNumber] = useState(value);
    const [text, setText] = useState(value);
    const inputRef = useRef();
    const handleIcon = (icon) => {
      switch (icon) {
        case "business":
          return Ibusiness;
        case "career":
          return Icareer;
        case "type":
          return Itype;
        case "location":
          return Ilocation;
        default:
          return Itype;
      }
    };
    const handleText = (value) => {
      setText(value);
      handleEvent(value);
    };
    const handleNumber = (value) => {
      let calcValue;
      if (value > 0) {
        calcValue = Math.min(100, Math.max(1, value));
      } else {
        calcValue = value;
      }

      setNumber(calcValue);
      handleEvent(calcValue);
    };
    const handleNumberBlur = (value) => {
      if (value === 0) {
        setNumber(minValue);
        handleEvent(minValue);
      }
    };
    const incrementValue = (value) => {
      const calcValue = Math.min(100, Math.max(0, value + 1));
      setNumber(calcValue);
      handleEvent(calcValue);
    };
    const decrementValue = (value) => {
      const calcValue = Math.min(100, Math.max(minValue, value - 1));
      setNumber(calcValue);
      handleEvent(calcValue);
    };
    const selectText = (value) => {
      inputRef.current.focus();
      //setToggleOption(false);
      handleToggle();
      setText(value);
      handleEvent(value);
    };

    useEffect(() => {
      if (type === "number") {
        setNumber(value);
      } else {
        setText(value);
      }
    }, [value]);
    const setInputType = (type) => {
      switch (type) {
        case "text":
          return (
            <TextInput>
              <Input
                id={type}
                type={type}
                className={`input-text ${
                  value.length > parseInt(maxLength) ? "error" : ""
                }`}
                placeholder={placeholder}
                value={value}
                onChange={(e) => handleEvent(e.target.value)}
                readOnly={readOnly}
                disabled={disabled}
                ref={ref}
                mobile={mobile}
                {...restProps} // Spread the restProps here
              />
              {value.length > parseInt(maxLength) && (
                <p className="input-guide error">{errorMsg}</p>
              )}
            </TextInput>
          );
        case "textarea":
          return (
            <TextInput>
              <Textarea
                id={type}
                type={type}
                className={`input-text ${
                  value.length > parseInt(maxLength) ? "error" : ""
                }`}
                placeholder={placeholder}
                value={text}
                onChange={(e) => handleText(e.target.value)}
                readOnly={readOnly}
                disabled={disabled}
                ref={ref}
                mobile={mobile}
                {...restProps}
              />
              {value.length > parseInt(maxLength) && (
                <p className="input-guide error">{errorMsg}</p>
              )}
            </TextInput>
          );
        case "input-select":
          return (
            <SelectInputWrapper>
              <div className="select-wrapper">
                <Input
                  ref={inputRef}
                  id={type}
                  type={type}
                  className={`input-text ${toggleOpt ? "active" : ""} ${
                    value.length > parseInt(maxLength) ? "error" : ""
                  }`}
                  placeholder={placeholder}
                  value={value}
                  onChange={(e) => handleText(e.target.value)}
                  readOnly={readOnly}
                  disabled={disabled}
                  mobile={mobile}
                  {...restProps}
                />
                {/* 리스트 이벤트 사용으로 토글 컴포넌트 미적용 */}
                <button onClick={handleToggle}>
                  <img src={toggleOpt ? arrow_up : arrow_down} alt="toggle" />
                </button>
              </div>

              {toggleOpt && (
                <div className="option-group">
                  {inputArr.map((item, idx) => {
                    return (
                      <li key={idx} onClick={() => selectText(item)}>
                        {item}
                      </li>
                    );
                  })}
                </div>
              )}
              {value.length > parseInt(maxLength) && (
                <p className="input-guide error">{errorMsg}</p>
              )}
            </SelectInputWrapper>
          );
        case "number":
          return (
            <>
              <Input
                min={minValue}
                max={100}
                id={"text"}
                pattern="\d*"
                type={type}
                className={`input-text number ${!value ? "error" : ""} ${
                  icon ? "icon-input" : ""
                }`}
                value={defaultValue !== null ? Number(defaultValue) : number}
                onChange={(e) => handleNumber(e.target.value)}
                onBlur={(e) => handleNumberBlur(e.target.value)}
                readOnly={readOnly}
                disabled={disabled}
                ref={ref}
                mobile={mobile}
                {...restProps}
              />
              <br />
              {number !== undefined || number !== 0 ? (
                <p className="budget-replace number-value">
                  {defaultValue
                    ? defaultValue
                    : number === undefined || number.length === 0
                    ? placeholder
                    : number}
                  {number && countType}
                </p>
              ) : (
                <p className="budget-replace number-value">{placeholder}</p>
              )}
              <div className="number-spinner">
                <button onClick={() => incrementValue(number)}>
                  <img src={arrow_up} alt="추가" title="추가" />
                </button>
                <button onClick={() => decrementValue(number)}>
                  <img src={arrow_down} alt="감소" title="감소" />
                </button>
              </div>
            </>
          );
        default:
          throw new Error(`제공되는 타입의 인풋이 없습니다 ${type}`);
      }
    };
    return (
      <StyledWrapper mobile={mobile}>
        {isIcon && <Icon src={handleIcon(icon)} alt={icon} />}
        {isLabeled && <label htmlFor="input">{label}</label>}
        {setInputType(type)}
      </StyledWrapper>
    );
  }
);

export default LabelInput;

const StyledWrapper = styled.div`
  min-height: 50px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  label {
    width: 65px;
    color: #999;
    text-align: start;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin-right: 10px;
  }
  & .number-spinner {
    position: absolute;
    right: 18px;
    display: flex;
    flex-direction: column;
    & button {
      padding: 0;
      text-align: right;
      cursor: pointer;
      background: none;
      border: none;
    }
  }
  & .number-value {
    position: absolute;
    font-size: 14px;
    left: 30px;
    padding: 15px;
    color: #999;
    pointer-events: none;
  }
`;
const Input = styled.input`
  display: flex;
  width: ${(props) => (props.isLabeled ? "calc(100% - 75px)" : "100%")};
  height: 50px;
  padding: 15.5px 18px 15.5px 0px;
  padding-left: ${(props) => (props.isIcon ? "42px" : "18px")};
  padding-right: ${(props) => (props.mobile ? "45px" : "18px")};
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--stroke_gray04, #eee);
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */

  &.input-text {
    &.number {
      color: transparent !important;
      padding: 0px 18px;
      padding-left: 42px !important;
    }
    &:focus {
      color: initial;
    }
  }
  &.active {
    border-color: #0bb899;
  }
  &:disabled {
    border-radius: 5px;
    border: 1px solid var(--stroke_gray04, #eee);
    background: #f7f7f7;
    cursor: not-allowed;
  }
`;
const Textarea = styled.textarea`
  display: flex;
  width: ${(props) => (props.isLabeled ? "calc(100% - 75px)" : "100%")};
  min-height: 154px;
  padding: 15.5px 18px;
  margin-top: 10px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--stroke_gray04, #eee);
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33em; /* 14px */
  resize: none;
`;

// 선택인풋용 스타일
const SelectInputWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.isLabeled ? "calc(100% - 75px)" : "100%")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & input {
    width: 100%;
  }
  & button {
    position: absolute;
    right: 18px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  & .option-group {
    display: block;
    position: absolute;
    background: #fff;
    border: 1px solid var(--stroke_gray04, #eee);
    border-radius: 5px;
    z-index: 999;
    top: 60px;
    width: 100%;

    & li {
      cursor: pointer;
      height: 45px;
      padding: 0 18px;
      font-size: 14px;
      list-style: none;
      display: flex;
      align-items: center;
      text-indent: 10px;
      color: #999;
      &:hover {
        background: var(--mono-m-bg, #f6f6f6);
      }
    }
  }
  & .select-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    & button {
      margin-left: 6px;
    }
  }
`;
const Icon = styled.img`
  position: absolute;
  padding-left: 18px;
`;
const TextInput = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
