import React from "react";
import Button from "components/Button/Button";
import styled from "styled-components";
const MessageModal = ({
  title,
  message,
  confirmText,
  confirmCallback,
  cancelText,
  cancelCallback,
  warnMessage,
  closedBtn = false,
}) => {
  return (
    <StyledModalwrapper className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con message">
        {closedBtn && (
          <button type="button" className="btn btn-close wh"></button>
        )}
        <div className="text">
          <p className="strong">{title}</p>
          <p>{message}</p>
        </div>
        <div className="btn-wrap">
          {cancelText ? (
            <>
              <Button
                text={cancelText}
                color={"grey"}
                type={"half"}
                className={"mr-6"}
                onClick={() => {
                  cancelCallback();
                }}
              />
              <Button
                text={confirmText}
                color={"primary"}
                type={"full"}
                className={"btn-primary"}
                onClick={() => {
                  confirmCallback();
                }}
              />
            </>
          ) : (
            <button
              onClick={() => {
                confirmCallback();
              }}
              type="button"
              className="btn btn-lg btn-primary"
            >
              {confirmText}
            </button>
          )}
        </div>
        {warnMessage && (
          <div className="text mt-30 txt-c--red">{warnMessage}</div>
        )}
      </div>
    </StyledModalwrapper>
  );
};

export default MessageModal;
const StyledModalwrapper = styled.div`
  & .btn-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & button {
      width: 60%;
      max-width: 200px;
    }
    & button:first-child {
      width: calc(40% - 6px);
      max-width: 94px;
    }
  }
`;
