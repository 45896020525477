import React, { useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Profile from "pages/Mypage/Profile";
import AiprojectC from "pages/Mypage/AiprojectC";
import AiprojectCDetail from "pages/Mypage/AiprojectCDetail";
import AiprojectH from "pages/Mypage/AiprojectH";
import AiprojectHDetail from "pages/Mypage/AiprojectHDetail";
import Revenue from "pages/Mypage/Revenue";
import useAuth from "hooks/useAuth";
import useViewCheck from "hooks/useViewCheck";

const Mypage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { isMobile } = useViewCheck();

  useEffect(() => {
    // TODO: auth 체크
    if (location.pathname === "/mypage" || location.pathname === "/mypage/") {
      navigateDefaultPage();
    }
  }, [location]);

  const navigateDefaultPage = () => {
    navigate("/mypage/profile", { replace: true });
  };

  const match = useCallback(
    (path) => {
      const curPath = location.pathname.split("/");
      if (curPath[2] === path) return true;
    },
    [location]
  );

  return (
    <div className="container">
      <div className="section">
        {/* {!isMobile && */}
        <div className="mypage-tab">
          <Link
            to="/mypage/profile"
            className={`btn ${match("profile") ? "active" : ""}`}
          >
            프로필
          </Link>
          <Link
            to="/mypage/aiprojectc"
            className={`btn c ${match("aiprojectc") ? "active" : ""}`}
          >
            프로젝트 관리
          </Link>
          {!userInfo.ROLE?.split("_").includes("USER") && (
            <Link
              to="/mypage/aiprojecth"
              className={`btn h ${match("aiprojecth") ? "active" : ""}`}
            >
              프로젝트 관리
            </Link>
          )}
          {!userInfo.ROLE?.split("_").includes("USER") && (
            <Link
              to="/mypage/revenue"
              className={`btn ${match("revenue") ? "active" : ""}`}
            >
              정산 관리
            </Link>
          )}
        </div>
        {/* } */}
        <Routes>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/aiprojectc" element={<AiprojectC />}></Route>
          <Route
            path="/aiprojectc/:id"
            element={<AiprojectCDetail isMobile={isMobile} />}
          ></Route>
          <Route path="/aiprojecth" element={<AiprojectH />}></Route>
          <Route
            path="/aiprojecth/:id"
            element={<AiprojectHDetail isMobile={isMobile} />}
          ></Route>
          <Route path="/revenue" element={<Revenue />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default Mypage;
