import api from 'api';

export const getAiProjectList = (params, body, callback) => {
	api.post('/project/view/list', body, {
		params
	}).then((res) => { callback(res) })
}

export const getAiProjectDetailRequest = (seq, callback) => {
	api.get(`/project/view/${seq}`).then((res) => { callback(res) })
}

export const aiProjectRequest = (body, callback) => {
	api.post('/project/auth/write', body).then((res) => { callback(res) })
}

export const aiProjectRequestSimple = (body, callback) => {
	api.post('/project/auth/write/simple', body).then((res) => { callback(res) })
}

export const aiProjectModify = (seq, body, callback) => {
	api.post(`/project/auth/modify/${seq}`, body).then((res) => { callback(res) })
}

export const getAiProjectRecommend = (params, callback) => {
	api.get('/project/recommend', { params }).then((res) => { callback(res) })
}

export const getAiProjectQnaList = (auth, seq, params, callback) => {
	if (auth) {
		api.get(`/qna/auth/view/${seq}`, { params }).then((res) => { callback(res) })
	} else {
		api.get(`/qna/view/${seq}`, { params }).then((res) => { callback(res) })
	}
}
export const aiProjectQuestionWrite = (seq, body, callback) => {
	api.post(`/qna/auth/question/write/${seq}`, body).then((res) => { callback(res) })
}

export const aiProjectAnswerWrite = (seq, body, callback) => {
	api.post(`/qna/auth/reply/write/${seq}`, body).then((res) => { callback(res) })
}

export const aiProjectAnswerDelete = (seq, callback) => {
	api.delete(`/qna/auth/reply/delete`, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectQuestionDelete = (seq, callback) => {
	api.delete(`/qna/auth/question/delete`, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectQuestionEdit = (seq, body, callback) => {
	api.post(`/qna/auth/question/modify`, body, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectAnswerEdit = (seq, body, callback) => {
	api.post(`/qna/auth/reply/modify`, body, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectQuestionReport = (seq, body, callback) => {
	api.post(`/qna/auth/question/report`, body, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectAReport = (seq, body, callback) => {
	api.post(`/qna/auth/reply/report`, body, { params: { seq } }).then((res) => { callback(res) })
}


export const aiProjectlike = (seq, callback) => {
	api.post(`/project-like/auth/apply`, {}, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectApply = (seq, body, callback) => {
	api.post(`/project-application/auth/apply`, body, { params: { seq } }).then((res) => { callback(res) })
}

export const aiProjectApplyCheck = (seq, callback) => {
	api.get(`/project-application/auth/check/${seq}`, { params: { seq } }).then((res) => { callback(res) })
}