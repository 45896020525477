import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import FoundEmail from "./FoundEmail";
import SnsAuth from "./SnsAuth";
import AlreadySignedup from "./AlreadySignedup";
import NotSignedup from "./NotSignedup";
import ChangePassword from "./ChangePassword";

const Idinquiry = () => {
  // eslint-disable-next-line no-lone-blocks
  {
    /*
		type
		1. 휴대폰 인증 페이지
		2. 인증 후 이미 가입된 계정 페이지 => 로그인, 비밀번호 찾기
		2-1. 비밀번호 찾기 => 이메일 발송 메시지 모달
		3. 비밀번호 변경 페이지(이메일 내 링크 클릭 시)
		3-1. 비밀번호 변경 완료 모달
		3. 가입된 계정 X 페이지		
		4. 회원가입페이지에서 유입 된 경우 이미 가입된 계정 페이지 => 기존 계정으로 로그인하기
	*/
  }
  const location = useLocation();
  const navigate = useNavigate();

  const navigateDefaultPage = () => {
    navigate("/idinquiry/snsauth", { replace: true });
  };

  useEffect(() => {
    if (
      location.pathname === "/idinquiry" ||
      location.pathname === "/idinquiry/"
    ) {
      navigateDefaultPage();
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/snsauth" element={<SnsAuth />}></Route>
      <Route path="/foundemail" element={<FoundEmail />}></Route>
      <Route path="/alreadysignedup" element={<AlreadySignedup />}></Route>
      <Route path="/notsignedup" element={<NotSignedup />}></Route>
      <Route path="/changepassword" element={<ChangePassword />}></Route>
    </Routes>
  );
};

export default Idinquiry;
