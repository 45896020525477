import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userProjectList, userLikeHerosList } from "services/projectManagement";
import { priceFormat } from "utils/utils";

import Pagination from "components/common/Pagination";

import folder_color from "assets/images/icon/folder_color.svg";
import heart_fill from "assets/images/icon/heart_fill.svg";
import { GRADE_TYPE } from "utils/data";
import useViewCheck from "hooks/useViewCheck";

import styled from "styled-components";
const StyledBadgeWrapper = styled.div`
  height: 38px;
`;
const AiheroseC = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();

  const [cList, setCList] = useState([]);
  const [likeHList, setLikeHList] = useState([]);
  const [pPage, setPPage] = useState(1);
  const [hPage, setHPage] = useState(1);
  const [pPaginationData, setPPaginationData] = useState(null);
  const [hPaginationData, setHPaginationData] = useState(null);

  useEffect(() => {
    userProjectList(
      {
        page: pPage,
      },
      (res) => {
        setCList(res.response.list);
        const {
          navigateFirstPage,
          navigateLastPage,
          navigatepageNums,
          nextPage,
          pageNum,
          prePage,
          isFirstPage,
          isLastPage,
          hasPreviousPage,
          hasNextPage,
          pages,
        } = res.response;
        setPPaginationData({
          navigateFirstPage,
          navigateLastPage,
          navigatepageNums,
          nextPage,
          pageNum,
          prePage,
          isFirstPage,
          isLastPage,
          hasPreviousPage,
          hasNextPage,
          pages,
        });
      }
    );
  }, [pPage]);
  useEffect(() => {
    userLikeHerosList(
      {
        page: hPage,
      },
      (res) => {
        setLikeHList(res.response.list);
        const {
          navigateFirstPage,
          navigateLastPage,
          navigatepageNums,
          nextPage,
          pageNum,
          prePage,
          isFirstPage,
          isLastPage,
          hasPreviousPage,
          hasNextPage,
          pages,
        } = res.response;
        setHPaginationData({
          navigateFirstPage,
          navigateLastPage,
          navigatepageNums,
          nextPage,
          pageNum,
          prePage,
          isFirstPage,
          isLastPage,
          hasPreviousPage,
          hasNextPage,
          pages,
        });
      }
    );
  }, [hPage]);

  return (
    <div className="mypage-con">
      <div className="mypage-tit">
        <img src={folder_color} alt="" />
        나의 프로젝트
      </div>
      <ul className="project-list">
        {cList.length ? (
          <>
            {cList.map((item, i) => (
              <li
                className={`project-list--item ${item.DELETED ? "done" : ""}`}
                onClick={() => {
                  if (item.DELETED) return;
                  navigate(`/mypage/aiprojectc/${item.SEQ}`);
                }}
                key={`user-project-c-list-${i}`}
              >
                <div className="top">
                  {item.TYPE === "BUSINESS" ? (
                    <span className="badge badge-round primary">
                      기업 프로젝트
                    </span>
                  ) : (
                    <span className="badge badge-round blue">
                      개인 프로젝트
                    </span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "RESIDENT") && (
                    <span className="badge badge-round orange">상주</span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "REMOTE") && (
                    <span className="badge badge-round blue-line">원격</span>
                  )}
                  <div className="right">
                    {item.DELETED ? (
                      <span className="badge primary">종료</span>
                    ) : (
                      <>
                        {item.STATUS === "APPLY" && !item.REFUSE_AT && (
                          <span className="badge yellow">등록 단계</span>
                        )}
                        {item.STATUS === "APPLY" && item.REFUSE_AT && (
                          <span className="badge yellow">반려</span>
                        )}
                        {item.STATUS === "MATCHING" && (
                          <span className="badge blue">매칭 진행</span>
                        )}
                        {item.STATUS === "PROGRESS" && (
                          <span className="badge primary">진행 중</span>
                        )}
                        {item.STATUS === "COMPLETE" && (
                          <span className="badge primary">완료</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="tit">{item.PROJECT_NAME}</div>
                <div className="info">
                  <span className="text">
                    예산 및 급여 방식:
                    {item.PAY_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        제안 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;{priceFormat(item.BUDGET_MIN)}~
                        {priceFormat(item.BUDGET_MAX)}만 원
                        <br className="m-block" />
                        <span className="badge badge-sm skyblue">
                          {item.PAY_METHOD === "SALARY"
                            ? "월급"
                            : item.PAY_METHOD === "WAGE"
                            ? "주급"
                            : "프로젝트 완료 후"}
                        </span>
                      </>
                    )}
                  </span>
                  <span className="text">
                    기간:
                    {item.PERIOD_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        협의 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;
                        {item.PROJECT_PERIOD % 4 === 0
                          ? item.PROJECT_PERIOD / 4
                          : item.PROJECT_PERIOD}
                        {item.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                      </>
                    )}
                  </span>
                  <span className="text">
                    데이터 보유 현황: 데이터 {item.HAVE_DATA ? "있음" : "없음"}
                  </span>
                </div>
                <div className="option">
                  <label className="label">산업 분야</label>
                  <span>
                    {item.EDUCATION && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        교육
                      </span>
                    )}
                    {item.SERVICE && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        서비스
                      </span>
                    )}
                    {item.MANUFACTURING && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        제조, 화학, 에너지
                      </span>
                    )}
                    {item.VEHICLE && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        자동차
                      </span>
                    )}
                    {item.SALES && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        판매, 유통
                      </span>
                    )}
                    {item.MEDIA && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        미디어, 엔터테인먼트
                      </span>
                    )}
                    {item.TOUR && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        관광, 스포츠, 레저
                      </span>
                    )}
                    {item.CONSTRUCT && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        부동산, 건설, 건축
                      </span>
                    )}
                    {item.FINANCE && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        금융
                      </span>
                    )}
                    {item.MEDICAL && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        의료, 제약, 바이오, 헬스케어
                      </span>
                    )}
                    {item.IT && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        IT, 통신
                      </span>
                    )}
                    {item.GOVERNMENT && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        정부, 기관
                      </span>
                    )}
                    {item.FOOD && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        식품
                      </span>
                    )}
                    {item.AGRICULTURE && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        농업
                      </span>
                    )}
                    {item.GAME && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        게임
                      </span>
                    )}
                    {item.INDUSTRY_ETC && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        기타
                      </span>
                    )}

                    {isMobile
                      ? item.OPTIONS > 1 && (
                          <span className="text">+{item.OPTIONS - 1}</span>
                        )
                      : item.OPTIONS > 3 && (
                          <span className="text">+{item.OPTIONS - 3}</span>
                        )}
                  </span>
                  <br className="m-block" />
                  <label className="label">기술 분야</label>
                  <span>
                    {item.VIDEO && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        동영상
                      </span>
                    )}
                    {item.IMAGE && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        이미지
                      </span>
                    )}
                    {item.SOUND && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        음성
                      </span>
                    )}
                    {item.TEXT && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        텍스트
                      </span>
                    )}
                    {item.BIG_DATA && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        빅데이터
                      </span>
                    )}
                    {item.FUSION && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        융합
                      </span>
                    )}
                    {item.SKILL_ETC && (
                      <span
                        className={`badge badge-hide primary2 ${
                          isMobile ? "badge-hide-m" : "badge-hide"
                        } `}
                      >
                        기타
                      </span>
                    )}

                    {isMobile
                      ? item.SKILL_OPTIONS > 1 && (
                          <span className="text">
                            +{item.SKILL_OPTIONS - 1}
                          </span>
                        )
                      : item.SKILL_OPTIONS > 3 && (
                          <span className="text">
                            +{item.SKILL_OPTIONS - 3}
                          </span>
                        )}
                  </span>
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            <div className="container-none">아직 등록된 프로젝트가 없어요.</div>
          </>
        )}
      </ul>
      {pPaginationData && (
        <Pagination
          paginationData={pPaginationData}
          page={pPage}
          setPage={setPPage}
        />
      )}
      <div className="m-block hr"></div>
      <div className="mypage-tit">
        <img src={heart_fill} alt="" />
        관심 파트너
      </div>
      <ul className="heroes-list"></ul>
      <ul className="text-list type3">
        {likeHList.length ? (
          likeHList.map((item) => (
            <React.Fragment key={`my-like-heros-${item.HEROS}`}>
              {isMobile ? (
                <li
                  className="heroes-list--item"
                  onClick={() => navigate(`/heroes/${item.HEROS}`)}
                >
                  <div className="info">
                    <span className="text name">{item.NAME}</span>
                    <span
                      className={`grade type-${GRADE_TYPE.indexOf(item.GRADE)}`}
                    ></span>
                    <span className={`rate ${item.SCORE ? "" : "no-score"}`}>
                      {item.SCORE ? item.SCORE : " "}
                    </span>
                  </div>
                  <div className="info2">
                    <span className="text">
                      <label className="label">급여 방식</label>
                      {item.SALARY_NEGOTIABLE ? (
                        <span
                          className="badge badge-sm skyblue"
                          style={{ marginLeft: 0 }}
                        >
                          협의가능
                        </span>
                      ) : (
                        <>
                          {priceFormat(item.DESIRED_SALARY)}만 원
                          <span className="badge badge-sm skyblue">
                            {item.PAY_METHOD === "SALARY"
                              ? "월급"
                              : item.PAY_METHOD === "WAGE"
                              ? "주급"
                              : "프로젝트 완료 후"}
                          </span>
                        </>
                      )}
                    </span>
                    <span className="text">
                      <label className="label">업력(경력)</label>
                      {item.CAREER}년<label className="label div">횟수</label>
                      {priceFormat(item.CLIENT)}회
                    </span>
                  </div>

                  <StyledBadgeWrapper className="tag">
                    <label className="label">전문 분야</label>
                    <div className="badges">
                      {item.EDUCATION && (
                        <span className="badge badge-md badge-hide primary2">
                          교육
                        </span>
                      )}
                      {item.SERVICE && (
                        <span className="badge badge-md badge-hide primary2">
                          서비스
                        </span>
                      )}
                      {item.MANUFACTURING && (
                        <span className="badge badge-md badge-hide primary2">
                          제조, 화학, 에너지
                        </span>
                      )}
                      {item.VEHICLE && (
                        <span className="badge badge-md badge-hide primary2">
                          자동차
                        </span>
                      )}
                      {item.SALES && (
                        <span className="badge badge-md badge-hide primary2">
                          판매, 유통
                        </span>
                      )}
                      {item.MEDIA && (
                        <span className="badge badge-md badge-hide primary2">
                          미디어, 엔터테인먼트
                        </span>
                      )}
                      {item.TOUR && (
                        <span className="badge badge-md badge-hide primary2">
                          관광, 스포츠, 레저
                        </span>
                      )}
                      {item.CONSTRUCT && (
                        <span className="badge badge-md badge-hide primary2">
                          부동산, 건설, 건축
                        </span>
                      )}
                      {item.FINANCE && (
                        <span className="badge badge-md badge-hide primary2">
                          금융
                        </span>
                      )}
                      {item.MEDICAL && (
                        <span className="badge badge-md badge-hide primary2">
                          의료, 제약, 바이오, 헬스케어
                        </span>
                      )}
                      {item.IT && (
                        <span className="badge badge-md badge-hide primary2">
                          IT, 통신
                        </span>
                      )}
                      {item.GOVERNMENT && (
                        <span className="badge badge-md badge-hide primary2">
                          정부, 기관
                        </span>
                      )}
                      {item.FOOD && (
                        <span className="badge badge-md badge-hide primary2">
                          식품
                        </span>
                      )}
                      {item.AGRICULTURE && (
                        <span className="badge badge-md badge-hide primary2">
                          농업
                        </span>
                      )}
                      {item.GAME && (
                        <span className="badge badge-md badge-hide primary2">
                          게임
                        </span>
                      )}
                      {item.ETC && (
                        <span className="badge badge-md badge-hide primary2">
                          기타
                        </span>
                      )}
                      {item.OPTIONS > 3 && (
                        <span className="text">+{item.OPTIONS - 3}</span>
                      )}
                    </div>
                  </StyledBadgeWrapper>
                  <StyledBadgeWrapper className="tag">
                    <label className="label">보유 스킬</label>
                    <div className="badges">
                      {item.VIDEO && (
                        <span className="badge badge-md badge-hide primary2">
                          동영상
                        </span>
                      )}
                      {item.IMAGE && (
                        <span className="badge badge-md badge-hide primary2">
                          이미지
                        </span>
                      )}
                      {item.SOUND && (
                        <span className="badge badge-md badge-hide primary2">
                          음성
                        </span>
                      )}
                      {item.TEXT && (
                        <span className="badge badge-md badge-hide primary2">
                          텍스트
                        </span>
                      )}
                      {item.BIG_DATA && (
                        <span className="badge badge-md badge-hide primary2">
                          빅데이터
                        </span>
                      )}
                      {item.FUSION && (
                        <span className="badge badge-md badge-hide primary2">
                          융합
                        </span>
                      )}
                      {item.ETC && (
                        <span className="badge badge-md badge-hide primary2">
                          기타
                        </span>
                      )}
                      {item.SKILL_OPTIONS > 3 && (
                        <span className="text">+{item.SKILL_OPTIONS - 3}</span>
                      )}
                      {item.SKILL_OPTIONS === 0 && (
                        <span className="text">-</span>
                      )}
                    </div>
                  </StyledBadgeWrapper>
                </li>
              ) : (
                <li
                  className="text-list--item"
                  onClick={() => navigate(`/heroes/${item.HEROS}`)}
                >
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">이름</label>
                    <span className="text name">{item.NAME}</span>
                    <span
                      className={`grade type-${GRADE_TYPE.indexOf(item.GRADE)}`}
                    ></span>
                    <span className={`rate ${item.SCORE ? "" : "no-score"}`}>
                      {item.SCORE ? item.SCORE : " "}
                    </span>
                  </div>
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">급여 방식</label>
                    {item.SALARY_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={{ marginLeft: 0 }}
                      >
                        협의가능
                      </span>
                    ) : (
                      <>
                        <span className="text">
                          <span className="ellipsis">
                            {priceFormat(item.DESIRED_SALARY)}
                          </span>
                          만 원
                        </span>
                        <span
                          className="badge badge-sm skyblue"
                          style={{ marginLeft: 10 }}
                        >
                          {item.PAY_METHOD === "SALARY"
                            ? "월급"
                            : item.PAY_METHOD === "WAGE"
                            ? "주급"
                            : "프로젝트 완료 후"}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">업력(경력)</label>
                    <span className="text">{item.CAREER}년</span>
                  </div>
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">횟수</label>
                    <span className="text">
                      <span className="ellipsis">
                        {priceFormat(item.PROJECT_FINISH)}
                      </span>
                      회
                    </span>
                  </div>
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">전문 분야</label>
                    {item.EDUCATION && (
                      <span className="text badge badge-sm badge-hide primary2">
                        교육
                      </span>
                    )}
                    {item.SERVICE && (
                      <span className="text badge badge-sm badge-hide primary2">
                        서비스
                      </span>
                    )}
                    {item.MANUFACTURING && (
                      <span className="text badge badge-sm badge-hide primary2">
                        제조, 화학, 에너지
                      </span>
                    )}
                    {item.VEHICLE && (
                      <span className="text badge badge-sm badge-hide primary2">
                        자동차
                      </span>
                    )}
                    {item.SALES && (
                      <span className="text badge badge-sm badge-hide primary2">
                        판매, 유통
                      </span>
                    )}
                    {item.MEDIA && (
                      <span className="text badge badge-sm badge-hide primary2">
                        미디어, 엔터테인먼트
                      </span>
                    )}
                    {item.TOUR && (
                      <span className="text badge badge-sm badge-hide primary2">
                        관광, 스포츠, 레저
                      </span>
                    )}
                    {item.CONSTRUCT && (
                      <span className="text badge badge-sm badge-hide primary2">
                        부동산, 건설, 건축
                      </span>
                    )}
                    {item.FINANCE && (
                      <span className="text badge badge-sm badge-hide primary2">
                        금융
                      </span>
                    )}
                    {item.MEDICAL && (
                      <span className="text badge badge-sm badge-hide primary2">
                        의료, 제약, 바이오, 헬스케어
                      </span>
                    )}
                    {item.IT && (
                      <span className="text badge badge-sm badge-hide primary2">
                        IT, 통신
                      </span>
                    )}
                    {item.GOVERNMENT && (
                      <span className="text badge badge-sm badge-hide primary2">
                        정부, 기관
                      </span>
                    )}
                    {item.FOOD && (
                      <span className="text badge badge-sm badge-hide primary2">
                        식품
                      </span>
                    )}
                    {item.AGRICULTURE && (
                      <span className="text badge badge-sm badge-hide primary2">
                        농업
                      </span>
                    )}
                    {item.GAME && (
                      <span className="text badge badge-sm badge-hide primary2">
                        게임
                      </span>
                    )}
                    {item.ETC && (
                      <span className="text badge badge-sm badge-hide primary2">
                        기타
                      </span>
                    )}
                    {item.OPTIONS > 3 && (
                      <span className="num">+{item.OPTIONS - 3}</span>
                    )}
                  </div>
                  <div className="col" style={{ height: "38px" }}>
                    <label className="label">보유 스킬</label>
                    {item.VIDEO && (
                      <span className="text badge badge-sm badge-hide primary2">
                        동영상
                      </span>
                    )}
                    {item.IMAGE && (
                      <span className="text badge badge-sm badge-hide primary2">
                        이미지
                      </span>
                    )}
                    {item.SOUND && (
                      <span className="text badge badge-sm badge-hide primary2">
                        음성
                      </span>
                    )}
                    {item.TEXT && (
                      <span className="text badge badge-sm badge-hide primary2">
                        텍스트
                      </span>
                    )}
                    {item.BIG_DATA && (
                      <span className="text badge badge-sm badge-hide primary2">
                        빅데이터
                      </span>
                    )}
                    {item.FUSION && (
                      <span className="text badge badge-sm badge-hide primary2">
                        융합
                      </span>
                    )}
                    {item.ETC && (
                      <span className="text badge badge-sm badge-hide primary2">
                        기타
                      </span>
                    )}
                    {item.SKILL_OPTIONS > 3 && (
                      <span className="num">+{item.SKILL_OPTIONS - 3}</span>
                    )}
                  </div>
                </li>
              )}
            </React.Fragment>
          ))
        ) : (
          <>
            <div className="container-none">아직 관심있는 파트너가 없어요.</div>
          </>
        )}
      </ul>
      {hPaginationData && (
        <Pagination
          paginationData={hPaginationData}
          page={hPage}
          setPage={setHPage}
        />
      )}
    </div>
  );
};

export default AiheroseC;
