import React, { useEffect, useState } from "react";
import styled from "styled-components";

import timeLineIcon from "assets/images/icon/time_line.svg";
import viewIcon from "assets/images/icon/view_black.svg";

const TAG_COLOR_LIST = ["#CCFF00", "#FFBC27", "#BAEEFE", "#FFB2B2", "#D8BAFF"];

const BadgeCard = ({ type, card, move2URL }) => {
  const [randomIdx, setRandomIdx] = useState([]);

  useEffect(() => {
    const res = getRandomUniqueValues(0, TAG_COLOR_LIST.length - 1, 2);
    setRandomIdx(res);
  }, []);

  const str2List = (str) => {
    return str.split(",");
  };

  /** 랜덤한 정수값 가져오기 */
  const getRandomUniqueValues = (min, max, count) => {
    if (max - min + 1 < count) return;
    const uniqueValues = new Set();
    while (uniqueValues.size < count) {
      uniqueValues.add(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    return Array.from(uniqueValues);
  };

  const changeTimeFormat = (time) => {
    return `${time.split("T")[0].replaceAll("-", ".")} 작성`;
  };

  switch (type) {
    case "partner":
      return (
        <StyledWrapper className="partner" onClick={() => move2URL(card.URL)}>
          <div className="img-wrapper">
            <img src={card.FILE} alt="img-banner" />
          </div>
          <div className="title">{card.MAIN_TITLE}</div>
          <div className="bottom">
            <div className="tags">
              {str2List(card.KEYWORD).map((keyword, i) => (
                <span
                  className="tag"
                  key={i}
                  style={{ backgroundColor: TAG_COLOR_LIST[randomIdx[i]] }}
                >
                  {keyword}
                </span>
              ))}
            </div>
            <div className="hits">
              {/* <img src={viewIcon} alt="icon-hits" />
              {card.HITS} */}
            </div>
          </div>
        </StyledWrapper>
      );

    case "plain_detail":
      return (
        <StyledWrapper className="detail" onClick={() => move2URL(card.URL)}>
          <div className="img-wrapper">
            <img src={card.FILE} alt="img-banner" />
            <div className="badge-wrapper">
              <span className="badge-time">
                <img src={timeLineIcon} alt="icon-time" />
                {card.TIME}분
              </span>
              <span className="badge-created">
                {changeTimeFormat(card.CREATED_AT)}
              </span>
            </div>
          </div>
          <div className="title">{card.MAIN_TITLE}</div>
          <div className="title-detail">{card.DETAIL}</div>
          <div className="bottom">
            <div className="profile">
              <img src={card.PARTNER_PROFILE} alt="img-profile" />
              <span>{card.PARTNER_NICKNAME}</span>
            </div>
            <div className="hits">
              <img src={viewIcon} alt="img-hits" />
              {card.VIEW}
            </div>
          </div>
        </StyledWrapper>
      );

    case "plain_simple":
      return (
        <StyledWrapper className="simple" onClick={() => move2URL(card.URL)}>
          <div className="img-wrapper">
            <img src={card.FILE} alt="img-banner" />
          </div>
          <div className="title-wrapper">
            <div className="title-detail">{card.DETAIL}</div>
            <div className="title">{card.MAIN_TITLE}</div>
          </div>
          <div className="bottom">
            <div className="hits">
              <img src={viewIcon} alt="icon-hits" />
              {card.HITS}
            </div>
          </div>
        </StyledWrapper>
      );
  }
};

export default BadgeCard;

const StyledWrapper = styled.div`
  cursor: pointer;

  & > .img-wrapper > img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: fill;
    transition: 0.1s;
  }

  & .title {
    width: 100%;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
    line-height: 133%;
    text-align: left;
    cursor: pointer;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
  }

  & .title-detail {
    width: 100%;
    height: 38px;
    font-size: 14px;
    line-height: 133%;
    color: #999;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
  }

  & > .bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .hits {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #000;

      & > img {
        margin-right: 2px;
      }
    }
  }

  &.partner {
    width: 330px;

    & > .img-wrapper {
      position: relative;

      & > img {
        width: 100%;
        height: 180px;
      }
    }

    & > .title {
      margin-top: 10px;
    }

    & > .bottom > .tags {
      display: flex;
      gap: 6px;

      & > .tag {
        padding: 4px 10px;
        font-size: 12px;
        line-height: 133%;
        border-radius: 5px;
        color: #000;
      }
    }
  }

  &.detail {
    width: 330px;

    & > .img-wrapper {
      position: relative;

      & > img {
        width: 100%;
        height: 180px;
      }

      & > .badge-wrapper {
        position: absolute;
        left: 20px;
        top: 20px;
        display: flex;
        align-items: center;

        & > span {
          padding: 4px 10px;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 133%;
          color: #000;
          border-radius: 5px;

          /* From https://css.glass */
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);

          &.badge-time {
            background: rgba(0, 255, 133, 0.7);

            & > img {
              margin-right: 4px;
            }
          }

          &.badge-created {
            margin-left: 4px;
            background: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }

    & > .title {
      margin-top: 10px;
    }

    & > .title-detail {
      margin-top: 6px;
    }

    & > .bottom > .profile {
      display: flex;
      align-items: center;

      & > img {
        margin-right: 6px;
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }

      & > span {
        max-width: 223px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }

  &.simple {
    width: 245px;

    & > .img-wrapper > img {
      width: 100%;
      height: 245px;
    }

    & > .title-wrapper {
      & > .title-detail {
        margin-top: 10px;
        height: auto;
        max-height: 38px;
      }

      & > .title {
        margin-top: 2px;
      }
    }

    & > .bottom {
      justify-content: end;
    }
  }
`;
