import React, { useState } from "react";
import styled from "styled-components";
import { addLeadingZero } from "utils/utils";
const CareerCard = ({ data }) => {
  const changeBadgeArray = (data) => {
    if (!data) return;
    const dataTask = Object.entries(data)
      .filter(([key, value]) => typeof value === "boolean" && value === true)
      .map(([key]) => key);
    return dataTask;
  };

  return (
    <StyledWrapper>
      <InfoBox>
        <div className="card-header">
          <span className="project-title">
            <span>{data.COMPANY_NAME}</span>
            <span>/</span>
            <span>{data.PROJECT_NAME}</span>
          </span>
          <div className="date-group">
            <div className="flex">
              <span>
                {data.START_AT?.length === 0 || data.START_AT === null
                  ? ""
                  : addLeadingZero(data.START_AT?.split("-")[0]) +
                    ". " +
                    addLeadingZero(data.START_AT?.split("-")[1])}
              </span>
              {data.START_AT?.length > 0 && (
                <span style={{ fontSize: "16px", margin: "0 4px" }}>~</span>
              )}
              <span>
                {data.END_AT?.length === 0 || data.END_AT === null
                  ? ""
                  : addLeadingZero(data.END_AT?.split("-")[0]) +
                    ". " +
                    addLeadingZero(data.END_AT?.split("-")[1])}
              </span>
            </div>
          </div>
        </div>
      </InfoBox>
      <InputBox>
        <div className="textarea-wrapper">
          <p value={data?.PERFORMANCE} />
          {data?.PERFORMANCE}
        </div>
        {changeBadgeArray(data.INTERESTED_AI_TASK)?.length > 0 && (
          <>
            <Divider />
            <div>
              <div className="section-tit" style={{ marginBottom: "10px" }}>
                적용된 AI 기술
              </div>
              {changeBadgeArray(data.INTERESTED_AI_TASK)?.map((item, idx) => (
                <span
                  key={idx}
                  className="badge badge-md purple"
                  style={{
                    backgroundColor: "#F6F6F6",
                    marginRight: "6px",
                    marginBottom: "6px",
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
          </>
        )}
      </InputBox>
    </StyledWrapper>
  );
};

export default React.memo(CareerCard);

const StyledWrapper = styled.section`
  display: flex;
  width: 100%;
  margin: 5px 0;
  padding: 15px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 5px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  & input {
    font-weight: 700;
    &::placeholder {
      color: var(--mono-m-gray-01, #999);
    }
  }
  & .flex {
    display: flex;
  }
  &:hover {
    & button {
      opacity: 1;
    }
  }
`;
const InfoBox = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  & .card-header {
    display: inline-flex;
    align-items: center;

    & span.project-title {
      color: var(--mono-m-black01, #333);
      /* Body 600 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px; /* 132.857% */
      span {
        margin-right: 6px;
      }
    }
    & .date-group {
      color: var(--mono-m-black01, #333);
      /* Caption 500 */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px; /* 132.5% */
    }
  }
`;

const InputBox = styled.section`
  width: 100%;
  & .textarea-wrapper {
    color: var(--mono-m-black01, #333);
    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.6px; /* 132.857% */
  }
  & .section-tit {
    padding-top: 0;
  }
  & .badge {
    border-radius: 10px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    background: var(--mono-m-bg, #f6f6f6);
  }
`;
const Divider = styled.div`
  border-top: 1px solid var(--mono-m-gray03-stroke, #eee);
  width: 100%;
  margin: 10px 0;
`;
