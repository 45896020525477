import { ALARM_CASE_LIST } from "./data";

export const getParameterByName = (query, name) => {
  // 쿼리검색
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(query);
  return results == null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const dateFormat = (date, format) => {
  if (!date) return;
  const letters = (date) => {
    return date >= 10 ? date : "0" + date;
  };

  let month = letters(date.getMonth() + 1);
  let day = letters(date.getDate());
  let hours = letters(date.getHours());
  let min = letters(date.getMinutes());
  let sec = letters(date.getSeconds());

  switch (format) {
    case "yyyyMMddhhmmss":
      return `${date.getFullYear()}${month}${day}${hours}${min}${sec}`;
    default:
      return `${date.getFullYear()}-${month}-${day}`;
  }
};

export const dateFormatType2 = (date) => {
  if (!date) return;
  let dateArr = date.split("T");
  let timeArr = dateArr[1].split(":");

  return `${dateArr[0]} ${timeArr[0]}:${timeArr[1]}`;
};

export const dateFormatType2NoTime = (date) => {
  if (!date) return;
  let dateArr = date.split("T");

  return `${dateArr[0]}`;
};

export const dateFormatType2JustTime = (date) => {
  if (!date) return;
  let dateArr = date.split("T");
  let timeArr = dateArr[1].split(":");

  return `${timeArr[0]}:${timeArr[1]}`;
};

export const priceFormat = (price) => {
  if (!price) return 0;
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getGradeName = (num) => {
  switch (num) {
    case "IRON":
      return "아이언";
    case "BRONZE":
      return "브론즈";
    case "SILVER":
      return "실버";
    case "GOLD":
      return "골드";
    case "DIAMOND":
      return "다이아몬드";
    default:
      return;
  }
};

export const comma = (str) => {
  str = String(str);
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
};

export const uncomma = (str) => {
  str = String(str);
  return str.replace(/[^\d]+/g, "");
};

export const phoneFormat = (phoneNum) => {
  if (!phoneNum) return;
  return phoneNum
    .replace(/[^0-9]/g, "") // 숫자를 제외한 모든 문자 제거
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

export const getBirthday = (juminNum) => {
  return juminNum.replace(
    /^(\d{2})(\d{2})(\d{2})(\d{1})\d{6}$/,
    (match, year, month, day, yearTest) => {
      // console.log(year + '-' + month + '-' + day)
      let prefix = parseInt(yearTest) >= 3 ? "20" : "19";
      return prefix + year + "-" + month + "-" + day;
    }
  );
};

export const fileNameFormat = (filename) => {
  if (!filename) return;
  const maxLength = 15;
  const dot = /\.*?(\.[^.]+)$/;
  const nameRegex = /^(.*?)\./;
  const fileFormat = dot.exec(filename) ? dot.exec(filename)[1] : "";
  const fileNameNoFormat = nameRegex.exec(filename)
    ? nameRegex.exec(filename)[1]
    : filename;
  const fileNameNoFormatSlice = fileNameNoFormat.substring(0, maxLength);

  if (fileNameNoFormat.length > maxLength) {
    return fileNameNoFormatSlice + "..." + fileFormat;
  } else {
    return fileNameNoFormat + fileFormat;
  }
};

export const highlightIncludedText = (text = "", value = "") => {
  // console.log(text, value)
  text = text.replace(/(<([^>]+)>)/gi, "");
  text = text.replaceAll("&nbsp;", " ");
  const title = text.toLowerCase();
  const searchValue = value.toLowerCase();
  if (searchValue !== "" && title.includes(searchValue)) {
    const matchText = text.split(new RegExp(`(${searchValue})`, "gi"));
    return (
      <>
        {matchText.map((text, index) =>
          text.toLowerCase() === searchValue.toLowerCase() ? (
            <span key={index} className="txt-c--sub">
              {text}
            </span>
          ) : (
            text
          )
        )}
      </>
    );
  }

  return text;
};

export const birthdayFormat = (birthday) => {
  //yyyy-MM-dd => yyMMdd
  // console.log(birthday)
  return birthday.replace(/(\d{2})(\d{2})-(\d{2})-(\d{2})/, "$2$3$4");
};

export const nameMasking = (name) => {
  // 이름이 2글자인 경우에는 뒷자리만 마스킹
  if (name.length === 2) {
    return name[0] + "*";
  }

  // 이름이 3글자 이상인 경우에는 양 끝을 마스킹
  const masked = name.replace(
    /^(.).+(.)$/,
    function (match, firstChar, lastChar) {
      const stars = "*".repeat(match.length - 2);
      return firstChar + stars + lastChar;
    }
  );

  return masked;
};

export const alarmUrl = (alarmItem) => {
  const alarmCase = ALARM_CASE_LIST.find((item) => {
    return item.TYPE === alarmItem.TYPE;
  });
  if (alarmItem.TYPE === "CALCULATE_DONE") {
    // 예외 상황, CALCULATE_DONE인 경우 data값이 오지만 url뒤에 data값을 붙이지않아도 됨
    return alarmCase.URL;
  }
  return alarmCase.URL + (alarmItem.DATA || "") + (alarmCase.TAB || "");
};

export const alarmName = (alarmItem) => {
  const alarmCase = ALARM_CASE_LIST.find((item) => {
    return item.TYPE === alarmItem.TYPE;
  });
  return alarmCase?.NAME;
};

export const checkURL = (str) => {
  if (!str) return false;
  return str.startsWith("http://") || str.startsWith("https://");
};

export const tagReplace = (text) => {
  text = text.replace(/(<([^>]+)>)/gi, "");
  text = text.replaceAll("&nbsp;", " ");

  return text;
};

export const checkUrlSplitStr = (input) => {
  if (!input) return;
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._;\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_;\+.~#()?&//=]*)/;

  const match = input.match(urlRegex);

  if (match) {
    const url = match[0];
    const before = input.substring(0, match.index);
    const after = input.substring(match.index + url.length);
    return { url, before, after };
  } else {
    return { url: "", before: input, after: "" };
  }
};

export const areArraysEqual = (arr1, arr2) =>
  JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());

export const convertKeysToLowerCase = (obj) => {
  const convertedObject = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = key
        .toLowerCase()
        .replace(/_([a-z])/g, (match, p1) => p1.toUpperCase()); // Convert snake_case to camelCase
      convertedObject[snakeCaseKey] = obj[key];
    }
  }
  return convertedObject;
};

export const calculateWidth = (text) => {
  const defaultCharacterWidth = 12;
  const specialCharacterWidth = 10;
  const spaceWidth = 6;

  return Array.from(text)
    .map((char) => {
      if (/\s/.test(char)) {
        // Space character
        return spaceWidth;
      } else if (/[^\uAC00-\uD7A3a-zA-Z0-9]/.test(char)) {
        // Special character
        return specialCharacterWidth;
      } else {
        // Default character (including Hangul)
        return defaultCharacterWidth;
      }
    })
    .reduce((width, charWidth) => width + charWidth, 0);
};
export const addLeadingZero = (numberString) => {
  const parsedNumber = parseInt(numberString, 10);
  if (!isNaN(parsedNumber) && parsedNumber >= 1 && parsedNumber <= 9) {
    return `0${parsedNumber}`;
  } else {
    return numberString;
  }
};
export const breakString = (str, maxLength) => {
  const withoutSpacesAndLineBreaks = str.replace(/[\n\r]/g, "");

  return withoutSpacesAndLineBreaks.slice(0, maxLength);
};
