import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ToggleButton from "components/Button/ToggleButton";
import Ibusiness from "assets/images/icon/icon_business.svg";
import Icareer from "assets/images/icon/icon_career.svg";
import Ilocation from "assets/images/icon/icon_location.svg";
import Itype from "assets/images/icon/icon_type.svg";

const SelectComponent = ({
  type = "full",
  className,
  isLabeled = true,
  label = "Label name",
  placeholder = "Placeholder",
  value,
  defaultValue,
  selectOption,
  icon = "type",
  isIcon = false,
  onChange,
  selectable = false,
  readOnly = false,
  disabled = false,
  handleEvent,
  toggleOpt = false,
  handleToggle,
  style = {},
  mobile,
  ...restProps
}) => {
  const [selectValue, setSelectValue] = useState(
    defaultValue
      ? selectOption.find((item) => item.id === defaultValue)?.name ||
          placeholder
      : placeholder
  );
  const handleOption = (value) => {
    handleEvent(value);
    setSelectValue(value.name);
  };
  const handleIcon = (icon) => {
    switch (icon) {
      case "business":
        return Ibusiness;
      case "career":
        return Icareer;
      case "type":
        return Itype;
      case "location":
        return Ilocation;
      default:
        return Itype;
    }
  };
  useEffect(() => {
    if (defaultValue) {
      const selectedName =
        selectOption.find((item) => item.id === defaultValue)?.name ||
        placeholder;
      setSelectValue(selectedName);
    }
    if (defaultValue === null) {
      setSelectValue(selectOption[0]?.name);
    }
  }, [defaultValue, selectOption, placeholder]);

  return (
    <StyledWrapper {...restProps} type={type}>
      {isIcon && <Icon src={handleIcon(icon)} alt={icon} />}
      {isLabeled && <label htmlFor="input">{label}</label>}
      {disabled ? (
        <Select style={style} className="disabled" {...restProps}>
          {selectValue}
        </Select>
      ) : (
        <Select
          style={{ paddingLeft: isIcon ? "42px" : "18px" }}
          className={toggleOpt ? "active" : ""}
          onClick={handleToggle}
          {...restProps}
        >
          <span>{selectValue}</span>
          <ToggleButton toggleOption={toggleOpt} />
          {toggleOpt && (
            <Option>
              {selectOption.map((item, idx) => (
                <li key={idx} onClick={() => handleOption(item)}>
                  {item.name}
                </li>
              ))}
            </Option>
          )}
        </Select>
      )}
    </StyledWrapper>
  );
};

export default SelectComponent;

const StyledWrapper = styled.div`
  min-height: 50px;
  width: ${(props) => (props.type === "full" ? "100%" : "50%")};
  display: inline-flex;
  align-items: center;

  label {
    width: 65px;
    color: #999;
    text-align: start;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin-right: 10px;
  }
`;
const Select = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => (props.isLabeled ? "calc(100% - 75px)" : "100%")};
  height: 50px;
  padding: 15.5px 18px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--stroke_gray04, #eee);
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  cursor: pointer;
  &.active {
    border-color: #0bb899;
  }
  &.disabled {
    background: #f7f7f7;
    cursor: not-allowed;
  }

  span {
    width: 100%;
  }
  button {
    padding: 0;
    text-align: right;
    background: transparent;
    border: none;
    pointer-events: none;
  }
  &:disabled {
    border-radius: 5px;
    border: 1px solid var(--stroke_gray04, #eee);
    background: #f7f7f7;
    cursor: not-allowed;
  }
`;
const Option = styled.div`
  background: #fff;
  z-index: 9999;
  position: absolute;
  top: 56px;
  width: 100%;
  /* height: 212px; */
  gap: 114px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  left: 0;
  overflow-y: auto;
  & li {
    cursor: pointer;
    height: 45px;
    padding: 0 18px;
    font-size: 14px;
    list-style: none;
    display: flex;
    align-items: center;
    text-indent: 10px;
    color: #999;
    &:hover {
      background: var(--mono-m-bg, #f6f6f6);
    }
  }
`;
const Icon = styled.img`
  position: absolute;
  padding-left: 18px;
`;
