import useViewCheck from "hooks/useViewCheck";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
const StyledPagination = styled.ul`
  padding-bottom: ${(props) => props.floatingPage && "90px"};
`;
const Pagination = ({ paginationData, page, setPage, floatingPage }) => {
  const { isMobile } = useViewCheck();
  const [navigatePageNum, setNavigatePageNum] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    const arrLength = isMobile ? 3 : 5;
    const lastPage = paginationData.pages;
    const divide =
      Math.floor(lastPage / arrLength) +
      (Math.floor(lastPage % arrLength) > 0 ? 1 : 0);
    const pagesArr = Array(lastPage)
      .fill()
      .map((v, lastPage) => lastPage + 1);
    const result = [];
    for (let i = 0; i < divide; i++) {
      result.push(pagesArr.splice(0, arrLength));
    }
    setNavigatePageNum(result);
  }, [isMobile, paginationData]);

  useEffect(() => {
    for (let i = 0; i < navigatePageNum.length; i++) {
      if (navigatePageNum[i].includes(page)) {
        setPageIndex(i);
      }
    }
  }, [page, navigatePageNum]);

  if (!paginationData.pages) return;

  return (
    <StyledPagination className={`pagination`}>
      <li className="pagination-item arrow first">
        <button
          type="button"
          className="btn"
          onClick={() => page <= 1 || setPage(1)}
        ></button>
      </li>
      <li className="pagination-item arrow prev">
        <button
          type="button"
          className="btn"
          onClick={() => page <= 1 || setPage(page - 1)}
        ></button>
      </li>
      <li className="pagination-item">
        {pageIndex !== 0 && (
          <button
            type="button"
            className={`btn`}
            onClick={() => {
              setPageIndex(pageIndex - 1);
              setPage(
                navigatePageNum[pageIndex - 1][
                  navigatePageNum[pageIndex - 1].length - 1
                ]
              );
            }}
          >
            ...
          </button>
        )}
        {navigatePageNum[pageIndex]?.map((item) => (
          <button
            type="button"
            className={`btn ${page === item ? "active" : ""}`}
            key={`pagination-btn-${item}`}
            onClick={() => setPage(item)}
          >
            {item}
          </button>
        ))}

        {pageIndex + 1 !== navigatePageNum.length && (
          <button
            type="button"
            className={`btn`}
            onClick={() => {
              setPageIndex(pageIndex + 1);
              setPage(navigatePageNum[pageIndex + 1][0]);
            }}
          >
            ...
          </button>
        )}
      </li>
      <li className="pagination-item arrow next">
        <button
          type="button"
          className="btn"
          onClick={() => page >= paginationData.pages || setPage(page + 1)}
        ></button>
      </li>
      <li className="pagination-item arrow last">
        <button
          type="button"
          className="btn"
          onClick={() =>
            page >= paginationData.pages || setPage(paginationData.pages)
          }
        ></button>
      </li>
    </StyledPagination>
  );
};

export default Pagination;
