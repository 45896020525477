import React, { useState } from "react";
import useModal from "hooks/useModal";
import { userConsultingRequest, userConsultantRequest } from "services/user";
import { userSolutionApply } from "services/solution";
import { aiSkillData } from "utils/data";
import useViewCheck from "hooks/useViewCheck";
import styled from "styled-components";
const StyledModal = styled.div`
  & .word-break {
    word-break: keep-all;
  }
`;
const ConsultModal = ({ type }) => {
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const [category, setCategory] = useState([]);
  const [details, setDetails] = useState("");

  const submitHandler = () => {
    const body = {
      details,
    };

    let tempAiSkill = {};
    aiSkillData.forEach((item) => {
      tempAiSkill = {
        ...tempAiSkill,
        [item.id]: category.includes(item.id),
      };
    });

    body.category = tempAiSkill;
    switch (type) {
      case "consulting":
        userConsultingRequest(body, (res) => {
          if (res.code === 0) {
            openModal({
              type: "messageModal",
              contents: {
                title: "컨설팅 의뢰 완료",
                message:
                  "컨설팅 의뢰가 완료되었어요.\n담당 매니저를 통해 곧 연락 드릴게요.",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          } else {
            openModal({
              type: "messageModal",
              contents: {
                title: "요청에 실패했습니다",
                message:
                  "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          }
        });
        break;
      case "consultant":
        userConsultantRequest(body, (res) => {
          if (res.code === 0) {
            openModal({
              type: "messageModal",
              contents: {
                title: "컨설턴트 신청 완료",
                message:
                  "컨설턴트 신청이 완료되었어요.\n담당 매니저를 통해 곧 연락 드릴게요.",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          } else {
            openModal({
              type: "messageModal",
              contents: {
                title: "요청에 실패했습니다",
                message:
                  "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          }
        });
        break;
      case "solution":
        userSolutionApply(body, (res) => {
          if (res.code === 0) {
            openModal({
              type: "messageModal",
              contents: {
                title: "요청 완료",
                message:
                  "솔루션 등록요청이 완료되었어요.\n내부 검토 후 담당 매니저를 통해 곧 연락 드릴게요.",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          } else {
            openModal({
              type: "messageModal",
              contents: {
                title: "요청에 실패했습니다",
                message:
                  "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                },
              },
            });
          }
        });
        break;
      default:
        return;
    }
  };

  return (
    <StyledModal className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con message">
        {type === "consulting" ? (
          <div className="text">
            <p className="strong">컨설팅 의뢰하기</p>
            <p>
              컨설팅 받고 싶은 AI 분야를 선택해 주세요.{" "}
              <br className="m-block" />
              (중복 선택 가능해요)
            </p>
          </div>
        ) : type === "consultant" ? (
          <div className="text">
            <p className="strong">컨설턴트 신청하기</p>
            <p>
              컨설팅 하고 싶은 AI 분야를 선택해 주세요.{" "}
              <br className="m-block" />
              (중복 선택 가능해요)
            </p>
          </div>
        ) : (
          <div className="text word-break ">
            <p className="strong">솔루션 등록 요청하기</p>
            <p>
              현재 보유하고 계신 솔루션의 기술 분야와 특성 및
              <br className="m-block" />
              장점에 대해 간단히 설명해 주세요.
              <br />
              (기술 분야 선택 시 중복 선택이 가능해요.)
            </p>
          </div>
        )}
        <div className="checkbox-list type2 mt-20">
          {aiSkillData.map((item) => (
            <label
              className="input-checkbox"
              key={`consult-aiskill-${item.id}`}
            >
              <input
                type="checkbox"
                checked={category.includes(item.id)}
                onChange={() => {
                  let tempArr = [...category];
                  if (tempArr.includes(item.id)) {
                    tempArr = tempArr.filter((a) => {
                      return a !== item.id;
                    });
                  } else {
                    tempArr.push(item.id);
                  }
                  setCategory([...tempArr]);
                }}
              />
              <span className="box"></span>
              <span className="text">{item.name}</span>
            </label>
          ))}
        </div>
        <div className="checkbox-list mt-10">
          <textarea
            className="input-textarea black"
            placeholder={`${
              type === "consulting"
                ? "어떤 부분이 궁금하신가요? 간략하게 작성해 주셔도 좋아요.(1,000자 이내)"
                : type === "consultant"
                ? "간단한 본인 소개를 해주세요.(1,000자 이내)"
                : "솔루션의 특성 및 장점에 대해 1,000자 이내로 작성해 주세요."
            }`}
            rows={isMobile ? "3" : "10"}
            maxLength={1000}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          ></textarea>
        </div>
        <div className="text sub">{details.length}/1000</div>
        <div className="btn-wrap">
          <button
            type="button"
            className="btn btn-half btn-primary"
            onClick={() => {
              submitHandler();
            }}
          >
            {type === "consulting"
              ? "의뢰하기"
              : type === "consultant"
              ? "신청하기"
              : "요청하기"}
          </button>
          <button
            type="button"
            className="btn btn-half btn-grey"
            onClick={() => closeModal()}
          >
            취소
          </button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ConsultModal;
