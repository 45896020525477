import api from 'api';

export const emailAuthCheck = (username, callback) => {
	api.post('/user/email-check', {
		username
	}).then((res) => {
		callback(res)
	})
};


export const authProfile = (callback) => {
	api.get('/user/auth/profile')
		.then((res) => {
			callback(res)
		})
}

export const authBusinessInfo = (callback) => {
	api.get('/user/auth/business-info').then((res) => callback(res))
}

export const userRegister = (body, callback) => {
	api.post('/user/register', body)
		.then((res) => {
			callback(res)
		})
}

export const userWithdrawal = (body, callback) => {
	api.post('/user/auth/withdrawal', body).then((res) => callback(res))
}

export const kmcTrCert = (randomStr, type, callback) => {
	api.get(`/kmc/certification/base`, { params: { certNum: randomStr, type } }).then((res) => {
		callback(res)
	})
}

export const userDIUserCheck = (di, callback) => {
	api.post(`/user/certification-check`, di).then((res) => {
		callback(res)
	})
}

export const userNickNameCheck = (nickname, callback) => {
	api.post('/user/nickname/check', { nickname })
		.then((res) => {
			callback(res)
		})
}

export const businessApply = (body, callback) => {

	api.post('/user/auth/business/apply', body)
		.then((res) => {
			callback(res)
		})
}

export const nicknameChangeAvailable = (callback) => {
	api.get('/user/auth/nicknameChangeDate/check').then(res => callback(res))
}

export const userNicknameChange = (nickname, callback) => {
	api.post('/user/auth/nickname/modify', { nickname }).then(res => callback(res))
}

export const userPasswordChange = (body, callback) => {
	api.post('/user/auth/password/change', body).then(res => callback(res))
}

export const userProfileImgChange = (profileImg, callback) => {
	api.post('/user/auth/profile/change', { profileImg }).then(res => callback(res))
}

export const userHeroesView = (callback) => {
	api.get('/heros/auth/view').then(res => callback(res))
}

export const applyHeroes = (body, callback) => {
	api.post('/heros/auth/apply', body).then(res => callback(res))
}

export const userInquiry = (body, callback) => {
	api.post('/inquiry/write', body).then(res => callback(res))
}

export const helpSearch = (params, callback) => {
	api.get('/cs/search', { params }).then(res => callback(res))
}

export const getNoticeList = (params, callback) => {
	api.get('/cs/notice/list', { params }).then(res => callback(res))
}

export const getNoticeDetail = (seq, callback) => {
	api.get(`/cs/notice/view/${seq}`, { params: { seq } }).then(res => callback(res))
}

export const getFAQList = (params, callback) => {
	api.get('/cs/faq/list', { params }).then(res => callback(res))
}

export const getFAQDetail = (seq, callback) => {
	api.get(`/cs/faq/view/${seq}`, { params: { seq } }).then(res => callback(res))
}

export const userCareerView = (callback) => {
	api.get('/career/auth/view').then(res => callback(res))
}

export const userCareerApply = (body, callback) => {
	api.post('/career/auth/apply', body).then(res => callback(res))
}

export const userCareerModify = (seq, body, callback) => {
	api.put(`/career/auth/modify/${seq}`, body).then(res => callback(res))
}

export const userCareerRemove = (seq, callback) => {
	api.delete(`/career/auth/remove/${seq}`).then(res => callback(res))
}

export const userEducationView = (callback) => {
	api.get('/education/auth/view').then(res => callback(res))
}

export const userEducationApply = (body, callback) => {
	api.post('/education/auth/apply', body).then(res => callback(res))
}

export const userEducationModify = (seq, body, callback) => {
	api.put(`/education/auth/modify/${seq}`, body).then(res => callback(res))
}

export const userEducationRemove = (seq, callback) => {
	api.delete(`/education/auth/remove/${seq}`).then(res => callback(res))
}

export const userCertificateView = (callback) => {
	api.get('/certificate/auth/view').then(res => callback(res))
}

export const userCertificateApply = (body, callback) => {
	api.post('/certificate/auth/apply', body).then(res => callback(res))
}

export const userCertificateModify = (seq, body, callback) => {
	api.put(`/certificate/auth/modify/${seq}`, body).then(res => callback(res))
}

export const userCertificateRemove = (seq, callback) => {
	api.delete(`/certificate/auth/remove/${seq}`).then(res => callback(res))
}

export const userConsultingRequest = (body, callback) => {
	api.post('/consult/auth/consulting/request', body).then(res => callback(res))
}

export const userConsultantRequest = (body, callback) => {
	api.post('/consult/auth/consultant/request', body).then(res => callback(res))
}

export const userSolutionPurchaseRequest = (seq, callback) => {
	api.post(`/solution/auth/purchase/${seq}`, null, { params: { seq } }).then(res => callback(res))
}

export const userPasswordResetMailSend = (di, callback) => {
	api.post('/user/password-reset/mail', null, { params: { di } }).then(res => callback(res))
}

export const userPasswordResetChange = (key, body, callback) => {
	api.post('/user/password-reset/change', body, { params: { key } }).then(res => callback(res))
}

export const authPhoneNumModify = (body, callback) => {
	api.post('/user/auth/phone/modify', body).then(res => callback(res))
}
