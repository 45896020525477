import useModal from 'hooks/useModal'
import React from 'react'

const WhiteModal = ({ title, message, confirmText, confirmCallback, closedBtn = true }) => {
	const { closeModal } = useModal();

	return (
		<div className="modal show">
			<div className="modal-dim"></div>
			<div className="modal-con lg p-50">
				{closedBtn && <button type="button" className="btn btn-close wh" onClick={() => closeModal()}></button>}
				<div className="modal-tit">{title}</div>
				<div className="text-box" style={{
					wordBreak: 'break-all'
				}}>
					{message}
				</div>
				<div className="btn-wrap">
					<button type="button" className="btn btn-lg btn-black type2" onClick={() => confirmCallback()}>확인</button>
				</div>
			</div>
		</div>
	)
}

export default WhiteModal