export const emailRFC5322RegEx = (email) => {
	const reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z]){0,63}@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/
	return reg.test(email);
}

export const nickNameRegEx = (nickname) => {
	const reg = /^(?!^[0-9]*$)[A-Za-z0-9가-힣]{2,20}$/
	return reg.test(nickname);
}

export const passwordRegEx = (password) => {
	const reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+]).{8,16}$/
	return reg.test(password);
}

export const juminNumRegEx = (juminNum) => {
	let reg = /^\d{6}-?\d{7}$/;
	return reg.test(juminNum)
}

export const onlyAlphabet = (e) => {
	return e.target.value.replace(/[^\\!-z]/gi, "");
}