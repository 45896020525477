import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import Select from "components/common/Select";
import { projectOfferList, projectOffer } from "services/projectManagement";
import styled from "styled-components";

const ProjectSuggestionModal = ({ id, heroesName, callback }) => {
  const { openModal, closeModal } = useModal();
  const [projectList, setProjectList] = useState([
    {
      id: null,
      name: "프로젝트를 선택해 주세요.",
    },
  ]);
  const [projectSelect, setProjectSelect] = useState(null);

  useEffect(() => {
    projectOfferList(id, (res) => {
      let tempArr = [];
      for (let i = 0; i < res.response.length; i++) {
        tempArr.push({
          id: res.response[i].SEQ,
          name: res.response[i].PROJECT_NAME,
        });
      }
      setProjectList([
        {
          id: null,
          name: "프로젝트를 선택해 주세요.",
        },
        ...tempArr,
      ]);
    });
  }, [id]);

  return (
    <div className="modal show">
      <div className="modal-dim"></div>
      <StyledBtnGroup className="modal-con message">
        <div className="text">
          <p className="strong">프로젝트 제안</p>
          <p>프로젝트의 이름을 확인 후 제안하세요.</p>
        </div>
        <div className="input">
          <Select
            width={"100%"}
            className={["full color-w"]}
            option={projectList}
            selected={projectSelect}
            setSelect={setProjectSelect}
          />
        </div>
        <div className="btn-wrap">
          <button
            type="button"
            className="btn btn-half btn-grey"
            onClick={() => {
              closeModal();
            }}
          >
            돌아가기
          </button>
          <button
            type="button"
            className="btn btn-full btn-primary"
            onClick={() => {
              const params = {
                projectSeq: projectSelect,
                userSeq: id,
              };

              projectOffer(params, (res) => {
                if (res.code === 0) {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "제안 완료",
                      message: `[${heroesName}]님에게 프로젝트 지원을 제안했어요.`,
                      confirmText: "확인",
                      confirmCallback: () => {
                        callback();
                        closeModal();
                      },
                    },
                  });
                } else {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "요청에 실패했습니다",
                      message:
                        "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                      confirmText: "확인",
                      confirmCallback: () => {
                        closeModal();
                        closeModal();
                      },
                    },
                  });
                }
              });
            }}
          >
            제안하기
          </button>
        </div>
      </StyledBtnGroup>
    </div>
  );
};

export default ProjectSuggestionModal;

const StyledBtnGroup = styled.div`
  & .btn-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;
    margin: 0;
    margin-top: 25px;
    & .btn-half {
      width: 94px;
      height: 50px;
      margin: 0;
    }
    & .btn-full {
      width: 200px;
      height: 50px;
      margin: 0;
    }
  }
`;
