import Pagination from "components/common/Pagination";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import useViewCheck from "hooks/useViewCheck";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  aiProjectAnswerDelete,
  aiProjectAnswerEdit,
  aiProjectAnswerWrite,
  aiProjectApply,
  aiProjectApplyCheck,
  aiProjectlike,
  aiProjectQuestionDelete,
  aiProjectQuestionEdit,
  aiProjectQuestionWrite,
  getAiProjectDetailRequest,
  getAiProjectQnaList,
  getAiProjectRecommend,
} from "services/aiproject";
import { userAddInfo, userAddInfoCheck } from "services/heros";
import styled from "styled-components";
import { aiIndustryData, aiSkillData } from "utils/data";
import { breakString, dateFormatType2, priceFormat } from "utils/utils";

import heartFill from "assets/images/icon/heart_fill.svg";
import heartLine from "assets/images/icon/heart_line.svg";
import logo from "assets/images/logo.svg";
import MetaTag from "MetaTag";
const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & .project-title {
    max-width: 640px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .btn-like {
    width: 26px;
    height: 100%;
    min-width: 26px;
    background-size: 100%;
    background-image: none;
    & img {
      transition: opacity 0.3s ease-in-out;
    }
    /* Define styles for heartFill and heartLine */
    .heartFill {
      opacity: 1;
    }

    .heartLine {
      opacity: 0.4;
    }

    /* Apply transition to opacity for a smooth fade effect */
  }
`;
const PAY_METHOD = {
  SALARY: "월급",
  WAGE: "주급",
  REWARD: "프로젝트 완료 후",
};
const AiProjectDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [isLoggedin, setLoggedin] = useState(false);
  const [applyCheck, setApplyCheck] = useState(true);
  const [data, setData] = useState({});
  const [recommendList, setRecommendList] = useState([]);
  const [qnaList, setQnaList] = useState([]);
  const [qnaPage, setQnaPage] = useState(1);
  const [qnaAuthList, setQnaAuthList] = useState(false);
  const [isQuestionWriting, setQuestionWriting] = useState(false);
  const [activeQ, setActiveQ] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [editQuestion, setEditQuestion] = useState({
    id: null,
    text: "",
  });
  const [newAnswer, setNewAnswer] = useState({
    id: null,
    text: "",
  });
  const [editAnswer, setEditAnswer] = useState({
    id: null,
    text: "",
  });
  const [paginationData, setPaginationData] = useState(null);

  const textareaRef = useRef(null);
  const replyTextareaRef = useRef(null);
  const [description, setDescription] = useState("");
  useEffect(() => {
    setUserId(auth.userInfo.SEQ);
    setUserRole(auth.userInfo.ROLE?.split("_") || []);
    setLoggedin(auth.isLoggedin);
  }, [auth]);

  useEffect(() => {
    if (!Number.isInteger(Number(id))) {
      navigate("/aiproject");
      return;
    }

    getAiProjectDetailRequest(id, (res) => {
      setData(res.response);
    });
  }, [id]);
  useEffect(() => {
    if (isLoggedin) {
      aiProjectApplyCheck(id, (res) => {
        setApplyCheck(res.response);
      });
    }
  }, [id, isLoggedin]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    const params = {
      type: data.PROJECT_TYPE,
      payMethod: data.PAY_METHOD,
      workMethod: data.WORK_METHOD,
      seq: id,
    };

    getAiProjectRecommend(params, (res) => {
      setRecommendList(res.response);
    });
  }, [data]);

  useEffect(() => {
    getQnaList();
  }, [id, qnaPage, qnaAuthList]);

  const getQnaList = useCallback(() => {
    const params = {
      page: qnaPage,
    };
    getAiProjectQnaList(qnaAuthList, id, params, (res) => {
      setQnaList(res.response.list);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
  }, [id, qnaPage, qnaAuthList]);

  const applyProject = () => {
    // 파트너 필수 추가 정보 여부 조회 API
    userAddInfoCheck((res) => {
      if (res.code !== 0) {
        console.log("[ERROR]", res.code);
        return;
      }

      const offer = data.PAY_NEGOTIABLE;
      const hasPartnerInfo = res.response;
      if (hasPartnerInfo) {
        // 2차정보 있음
        if (offer) {
          // 금액 제안 모달 open
          openOfferModal();
        } else {
          // AI프로젝트 지원하기
          applyAiProject(id);
        }
      } else {
        // 2차정보 없음 > 상세정보 추가 입력 모달 open
        openAddInfoModal(offer);
      }
    });
  };

  // 금액 제안 모달 open
  const openOfferModal = () => {
    openModal({
      type: "offerModal",
      contents: {
        postId: id,
        postTitle: data.PROJECT_NAME,
        clientPayMethod: data.PAY_METHOD,
        clientAmount: data.BUDGET_MIN,
        callback: () => {
          getAiProjectDetailRequest(id, (res) => {
            setData(res.response);
          });
          aiProjectApplyCheck(id, (res) => {
            setApplyCheck(res.response);
          });
        },
      },
    });
  };

  // AI프로젝트 지원하기
  const applyAiProject = (id) => {
    aiProjectApply(id, {}, (res) => {
      if (res.code === 0) {
        openModal({
          type: "messageModal",
          contents: {
            title: "지원 완료",
            message: `[${data.PROJECT_NAME}]에 지원이 성공적으로 완료되었어요.\n지원 및 매칭에 대한 안내 메일을 보내드릴게요.`,
            confirmText: "확인",
            confirmCallback: () => {
              getAiProjectDetailRequest(id, (res) => {
                setData(res.response);
              });
              aiProjectApplyCheck(id, (res) => {
                setApplyCheck(res.response);
              });
              closeModal();
            },
          },
        });
      } else {
        openModal({
          type: "messageModal",
          contents: {
            title: "요청에 실패했습니다",
            message:
              "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      }
    });
  };

  // 상세정보 추가 모달 open
  const openAddInfoModal = (offer) => {
    const type = userRole.includes("BUSINESS") ? "business" : "individual";
    openModal({
      type: "aiProjectAddInfoModal",
      contents: {
        type: type,
        offer: offer,
        applyCallback: (data) => {
          // 2차정보 저장
          userAddInfo(data, (res) => {
            if (res.code === 0) {
              // AI프로젝트 지원하기
              applyAiProject(id);
            } else {
              console.error("[ERROR]:", res);
            }
          });
        },
      },
    });
  };

  const reportPostModalOpen = (reportType, id) => {
    if (!isLoggedin) return;
    openModal({
      type: "reportModal",
      contents: {
        reportType,
        id,
      },
    });
  };

  const deleteQnA = (id, type) => {
    openModal({
      type: "messageModal",
      contents: {
        title: type === "q" ? "질문 삭제" : "답글 삭제",
        message: "삭제된 글은 다시 수정할 수 없어요.\n정말 삭제하시겠어요?",
        confirmText: "삭제하기",
        confirmCallback: () => {
          if (type === "q") {
            aiProjectQuestionDelete(id, (res) => {
              getQnaList();
              closeModal();
            });
          } else {
            aiProjectAnswerDelete(id, (res) => {
              getQnaList();
              closeModal();
            });
          }
        },
        cancelText: "돌아가기",
        cancelCallback: () => {
          closeModal();
        },
      },
    });
  };

  const onQuestionSend = useCallback(() => {
    const body = {
      contents: newQuestion,
    };
    aiProjectQuestionWrite(id, body, (res) => {
      getQnaList();
      setNewQuestion("");
      setQuestionWriting(false);
      textareaRef.current.style.height = "21px";
    });
  }, [newQuestion]);

  const onAnswerSend = useCallback(() => {
    const body = {
      contents: newAnswer.text,
    };
    aiProjectAnswerWrite(newAnswer.id, body, (res) => {
      getQnaList();
      setNewAnswer({
        id: null,
        text: "",
      });
    });
  }, [newAnswer]);

  const onAnswerEdit = useCallback(() => {
    const body = {
      contents: editAnswer.text,
    };
    aiProjectAnswerEdit(editAnswer.id, body, (res) => {
      getQnaList();
      setEditAnswer({
        id: null,
        text: "",
      });
      replyTextareaRef.current.style.height = "21px";
    });
  }, [editAnswer]);

  const onQuestionEdit = useCallback(() => {
    const body = {
      contents: editQuestion.text,
    };
    aiProjectQuestionEdit(editQuestion.id, body, (res) => {
      getQnaList();
      setEditQuestion({
        id: null,
        text: "",
      });
      replyTextareaRef.current.style.height = "21px";
    });
  }, [editQuestion]);

  const handleTextareaResizeHeight = () => {
    textareaRef.current.style.height = "21px";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };

  useEffect(() => {
    if (!replyTextareaRef.current) return;
    handleReplyTextareaResizeHeight();
  }, [replyTextareaRef, editQuestion, editAnswer]);

  const handleReplyTextareaResizeHeight = () => {
    replyTextareaRef.current.style.height = "21px";
    replyTextareaRef.current.style.height =
      replyTextareaRef.current.scrollHeight + "px";
  };

  return (
    <div className="container">
      <MetaTag
        title={
          data?.PROJECT_NAME ? data.PROJECT_NAME : "프로젝트 | AI 히어로즈"
        }
        desc={
          data?.PROJECT_DETAILS
            ? breakString(data.PROJECT_DETAILS, 80)
            : "당신의 프로젝트를 시작하세요. 검증된 각 분야 전문가와의 최적의 매칭을 경험하고 원하는 결과를 얻어보세요."
        }
        imgSrc={data?.BANNER ? data.BANNER : logo}
        robot={true}
      />
      <div className="section">
        <button
          onClick={() => {
            navigate("/aiproject");
          }}
          type="button"
          className="btn top-back"
        >
          리스트로 돌아가기
        </button>
        <div className="project-detail">
          <div className="left">
            <div className="project-list--item">
              <div className="top">
                {isMobile && (
                  <div
                    style={{
                      marginTop: -20,
                      marginBottom: 12,
                    }}
                  >
                    <span className="badge black auto">
                      공고 마감일 D-{data.DDAY === 0 ? "DAY" : data.DDAY}
                    </span>
                  </div>
                )}
                {/* {data.NEW === 1 && (
                  <span className="badge badge-round black type2">NEW</span>
                )} */}
                {/* {data.CLOSE_STAUTS === "COMPLETE" && (
                  <span className="badge badge-round done">완료</span>
                )}
                {data.CLOSE_STAUTS === "PROGRESS" && (
                  <span className="badge badge-round done">진행 중</span>
                )}
                {data.CLOSE_STAUTS === "MATCHING" && (
                  <span className="badge badge-round done">매칭 중</span>
                )} */}
                {data.PROJECT_TYPE === "BUSINESS" ? (
                  <span className="badge badge-round primary">
                    기업 프로젝트
                  </span>
                ) : (
                  <span className="badge badge-round blue">개인 프로젝트</span>
                )}
                {(data.WORK_METHOD === "BOTH" ||
                  data.WORK_METHOD === "RESIDENT") && (
                  <span className="badge badge-round orange">상주</span>
                )}
                {(data.WORK_METHOD === "BOTH" ||
                  data.WORK_METHOD === "REMOTE") && (
                  <span className="badge badge-round blue-line">원격</span>
                )}
                {!isMobile && (
                  <div className="right">
                    {data.DDAY < 0 ? (
                      <span className="badge black auto done">공고 마감</span>
                    ) : (
                      <span className="badge black auto">
                        공고 마감일 D-{data.DDAY === 0 ? "DAY" : data.DDAY}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <StyledButtonWrapper className="tit">
                <div className="project-title">{data.PROJECT_NAME}</div>
                {userRole?.includes("HEROS") && (
                  <button
                    type="button"
                    className={`btn btn-like ${
                      data.PROJECT_LIKE ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      aiProjectlike(id, (res) => {
                        if (res.code === 0) {
                          getAiProjectDetailRequest(id, (res) => {
                            setData(res.response);
                          });
                        }
                      });
                    }}
                    style={{ marginLeft: 6 }}
                  >
                    <img
                      alt="like"
                      className={data.PROJECT_LIKE ? "heartFill" : "heartLine"}
                      src={data.PROJECT_LIKE ? heartFill : heartLine}
                    />
                  </button>
                )}
              </StyledButtonWrapper>
              <div className="info">
                <span className="text">
                  예산 및 급여 방식:
                  {data.PAY_NEGOTIABLE ? (
                    <span
                      className="badge badge-sm skyblue"
                      style={{ marginLeft: 10 }}
                    >
                      제안 가능
                    </span>
                  ) : (
                    <>
                      &nbsp;{priceFormat(data.BUDGET_MIN)}~
                      {priceFormat(data.BUDGET_MAX)}만 원
                      <br className="m-block" />
                      <span className="badge badge-sm skyblue">
                        {PAY_METHOD[data.PAY_METHOD]}
                      </span>
                    </>
                  )}
                </span>
                <span className="text">
                  기간:
                  {data.PERIOD_NEGOTIABLE ? (
                    <span
                      className="badge badge-sm skyblue"
                      style={isMobile ? { marginLeft: 10 } : {}}
                    >
                      협의 가능
                    </span>
                  ) : (
                    <>
                      &nbsp;
                      {data.PROJECT_PERIOD % 4 === 0
                        ? data.PROJECT_PERIOD / 4
                        : data.PROJECT_PERIOD}
                      {data.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                    </>
                  )}
                </span>
                <span className="text">
                  데이터 보유 현황:{" "}
                  {data.HAVE_DATA ? "데이터 있음" : "데이터 없음"}
                </span>
              </div>
            </div>
            <div className="profile-sub">키워드</div>
            <div className="profile-badges">
              <div className="row">
                <label className="label">전문 분야</label>
                {data.AI_INDUSTRY && (
                  <div className="badges">
                    {Object.keys(data.AI_INDUSTRY).map((key) => {
                      if (data.AI_INDUSTRY[key]) {
                        const { name } = aiIndustryData.find(
                          (v) => v.id.toUpperCase() === key
                        );
                        return (
                          <span
                            key={key}
                            className="badge m5 badge-md primary2"
                          >
                            {name}
                          </span>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
              <div className="row">
                <label className="label">보유 스킬</label>
                {data.AI_SKILL && (
                  <div className="badges">
                    {Object.keys(data.AI_SKILL).map((key) => {
                      if (data.AI_SKILL[key]) {
                        const formattedKey =
                          key.toUpperCase() === "BIG_DATA"
                            ? "BIGDATA"
                            : key.toUpperCase();
                        const { name } = aiSkillData.find(
                          (v) => v.id.toUpperCase() === formattedKey
                        );
                        return (
                          <span
                            key={key}
                            className="badge m5 badge-md primary2"
                          >
                            {name}
                          </span>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
              {(data?.INTERESTED_AI_TASK?.MACHINE_LEARNING ||
                data?.INTERESTED_AI_TASK?.DEEP_LEARNING ||
                data?.INTERESTED_AI_TASK?.CLASSIFICATION ||
                data?.INTERESTED_AI_TASK?.CONVOLUTION ||
                data?.INTERESTED_AI_TASK?.CNN ||
                data?.INTERESTED_AI_TASK?.RNN ||
                data?.INTERESTED_AI_TASK?.CAM ||
                data?.INTERESTED_AI_TASK?.OCR ||
                data?.INTERESTED_AI_TASK?.STT ||
                data?.INTERESTED_AI_TASK?.TTS ||
                data?.INTERESTED_AI_TASK?.NLP ||
                data?.INTERESTED_AI_TASK?.GAN ||
                data?.INTERESTED_AI_TASK?.DEEP_FAKE ||
                data?.INTERESTED_AI_TASK?.OBJECT_DETECTION ||
                data?.INTERESTED_AI_TASK?.ANOMALY_DETECTION ||
                data?.INTERESTED_AI_TASK?.SEGMENTATION ||
                data?.INTERESTED_AI_TASK?.MULTITASK ||
                data?.FRAMEWORK?.R ||
                data?.FRAMEWORK?.PYTHON ||
                data?.FRAMEWORK?.C ||
                data?.FRAMEWORK?.CPP ||
                data?.FRAMEWORK?.AOS ||
                data?.FRAMEWORK?.IOS ||
                data?.FRAMEWORK?.TENSORFLOW ||
                data?.FRAMEWORK?.KERAS ||
                data?.FRAMEWORK?.CAFFE ||
                data?.FRAMEWORK?.CNTK ||
                data?.FRAMEWORK?.PYTORCH ||
                data?.FRAMEWORK?.DAVID ||
                data?.FRAMEWORK?.WEB_PPL ||
                data?.FRAMEWORK?.CHURCH ||
                data?.FRAMEWORK?.FIGARO ||
                data?.FRAMEWORK?.OPENAI_GYM ||
                data?.FRAMEWORK?.PYRO ||
                data?.FRAMEWORK?.TENSOR_FORCE ||
                data?.FRAMEWORK?.RLIB) && (
                <div className="row">
                  <label className="label">관심사</label>
                  {data.INTERESTED_AI_TASK && data.FRAMEWORK && (
                    <div className="badges">
                      {data.INTERESTED_AI_TASK.MACHINE_LEARNING && (
                        <span className="badge m5 badge-md purple">
                          Machine
                        </span>
                      )}
                      {data.INTERESTED_AI_TASK.DEEP_LEARNING && (
                        <span className="badge m5 badge-md purple">Deep</span>
                      )}
                      {data.INTERESTED_AI_TASK.CLASSIFICATION && (
                        <span className="badge m5 badge-md purple">
                          Classification
                        </span>
                      )}
                      {data.INTERESTED_AI_TASK.CONVOLUTION && (
                        <span className="badge m5 badge-md purple">
                          Convolution
                        </span>
                      )}
                      {data.INTERESTED_AI_TASK.CNN && (
                        <span className="badge m5 badge-md purple">CNN</span>
                      )}
                      {data.INTERESTED_AI_TASK.RNN && (
                        <span className="badge m5 badge-md purple">RNN</span>
                      )}
                      {data.INTERESTED_AI_TASK.CAM && (
                        <span className="badge m5 badge-md purple">CAM</span>
                      )}
                      {data.INTERESTED_AI_TASK.OCR && (
                        <span className="badge m5 badge-md purple">OCR</span>
                      )}
                      {data.INTERESTED_AI_TASK.STT && (
                        <span className="badge m5 badge-md purple">STT</span>
                      )}
                      {data.INTERESTED_AI_TASK.TTS && (
                        <span className="badge m5 badge-md purple">TTS</span>
                      )}
                      {data.INTERESTED_AI_TASK.NLP && (
                        <span className="badge m5 badge-md purple">NLP</span>
                      )}
                      {data.INTERESTED_AI_TASK.GAN && (
                        <span className="badge m5 badge-md purple">GAN</span>
                      )}
                      {data.INTERESTED_AI_TASK.DEEP_FAKE && (
                        <span className="badge m5 badge-md purple">Deep</span>
                      )}
                      {data.INTERESTED_AI_TASK.OBJECT_DETECTION && (
                        <span className="badge m5 badge-md purple">Object</span>
                      )}
                      {data.INTERESTED_AI_TASK.ANOMALY_DETECTION && (
                        <span className="badge m5 badge-md purple">
                          Anomaly
                        </span>
                      )}
                      {data.INTERESTED_AI_TASK.SEGMENTATION && (
                        <span className="badge m5 badge-md purple">
                          Segmentation
                        </span>
                      )}
                      {data.INTERESTED_AI_TASK.MULTITASK && (
                        <span className="badge m5 badge-md purple">Multi</span>
                      )}
                      {data.FRAMEWORK.R && (
                        <span className="badge m5 badge-md purple">R</span>
                      )}
                      {data.FRAMEWORK.PYTHON && (
                        <span className="badge m5 badge-md purple">Python</span>
                      )}
                      {data.FRAMEWORK.C && (
                        <span className="badge m5 badge-md purple">C</span>
                      )}
                      {data.FRAMEWORK.CPP && (
                        <span className="badge m5 badge-md purple">C</span>
                      )}
                      {data.FRAMEWORK.AOS && (
                        <span className="badge m5 badge-md purple">AOS</span>
                      )}
                      {data.FRAMEWORK.IOS && (
                        <span className="badge m5 badge-md purple">iOS</span>
                      )}
                      {data.FRAMEWORK.TENSORFLOW && (
                        <span className="badge m5 badge-md purple">
                          Tensorflow
                        </span>
                      )}
                      {data.FRAMEWORK.KERAS && (
                        <span className="badge m5 badge-md purple">Keras</span>
                      )}
                      {data.FRAMEWORK.CAFFE && (
                        <span className="badge m5 badge-md purple">Caffe</span>
                      )}
                      {data.FRAMEWORK.CNTK && (
                        <span className="badge m5 badge-md purple">CNTK</span>
                      )}
                      {data.FRAMEWORK.PYTORCH && (
                        <span className="badge m5 badge-md purple">
                          PyTorch
                        </span>
                      )}
                      {data.FRAMEWORK.DAVID && (
                        <span className="badge m5 badge-md purple">David</span>
                      )}
                      {data.FRAMEWORK.WEB_PPL && (
                        <span className="badge m5 badge-md purple">WebPPL</span>
                      )}
                      {data.FRAMEWORK.CHURCH && (
                        <span className="badge m5 badge-md purple">Church</span>
                      )}
                      {data.FRAMEWORK.FIGARO && (
                        <span className="badge m5 badge-md purple">Figaro</span>
                      )}
                      {data.FRAMEWORK.OPENAI_GYM && (
                        <span className="badge m5 badge-md purple">OpenAI</span>
                      )}
                      {data.FRAMEWORK.PYRO && (
                        <span className="badge m5 badge-md purple">Pyro</span>
                      )}
                      {data.FRAMEWORK.TENSOR_FORCE && (
                        <span className="badge m5 badge-md purple">
                          TensorForce
                        </span>
                      )}
                      {data.FRAMEWORK.RLIB && (
                        <span className="badge m5 badge-md purple">Rlib</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {(data.PROJECT_DETAILS || data.PROJECT_FILE) && (
              <div className="profile-sub">프로젝트 소개</div>
            )}
            {data.PROJECT_DETAILS && (
              <div className="profile-box">{data.PROJECT_DETAILS}</div>
            )}
            {data.PROJECT_FILE && (
              <button
                type="button"
                style={!data.PROJECT_DETAILS ? { marginTop: 0 } : {}}
                className="btn btn-full btn-primary btn-down"
                onClick={() => {
                  window.open(data.PROJECT_FILE);
                }}
                disabled={data.DDAY < 0}
              >
                참고 문서 다운로드
              </button>
            )}
            {isMobile && Boolean(recommendList.length) && (
              <div className="right">
                <div className="right-box">
                  <div className="box-tit">
                    프로젝트 <span className="txt-c--purple">추천</span>
                  </div>
                  <ul className="project-list type2">
                    {recommendList.map((item, i) => (
                      <li
                        className="project-list--item"
                        key={`project-recommend-ist-${i}`}
                        onClick={() => navigate(`/aiproject/${item.SEQ}`)}
                      >
                        <div className="tit">
                          {item.PROJECT_NAME}
                          <div className="right">
                            <span className="badge m5 badge-md black auto">
                              D-{item.DDAY === 0 ? "DAY" : item.DDAY}
                            </span>
                          </div>
                        </div>
                        <div className="info">
                          <span className="text">
                            예산 및 급여 방식:{" "}
                            {!item.PAY_NEGOTIABLE && (
                              <>
                                {priceFormat(item.BUDGET_MIN)}~
                                {priceFormat(item.BUDGET_MAX)}만 원
                              </>
                            )}
                          </span>
                        </div>
                        <div className="info">
                          <span className="text">
                            {item.PAY_NEGOTIABLE ? (
                              <span className="badge badge-sm skyblue">
                                제안 가능
                              </span>
                            ) : (
                              <span className="badge badge-sm skyblue">
                                {PAY_METHOD[item.PAY_METHOD]}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="option">
                          <span className="badge badge-round grey line">
                            NEW
                          </span>
                          <span className="badge badge-round grey line">
                            {item.PROJECT_TYPE === "BUSINESS"
                              ? "기업 프로젝트"
                              : "개인 프로젝트"}
                          </span>
                          {(item.WORK_METHOD === "BOTH" ||
                            item.WORK_METHOD === "RESIDENT") && (
                            <span className="badge badge-round grey line">
                              상주
                            </span>
                          )}
                          {(item.WORK_METHOD === "BOTH" ||
                            item.WORK_METHOD === "REMOTE") && (
                            <span className="badge badge-round grey line">
                              원격
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {userId === data.OWNER || !isLoggedin ? (
              <div className="profile-sub">프로젝트 QnA</div>
            ) : (
              <>
                <div className="profile-sub type2">
                  프로젝트 QnA
                  <div className="input-switch--wrap right">
                    <span className="text">내 글 보기</span>
                    <label className="input-switch">
                      <input
                        type="checkbox"
                        value={qnaAuthList}
                        onChange={() => {
                          setQnaAuthList(!qnaAuthList);
                          setQnaPage(1);
                        }}
                      />
                      <span className="switch"></span>
                    </label>
                  </div>
                </div>
                <div className="qna-input input-wrap">
                  {isQuestionWriting ? (
                    <div className="textarea-wrap">
                      <textarea
                        className="input-textarea"
                        rows="1"
                        autoFocus
                        value={newQuestion}
                        maxLength={1000}
                        onChange={(e) => {
                          setNewQuestion(e.target.value);
                          handleTextareaResizeHeight();
                        }}
                        ref={textareaRef}
                      ></textarea>
                      <div className="btns">
                        <button
                          type="button"
                          className="btn txt-c--sub"
                          onClick={() => onQuestionSend()}
                        >
                          질문하기
                        </button>
                        <button
                          type="button"
                          className="btn txt-c--grey"
                          onClick={() => {
                            setQuestionWriting(false);
                            setNewQuestion("");
                          }}
                        >
                          취소
                        </button>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="input-text type2"
                      placeholder="질문을 입력하세요."
                      onClick={() => setQuestionWriting(true)}
                    />
                  )}
                </div>
              </>
            )}
            {qnaList.length === 0 ? (
              <div className="container-none">아직 등록된 질문이 없어요.</div>
            ) : (
              <>
                <ul className="qna-list">
                  {qnaList.map((item, i) => (
                    <li className="qna-list--item" key={`qna-list--item_${i}`}>
                      {/* 질문 영역 */}
                      <div
                        className={`q ${
                          activeQ === item.Q_SEQ ? "active" : ""
                        }`}
                      >
                        {editQuestion.id === item.Q_SEQ ? (
                          <div
                            className="qna-input input-wrap"
                            style={{ margin: 0 }}
                          >
                            {/* 질문 수정 영역 */}
                            <div className="textarea-wrap">
                              <textarea
                                className="input-textarea"
                                rows="1"
                                autoFocus
                                value={editQuestion.text}
                                maxLength={1000}
                                onChange={(e) => {
                                  setEditQuestion({
                                    ...editQuestion,
                                    text: e.target.value,
                                  });
                                }}
                                ref={replyTextareaRef}
                              ></textarea>
                              <div className="btns">
                                <button
                                  type="button"
                                  className="btn txt-c--sub"
                                  onClick={() => onQuestionEdit()}
                                >
                                  수정하기
                                </button>
                                <button
                                  type="button"
                                  className="btn txt-c--grey"
                                  onClick={() => {
                                    setEditQuestion({ id: null, text: "" });
                                  }}
                                >
                                  취소
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* 답변 완료 여부 뱃지 */}
                            {item.A_CONTENTS ? (
                              <span className="badge badge-sm primary">
                                답변 완료
                              </span>
                            ) : (
                              <span className="badge badge-sm d-grey">
                                답변 대기
                              </span>
                            )}
                            {/* 질문 미리보기 영역 */}
                            <button
                              type="button"
                              className="btn tit"
                              onClick={() => {
                                if (item.Q_SEQ === activeQ) {
                                  setActiveQ(null);
                                } else {
                                  setActiveQ(item.Q_SEQ);
                                }
                              }}
                            >
                              {item.Q_CONTENTS}
                            </button>

                            {/* 답변 버튼 영역 */}
                            {userId === data.OWNER && !item.A_CONTENTS && (
                              <button
                                onClick={(e) => {
                                  if (newAnswer.id === item.Q_SEQ) return;
                                  setActiveQ(item.Q_SEQ);
                                  setNewAnswer({
                                    id: item.Q_SEQ,
                                    text: "",
                                  });
                                }}
                                type="button"
                                className="btn btn-a"
                              >
                                답변하기
                              </button>
                            )}
                            {userId === data.OWNER && item.A_CONTENTS && (
                              <button className="btn btn-a" disabled></button>
                            )}

                            {userId !== data.OWNER &&
                              userId !== item.Q_WRITER && (
                                <button className="btn btn-a" disabled></button>
                              )}
                            {/* 수정 신고 삭제 버튼 영역 */}
                            {userId === item.Q_WRITER ? (
                              <>
                                {!item.A_CONTENTS && (
                                  <button
                                    type="button"
                                    className="btn btn-modi"
                                    onClick={() => {
                                      setEditQuestion({
                                        id: item.Q_SEQ,
                                        text: item.Q_CONTENTS,
                                      });
                                    }}
                                  >
                                    수정
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="btn btn-delete"
                                  onClick={() => {
                                    deleteQnA(item.Q_SEQ, "q");
                                  }}
                                >
                                  삭제
                                </button>
                                <span className="name">나</span>
                              </>
                            ) : (
                              <>
                                {isLoggedin && (
                                  <button
                                    onClick={() => {
                                      reportPostModalOpen(
                                        "project_post_q",
                                        item.Q_SEQ
                                      );
                                    }}
                                    type="button"
                                    className="btn btn-report"
                                  ></button>
                                )}
                                <span className="name">
                                  {item.Q_WRITER_NAME}
                                </span>
                              </>
                            )}
                            <span className="date">
                              {dateFormatType2(item.Q_CREATED_AT)}
                            </span>
                          </>
                        )}
                      </div>

                      {/* 질문 자세히 보기 영역 */}
                      <div className="q-detail">{item.Q_CONTENTS}</div>

                      {/* 답변 영역 */}
                      {item.A_CONTENTS && (
                        <>
                          {editAnswer.id === item.A_SEQ ? (
                            <div className="a">
                              {/* 답변 수정 인풋*/}
                              <textarea
                                className="input-textarea"
                                value={editAnswer.text}
                                maxLength={1000}
                                onChange={(e) => {
                                  setEditAnswer({
                                    ...editAnswer,
                                    text: e.target.value,
                                  });
                                }}
                                ref={replyTextareaRef}
                              ></textarea>
                              <div className="btns">
                                <button
                                  onClick={() => {
                                    onAnswerEdit();
                                  }}
                                  type="button"
                                  className="btn txt-c--sub"
                                >
                                  수정
                                </button>
                                <button
                                  onClick={() => {
                                    setEditAnswer({
                                      id: null,
                                      text: "",
                                    });
                                  }}
                                  type="button"
                                  className="btn txt-c--grey"
                                >
                                  취소
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="a readonly">
                              {/* 답변 */}
                              <div className="text">{item.A_CONTENTS}</div>
                              <div className="btns">
                                {userId === data.OWNER ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn txt-c--blue"
                                      onClick={(e) => {
                                        e.target.parentElement.parentElement.parentElement.children[0].classList.add(
                                          "active"
                                        );
                                        setEditAnswer({
                                          id: item.A_SEQ,
                                          text: item.A_CONTENTS,
                                        });
                                      }}
                                    >
                                      수정
                                    </button>
                                    <button
                                      onClick={() => {
                                        deleteQnA(item.A_SEQ, "a");
                                      }}
                                      type="button"
                                      className="btn txt-c--grey"
                                    >
                                      삭제
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn txt-c--grey"
                                    onClick={() => {
                                      reportPostModalOpen(
                                        "project_post_a",
                                        item.A_SEQ
                                      );
                                    }}
                                  >
                                    신고
                                  </button>
                                )}
                                <span className="date">
                                  {dateFormatType2(item.A_CREATED_AT)}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {newAnswer.id === item.Q_SEQ && (
                        <div className="a">
                          <textarea
                            className="input-textarea"
                            placeholder="답변을 입력하세요."
                            maxLength={1000}
                            value={newAnswer.text}
                            onChange={(e) => {
                              setNewAnswer({
                                ...newAnswer,
                                text: e.target.value,
                              });
                            }}
                          ></textarea>
                          <div className="btns">
                            <button
                              onClick={() => {
                                onAnswerSend();
                              }}
                              type="button"
                              className="btn txt-c--sub"
                            >
                              저장
                            </button>
                            <button
                              onClick={() => {
                                setNewAnswer({
                                  id: null,
                                  text: "",
                                });
                              }}
                              type="button"
                              className="btn txt-c--grey"
                            >
                              취소
                            </button>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                {paginationData && (
                  <Pagination
                    paginationData={paginationData}
                    page={qnaPage}
                    setPage={setQnaPage}
                  />
                )}
              </>
            )}
            {isLoggedin &&
              (userId === data.OWNER ? (
                <div className="btn-wrap mt-70">
                  <button
                    onClick={() => {
                      navigate("/mypage/aiprojectc");
                    }}
                    type="button"
                    className="btn btn-lg btn-black type2"
                  >
                    프로젝트 관리로 이동하기
                  </button>
                </div>
              ) : (
                userRole.includes("HEROS") && (
                  <div className="btn-wrap mt-70">
                    {!applyCheck ? (
                      data.DDAY >= 0 ? (
                        <button
                          onClick={() => {
                            applyProject();
                          }}
                          type="button"
                          className="btn btn-lg btn-black type2"
                        >
                          프로젝트 지원하기
                        </button>
                      ) : (
                        // <button type="button" className="btn btn-lg" disabled>
                        //   {(!data.CLOSE_STAUTS ||
                        //     data.CLOSE_STAUTS === "COMPLETE") &&
                        //     "완료된 프로젝트예요."}
                        //   {data.CLOSE_STAUTS === "PROGRESS" &&
                        //     "공고 마감 후 현재 매칭 중이에요."}
                        //   {data.CLOSE_STAUTS === "MATCHING" &&
                        //     "공고 마감 후 현재 진행 중이에요."}
                        // </button>
                        <></>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-black type2"
                        disabled
                      >
                        프로젝트 지원 완료
                      </button>
                    )}
                  </div>
                )
              ))}
          </div>
          {!isMobile && Boolean(recommendList.length) && (
            <div className="right">
              <div className="right-box">
                <div className="box-tit">
                  프로젝트 <span className="txt-c--purple">추천</span>
                </div>
                <ul className="project-list type2">
                  {recommendList.map((item, i) => (
                    <li
                      className="project-list--item"
                      key={`project-recommend-ist-${i}`}
                      onClick={() => navigate(`/aiproject/${item.SEQ}`)}
                    >
                      <div className="tit">
                        {item.PROJECT_NAME}
                        <div className="right">
                          <span className="badge m5 badge-md black auto">
                            D-{item.DDAY === 0 ? "DAY" : item.DDAY}
                          </span>
                        </div>
                      </div>
                      <div className="info">
                        <span className="text">
                          예산 및 급여 방식:{" "}
                          {!item.PAY_NEGOTIABLE && (
                            <>
                              {priceFormat(item.BUDGET_MIN)}~
                              {priceFormat(item.BUDGET_MAX)}만 원
                            </>
                          )}
                          <br className="m-block" />
                        </span>
                      </div>
                      <div className="info">
                        <span className="text">
                          {item.PAY_NEGOTIABLE ? (
                            <span className="badge badge-sm skyblue">
                              금액 제안 가능
                            </span>
                          ) : (
                            <span className="badge badge-sm skyblue">
                              {PAY_METHOD[item.PAY_METHOD]}
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="option">
                        <span className="badge badge-round grey line">NEW</span>
                        <span className="badge badge-round grey line">
                          {item.PROJECT_TYPE === "BUSINESS"
                            ? "기업 프로젝트"
                            : "개인 프로젝트"}
                        </span>
                        {(item.WORK_METHOD === "BOTH" ||
                          item.WORK_METHOD === "RESIDENT") && (
                          <span className="badge badge-round grey line">
                            상주
                          </span>
                        )}
                        {(item.WORK_METHOD === "BOTH" ||
                          item.WORK_METHOD === "REMOTE") && (
                          <span className="badge badge-round grey line">
                            원격
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiProjectDetail;
