import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	authProfile,
} from 'services/user';
import { userInquiry } from 'services/user';
import useModal from 'hooks/useModal';
import { fileNameFormat } from 'utils/utils';
import { fileAttach } from 'utils/storage';

const Counsel = () => {
	const navigate = useNavigate();
	const { openModal, closeModal } = useModal();

	const [userInfo, setUserInfo] = useState({
		NAME: '',
		USERNAME: '',
		PHONE: '',
	})
	const [inquiryForm, setInquiryForm] = useState({
		title: '',
		contents: '',
		file: '',
		fileName: '',
	});
	const [agree, setAgree] = useState(false);
	const [inputDone, setInputDone] = useState(false);

	useEffect(() => {
		authProfile((res) => {
			const { NAME, USERNAME, PHONE } = res.response;
			setUserInfo({
				NAME,
				USERNAME,
				PHONE
			});
		})
	}, [])

	useEffect(() => {
		if (inquiryForm.title && inquiryForm.contents && agree) {
			setInputDone(true)
		} else {
			setInputDone(false)
		}
	}, [inquiryForm, agree])

	const onFileChange = (e) => {
		fileAttach(e, 'counsel/', (downloadURL, fileName) => {
			setInquiryForm({
				...inquiryForm,
				file: downloadURL,
				fileName: fileName,
			});
		})
	}

	const onInquirySave = useCallback(() => {
		const { title, contents, file, fileName } = inquiryForm;
		const { NAME, USERNAME, PHONE } = userInfo;
		const body = {
			name: NAME,
			email: USERNAME,
			phone: PHONE,
			title,
			contents,
			file,
			fileName
		}
		userInquiry(body, (res) => {
			if (res.code === 0) {
				openModal({
					type: 'messageModal',
					contents: {
						title: '문의하기 완료',
						message: '1:1 문의 요청이 완료되었어요.\n빠른 시일 내에 AI 히어로즈팀이 연락 드릴테니 조금만 기다려주세요!',
						confirmText: '확인',
						confirmCallback: () => {
							navigate('/help');
							closeModal();
						},
					}
				});
			} else {
				openModal({
					type: 'messageModal',
					contents: {
						title: '요청에 실패했습니다',
						message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
						confirmText: '확인',
						confirmCallback: () => {
							closeModal();
						},
					}
				});
			}
		})
	}, [userInfo, inquiryForm])

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<Link to='/help' className="btn top-back">고객센터로 돌아가기</Link>
					<div className="section-tit">1:1 문의하기</div>
					<label className="input-label">이름</label>
					<input type="text" className="input-text" value={userInfo.NAME} readOnly />
				</div>
				<div className="input-wrap">
					<label className="input-label">이메일</label>
					<input type="text" className="input-text" value={userInfo.USERNAME} readOnly />
				</div>
				<div className="input-wrap">
					<label className="input-label">연락처</label>
					<input type="text" className="input-text" value={userInfo.PHONE} readOnly />
				</div>
				<div className="input-wrap">
					<label className="input-label required">제목</label>
					<input
						type="text"
						className="input-text"
						placeholder="제목을 입력해 주세요."
						maxLength={50}
						value={inquiryForm.title}
						onChange={(e) => setInquiryForm({
							...inquiryForm,
							title: e.target.value
						})}
					/>
				</div>
				<div className="input-wrap">
					<label className="input-label required">내용</label>
					<textarea
						className="input-textarea"
						rows="10"
						placeholder="문의 내용을 상세히 입력해 주세요.
지원 매니저가 최대한 빠르게 답변드릴 수 있도록 하겠습니다.
(응답 기간: 영엽일 기준 1~2일 소요)"
						maxLength={1500}
						value={inquiryForm.contents}
						onChange={(e) => setInquiryForm({
							...inquiryForm,
							contents: e.target.value
						})}
					></textarea>
				</div>
				<div className="input-wrap mt-20">
					<label className="input-file">
						<input type="file" onChange={(e) => onFileChange(e)} />
						<span className={`input-text ${inquiryForm.file ? 'correct' : ''}`}>{inquiryForm.file ? fileNameFormat(inquiryForm.fileName) : '파일 첨부'}</span>
					</label>
				</div>
				<div className="input-wrap input-agree">
					<div className="input-agree--tit">개인정보 수집 및 이용에 대한 동의 내용</div>
					<div className="input-agree--con">
						(1) 개인정보 수집 항목 : 이름, 이메일, 연락처<br />
						(2) 수집 목적 : 고객 식별, 문의 응대, 서비스 품질 향상<br />
						(3) 보유 및 이용 기간 : 수집 목적이 달성되면 즉시 개인정보는 폐기합니다.<br />
						(단, 관계법령에서 일정 기간 정보의 보관을 규정한 경우에 한 해 분리 보관 후 파기합니다.)<br />
						<br />
						*위 동의는 거부할 수 있으며, 거부 시 해당 문의를 처리할 수 없습니다.<br />
					</div>
					<label className="input-checkbox">
						<input type="checkbox" value={agree} onChange={(e) => { setAgree(e.target.checked) }} />
						<span className="box"></span>
						<span className="text underline"><span>개인정보 수집 및 이용 동의 </span><span className="txt-c--red">(필수)</span></span>
					</label>
					<button type="button" className="btn btn-full btn-primary" disabled={!inputDone} onClick={() => { onInquirySave() }}>문의하기</button>
				</div>
			</div>

		</div>
	)
}

export default Counsel