import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import { getParameterByName } from "utils/utils";
import { topicRanking, topicSearch } from "services/topic";
import useAuth from "hooks/useAuth";

import Search from "components/common/Search";

import Topic from "pages/Community/Topic";
import AiStory from "pages/Community/AiStory";
import AiConsulting from "pages/Community/AiConsulting";
import TopicDetail from "pages/Community/TopicDetail";
import TopicWrite from "pages/Community/TopicWrite";
import AiStoryDetail from "pages/Community/AiStoryDetail";
import AiConsultingDetail from "./AiConsultingDetail";
import SEOMetaTag from "SEOMetaTag";

const Community = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchList, setSearchList] = useState(null);
  const [ranking, setRanking] = useState({});

  useEffect(() => {
    topicRanking((res) => {
      setRanking(res.response);
    });
  }, []);

  useEffect(() => {
    setSearchKeyword(getParameterByName(location.search, "search"));
  }, [location]);

  useEffect(() => {
    if (!searchKeyword) return;
    setSearchInput(searchKeyword);
    topicSearch({ keyword: searchKeyword }, (res) => {
      setSearchList({
        total: res.response.total + res.response.totalAi,
        data: [
          {
            dataName: "AI TALK",
            url: "/community/topic",
            list: [...res.response.data],
            total: res.response.total,
            keyword: searchKeyword,
          },
          {
            dataName: "AI 블로그",
            url: "/community",
            list: [...res.response.dataAi],
            total: res.response.totalAi,
            keyword: searchKeyword,
          },
        ],
      });
    });
  }, [searchKeyword]);

  const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const { isLoggedin } = useAuth();

    useEffect(() => {
      if (!(localStorage.getItem("jwt") || isLoggedin)) {
        navigate("/", { replace: true });
      }
    }, [isLoggedin]);

    return children;
  };

  return (
    <div className="container">
      <div className="section">
        {searchKeyword && searchList && <Search data={searchList} />}
        <Routes>

          <Route
            path="/"
            element={
              <>
                <SEOMetaTag
                  title={"AI 블로그 | AI 히어로즈"}
                  description={
                    "AI & IT 관련 신규 콘텐츠를 확인해보세요. 최신 정보와 트렌드를 통해 당신의 프로젝트를 더욱 성공적으로 만들어보세요."
                  }
                />
                <AiStory />
              </>
            }
          ></Route>
          <Route
            path="/:id"
            element={
              <>
                <SEOMetaTag
                  title={"AI 블로그 | AI 히어로즈"}
                  description={
                    "AI & IT 관련 신규 콘텐츠를 확인해보세요. 최신 정보와 트렌드를 통해 당신의 프로젝트를 더욱 성공적으로 만들어보세요."
                  }
                />
                <AiStoryDetail />
              </>
            }
          ></Route>
          <Route
            path="*"
            element={
              <Navigate to="/community" />
            }
          />
          {/* 사용하지 않는 페이지 제거 */}
          {/*<Route*/}
          {/*  path="/topic"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI TALK | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문 소통 공간입니다. AI 전문 커뮤니티에서 다양한 사람을 만나고 다양한 궁금증을 해소해보세요."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <Topic ranking={ranking} />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/topic/:id"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI TALK | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문 소통 공간입니다. AI 전문 커뮤니티에서 다양한 사람을 만나고 다양한 궁금증을 해소해보세요."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <TopicDetail ranking={ranking} />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*></Route>*/}
          {/*<Route*/}
          {/*  path="/topic/write"*/}
          {/*  element={*/}
          {/*    <ProtectedRoute>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI TALK | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문 소통 공간입니다. AI 전문 커뮤니티에서 다양한 사람을 만나고 다양한 궁금증을 해소해보세요."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <TopicWrite />*/}
          {/*    </ProtectedRoute>*/}
          {/*  }*/}
          {/*></Route>*/}
          {/*<Route*/}
          {/*  path="/topic/write/:id"*/}
          {/*  element={*/}
          {/*    <ProtectedRoute>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI TALK | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문 소통 공간입니다. AI 전문 커뮤니티에서 다양한 사람을 만나고 다양한 궁금증을 해소해보세요."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <TopicWrite />*/}
          {/*    </ProtectedRoute>*/}
          {/*  }*/}
          {/*></Route>*/}
          {/*<Route*/}
          {/*  path="/aiconsulting"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI 컨설팅 | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문가의 전문적인 조언과 가이드를 받으세요. AI를 활용한 비즈니스 혁신과 성장을 함께 도와드립니다."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <AiConsulting />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*></Route>*/}
          {/*<Route*/}
          {/*  path="/aiconsulting/:id"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <SEOMetaTag*/}
          {/*        title={"AI 컨설팅 | AI 히어로즈"}*/}
          {/*        description={*/}
          {/*          "AI 전문가의 전문적인 조언과 가이드를 받으세요. AI를 활용한 비즈니스 혁신과 성장을 함께 도와드립니다."*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <AiConsultingDetail />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*></Route>*/}
        </Routes>
      </div>
    </div>
  );
};

export default Community;
