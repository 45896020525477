import profile_default_img from "assets/images/profile_default_img.png";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userHerosDibsChange } from "services/heros";
import {
  heroesDibsTotal,
  heroesReviewPossibleWrite,
  herosReviewWrite,
  projectCompleteBefore,
  projectMeetingCancel,
  projectTaskResultListView,
  projectTaskResultTestDateWrite,
  userContractApply,
  userProjectDetail,
  userProjectDetailApplication,
  userProjectDetailApplicationCheck,
  userProjectDetailComplete,
  userProjectDetailContract,
  userProjectDetailContractCheck,
  userProjectDetailHeroesRefuse,
  userProjectDetailMeeting,
  userProjectDetailMeetingApply,
  userProjectDetailMeetingCheck,
  userProjectDetailMessage,
  userProjectDetailMessageIsNew,
  userProjectDetailTaskCount,
  userProjectDetailTaskList,
  userProjectHeroesInfo,
  userProjectMessageWrite,
} from "services/projectManagement";
import { GRADE_TYPE } from "utils/data";
import {
  checkURL,
  dateFormatType2,
  dateFormatType2NoTime,
  getParameterByName,
  phoneFormat,
  priceFormat,
} from "utils/utils";

const STATUS = ["APPLY", "MATCHING", "PROGRESS", "COMPLETE", "CLOSE"];

const AiprojectCDetail = ({ isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { id } = useParams();
  const { userInfo } = useAuth();
  const [tab, setTab] = useState("APPLY");
  const [tabV2, setTabV2] = useState("INFO"); //INFO, MESSAGE
  const [tabV2MobileHide, setTabV2MobileHide] = useState(true);
  const [data, setData] = useState(null);
  const [heroesInfo, setHeroesInfo] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [messageIsNew, setMessageIsNew] = useState(false);
  const [messageInputText, setMessageInputText] = useState("");
  const [manager, setManager] = useState(null);

  // 매칭
  const [matchingState, setMatchingState] = useState(0);
  const [dibs, setDibs] = useState(false);
  const [applyList, setApplyList] = useState([]);
  const [applyCount, setApplyCount] = useState(0);
  const [applyCheck, setApplyCheck] = useState(null);
  const [heroesCheckModal, setHeroesCheckModal] = useState({
    visible: false,
    data: null,
  });
  const [meetingList, setMeetingList] = useState([]);
  const [meetingCount, setMeetingCount] = useState(0);
  const [meetingCheck, setMeetingCheck] = useState(null);
  const [contract, setContract] = useState(null);

  // 매칭 모바일
  const [isMatchingListOn, setMatchingListOn] = useState(0);

  // 프로젝트 진행
  const [taskList, setTaskList] = useState([]);
  const [isMessageOpenFirst, setMessageOpenFirst] = useState(false); //task호출 시 first여부 판단
  const [taskCount, setTaskCount] = useState({
    COMPLETED: 0,
    TOTAL: 0,
  });
  const [taskResList, setTaskResList] = useState([]);
  const [taskResInputMod, setTaskResInputMod] = useState(null);
  const [taskResInout, setTaskResInput] = useState("");

  const [completeCheck, setCompleteCheck] = useState([]);

  const [reviewScore, setReviewScore] = useState(5);
  const [reviewInput, setReviewInput] = useState("");
  const [isReviewPossibleWrite, setReviewPossibleWrite] = useState(null);

  const messageListRef = useRef(null);

  useEffect(() => {
    const paramTab = getParameterByName(location.search, "tab");
    const paramTabV2 = getParameterByName(location.search, "tabV2");
    userProjectDetail(id, (res) => {
      if (res.code !== 0) {
        navigate("/");
      }
      setData(res.response);
      if (res.code !== 0) return;
      if (paramTab) {
        setTab(paramTab);
      } else {
        setTab(res.response.STATUS);
      }
      setManager(res.response.managerInfo);
      if (paramTabV2) setTabV2(paramTabV2);
    });
  }, [id, location]);

  useEffect(() => {
    userProjectDetailMessageIsNew(id, (res) => {
      if (res.code !== 0) return;
      setMessageIsNew(res.response);
    });
    if (tabV2 === "INFO") return;
    userProjectDetailMessage(id, (res) => {
      if (res.code !== 0) return;
      setMessageList(res.response);
    });
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [tabV2, messageListRef]);

  useEffect(() => {
    if (tab === "MATCHING") {
      // userProjectDetailTesk(data.SEQ, (res) => {
      // 	console.log(res)
      // })
      const applicationParams = {
        seq: id,
        dibs,
      };
      const meetingParams = {
        seq: id,
      };
      userProjectDetailApplication(applicationParams, (res) => {
        setApplyList(res.response.applyList);
        setApplyCount(res.response.count);
      });
      userProjectDetailMeeting(meetingParams, (res) => {
        setMeetingList(res.response.meetingList);
        setMeetingCount(res.response.count);
      });
      userProjectDetailContract(id, (res) => {
        setContract(res.response);
      });
    } else if (tab === "PROGRESS") {
      userProjectDetailTaskList(id, (res) => {
        if (res.code !== 0) return;
        setTaskList(res.response.viewList);
        setMessageOpenFirst(res.response.first);
      });
      userProjectDetailTaskCount(id, (res) => {
        setTaskCount(res.response);
      });
    } else if (tab === "MODEL") {
      projectTaskResultListView(id, (res) => {
        setTaskResList(res.response);
      });
    } else if (tab === "COMPLETE") {
      userProjectHeroesInfo(id, (res) => {
        setHeroesInfo(res.response);
        heroesReviewPossibleWrite(res.response.SEQ, id, (hRes) => {
          setReviewPossibleWrite(hRes.response);
        });
      });
    }
  }, [id, data, dibs, tab]);

  useEffect(() => {
    if (contract) {
      setMatchingState(2);
      setMatchingListOn(2);
    } else if (meetingList.length) {
      setMatchingState(1);
      setMatchingListOn(1);
    } else {
      setMatchingState(0);
      setMatchingListOn(0);
    }
  }, [applyList, meetingList, contract]);

  // const applyCheckListHandler = (id) => {
  // console.log(id)
  // let tempArr = [...applyCheckList];
  // if (tempArr.includes(id)) {
  // 	tempArr = tempArr.filter((a) => {
  // 		return a !== id
  // 	})
  // } else {
  // 	tempArr.push(id);
  // }
  // setApplyCheckList(tempArr)
  // }

  const messageSend = useCallback(() => {
    const body = {
      contents: messageInputText,
    };
    userProjectMessageWrite(id, body, (res) => {
      if (res.code !== 0) return;
      userProjectDetailMessageIsNew(id, (res) => {
        setMessageIsNew(res.response);
      });
      userProjectDetailMessage(id, (res) => {
        if (res.code !== 0) return;
        setMessageList(res.response);
      });
    });
  }, [id, messageInputText]);

  useEffect(() => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [messageList]);

  const heroesModalOpen = useCallback((id, state) => {
    switch (state) {
      case 0:
        userProjectDetailApplicationCheck(id, (res) => {
          if (res.code !== 0) return;
          heroesDibsTotal(res.response.APPLICANT, (dibsTotalRes) => {
            // console.log(dibsTotalRes.response)
            if (dibsTotalRes.code !== 0) {
              setHeroesCheckModal({
                visible: true,
                data: res.response,
                dibsTotal: 0,
              });
            } else {
              setHeroesCheckModal({
                visible: true,
                data: res.response,
                dibsTotal: priceFormat(dibsTotalRes.response),
              });
            }
          });
        });
        break;
      case 1:
        userProjectDetailMeetingCheck(id, (res) => {
          setHeroesCheckModal({
            visible: true,
            data: res.response,
            conId: id,
          });
        });
        break;
      case 2:
        userProjectDetailContractCheck(id, (res) => {
          setHeroesCheckModal({
            visible: true,
            data: res.response,
          });
        });
        break;
      default:
        return;
    }
  });

  const stateComPonent = () => {
    // console.log(tab)
    switch (tab) {
      case "APPLY":
        return (
          <>
            {!data.REFUSE_AT ? (
              <>
                {data.STATUS === "APPLY" ? (
                  <>
                    <div className="state-guide">
                      <div className="icon type-meatball"></div>
                      <div className="text">
                        담당 매니저가 프로젝트를 검토하고 있어요.
                        <br />
                        빠른 시일 내에 연락드릴게요.
                        <br />
                        (검토 기간: 영업일 기준 1~2일 소요)
                        <p className="sub">
                          프로젝트 의뢰 일시 ㅣ{" "}
                          {dateFormatType2(data.CREATED_AT)}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn btn-lg btn-black type2"
                        onClick={() => {
                          openModal({
                            type: "messageModal",
                            contents: {
                              title: "참고해 주세요!",
                              message:
                                "프로젝트의 정보를 수정하시면 새롭게 검토가 진행돼요.\n(검토 기간: 영업일 기준 1~2일 소요)",
                              confirmText: "수정하기",
                              confirmCallback: () => {
                                navigate(`/aiproject/request?edit=${id}`);
                                closeModal();
                              },
                              cancelText: "돌아가기",
                              cancelCallback: () => {
                                closeModal();
                              },
                            },
                          });
                        }}
                      >
                        프로젝트 정보 수정하기
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="state-guide">
                      <div className="icon type-check"></div>
                      <div className="text">
                        프로젝트 등록이 완료되었어요. <br />
                        파트너 매칭을 통해서 프로젝트를 진행해 보세요!
                        <p className="sub">
                          등록 완료 ㅣ {dateFormatType2(data.APPROVED_AT)}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          navigate(`/aiproject/${id}`);
                        }}
                        className="btn btn-lg btn-black type2"
                        disabled={STATUS.indexOf(data.STATUS) >= 2}
                      >
                        나의 프로젝트 확인하기
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="state-guide">
                  <div className="icon type-write"></div>
                  <div className="text">
                    프로젝트 등록이 반려되었어요.
                    <br />
                    아래 내용을 확인 후 다시 의뢰해 주세요.
                  </div>
                  <div className="box">{data.REFUSE_REASON}</div>
                  <button
                    type="button"
                    className="btn btn-lg btn-black type2"
                    onClick={() => {
                      openModal({
                        type: "messageModal",
                        contents: {
                          title: "참고해 주세요!",
                          message:
                            "프로젝트의 정보를 수정하시면 새롭게 검토가 진행돼요.\n(검토 기간: 영업일 기준 1~2일 소요)",
                          confirmText: "수정하기",
                          confirmCallback: () => {
                            navigate(`/aiproject/request?edit=${id}`);
                            closeModal();
                          },
                          cancelText: "돌아가기",
                          cancelCallback: () => {
                            closeModal();
                          },
                        },
                      });
                    }}
                  >
                    프로젝트 정보 수정하기
                  </button>
                </div>
              </>
            )}
          </>
        );
      case "MATCHING":
        return (
          <>
            {!isMobile && (
              <div className="state-tip">
                <p>
                  매칭 진행은 <strong>지원리스트 &gt; 미팅 &gt; 계약</strong> 총
                  3단계로 순차적으로 진행돼요.{" "}
                </p>
                <p>
                  미팅은 <strong>최대 2곳의 파트너와 가능해요.</strong>{" "}
                </p>
              </div>
            )}
            <div className="list-sort">
              <button type="button" className="btn">
                최신순
              </button>
            </div>
            <div className="list-wrap">
              <div
                className={`list ${matchingState === 0 ? "active" : ""} ${
                  matchingState > 0 ? "done" : ""
                }`}
              >
                <div
                  className={`list-tit ${
                    isMatchingListOn === 0 ? "m-list-active" : ""
                  }`}
                  onClick={() => setMatchingListOn(0)}
                >
                  <div className="d-flex align-center">
                    <button
                      type="button"
                      className={`btn m-block mr-5 ${
                        isMatchingListOn === 0 ? "btn-plus" : "btn-minus"
                      }`}
                    ></button>
                    지원리스트 &#40;{applyCount}&#41;
                  </div>
                  <div className="right">
                    <span className="text">찜한 파트너</span>
                    <label
                      className="input-switch"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={dibs}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          setDibs(e.target.checked);
                        }}
                      />
                      <span className="switch"></span>
                    </label>
                  </div>
                </div>
                {((isMobile && isMatchingListOn === 0) || !isMobile) && (
                  <>
                    <div className="list-top">
                      {
                        <label className="input-checkbox">
                          {/* 
												<input
													type="checkbox"
													checked={(applyCheckList.length === applyList.length) && applyCheckList.length}
													onChange={() => {
														if (applyCheckList.length !== applyList.length) {
															const tempArr = [];
															for (let i = 0; i < applyList.length; i++) {
																tempArr.push(applyList[i].SEQ)
															}
															setApplyCheckList(tempArr);
														} else {
															setApplyCheckList([]);
														}
													}}
												/>
												{Boolean(applyList.length) &&
													<>
														<span className="box sm"></span>
														<span className="text">전체</span>
													</>
												} */}
                        </label>
                      }
                      <div className="right">
                        <button
                          type="button"
                          className="btn btn-xsm btn-blueline"
                          disabled={
                            (!applyCheck || meetingCount > 1) &&
                            matchingState <= 0
                          }
                          onClick={() => {
                            if (
                              !applyCheck ||
                              meetingCount >= 2 ||
                              data.STATUS !== "MATCHING"
                            )
                              return;
                            if (!applyCheck) return;

                            // if (applyCheckList.length > 2) {
                            // 	openModal({
                            // 		type: 'messageModal',
                            // 		contents: {
                            // 			title: '참고해 주세요!',
                            // 			message: '최대 2개의 팀만 미팅이 가능합니다',
                            // 			confirmText: '확인',
                            // 			confirmCallback: () => {
                            // 				closeModal();
                            // 			}
                            // 		}
                            // 	})
                            // 	return;
                            // }
                            openModal({
                              type: "messageModal",
                              contents: {
                                title: "참고해 주세요!",
                                message:
                                  "일정이 정해진 이후에는 미팅을 취소할 수 없어요.\n부득이한 사정으로 취소를 해야할 경우, 고객센터로 문의해 주세요.",
                                confirmText: "미팅 진행하기",
                                confirmCallback: () => {
                                  userProjectDetailMeetingApply(
                                    applyCheck,
                                    (res) => {
                                      const APPLY_USER = applyList.find(
                                        (item) => {
                                          return item.SEQ === applyCheck;
                                        }
                                      );
                                      if (res.code === 0) {
                                        openModal({
                                          type: "messageModal",
                                          contents: {
                                            title: "미팅 요청 완료",
                                            message: `[${APPLY_USER.NAME}]님에게 미팅을 요청했어요\n미팅 방식 및 일정을 정하기 위해 곧 연락 드릴게요.`,
                                            confirmText: "확인",
                                            confirmCallback: () => {
                                              userProjectDetail(id, (res) => {
                                                if (res.code !== 0) return;
                                                setData(res.response);
                                                setTab(res.response.STATUS);
                                                closeModal();
                                                setHeroesCheckModal({
                                                  visible: false,
                                                  data: null,
                                                });
                                                setApplyCheck(null);
                                              });
                                              closeModal();
                                            },
                                          },
                                        });
                                      } else {
                                        openModal({
                                          type: "messageModal",
                                          contents: {
                                            title: "요청에 실패했습니다",
                                            message:
                                              "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                            confirmText: "확인",
                                            confirmCallback: () => {
                                              closeModal();
                                              closeModal();
                                            },
                                          },
                                        });
                                      }
                                    }
                                  );
                                },
                                cancelText: "돌아가기",
                                cancelCallback: () => {
                                  closeModal();
                                },
                              },
                            });
                          }}
                        >
                          미팅 요청하기
                        </button>
                      </div>
                    </div>
                    {applyList.length ? (
                      <>
                        {applyList.map((item) => (
                          <div
                            className={`input-checkbox card ${
                              item.STATUS === "REFUSE" ? "disabled" : ""
                            }`}
                            key={`apply-list-item-${item.SEQ}`}
                            onClick={() => {
                              if (item.STATUS === "REFUSE") return;
                              heroesModalOpen(item.SEQ, 0);
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`apply-list-item-${item.SEQ}`}
                              checked={applyCheck === item.SEQ}
                              onChange={() => {
                                // applyCheckListHandler(item.SEQ)
                                if (
                                  meetingCount > 1 ||
                                  data.STATUS !== "MATCHING" ||
                                  matchingState >= 2
                                )
                                  return;
                                if (applyCheck === item.SEQ) {
                                  setApplyCheck(null);
                                } else {
                                  setApplyCheck(item.SEQ);
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <label
                              className="box sm"
                              htmlFor={`apply-list-item-${item.SEQ}`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></label>
                            <div className="info">
                              {item.STATUS === "REFUSE" ? (
                                <span className="state type-1">거절</span>
                              ) : item.DIBS ? (
                                <span className="state type-2 like">찜</span>
                              ) : (
                                <span
                                  className={`state ${
                                    item.CHECKED ? "type-3" : "type-1"
                                  }`}
                                >
                                  {item.CHECKED ? "읽음" : "안 읽음"}
                                </span>
                              )}
                              <div className="name">
                                <span className="txt">{item.NAME}</span>
                                <span
                                  className={`grade-${GRADE_TYPE.indexOf(
                                    item.GRADE
                                  )}`}
                                ></span>
                              </div>
                              <div
                                className={`rate ${
                                  item.SCORE ? "" : "no-score"
                                }`}
                              >
                                {item.SCORE ? item.SCORE : " "}
                              </div>
                              <div className="info">
                                {item.SALARY_NEGOTIABLE ? (
                                  "금액 협의 가능"
                                ) : (
                                  <>
                                    {item.OFFER_PAY_METHOD === "SALARY"
                                      ? "월급"
                                      : item.OFFER_PAY_METHOD === "WAGE"
                                      ? "주급"
                                      : "프로젝트 완료 후"}{" "}
                                    {item.OFFER
                                      ? `${item.OFFER}만 원`
                                      : ` | 협의 가능`}
                                  </>
                                )}
                              </div>
                              <div className="info">
                                {item.ROLE === "ROLE_HEROS_PERSONAL"
                                  ? "개인 파트너"
                                  : "기업 파트너"}
                                / {item.CAREER}년
                              </div>
                              <div className="date">
                                {item.STATUS === "APPLY" && "지원"} |{" "}
                                {dateFormatType2(item.CREATED_AT)}
                                <span
                                  className="badge badge-sm grey"
                                  style={{ marginLeft: 2 }}
                                >
                                  {item.DDAY}일 차
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="none">아직 지원한 파트너가 없어요.</div>
                    )}
                  </>
                )}
              </div>
              <div
                className={`list ${matchingState === 1 ? "active" : ""} ${
                  matchingState > 1 && matchingState !== 1 ? "done" : ""
                }`}
              >
                <div
                  className={`list-tit ${
                    isMatchingListOn === 1 ? "m-list-active" : ""
                  }`}
                  onClick={() => setMatchingListOn(1)}
                >
                  <div className="d-flex align-center">
                    <button
                      type="button"
                      className={`btn m-block mr-5 ${
                        isMatchingListOn === 1 ? "btn-plus" : "btn-minus"
                      }`}
                    ></button>
                    미팅 &#40;{meetingCount}&#41;
                  </div>
                </div>
                {((isMobile && isMatchingListOn === 1) || !isMobile) && (
                  <>
                    <div className="list-top">
                      &nbsp;
                      <div className="right">
                        <button
                          type="button"
                          className="btn btn-xsm btn-blueline"
                          disabled={!meetingCheck && matchingState <= 1}
                          onClick={() => {
                            if (contract) return;
                            if (!meetingCheck) return;

                            userContractApply(meetingCheck, (res) => {
                              if (res.code === 2) {
                                openModal({
                                  type: "messageModal",
                                  contents: {
                                    title: "계약 요청",
                                    message:
                                      "미팅이 완료되지 않아, 계약을 요청할수 없습니다.",
                                    confirmText: "확인",
                                    confirmCallback: () => {
                                      closeModal();
                                    },
                                  },
                                });
                              } else if (res.code !== 0) {
                                openModal({
                                  type: "messageModal",
                                  contents: {
                                    title: "계약 요청",
                                    message:
                                      "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                    confirmText: "확인",
                                    confirmCallback: () => {
                                      closeModal();
                                    },
                                  },
                                });
                              } else {
                                userProjectDetail(id, (res) => {
                                  if (res.code !== 0) return;
                                  setData(res.response);
                                  setTab(res.response.STATUS);
                                });
                              }
                            });
                          }}
                        >
                          계약 요청하기
                        </button>
                      </div>
                    </div>
                    {meetingList.length ? (
                      <>
                        {meetingList.map((item) => (
                          <div
                            className={`input-checkbox card ${
                              item.DELETED ? "disabled" : ""
                            }`}
                            key={`apply-list-item-${item.SEQ}`}
                            onClick={() => {
                              if (item.DELETED) return;
                              heroesModalOpen(item.SEQ, 1);
                            }}
                          >
                            <input
                              id={`meeting-list-item-${item.SEQ}`}
                              type="checkbox"
                              checked={meetingCheck === item.SEQ}
                              onChange={() => {
                                if (meetingCheck === item.SEQ) {
                                  setMeetingCheck(null);
                                } else {
                                  setMeetingCheck(item.SEQ);
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              disabled={contract}
                            />
                            <label
                              htmlFor={`meeting-list-item-${item.SEQ}`}
                              className="box sm"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></label>
                            <div className="info">
                              {/* <span className="state type-2">미팅 완료</span> */}
                              {!item.DELETED ? (
                                item.STATUS === "COMPLETE" ? (
                                  <span className="state type-2">
                                    미팅 완료
                                  </span>
                                ) : (
                                  <span className="state type-1">
                                    미팅 예정
                                  </span>
                                )
                              ) : (
                                <span className="state type-1">종료</span>
                              )}
                              <div className="name">
                                <span className="txt">{item.NAME}</span>
                                <span
                                  className={`grade-${GRADE_TYPE.indexOf(
                                    item.GRADE
                                  )}`}
                                ></span>
                              </div>
                              <div
                                className={`rate ${
                                  item.SCORE ? "" : "no-score"
                                }`}
                              >
                                {item.SCORE ? item.SCORE : " "}
                              </div>
                              <div className="info">
                                {item.SALARY_NEGOTIABLE ? (
                                  "금액 협의 가능"
                                ) : (
                                  <>
                                    {item.OFFER_PAY_METHOD === "SALARY"
                                      ? "월급"
                                      : item.OFFER_PAY_METHOD === "WAGE"
                                      ? "주급"
                                      : "프로젝트 완료 후"}{" "}
                                    {item.OFFER
                                      ? `${item.OFFER}만 원`
                                      : ` | 협의 가능`}
                                  </>
                                )}
                              </div>
                              <div className="info">
                                {item.ROLE === "ROLE_HEROS_PERSONAL"
                                  ? "개인 파트너"
                                  : "기업 파트너"}{" "}
                                / {item.CAREER}년
                              </div>
                              {item.MEETING_AT ? (
                                <div className="date">
                                  {item.MEETING_METHOD === "OFFLINE"
                                    ? "오프라인"
                                    : "온라인"}
                                  ㅣ{dateFormatType2NoTime(item.MEETING_AT)}{" "}
                                  {item.MEETING_TIME}
                                </div>
                              ) : (
                                <div className="date">-</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="none">아직 선택하신 파트너가 없어요.</div>
                    )}
                  </>
                )}
              </div>
              <div className={`list ${matchingState === 2 ? "active" : ""}`}>
                <div
                  className={`list-tit ${
                    isMatchingListOn === 2 ? "m-list-active" : ""
                  }`}
                  onClick={() => setMatchingListOn(2)}
                >
                  <div className="d-flex align-center">
                    <button
                      type="button"
                      className={`btn m-block mr-5 ${
                        isMatchingListOn === 2 ? "btn-plus" : "btn-minus"
                      }`}
                    ></button>
                    계약
                  </div>
                </div>
                {((isMobile && isMatchingListOn === 2) || !isMobile) && (
                  <>
                    <div className="list-top"></div>
                    {contract ? (
                      <>
                        <div
                          className="input-checkbox card"
                          key={`apply-list-item-${contract.SEQ}`}
                          onClick={() => {
                            heroesModalOpen(contract.SEQ, 2);
                          }}
                        >
                          <input
                            id={`meeting-list-item-${contract.SEQ}`}
                            type="checkbox"
                            checked
                            onChange={() => {}}
                          />
                          <label
                            htmlFor={`meeting-list-item-${contract.SEQ}`}
                            className="box sm"
                          ></label>
                          <div className="info">
                            {contract.COMPLETED ? (
                              <span className="state type-2">계약 완료</span>
                            ) : (
                              <span className="state type-2">계약 예정</span>
                            )}
                            <div className="name">
                              <span className="txt">{contract.NAME}</span>
                              <span
                                className={`grade-${GRADE_TYPE.indexOf(
                                  contract.GRADE
                                )}`}
                              ></span>
                            </div>
                            <div
                              className={`rate ${
                                contract.SCORE ? "" : "no-score"
                              }`}
                            >
                              {contract.SCORE ? contract.SCORE : " "}
                            </div>
                            <div className="info">
                              {contract.SALARY_NEGOTIABLE ? (
                                "금액 협의 가능"
                              ) : (
                                <>
                                  {contract.OFFER_PAY_METHOD === "SALARY"
                                    ? "월급"
                                    : contract.OFFER_PAY_METHOD === "WAGE"
                                    ? "주급"
                                    : "프로젝트 완료 후"}{" "}
                                  {contract.OFFER
                                    ? `${contract.OFFER}만 원`
                                    : ` | 협의 가능`}
                                </>
                              )}
                            </div>
                            <div className="info">
                              {contract.ROLE === "ROLE_HEROS_PERSONAL"
                                ? "개인 파트너"
                                : "기업 파트너"}{" "}
                              / {contract.CAREER}년
                            </div>
                            <div className="date">
                              {/* {contract.CONTRACT_AT ? contract.CONTRACT_AT : ''} */}
                              {contract.MEETING_METHOD === "OFFLINE"
                                ? "오프라인"
                                : "온라인"}
                              ㅣ{dateFormatType2NoTime(contract.MEETING_AT)}{" "}
                              {contract.MEETING_TIME}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="none">아직 선택하신 파트너가 없어요.</div>
                    )}
                  </>
                )}
              </div>
            </div>
            {heroesCheckModal.visible && (
              <div className="modal show">
                <div className="modal-dim"></div>
                {heroesCheckModal.data && (
                  <div className={`modal-con lg ${isMobile ? "full" : ""}`}>
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => {
                        setHeroesCheckModal({ visible: false, data: null });
                        userProjectDetailApplication(
                          { seq: id, dibs },
                          (res) => {
                            setApplyList(res.response.applyList);
                            setApplyCount(res.response.count);
                          }
                        );
                      }}
                    ></button>
                    {/* {console.log(heroesCheckModal.data.COMPLETED, heroesCheckModal.data.COMPLETED)} */}
                    <div
                      className="profile-wrap scroll"
                      style={
                        (heroesCheckModal.data.COMPLETED === false ||
                          heroesCheckModal.data.COMPLETED === true) &&
                        isMobile
                          ? { maxHeight: "100%" }
                          : {}
                      }
                    >
                      {heroesCheckModal.data.INTRODUCE_SIMPLE && (
                        <div className="profile-top">
                          “
                          <div className="text">
                            {heroesCheckModal.data.INTRODUCE_SIMPLE}
                          </div>
                          ”
                        </div>
                      )}
                      <div className="profile-info">
                        {heroesCheckModal.data.COMPLETED && (
                          <>
                            {heroesCheckModal.data.PROFILE_IMG ? (
                              <div className="image m-none">
                                <img
                                  src={heroesCheckModal.data.PROFILE_IMG}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div className="image m-none">
                                <img src={profile_default_img} alt="" />
                              </div>
                            )}
                          </>
                        )}
                        <div className="text">
                          <div className="badges">
                            <span className="badge blue">
                              {heroesCheckModal.data.ROLE ===
                              "ROLE_HEROS_PERSONAL"
                                ? "개인 파트너"
                                : "기업 파트너"}
                            </span>
                            {(heroesCheckModal.data.WORK_METHOD === "BOTH" ||
                              heroesCheckModal.data.WORK_METHOD ===
                                "RESIDENT") && (
                              <span className="badge orange">상주</span>
                            )}
                            {(heroesCheckModal.data.WORK_METHOD === "BOTH" ||
                              heroesCheckModal.data.WORK_METHOD ===
                                "REMOTE") && (
                              <span className="badge blue-line">원격</span>
                            )}
                          </div>
                          <div className="name">
                            {heroesCheckModal.data.NAME}
                            <span
                              className={`grade type-${GRADE_TYPE.indexOf(
                                heroesCheckModal.data.GRADE
                              )}`}
                            ></span>
                            <div
                              className={`rate ${
                                heroesCheckModal.data.SCORE ? "" : "no-score"
                              }`}
                            >
                              {heroesCheckModal.data.SCORE ? (
                                <>
                                  <span
                                    className={`star ${
                                      Number(heroesCheckModal.data.SCORE) >= 1
                                        ? "fill"
                                        : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`star ${
                                      Number(heroesCheckModal.data.SCORE) >= 2
                                        ? "fill"
                                        : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`star ${
                                      Number(heroesCheckModal.data.SCORE) >= 3
                                        ? "fill"
                                        : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`star ${
                                      Number(heroesCheckModal.data.SCORE) >= 4
                                        ? "fill"
                                        : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`star ${
                                      Number(heroesCheckModal.data.SCORE) >= 5
                                        ? "fill"
                                        : ""
                                    }`}
                                  ></span>
                                  <span className="num">
                                    &#40;{heroesCheckModal.data.SCORE || 0}&#41;
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="m-row">
                            <div class="m-block">
                              {heroesCheckModal.data.COMPLETED && (
                                <>
                                  {heroesCheckModal.data.PROFILE_IMG ? (
                                    <div class="img">
                                      <img
                                        src={heroesCheckModal.data.PROFILE_IMG}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    <div className="img">
                                      <img src={profile_default_img} alt="" />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            <div>
                              <p>
                                <span>희망 급여:</span>
                                {heroesCheckModal.data.SALARY_NEGOTIABLE ? (
                                  <span
                                    className="badge badge-sm skyblue"
                                    style={{ marginLeft: 10 }}
                                  >
                                    협의 가능
                                  </span>
                                ) : (
                                  <>
                                    &nbsp;
                                    {priceFormat(
                                      heroesCheckModal.data.OFFER ||
                                        heroesCheckModal.data.DESIRED_SALARY
                                    )}
                                    만 원
                                    <span
                                      className="badge badge-sm skyblue"
                                      style={{ marginLeft: 10 }}
                                    >
                                      {data.PAY_METHOD === "SALARY"
                                        ? "월급"
                                        : data.PAY_METHOD === "WAGE"
                                        ? "주급"
                                        : "프로젝트 완료 후"}
                                    </span>
                                  </>
                                )}
                              </p>
                              <p>경력: {heroesCheckModal.data.CAREER}년</p>
                              <p>
                                선호 프로젝트:{" "}
                                {heroesCheckModal.data.PREFER_PROJECT_TYPE ===
                                "BUSINESS"
                                  ? "기업 프로젝트"
                                  : "개인 프로젝트"}
                              </p>
                              {heroesCheckModal.data.USERNAME && (
                                <p>이메일: {heroesCheckModal.data.USERNAME}</p>
                              )}
                              {heroesCheckModal.data.PHONE && (
                                <p>
                                  연락처:{" "}
                                  {phoneFormat(heroesCheckModal.data.PHONE)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-sub">키워드</div>
                      <div
                        className="profile-badges"
                        style={isMobile ? { marginRight: -25 } : {}}
                      >
                        <div className="row">
                          <label className="label">전문 분야</label>
                          <div className="badges">
                            {heroesCheckModal.data.BAILIWICK.EDUCATION && (
                              <span className="badge m5 badge-md primary2">
                                교육
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.SERVICE && (
                              <span className="badge m5 badge-md primary2">
                                서비스
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.MANUFACTURING && (
                              <span className="badge m5 badge-md primary2">
                                제조, 화학, 에너지
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.VEHICLE && (
                              <span className="badge m5 badge-md primary2">
                                자동차
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.SALES && (
                              <span className="badge m5 badge-md primary2">
                                판매, 유통
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.MEDIA && (
                              <span className="badge m5 badge-md primary2">
                                미디어, 엔터테인먼트
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.TOUR && (
                              <span className="badge m5 badge-md primary2">
                                관광, 스포츠, 레저
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.CONSTRUCT && (
                              <span className="badge m5 badge-md primary2">
                                부동산, 건설, 건축
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.FINANCE && (
                              <span className="badge m5 badge-md primary2">
                                금융
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.MEDICAL && (
                              <span className="badge m5 badge-md primary2">
                                의료, 제약, 바이오, 헬스케어
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.IT && (
                              <span className="badge m5 badge-md primary2">
                                IT, 통신
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.GOVERNMENT && (
                              <span className="badge m5 badge-md primary2">
                                정부, 기관
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.FOOD && (
                              <span className="badge m5 badge-md primary2">
                                식품
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.AGRICULTURE && (
                              <span className="badge m5 badge-md primary2">
                                농업
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.GAME && (
                              <span className="badge m5 badge-md primary2">
                                게임
                              </span>
                            )}
                            {heroesCheckModal.data.BAILIWICK.ETC && (
                              <span className="badge m5 badge-md primary2">
                                기타
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <label className="label">보유 스킬</label>
                          <div className="badges">
                            {heroesCheckModal.data.SKILL_RETENTION.VIDEO ||
                            heroesCheckModal.data.SKILL_RETENTION.IMAGE ||
                            heroesCheckModal.data.SKILL_RETENTION.SOUND ||
                            heroesCheckModal.data.SKILL_RETENTION.TEXT ||
                            heroesCheckModal.data.SKILL_RETENTION.BIG_DATA ||
                            heroesCheckModal.data.SKILL_RETENTION.FUSION ||
                            heroesCheckModal.data.SKILL_RETENTION.SKILL_ETC ? (
                              <>
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .VIDEO && (
                                  <span className="badge m5 badge-md primary2">
                                    동영상
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .IMAGE && (
                                  <span className="badge m5 badge-md primary2">
                                    이미지
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .SOUND && (
                                  <span className="badge m5 badge-md primary2">
                                    음성
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION.TEXT && (
                                  <span className="badge m5 badge-md primary2">
                                    텍스트
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .BIG_DATA && (
                                  <span className="badge m5 badge-md primary2">
                                    빅데이터
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .FUSION && (
                                  <span className="badge m5 badge-md primary2">
                                    융합
                                  </span>
                                )}
                                {heroesCheckModal.data.SKILL_RETENTION
                                  .SKILL_ETC && (
                                  <span className="badge m5 badge-md primary2">
                                    기타
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="badge-md" style={{ padding: 0 }}>
                                -
                              </div>
                            )}
                          </div>
                        </div>

                        {(heroesCheckModal.data.INTERESTED_AI_TASK
                          .MACHINE_LEARNING ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .DEEP_LEARNING ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .CLASSIFICATION ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .CONVOLUTION ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.CNN ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.RNN ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.CAM ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.OCR ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.STT ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.TTS ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.NLP ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.GAN ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.DEEP_FAKE ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .OBJECT_DETECTION ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .ANOMALY_DETECTION ||
                          heroesCheckModal.data.INTERESTED_AI_TASK
                            .SEGMENTATION ||
                          heroesCheckModal.data.INTERESTED_AI_TASK.MULTITASK ||
                          heroesCheckModal.data.FRAMEWORK.R ||
                          heroesCheckModal.data.FRAMEWORK.PYTHON ||
                          heroesCheckModal.data.FRAMEWORK.C ||
                          heroesCheckModal.data.FRAMEWORK.CPP ||
                          heroesCheckModal.data.FRAMEWORK.AOS ||
                          heroesCheckModal.data.FRAMEWORK.IOS ||
                          heroesCheckModal.data.FRAMEWORK.TENSORFLOW ||
                          heroesCheckModal.data.FRAMEWORK.KERAS ||
                          heroesCheckModal.data.FRAMEWORK.CAFFE ||
                          heroesCheckModal.data.FRAMEWORK.CNTK ||
                          heroesCheckModal.data.FRAMEWORK.PYTORCH ||
                          heroesCheckModal.data.FRAMEWORK.DAVID ||
                          heroesCheckModal.data.FRAMEWORK.WEB_PPL ||
                          heroesCheckModal.data.FRAMEWORK.CHURCH ||
                          heroesCheckModal.data.FRAMEWORK.FIGARO ||
                          heroesCheckModal.data.FRAMEWORK.OPENAI_GYM ||
                          heroesCheckModal.data.FRAMEWORK.PYRO ||
                          heroesCheckModal.data.FRAMEWORK.TENSOR_FORCE ||
                          heroesCheckModal.data.FRAMEWORK.RLIB) && (
                          <div className="row">
                            <label className="label">관심사</label>
                            <div className="badges">
                              <>
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .MACHINE_LEARNING && (
                                  <span className="badge m5 badge-md purple">
                                    Machine Learning
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .DEEP_LEARNING && (
                                  <span className="badge m5 badge-md purple">
                                    Deep Learning
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .CLASSIFICATION && (
                                  <span className="badge m5 badge-md purple">
                                    Classification
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .CONVOLUTION && (
                                  <span className="badge m5 badge-md purple">
                                    Convolution
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .CNN && (
                                  <span className="badge m5 badge-md purple">
                                    CNN
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .RNN && (
                                  <span className="badge m5 badge-md purple">
                                    RNN
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .CAM && (
                                  <span className="badge m5 badge-md purple">
                                    CAM
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .OCR && (
                                  <span className="badge m5 badge-md purple">
                                    OCR
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .STT && (
                                  <span className="badge m5 badge-md purple">
                                    STT
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .TTS && (
                                  <span className="badge m5 badge-md purple">
                                    TTS
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .NLP && (
                                  <span className="badge m5 badge-md purple">
                                    NLP
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .GAN && (
                                  <span className="badge m5 badge-md purple">
                                    GAN
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .DEEP_FAKE && (
                                  <span className="badge m5 badge-md purple">
                                    Deep Fake
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .OBJECT_DETECTION && (
                                  <span className="badge m5 badge-md purple">
                                    Object Detection
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .ANOMALY_DETECTION && (
                                  <span className="badge m5 badge-md purple">
                                    Anomaly Detection
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .SEGMENTATION && (
                                  <span className="badge m5 badge-md purple">
                                    Segmentation
                                  </span>
                                )}
                                {heroesCheckModal.data.INTERESTED_AI_TASK
                                  .MULTITASK && (
                                  <span className="badge m5 badge-md purple">
                                    Multi
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.R && (
                                  <span className="badge m5 badge-md purple">
                                    R
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.PYTHON && (
                                  <span className="badge m5 badge-md purple">
                                    Python
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.C && (
                                  <span className="badge m5 badge-md purple">
                                    C
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.CPP && (
                                  <span className="badge m5 badge-md purple">
                                    C
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.AOS && (
                                  <span className="badge m5 badge-md purple">
                                    AOS
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.IOS && (
                                  <span className="badge m5 badge-md purple">
                                    iOS
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.TENSORFLOW && (
                                  <span className="badge m5 badge-md purple">
                                    Tensorflow
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.KERAS && (
                                  <span className="badge m5 badge-md purple">
                                    Keras
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.CAFFE && (
                                  <span className="badge m5 badge-md purple">
                                    Caffe
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.CNTK && (
                                  <span className="badge m5 badge-md purple">
                                    CNTK
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.PYTORCH && (
                                  <span className="badge m5 badge-md purple">
                                    PyTorch
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.DAVID && (
                                  <span className="badge m5 badge-md purple">
                                    David
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.WEB_PPL && (
                                  <span className="badge m5 badge-md purple">
                                    WebPPL
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.CHURCH && (
                                  <span className="badge m5 badge-md purple">
                                    Church
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.FIGARO && (
                                  <span className="badge m5 badge-md purple">
                                    Figaro
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.OPENAI_GYM && (
                                  <span className="badge m5 badge-md purple">
                                    OpenAI
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.PYRO && (
                                  <span className="badge m5 badge-md purple">
                                    Pyro
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK
                                  .TENSOR_FORCE && (
                                  <span className="badge m5 badge-md purple">
                                    TensorForce
                                  </span>
                                )}
                                {heroesCheckModal.data.FRAMEWORK.RLIB && (
                                  <span className="badge m5 badge-md purple">
                                    Rlib
                                  </span>
                                )}
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                      {(heroesCheckModal.data.INTRODUCE_DETAILS ||
                        heroesCheckModal.data.FILE) && (
                        <>
                          <div className="profile-sub">파트너 소개</div>
                          {heroesCheckModal.data.INTRODUCE_DETAILS && (
                            <div className="profile-box">
                              {heroesCheckModal.data.INTRODUCE_DETAILS}
                            </div>
                          )}
                          {heroesCheckModal.data.FILE && (
                            <button
                              type="button"
                              style={
                                !heroesCheckModal.data.INTRODUCE_DETAILS
                                  ? { marginTop: 0 }
                                  : {}
                              }
                              className="btn btn-full btn-primary btn-down"
                              onClick={() =>
                                window.open(heroesCheckModal.data.FILE)
                              }
                            >
                              포트폴리오 다운로드
                            </button>
                          )}
                        </>
                      )}
                      {heroesCheckModal.data.CAREER_LIST && (
                        <>
                          <div className="profile-sub">경력</div>
                          <ul className="profile-history">
                            {heroesCheckModal.data.CAREER_LIST?.map((item) => (
                              <li className="profile-history--item">
                                <div className="left">
                                  <div className="name">
                                    {item.COMPANY_NAME}
                                  </div>
                                  <div className="team">
                                    {item.DEPARTMENT} / {item.ROLE}
                                  </div>
                                  <div className="date">
                                    {item.START_AT.split("-")[0] +
                                      ". " +
                                      item.START_AT.split("-")[1]}{" "}
                                    ~{" "}
                                    {item.CURRENT
                                      ? "재직중"
                                      : item.END_AT?.split("-")[0] +
                                        ". " +
                                        item.END_AT?.split("-")[1]}
                                  </div>
                                </div>
                                <div className="right">{item.PERFORMANCE}</div>
                              </li>
                            ))}
                          </ul>
                          <div className="m-block hr-line"></div>
                        </>
                      )}
                      {heroesCheckModal.data.EDUCATION && (
                        <>
                          <div className="profile-sub">학력</div>
                          <ul className="profile-history">
                            {heroesCheckModal.data.EDUCATION?.map((item) => (
                              <li className="profile-history--item">
                                <div className="left">
                                  <div className="name">{item.SCHOOL_NAME}</div>
                                  <div className="team">
                                    {item.MAJOR} / {item.DEGREE}
                                  </div>
                                  <div className="date">
                                    {item.START_AT.split("-")[0] +
                                      ". " +
                                      item.START_AT.split("-")[1]}{" "}
                                    ~{" "}
                                    {item.CURRENT
                                      ? "재직중"
                                      : item.END_AT?.split("-")[0] +
                                        ". " +
                                        item.END_AT?.split("-")[1]}
                                  </div>
                                </div>
                                <div className="right">{item.PERFORMANCE}</div>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {Boolean(heroesCheckModal.data.CERTIFICATE?.length) && (
                        <>
                          <div className="profile-sub">수상 및 자격증</div>
                          <ul className="text-list type2">
                            {heroesCheckModal.data.CERTIFICATE?.map((item) => (
                              <li className="text-list--item">
                                <div className="left">
                                  <div className="badge-wrap">
                                    <span className="badge badge-sm primary">
                                      {item.TYPE === "AWARD"
                                        ? "수상"
                                        : "자격증"}
                                    </span>
                                  </div>
                                  <div className="strong">{item.NAME}</div>
                                </div>
                                <div className="right">
                                  <div>{item.CERTIFICATION_AUTHORITY}</div>
                                  <div>{item.ISSUED_AT}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {Boolean(heroesCheckModal.data.PROJECT_QNA.length) && (
                        <>
                          <div className="profile-sub">주고 받은 QnA</div>
                          <div className="message-wrap">
                            {heroesCheckModal.data.PROJECT_QNA.map((item) => (
                              <>
                                <div className="message-left">
                                  <div className="box">{item.Q_CONTENTS}</div>
                                  <div className="date">
                                    {dateFormatType2(item.Q_CREATED_AT)}
                                  </div>
                                </div>
                                {item.A_CREATED_AT && (
                                  <div className="message-right">
                                    <div className="box">{item.A_CONTENTS}</div>
                                    <div className="date">
                                      {dateFormatType2(item.A_CREATED_AT)}
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </>
                      )}
                      {heroesCheckModal.data.meetingList && (
                        <>
                          {heroesCheckModal.data.meetingList?.map((item, i) => (
                            <div
                              className="profile-detail"
                              key={`meeting-list-item-${i}`}
                            >
                              <div className="profile-sub">미팅 정보</div>
                              <div className="col-wrap">
                                <div className="col">
                                  <div className="row">
                                    <label className="label">미팅 일시</label>
                                    {dateFormatType2NoTime(
                                      item.MEETING_AT
                                    )}{" "}
                                    {item.MEETING_TIME}
                                    {item.STATUS === "COMPLETE" ? (
                                      <span className="badge badge-sm primary">
                                        미팅 완료
                                      </span>
                                    ) : (
                                      <span className="badge badge-sm d-grey2">
                                        미팅 예정
                                      </span>
                                    )}
                                  </div>
                                  <div className="row">
                                    <label className="label">장소/링크</label>
                                    {/* <a href="meet.google.com/dskfdi23" target="_blank" className="a-link">{item.PLACE}</a> */}
                                    {checkURL(item.PLACE) ? (
                                      <a
                                        href={item.PLACE}
                                        target="_blank"
                                        className="a-link"
                                        rel="noreferrer"
                                      >
                                        {item.PLACE}
                                      </a>
                                    ) : (
                                      item.PLACE
                                    )}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="row">
                                    <label className="label">미팅 방식</label>
                                    {item.MEETING_METHOD
                                      ? item.MEETING_METHOD === "OFFLINE"
                                        ? "오프라인"
                                        : "온라인"
                                      : "-"}
                                  </div>
                                  <div className="row">
                                    <label className="label">담당 매니저</label>
                                    {manager.NAME} / {manager.PHONE}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {heroesCheckModal.data.COMPLETED !== false &&
                        heroesCheckModal.data.COMPLETED !== true &&
                        heroesCheckModal.data.STATUS !== "APPLY" &&
                        heroesCheckModal.data?.meetingList.length === 0 && (
                          <>
                            <div className="profile-detail">
                              <div className="profile-sub">미팅 정보</div>
                              <div className="col-wrap">
                                <div className="col">
                                  <div className="row">
                                    <label className="label">미팅 일시</label>-
                                    {/* <span className="badge badge-sm d-grey2">미팅 예정</span> */}
                                  </div>
                                  <div className="row">
                                    <label className="label">장소/링크</label>-
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="row">
                                    <label className="label">미팅 방식</label>-
                                  </div>
                                  <div className="row">
                                    <label className="label">담당 매니저</label>
                                    {manager.NAME} / {manager.PHONE}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      {heroesCheckModal.data.CONTRACT_AT && (
                        <div className="profile-detail">
                          <div className="profile-sub">계약 정보</div>
                          <div className="col-wrap">
                            <div className="col">
                              <div className="row">
                                <label className="label">계약 금액</label>
                                {priceFormat(heroesCheckModal.data.TOTAL)}원
                              </div>
                              <div className="row">
                                <label className="label">계약 기간</label>
                                {heroesCheckModal.data.START_AT}~
                                {heroesCheckModal.data.END_AT}
                              </div>
                            </div>
                            <div className="col">
                              <div className="row">
                                <label className="label">계약일</label>
                                <span>{heroesCheckModal.data.CONTRACT_AT}</span>
                                {heroesCheckModal.data.COMPLETED ? (
                                  <span className="badge badge-sm primary">
                                    계약 완료
                                  </span>
                                ) : (
                                  <span className="badge badge-sm primary">
                                    계약 예정
                                  </span>
                                )}
                              </div>
                              <div className="row">
                                <label className="label">대금 보호방식</label>
                                에스크로
                              </div>
                              <div
                                className="row"
                                style={!isMobile ? { overflow: "inherit" } : {}}
                              >
                                {contract.FILE && (
                                  <button
                                    type="button"
                                    className="btn btn-full btn-primary m-mt-6"
                                    onClick={() => {
                                      window.open(contract.FILE);
                                    }}
                                  >
                                    계약서 보기
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!heroesCheckModal.data.CONTRACT_AT &&
                        typeof heroesCheckModal.data.COMPLETED !==
                          "undefined" &&
                        heroesCheckModal.data.COMPLETED !== true && (
                          <div className="profile-detail">
                            <div className="profile-sub">계약 정보</div>
                            <div className="col-wrap">
                              <div className="col">
                                <div className="row">
                                  <label className="label">계약 금액</label>-
                                </div>
                                <div className="row">
                                  <label className="label">계약 기간</label>-
                                </div>
                              </div>
                              <div className="col">
                                <div className="row">
                                  <label className="label">계약일</label>
                                  <span>-</span>
                                  <span className="badge badge-sm primary">
                                    계약 예정
                                  </span>
                                </div>
                                <div className="row">
                                  <label className="label">대금 보호방식</label>
                                  에스크로
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    {heroesCheckModal.data.COMPLETED !== false &&
                      heroesCheckModal.data.COMPLETED !== true &&
                      heroesCheckModal.data.STATUS !== "APPLY" &&
                      heroesCheckModal.data.meetingList.length !== 0 && (
                        <div className="btn-wrap type2 mt-40">
                          <button
                            type="button"
                            className="btn btn-lg btn-black type2"
                            disabled={contract}
                            onClick={() => {
                              userContractApply(
                                heroesCheckModal.conId,
                                (res) => {
                                  if (res.code === 2) {
                                    openModal({
                                      type: "messageModal",
                                      contents: {
                                        title: "계약 요청",
                                        message:
                                          "미팅이 완료되지 않아, 계약을 요청할수 없습니다.",
                                        confirmText: "확인",
                                        confirmCallback: () => {
                                          closeModal();
                                        },
                                      },
                                    });
                                  } else if (res.code !== 0) {
                                    openModal({
                                      type: "messageModal",
                                      contents: {
                                        title: "계약 요청",
                                        message:
                                          "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                        confirmText: "확인",
                                        confirmCallback: () => {
                                          closeModal();
                                        },
                                      },
                                    });
                                  } else {
                                    userProjectDetail(id, (res) => {
                                      if (res.code !== 0) return;
                                      setData(res.response);
                                      setTab(res.response.STATUS);
                                    });
                                    setHeroesCheckModal({
                                      visible: false,
                                      data: null,
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            계약 요청하기
                          </button>
                        </div>
                      )}
                    {heroesCheckModal.data.COMPLETED !== false &&
                      heroesCheckModal.data.COMPLETED !== true &&
                      heroesCheckModal.data.STATUS !== "APPLY" &&
                      heroesCheckModal.data.meetingList?.length === 0 && (
                        <div className="btn-wrap type2 mt-40">
                          <button
                            type="button"
                            className="btn btn-lg btn-grey"
                            onClick={() => {
                              projectMeetingCancel(
                                heroesCheckModal.conId,
                                (res) => {
                                  if (res.code !== 0) {
                                    openModal({
                                      type: "messageModal",
                                      contents: {
                                        title: "요청에 실패했습니다",
                                        message:
                                          "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                        confirmText: "확인",
                                        confirmCallback: () => {
                                          closeModal();
                                        },
                                      },
                                    });
                                  } else {
                                    userProjectDetail(id, (res) => {
                                      if (res.code !== 0) return;
                                      setData(res.response);
                                      setTab(res.response.STATUS);
                                    });
                                    setHeroesCheckModal({
                                      visible: false,
                                      data: null,
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            미팅 취소
                          </button>
                        </div>
                      )}
                    {heroesCheckModal.data.STATUS === "APPLY" && (
                      <div className="btn-wrap type2 mt-40">
                        <button
                          type="button"
                          className={`btn btn-like type2 ${
                            heroesCheckModal.data.DIBS ? "active" : ""
                          }`}
                          onClick={() => {
                            userHerosDibsChange(
                              heroesCheckModal.data.APPLICANT,
                              (res) => {
                                if (res.code === 0) {
                                  const applicationParams = {
                                    seq: id,
                                    dibs,
                                  };
                                  heroesModalOpen(heroesCheckModal.data.SEQ, 0);
                                  userProjectDetailApplication(
                                    applicationParams,
                                    (res) => {
                                      setApplyList(res.response.applyList);
                                      setApplyCount(res.response.count);
                                    }
                                  );
                                }
                              }
                            );
                          }}
                        >
                          {heroesCheckModal.dibsTotal}
                        </button>
                        {/* <button type="button" className={`btn btn-like type2 ${heroesCheckModal.data.DIBS ? 'active' : ''}`}>2,632</button> */}
                        <button
                          type="button"
                          className="btn btn-lg btn-black type2"
                          disabled={
                            meetingCount >= 2 || data.STATUS !== "MATCHING"
                          }
                          onClick={() => {
                            openModal({
                              type: "messageModal",
                              contents: {
                                title: "참고해 주세요!",
                                message:
                                  "일정이 정해진 이후에는 미팅을 취소할 수 없어요.\n부득이한 사정으로 취소를 해야할 경우, 고객센터로 문의해 주세요.",
                                confirmText: "미팅 진행하기",
                                confirmCallback: () => {
                                  userProjectDetailMeetingApply(
                                    heroesCheckModal.data.SEQ,
                                    (res) => {
                                      if (res.code === 0) {
                                        openModal({
                                          type: "messageModal",
                                          contents: {
                                            title: "미팅 요청 완료",
                                            message: `[${heroesCheckModal.data.NAME}]님에게 미팅을 요청했어요\n미팅 방식 및 일정을 정하기 위해 곧 연락 드릴게요.`,
                                            confirmText: "확인",
                                            confirmCallback: () => {
                                              const applicationParams = {
                                                seq: id,
                                                dibs,
                                              };
                                              const meetingParams = {
                                                seq: id,
                                              };
                                              userProjectDetailApplication(
                                                applicationParams,
                                                (res) => {
                                                  setApplyList(
                                                    res.response.applyList
                                                  );
                                                  setApplyCount(
                                                    res.response.count
                                                  );
                                                }
                                              );
                                              userProjectDetailMeeting(
                                                meetingParams,
                                                (res) => {
                                                  setMeetingList(
                                                    res.response.meetingList
                                                  );
                                                  setMeetingCount(
                                                    res.response.count
                                                  );
                                                }
                                              );
                                              closeModal();
                                              setHeroesCheckModal({
                                                visible: false,
                                                data: null,
                                              });
                                            },
                                          },
                                        });
                                        closeModal();
                                      } else {
                                        openModal({
                                          type: "messageModal",
                                          contents: {
                                            title: "요청에 실패했습니다",
                                            message:
                                              "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                            confirmText: "확인",
                                            confirmCallback: () => {
                                              closeModal();
                                              closeModal();
                                            },
                                          },
                                        });
                                      }
                                    }
                                  );
                                },
                                cancelText: "돌아가기",
                                cancelCallback: () => {
                                  closeModal();
                                },
                              },
                            });
                          }}
                        >
                          미팅 요청하기
                        </button>
                        <button
                          type="button"
                          className="btn btn-lg btn-grey"
                          onClick={() => {
                            userProjectDetailHeroesRefuse(
                              heroesCheckModal.data.SEQ,
                              (res) => {
                                if (res.code !== 0) {
                                  openModal({
                                    type: "messageModal",
                                    contents: {
                                      title: "요청에 실패했습니다",
                                      message:
                                        "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                                      confirmText: "확인",
                                      confirmCallback: () => {
                                        closeModal();
                                      },
                                    },
                                  });
                                  setHeroesCheckModal({
                                    visible: false,
                                    data: null,
                                  });
                                } else {
                                  setHeroesCheckModal({
                                    visible: false,
                                    data: null,
                                  });
                                  userProjectDetailApplication(
                                    { seq: id, dibs },
                                    (res) => {
                                      setApplyList(res.response.applyList);
                                      setApplyCount(res.response.count);
                                    }
                                  );
                                }
                              }
                            );
                          }}
                        >
                          거절하기
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        );
      case "PROGRESS":
        return (
          <>
            {!(data.COMPLETE_BEFORE && data.STATUS === "PROGRESS") ? (
              <>
                {!isMobile && (
                  <div className="state-tip">
                    <p>
                      요구사항 TASK의{" "}
                      <strong>
                        최초 세팅은 매니저가 계약서를 기반으로 진행
                      </strong>
                      해 드려요.
                    </p>
                    <p>
                      상호 간의 충분한 논의를 통해{" "}
                      <strong>요구사항 TASK와 일정을 변경</strong>할 수 있어요.
                    </p>
                  </div>
                )}
                <div className="percent-wrap">
                  <div className="top">
                    <strong>{taskCount.COMPLETED}</strong> / {taskCount.TOTAL}
                  </div>
                  <div className="percent">
                    <div className="bar-wrap">
                      <span
                        className="bar"
                        style={{
                          width: `${
                            Math.round(
                              ((taskCount.COMPLETED || 0) / taskCount.TOTAL) *
                                100
                            ) || 0
                          }%`,
                        }}
                      ></span>
                    </div>
                    <span
                      className={`num ${
                        Math.round(
                          ((taskCount.COMPLETED || 0) / taskCount.TOTAL) * 100
                        ) > 0
                          ? "active"
                          : ""
                      }`}
                    >
                      <strong>{`${
                        Math.round(
                          ((taskCount.COMPLETED || 0) / taskCount.TOTAL) * 100
                        ) || 0
                      }`}</strong>
                      %
                    </span>
                    <button
                      type="button"
                      className="btn btn-xsm btn-history"
                      onClick={() => {
                        openModal({
                          type: "projectHistoryModal",
                          contents: {
                            id: data.SEQ,
                          },
                        });
                      }}
                    >
                      {!isMobile && "히스토리 보기"}
                    </button>
                  </div>
                </div>
                <ul className="history-list">
                  <li className="history-list--item top">
                    <div className="text-left">상태</div>
                    <div className="text-left">TASK</div>
                    <div className="text-left">등록한 사람</div>
                    <div className="text-right">등록일</div>
                    <div className="text-right">완료 예정일</div>
                    <div className="text-right">완료 일자</div>
                    <div></div>
                  </li>
                  {taskList?.map((item) => (
                    <li
                      className={`history-list--item ${
                        item.DELETED || item.STATUS === "CANCEL" ? "stroke" : ""
                      } ${item.STATUS === "SUGGEST" ? "active" : ""}`}
                      key={`task-list-item-${item.SEQ}`}
                    >
                      <div className="text-left">
                        {!item.DELETED ? (
                          <>
                            {(item.STATUS === "PROGRESS" ||
                              item.STATUS === "APPROVAL") && (
                              <span className="badge badge-sm d-grey">
                                진행중
                              </span>
                            )}
                            {item.STATUS === "SUGGEST" &&
                              item.WRITER === userInfo.SEQ && (
                                <span className="badge badge-sm deepblue">
                                  보낸 요청
                                </span>
                              )}
                            {item.STATUS === "SUGGEST" &&
                              item.WRITER !== userInfo.SEQ && (
                                <span className="badge badge-sm deepblue">
                                  받은 요청
                                </span>
                              )}
                            {item.STATUS === "COMPLETE" && (
                              <span className="badge badge-sm deeppurple">
                                완료
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="text-left">-</div>
                          </>
                        )}
                      </div>
                      <div className="text-left tit">{item.NAME}</div>
                      <div className="text-left">{item.WRITER_NAME}</div>
                      <div className="text-right">
                        {dateFormatType2NoTime(item.CREATED_AT)}
                      </div>
                      <div className="text-right">
                        {item.EXPECTED_COMPLETED_AT}
                      </div>
                      <div className="text-right">
                        {item.COMPLETED ? item.COMPLETED_AT : "-"}
                      </div>
                      {!item.DELETED &&
                      data.STATUS !== "COMPLETE" &&
                      !data.COMPLETE_BEFORE ? (
                        <>
                          {item.STATUS === "PROGRESS" && (
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btn-sm btn-line"
                                onClick={() => {
                                  openModal({
                                    type: "taskModal",
                                    contents: {
                                      type: "SUGGEST",
                                      seq: item.SEQ,
                                      taskData: {
                                        text: item.NAME,
                                        date: item.EXPECTED_COMPLETED_AT,
                                      },
                                      callback: () => {
                                        userProjectDetailTaskList(id, (res) => {
                                          if (res.code !== 0) return;
                                          setTaskList(res.response.viewList);
                                        });
                                      },
                                    },
                                  });
                                }}
                              >
                                수정 요청
                              </button>
                            </div>
                          )}
                          {item.STATUS === "SUGGEST" &&
                            item.WRITER !== userInfo.SEQ && (
                              <div className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-blue"
                                  onClick={() => {
                                    openModal({
                                      type: "taskModal",
                                      contents: {
                                        type: "ACCREJ",
                                        seq: item.SEQ,
                                        taskData: {
                                          text: item.NAME,
                                          date: item.EXPECTED_COMPLETED_AT,
                                        },
                                        callback: () => {
                                          userProjectDetailTaskList(
                                            id,
                                            (res) => {
                                              if (res.code !== 0) return;
                                              setTaskList(
                                                res.response.viewList
                                              );
                                            }
                                          );
                                        },
                                      },
                                    });
                                  }}
                                >
                                  수락/거절
                                </button>
                              </div>
                            )}
                          {item.STATUS === "SUGGEST" &&
                            item.WRITER === userInfo.SEQ && (
                              <div className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-line grey"
                                >
                                  요청 중...
                                </button>
                              </div>
                            )}
                          {item.STATUS === "CANCEL" && (
                            <div className="text-right">-</div>
                          )}
                          {item.STATUS === "COMPLETE" && (
                            <div className="text-right">-</div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="text-right">-</div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="history-list--bottom">
                  {data.STATUS !== "COMPLETE" && !data.COMPLETE_BEFORE && (
                    <button
                      type="button"
                      className="btn btn-sm btn-line btn-add"
                      onClick={() => {
                        openModal({
                          type: "taskModal",
                          contents: {
                            type: "APPLY",
                            seq: id,
                            callback: () => {
                              userProjectDetailTaskList(id, (res) => {
                                if (res.code !== 0) return;
                                setTaskList(res.response.viewList);
                              });
                            },
                          },
                        });
                      }}
                    >
                      추가
                    </button>
                  )}
                </div>
                <div className="btn-wrap mt-40">
                  {data.STATUS !== "COMPLETE" && !data.COMPLETE_BEFORE && (
                    <button
                      type="button"
                      className="btn btn-lg btn-black type2"
                      onClick={() => {
                        openModal({
                          type: "messageModal",
                          contents: {
                            title: "프로젝트 완료",
                            message:
                              "프로젝트를 완료하면 더 이상 TASK를 진행할 수 없어요.\n모든 TASK의 진행 상태를 확인해 주세요.",
                            confirmText: "완료하기",
                            confirmCallback: () => {
                              projectCompleteBefore(id, (res) => {
                                if (res.code === 0) {
                                  userProjectDetail(id, (res) => {
                                    setData(res.response);
                                  });
                                  closeModal();
                                }
                              });
                            },
                            cancelText: "다시 확인하기",
                            cancelCallback: () => {
                              closeModal();
                            },
                          },
                        });
                      }}
                    >
                      프로젝트 완료하기
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <ul className="survey-list">
                  <li className="survey-list--item">
                    <div className="q">
                      <span className="num">01.</span>
                      프로젝트 진행부터 모델 검증까지 전체 프로세스를 정상적으로
                      진행하셨나요?
                    </div>
                    <div className="check">
                      <label className="input-checkbox">
                        <input
                          type="checkbox"
                          checked={completeCheck.includes("1")}
                          onChange={() => {
                            let tempArr = [...completeCheck];
                            if (completeCheck.includes("1")) {
                              tempArr = tempArr.filter((a) => {
                                return a !== "1";
                              });
                              // console.log(tempArr)
                            } else {
                              tempArr.push("1");
                            }
                            setCompleteCheck(tempArr);
                          }}
                        />
                        <span className="box text">네</span>
                      </label>
                    </div>
                  </li>
                  <li className="survey-list--item">
                    <div className="q">
                      <span className="num">02.</span>
                      프로젝트 종료 시점 이후 N주 내 대금 지급을 진행하는 것에
                      동의하시나요?
                    </div>
                    <div className="check">
                      <label className="input-checkbox">
                        <input
                          type="checkbox"
                          checked={completeCheck.includes("2")}
                          onChange={() => {
                            let tempArr = [...completeCheck];
                            if (completeCheck.includes("2")) {
                              tempArr = tempArr.filter((a) => {
                                return a !== "2";
                              });
                              // console.log(tempArr)
                            } else {
                              tempArr.push("2");
                            }
                            setCompleteCheck(tempArr);
                          }}
                        />
                        <span className="box text">네</span>
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="btn-wrap mt-40">
                  <button
                    type="button"
                    className="btn btn-lg btn-black type2"
                    disabled={completeCheck.length < 2}
                    onClick={() => {
                      userProjectDetailComplete(id, (res) => {
                        if (res.code !== 0) return;
                        userProjectDetail(id, (res) => {
                          if (res.code !== 0) return;
                          setData(res.response);
                          setTab(res.response.STATUS);
                        });
                      });
                    }}
                  >
                    프로젝트 완료를 축하드려요!
                  </button>
                </div>
              </>
            )}
          </>
        );
      case "COMPLETE":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-check"></div>
              <div className="text">
                프로젝트가 최종 완료되었어요.
                <p className="sub type2">
                  담당 파트너 ㅣ {heroesInfo?.NAME}
                  <br />총 진행기간 ㅣ {dateFormatType2NoTime(
                    data.START_AT
                  )} ~ {dateFormatType2NoTime(data.COMPLETE_AT)}
                </p>
              </div>
              <div className="review-input">
                <div className="rate">
                  <button
                    type="button"
                    className={`btn btn-star ${
                      Number(reviewScore) >= 1 ? "active" : ""
                    }`}
                    onClick={() => setReviewScore(1)}
                  ></button>
                  <button
                    type="button"
                    className={`btn btn-star ${
                      Number(reviewScore) >= 2 ? "active" : ""
                    }`}
                    onClick={() => setReviewScore(2)}
                  ></button>
                  <button
                    type="button"
                    className={`btn btn-star ${
                      Number(reviewScore) >= 3 ? "active" : ""
                    }`}
                    onClick={() => setReviewScore(3)}
                  ></button>
                  <button
                    type="button"
                    className={`btn btn-star ${
                      Number(reviewScore) >= 4 ? "active" : ""
                    }`}
                    onClick={() => setReviewScore(4)}
                  ></button>
                  <button
                    type="button"
                    className={`btn btn-star ${
                      Number(reviewScore) >= 5 ? "active" : ""
                    }`}
                    onClick={() => setReviewScore(5)}
                  ></button>
                </div>
                <textarea
                  rows={3}
                  disabled={!isReviewPossibleWrite}
                  className="input-textarea"
                  placeholder="여러분의 소중한 의견은 파트너에게 큰 힘이 됩니다."
                  value={reviewInput}
                  onChange={(e) => {
                    setReviewInput(e.target.value);
                  }}
                ></textarea>
              </div>
              <button
                type="button"
                className="btn btn-lg btn-black type2"
                disabled={!isReviewPossibleWrite}
                onClick={() => {
                  if (!isReviewPossibleWrite) {
                    openModal({
                      type: "messageModal",
                      contents: {
                        title: "리뷰 등록 실패",
                        message: "이미 리뷰를 작성하셨습니다.",
                        confirmText: "확인",
                        confirmCallback: () => {
                          setReviewInput("");
                          setReviewScore(5);
                          closeModal();
                        },
                      },
                    });
                    return;
                  }
                  herosReviewWrite(
                    heroesInfo?.SEQ,
                    id,
                    {
                      score: reviewScore,
                      review: reviewInput,
                    },
                    (res) => {
                      if (res.code !== 0) return;
                      openModal({
                        type: "messageModal",
                        contents: {
                          title: "리뷰 등록 완료",
                          message: "소중한 의견 감사드려요!",
                          confirmText: "확인",
                          confirmCallback: () => {
                            setReviewInput("");
                            setReviewScore(5);
                            closeModal();
                            navigate("/mypage/aiprojectc");
                          },
                        },
                      });
                    }
                  );
                }}
              >
                {isReviewPossibleWrite
                  ? "리뷰 등록하기"
                  : "리뷰를 작성해 주셨어요."}
              </button>
            </div>
          </>
        );
      case "COLSE":
        return <></>;
      case "MODEL":
        return (
          <>
            {Boolean(taskResList.length) ? (
              <ul className="history-list type2">
                <li className="history-list--item top">
                  <div className="text-left">적합성</div>
                  <div className="text-left">검증 항목</div>
                  <div className="text-left">결과 / 목표(%)</div>
                  <div className="text-right">검증 일시</div>
                  <div className="text-right">비고</div>
                  <div className="text-right"></div>
                </li>

                <>
                  {taskResList.map((item) => (
                    <React.Fragment key={`task-res-list-item-${item.SEQ}`}>
                      <li className="history-list--item">
                        <div className="text-left">
                          {item.SUITABILITY === "-" && (
                            <span className={`badge badge-sm d-grey`}>
                              검증 전
                            </span>
                          )}
                          {item.SUITABILITY === "적합" && (
                            <span className={`badge badge-sm deeppurple`}>
                              적합
                            </span>
                          )}
                          {item.SUITABILITY === "부적합" && (
                            <span className={`badge badge-sm danger`}>
                              부적합
                            </span>
                          )}
                        </div>
                        <div className="text-left tit">{item.NAME}</div>
                        <div className="text-left">
                          <strong
                            style={
                              item.SUITABILITY === "부적합"
                                ? { color: "#DF2E47" }
                                : {}
                            }
                          >
                            {item.RESULT_PERCENT || 0} /{" "}
                            {item.TARGET_PERCENT || 0}
                          </strong>
                        </div>
                        <div className="text-right">
                          {dateFormatType2(item.UPDATED_AT)}
                        </div>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-list"
                            onClick={() => {
                              openModal({
                                type: "whiteModal",
                                contents: {
                                  title: "평가 외 확인사항",
                                  message: item.MEMO,
                                  confirmCallback: () => {
                                    closeModal();
                                  },
                                  closedBtn: false,
                                },
                              });
                            }}
                          ></button>
                        </div>
                        <div className="text-right">
                          {item.RESULT_REPORT_LINK && (
                            <button
                              type="button"
                              className="btn btn-down btn-sm btn-prupleline"
                              onClick={() => {
                                window.open(item.RESULT_REPORT_LINK);
                              }}
                            >
                              결과 레포트
                            </button>
                          )}
                        </div>
                      </li>
                      <li className="history-list--item entry">
                        {taskResInputMod && taskResInputMod === item.SEQ ? (
                          <div className="row">
                            <label className="label">테스트 데이터</label>
                            <input
                              type="text"
                              className="input-text"
                              placeholder="경로(Google 링크)를 입력해 주세요."
                              value={taskResInout}
                              onChange={(e) => setTaskResInput(e.target.value)}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-black type2"
                              disabled={!taskResInout}
                              onClick={() => {
                                projectTaskResultTestDateWrite(
                                  item.SEQ,
                                  { taskDateLink: taskResInout },
                                  (res) => {
                                    if (res.code !== 0) return;
                                    projectTaskResultListView(id, (res) => {
                                      setTaskResList(res.response);
                                      setTaskList(null);
                                      setTaskResInput("");
                                      setTaskResInputMod(null);
                                    });
                                  }
                                );
                              }}
                            >
                              저장
                            </button>
                          </div>
                        ) : (
                          <div className="row">
                            <label className="label">테스트 데이터</label>
                            <a
                              href={item.TEST_DATE_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.TEST_DATE_LINK}
                            </a>
                            <button
                              type="button"
                              className="btn btn-sm btn-black type2"
                              onClick={() => {
                                setTaskResInput(item.TEST_DATE_LINK);
                                setTaskResInputMod(item.SEQ);
                              }}
                            >
                              수정
                            </button>
                          </div>
                        )}
                        <div className="row">
                          <label className="label">인퍼런스 코드</label>
                          <a
                            href={item.INFERENCE_CODE_LINK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.INFERENCE_CODE_LINK}
                          </a>
                        </div>
                        <div className="row">
                          <label className="label">결과 데이터</label>
                          <a
                            href={item.RESULT_REPORT_LINK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.RESULT_REPORT_LINK}
                          </a>
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </>
              </ul>
            ) : (
              <>
                <div className="container-none">모델 검증 전이에요.</div>
              </>
            )}
          </>
        );
      default:
        return (
          <>
            {/* <div className="state-guide">
							<div className="icon type-meatball"></div>
							<div className="text">
								담당 매니저가 프로젝트를 검토하고 있어요.<br />
								빠른 시일 내에 연락드릴게요.<br />
								(검토 기간: 영업일 기준 1~2일 소요)
								<p className="sub">
									프로젝트 의뢰 일시 ㅣ {dateFormatType2(data.CREATED_AT)}
								</p>
							</div>
							<button type="button" className="btn btn-lg btn-black type2">프로젝트 정보 수정하기</button>
						</div> */}
          </>
        );
    }
  };

  return (
    <div className="mypage-con" style={isMobile ? { paddingTop: 96 } : {}}>
      {data && (
        <>
          {!isMobile && (
            <button
              type="button"
              className={`btn top-back mb-20`}
              onClick={() => navigate(-1)}
            >
              리스트로 돌아가기
            </button>
          )}
          {isMobile && tab === "MATCHING" && (
            <div className="state-tip" style={isMobile ? { marginTop: 0 } : {}}>
              <p>
                매칭 진행은 <strong>지원리스트 &gt; 미팅 &gt; 계약</strong> 총
                3단계로 순차적으로 진행돼요.{" "}
              </p>
              <p>
                미팅은 <strong>최대 2곳의 파트너와 가능해요.</strong>{" "}
              </p>
            </div>
          )}
          {isMobile && tab === "PROGRESS" && (
            <div className="state-tip" style={isMobile ? { marginTop: 0 } : {}}>
              <p>
                요구사항 TASK의{" "}
                <strong>최초 세팅은 매니저가 계약서를 기반으로 진행</strong>해
                드려요.
              </p>
              <p>
                상호 간의 충분한 논의를 통해{" "}
                <strong>요구사항 TASK와 일정을 변경</strong>할 수 있어요.
              </p>
            </div>
          )}
          <div className="mypage-tit text">{data.PROJECT_NAME}</div>
          <div className="mypage-state">
            <div className="left">
              {!isMobile && (
                <div className="tab-btn">
                  <button
                    type="button"
                    className={`btn ${tabV2 === "INFO" ? "active" : ""}`}
                    onClick={() => setTabV2("INFO")}
                  >
                    기본 정보
                  </button>
                  <div className="tooltip guide top">
                    <button
                      type="button"
                      className={`btn ${tabV2 === "MESSAGE" ? "active" : ""} ${
                        messageIsNew ? "new" : ""
                      }`}
                      onClick={() => {
                        if (STATUS.indexOf(data.STATUS) >= 2) {
                          setTabV2("MESSAGE");
                        }
                      }}
                    >
                      메시지
                    </button>
                    {isMessageOpenFirst && (
                      <div className="tooltip-message">
                        담당 파트너와 자유롭게 이야기를 나눠보세요!
                      </div>
                    )}
                  </div>
                </div>
              )}
              {tabV2 === "INFO" ? (
                <div className={`tab-con ${isMobile ? "tab-con-m" : ""}`}>
                  <ul
                    className={`info-list ${tabV2MobileHide ? "hide" : "show"}`}
                  >
                    <li className="info-list--item">
                      <label className="label">예산</label>
                      <div className="text">
                        {data.PAY_NEGOTIABLE ? (
                          <span className="badge badge-sm skyblue">
                            제안 가능
                          </span>
                        ) : (
                          <>
                            {priceFormat(data.BUDGET_MIN)}~
                            {priceFormat(data.BUDGET_MAX)}만 원<br />
                            <span
                              className="badge badge-sm skyblue"
                              style={{ display: "inline-block", marginTop: 8 }}
                            >
                              {data.PAY_METHOD === "SALARY"
                                ? "월급"
                                : data.PAY_METHOD === "WAGE"
                                ? "주급"
                                : "프로젝트 완료 후"}
                            </span>
                          </>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">기간</label>
                      <div className="text">
                        {data.PERIOD_NEGOTIABLE ? (
                          <span
                            className="badge badge-sm skyblue"
                            style={{ display: "inline-block" }}
                          >
                            협의 가능
                          </span>
                        ) : (
                          <>
                            {data.PROJECT_PERIOD % 4 === 0
                              ? data.PROJECT_PERIOD / 4
                              : data.PROJECT_PERIOD}
                            {data.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                          </>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">데이터 보유 현황</label>
                      <div className="text">
                        {data.HAVE_DATA ? "데이터 있음" : "데이터 없음"}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">진행 방식</label>
                      <div className="text">
                        {data.WORK_METHOD === "BOTH"
                          ? "상주, 원격"
                          : data.WORK_METHOD === "RESIDENT"
                          ? "상주"
                          : "원격"}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">의뢰 마감일</label>
                      <div className="text">{data.CLOSE_DATE}</div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">산업 분야</label>
                      <div className="text badge-flex-box">
                        {data.AI_INDUSTRY.EDUCATION && (
                          <span className="badge m5 badge-md primary2">
                            교육
                          </span>
                        )}
                        {data.AI_INDUSTRY.SERVICE && (
                          <span className="badge m5 badge-md primary2">
                            서비스
                          </span>
                        )}
                        {data.AI_INDUSTRY.MANUFACTURING && (
                          <span className="badge m5 badge-md primary2">
                            제조, 화학, 에너지
                          </span>
                        )}
                        {data.AI_INDUSTRY.VEHICLE && (
                          <span className="badge m5 badge-md primary2">
                            자동차
                          </span>
                        )}
                        {data.AI_INDUSTRY.SALES && (
                          <span className="badge m5 badge-md primary2">
                            판매, 유통
                          </span>
                        )}
                        {data.AI_INDUSTRY.MEDIA && (
                          <span className="badge m5 badge-md primary2">
                            미디어, 엔터테인먼트
                          </span>
                        )}
                        {data.AI_INDUSTRY.TOUR && (
                          <span className="badge m5 badge-md primary2">
                            관광, 스포츠, 레저
                          </span>
                        )}
                        {data.AI_INDUSTRY.CONSTRUCT && (
                          <span className="badge m5 badge-md primary2">
                            부동산, 건설, 건축
                          </span>
                        )}
                        {data.AI_INDUSTRY.FINANCE && (
                          <span className="badge m5 badge-md primary2">
                            금융
                          </span>
                        )}
                        {data.AI_INDUSTRY.MEDICAL && (
                          <span className="badge m5 badge-md primary2">
                            의료, 제약, 바이오, 헬스케어
                          </span>
                        )}
                        {data.AI_INDUSTRY.IT && (
                          <span className="badge m5 badge-md primary2">
                            IT, 통신
                          </span>
                        )}
                        {data.AI_INDUSTRY.GOVERNMENT && (
                          <span className="badge m5 badge-md primary2">
                            정부, 기관
                          </span>
                        )}
                        {data.AI_INDUSTRY.FOOD && (
                          <span className="badge m5 badge-md primary2">
                            식품
                          </span>
                        )}
                        {data.AI_INDUSTRY.AGRICULTURE && (
                          <span className="badge m5 badge-md primary2">
                            농업
                          </span>
                        )}
                        {data.AI_INDUSTRY.GAME && (
                          <span className="badge m5 badge-md primary2">
                            게임
                          </span>
                        )}
                        {data.AI_INDUSTRY.ETC && (
                          <span className="badge m5 badge-md primary2">
                            기타
                          </span>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">기술 분야</label>
                      <div className="text badge-flex-box">
                        {data.AI_SKILL.VIDEO && (
                          <span className="badge m5 badge-md primary2">
                            동영상
                          </span>
                        )}
                        {data.AI_SKILL.IMAGE && (
                          <span className="badge m5 badge-md primary2">
                            이미지
                          </span>
                        )}
                        {data.AI_SKILL.SOUND && (
                          <span className="badge m5 badge-md primary2">
                            음성
                          </span>
                        )}
                        {data.AI_SKILL.TEXT && (
                          <span className="badge m5 badge-md primary2">
                            텍스트
                          </span>
                        )}
                        {data.AI_SKILL.BIG_DATA && (
                          <span className="badge m5 badge-md primary2">
                            빅데이터
                          </span>
                        )}
                        {data.AI_SKILL.FUSION && (
                          <span className="badge m5 badge-md primary2">
                            융합
                          </span>
                        )}
                        {data.AI_SKILL.ETC && (
                          <span className="badge m5 badge-md primary2">
                            기타
                          </span>
                        )}
                      </div>
                    </li>
                    {manager && (
                      <li className="info-list--item">
                        <label className="label">담당 매니저</label>
                        <div className="manager">
                          {manager.PROFILE_IMG && (
                            <div className="img-wrap">
                              <img src={manager.PROFILE_IMG} alt="" />
                            </div>
                          )}
                          <div className="txt-wrap">
                            <div className="name">{manager.NAME}</div>
                            <div className="text">{manager.PHONE}</div>
                            <div className="text">{manager.USERNAME}</div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  {isMobile && (
                    <button
                      type="button"
                      className={`btn m-btn ${
                        tabV2MobileHide ? "hide" : "show"
                      }`}
                      onClick={() => {
                        setTabV2MobileHide(!tabV2MobileHide);
                      }}
                    >
                      {tabV2MobileHide ? "더 보기" : "접기"}
                    </button>
                  )}
                </div>
              ) : (
                <div
                  className={`tab-con ${isMobile ? "tab-con-m" : ""} message`}
                >
                  {isMobile && (
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => setTabV2("INFO")}
                    ></button>
                  )}
                  <div className="message-wrap" ref={messageListRef}>
                    {isMobile && <div className="message-tit">메시지</div>}
                    <div className="message-guide">
                      ※ 원할한 프로젝트 운영을 위해
                      <br />
                      담당 매니저가 메시지 내역을 확인할 수 있어요.
                    </div>
                    <div className="message-scroll">
                      {/* <button type="button" className="btn btn-history">이전 메시지 보기</button> */}
                      {Boolean(messageList.length) &&
                        messageList.map((item) => (
                          <div
                            className={`${
                              item.SEND_USER === userInfo.SEQ
                                ? "message-right"
                                : "message-left"
                            }`}
                            key={`message-list-item-${item.SEQ}`}
                          >
                            <div className="box">{`${item.CONTENTS}`}</div>
                            {item.SEND_USER === userInfo.SEQ ? (
                              <div className="date">
                                {item.CHECKED ? (
                                  <span className="state read">읽음</span>
                                ) : (
                                  <span className="state">안 읽음</span>
                                )}
                                {dateFormatType2(item.CREATED_AT)}
                              </div>
                            ) : (
                              <div className="date">
                                {dateFormatType2(item.CREATED_AT)}
                                {item.CHECKED ? (
                                  <span className="state read">읽음</span>
                                ) : (
                                  <span className="state">안 읽음</span>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="message-input">
                    <textarea
                      type="text"
                      className="input-text"
                      placeholder="메시지를 입력하세요."
                      value={messageInputText}
                      onChange={(e) => {
                        setMessageInputText(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.nativeEvent.isComposing) {
                          return;
                        }
                        if (e.key === "Enter" && e.shiftKey) {
                          return;
                        } else if (e.key === "Enter") {
                          messageSend();
                          setMessageInputText("");
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-black btn-sm type2"
                      onClick={() => {
                        messageSend();
                        setMessageInputText("");
                      }}
                      onKeyDown={(e) => {
                        let keys = [];
                        keys[e.keyCode] = true;
                        if (e.keyCode === 13) {
                        }
                        if (keys[13] && keys[16]) {
                          messageSend();
                          setMessageInputText("");
                        }
                      }}
                      onKeyUp={(e) => {}}
                    >
                      전송
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="right">
              <div className="state">
                <div
                  className={`state-1 ${
                    STATUS.indexOf(data.STATUS) >= 0 ? "active" : ""
                  }`}
                  onClick={() => {
                    if (STATUS.indexOf(data.STATUS) >= 0) {
                      setTab("APPLY");
                    }
                  }}
                >
                  등록 단계
                </div>
                <div
                  className={`state-2 ${
                    STATUS.indexOf(data.STATUS) >= 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    if (STATUS.indexOf(data.STATUS) >= 1) {
                      setTab("MATCHING");
                    }
                  }}
                >
                  매칭 진행
                </div>
                <div
                  className={`state-3 ${
                    STATUS.indexOf(data.STATUS) >= 2 ? "active" : ""
                  }`}
                  onClick={() => {
                    if (STATUS.indexOf(data.STATUS) >= 2) {
                      setTab("PROGRESS");
                    }
                  }}
                >
                  프로젝트 진행
                </div>
                <div
                  className={`state-4 ${
                    STATUS.indexOf(data.STATUS) >= 3 ? "active" : ""
                  }`}
                  onClick={() => {
                    if (STATUS.indexOf(data.STATUS) >= 3) {
                      setTab("COMPLETE");
                    }
                  }}
                >
                  최종 완료
                </div>
                <div
                  className={`state-5 ${
                    STATUS.indexOf(data.STATUS) >= 2 ? "active" : ""
                  }`}
                  onClick={() => {
                    if (STATUS.indexOf(data.STATUS) >= 2) {
                      setTab("MODEL");
                    }
                  }}
                >
                  모델 검증
                </div>
              </div>
              {stateComPonent()}
            </div>
          </div>
          {isMobile && STATUS.indexOf(data.STATUS) >= 2 && (
            <div className="tooltip guide top left btn floating-btn">
              <button
                type="button"
                className="btn floating-btn"
                onClick={() => setTabV2("MESSAGE")}
              ></button>
              {isMessageOpenFirst && (
                <div className="tooltip-message m-block">
                  담당 파트너와 자유롭게 대화를 나눠보세요!
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AiprojectCDetail;
