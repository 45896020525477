import React, { createContext, useState, useEffect } from 'react';
import { auth } from 'services/auth';
import { authProfile } from 'services/user';
import { GRADE_TYPE } from 'utils/data';

export let AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [isLoggedin, setLogin] = useState(false);
	const [userInfo, setUserInfo] = useState({
		NICKNAME: '',
		GRADE: '',
		PROFILE_IMG: '',
		USERNAME: '',
		ROLE: null,
		SEQ: null,
		SOCIAL_TYPE: '',
	});

	useEffect(() => {
		if (localStorage.getItem('jwt')) setLogin(true);
		const getJwt = () => {
			let jwt = localStorage.getItem('jwt');
			if (jwt) {
				setLogin(true)
			} else {
				setLogin(false)
			}
		}
		window.addEventListener("storage", getJwt);
		return () => {
			window.removeEventListener("storage", getJwt);
		}
	}, [])

	useEffect(() => {
		// if (!isLoggedin || userInfo.NICKNAME.length > 0) {
		if (!isLoggedin) {
			setUserInfo({
				NICKNAME: '',
				GRADE: '',
				PROFILE_IMG: '',
				USERNAME: '',
				ROLE: null,
				SEQ: null,
				SOCIAL_TYPE: '',
			})
			return;
		}
		authProfile((res) => {
			const { NICKNAME, PROFILE_IMG, USERNAME, ROLE, SEQ, GRADE, SOCIAL_TYPE } = res.response;
			setUserInfo({
				NICKNAME,
				PROFILE_IMG,
				USERNAME,
				ROLE,
				SEQ,
				GRADE,
				SOCIAL_TYPE,
			})
		});
	}, [isLoggedin])


	const signin = ({ email, password }, callback) => {
		auth.signin({ email, password }, (res) => {
			setLogin(res.code === 0);
			callback(res);
		})
	};

	const signout = (callback) => {
		auth.signout(() => {
			setLogin(false);
			setUserInfo({
				NICKNAME: '',
				GRADE: '',
				PROFILE_IMG: '',
				USERNAME: '',
				ROLE: null,
				SEQ: null,
				SOCIAL_TYPE: '',
			})
			callback();
		});
	};

	const socialLogin = (params, callback) => {
		auth.socialLogin(params, (res) => {
			setLogin(res.code === 0 && res.response.jwt);
			callback(res);
		});
	}

	const userInfoNicknameChange = (nickname) => {
		console.log('authProvider userInfoNicknameChange() >>>', nickname)
		setUserInfo({
			...userInfo,
			NICKNAME: nickname
		})
	}

	const userInfoImgChange = (profileImg) => {
		setUserInfo({
			...userInfo,
			PROFILE_IMG: profileImg
		})
	}

	const onUserInfoChange = () => {
		// 유저정보 다시 불러오기
		authProfile((res) => {
			const { NICKNAME, PROFILE_IMG, USERNAME, ROLE, SEQ, GRADE, SOCIAL_TYPE } = res.response;
			setUserInfo({
				NICKNAME,
				PROFILE_IMG,
				USERNAME,
				ROLE,
				SEQ,
				GRADE,
				SOCIAL_TYPE,
			})
		});
	}

	let value = { isLoggedin, userInfo, signin, signout, socialLogin, userInfoNicknameChange, userInfoImgChange, onUserInfoChange };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;