import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import { GRADE_TYPE } from "utils/data";
import { alarmName, alarmUrl, dateFormatType2 } from "utils/utils";
import {
  alarmListView,
  authAlarmDelete,
  authAlarmDeleteAll,
  authAlarmDeleteCheckedAlarm,
  authAlarmRead,
} from "services/alarm";
import useViewCheck from "hooks/useViewCheck";
import { projectHasNew } from "services/home";
import styled from "styled-components";
import TextNewIcon from "assets/images/icon/text_new.svg";
import BetaIcon from "assets/images/beta.svg";

const Header = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const registerType = searchParams.get("type");

  const auth = useAuth();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { isMobile } = useViewCheck();

  const [type2, setType2] = useState(false);
  const [myMenu, setMyMenu] = useState(false);
  const [mobileMyMenu, setMobileMyMenu] = useState(false);
  const [alarm, setAlarm] = useState(false);
  const [alarmList, setAlarmList] = useState(null);
  const [userRole, setUserRole] = useState([]);
  const [userGrade, setUserGrade] = useState("");
  const [userNickName, setUserNickName] = useState(0);
  const [userProfileImg, setProfileImg] = useState("");

  const [hasNew, setHasNew] = useState(false);

  const myMenuRef = useRef();
  const alarmRef = useRef();

  useEffect(() => {
    projectHasNew((res) => {
      setHasNew(res.response);
    });
  }, []);

  useEffect(() => {
    if (!location.pathname) return;
    const type2_url = ["help", "service", "privacy", "idinquiry", "corpintro"];
    const curPath = location.pathname.split("/");
    if (type2_url.includes(curPath[1])) {
      setType2(true);
    } else {
      setType2(false);
    }
  }, [location]);

  useEffect(() => {
    if (auth.isLoggedin) {
      const { ROLE, NICKNAME, PROFILE_IMG, GRADE } = auth.userInfo;
      setUserRole(ROLE?.split("_") || []);
      setUserNickName(NICKNAME);
      setProfileImg(PROFILE_IMG);
      setUserGrade(GRADE);
    }
  }, [auth]);

  useEffect(() => {
    if (!auth.isLoggedin) return;
    getAlarmList();
  }, [auth, location, alarm]);

  useEffect(() => {
    if (!myMenu) return;
    myMenuRef.current.focus();
  }, [myMenu]);

  useEffect(() => {
    if (!alarm) return;
    alarmRef.current.focus();
  }, [alarm]);

  const getAlarmList = () => {
    if (!auth.isLoggedin) return;
    alarmListView((res) => {
      setAlarmList(res.response);
    });
  };

  const match = useCallback(
    (path) => {
      const curPath = location.pathname.split("/");
      if (curPath[1] === path) return true;
    },
    [location]
  );

  const signOut = () => {
    auth.signout(() => {
      // console.log('로그아웃 성공');
      navigate("/");
      setMyMenu(false);
    });
  };
  const handleLinkClick = (e) => {
    if (!auth.isLoggedin) {
      e.preventDefault();
      openModal({ type: "loginModal" });
    }
  };
  return (
    <>
      {!type2 && (
        <StyledHeader className="header">
          <div className="header-wrap">
            <div className="left">
              <Link
                to="/"
                preventScrollReset={true}
                className="btn header-logo"
              />
              {!match("join") && (
                <>
                  {!isMobile && (
                    <ul className="header-menu">
                      <li className="header-menu--item">
                        <Link
                          to="/aiproject"
                          className={`btn ${
                            match("aiproject") ? "active" : ""
                          }`}
                        >
                          진행 중인 프로젝트
                          {hasNew && (
                            <NewBadge>
                              <img src={TextNewIcon} alt="new" />
                            </NewBadge>
                          )}
                        </Link>
                      </li>
                      <li className="header-menu--item">
                        <Link
                          to="/heroes"
                          className={`btn ${match("heroes") ? "active" : ""}`}
                          onClick={(e) => handleLinkClick(e)}
                        >
                          실시간 파트너
                        </Link>
                      </li>
                      <li className="header-menu--item">
                        <Link
                          to="/community"
                          className={`btn ${
                            match("community") ? "active" : ""
                          }`}
                        >
                          블로그
                        </Link>
                      </li>
                      <li className="header-menu--item">
                        <Link
                          to="/solutionstore"
                          className={`btn ${
                            match("solutionstore") ? "active" : ""
                          }`}
                        >
                          솔루션 스토어
                          <img src={BetaIcon} alt="icon-beta" />
                        </Link>
                      </li>
                    </ul>
                  )}
                </>
              )}
            </div>
            {!match("join") && (
              <>
                <div className="right">
                  {auth.isLoggedin ? (
                    <>
                      {registerType !== "register" && (
                        <div className="tooltip guide bottom">
                          {userRole?.includes("USER") && (
                            <Link
                              to={`/mypage/profile?type=register`}
                              type="button"
                              className="btn btn-md btn-black type3"
                            >
                              파트너 등록
                            </Link>
                          )}
                          {localStorage.getItem("is-first") === "true" &&
                            localStorage.getItem("is-first-home") !=
                              auth.userInfo.SEQ && (
                              <div className="tooltip-message header-tooltip">
                                공급사라면 파트너 등록은 필수!
                              </div>
                            )}
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setAlarm(!alarm);
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        type="button"
                        className={`btn alarm ${
                          alarmList?.unread?.length ? "new" : ""
                        }`}
                      ></button>
                      {alarm && (
                        <button
                          className="btn cursor-default"
                          onBlur={() => setAlarm(false)}
                          ref={alarmRef}
                        >
                          {alarmList.read.length || alarmList.unread.length ? (
                            <ul className="alarm-list">
                              <li className="alarm-list--item top">
                                <div className="tit">알림</div>
                                <div className="right">
                                  <button
                                    type="button"
                                    className="btn"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                      authAlarmDeleteCheckedAlarm((res) => {
                                        if (res.code === 0) getAlarmList();
                                      });
                                    }}
                                  >
                                    읽은 알림 삭제
                                  </button>
                                  <button
                                    type="button"
                                    className="btn"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                      authAlarmDeleteAll((res) => {
                                        if (res.code === 0) getAlarmList();
                                      });
                                    }}
                                  >
                                    전체 삭제
                                  </button>
                                </div>
                              </li>

                              <li className="alarm-list--item">
                                {alarmList.unread.map((item, i) => (
                                  <React.Fragment
                                    key={`alarm-list-new-item-${i}`}
                                  >
                                    <div
                                      className="alarm-wrap new"
                                      onClick={() => {
                                        navigate(`${alarmUrl(item)}`);
                                        if (!item.CHECKED) {
                                          authAlarmRead(item.SEQ, (res) => {
                                            if (res.code === 0) getAlarmList();
                                          });
                                        }
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-delete"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          authAlarmDelete(item.SEQ, (res) => {
                                            if (res.code === 0) getAlarmList();
                                          });
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                      ></button>
                                      <div className="top">
                                        {alarmName(item)}
                                      </div>
                                      <div className="tit">{item.MSG}</div>
                                      <div className="date">
                                        {dateFormatType2(item.CREATED_AT)}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                                {alarmList.read.map((item, i) => (
                                  <React.Fragment
                                    key={`alarm-list-read-item-${i}`}
                                  >
                                    <div
                                      className="alarm-wrap read"
                                      onClick={() => {
                                        navigate(`${alarmUrl(item)}`);
                                        if (!item.CHECKED) {
                                          authAlarmRead(item.SEQ, (res) => {
                                            if (res.code === 0) getAlarmList();
                                          });
                                        }
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-delete"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          authAlarmDelete(item.SEQ, (res) => {
                                            if (res.code === 0) getAlarmList();
                                          });
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                      ></button>
                                      <div className="top">
                                        파트너 지원 안내
                                      </div>
                                      <div className="tit">{item.MSG}</div>
                                      <div className="date">
                                        {dateFormatType2(item.CREATED_AT)}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </li>
                            </ul>
                          ) : (
                            <ul className="alarm-list">
                              <li className="alarm-list--item none">
                                받은 알림이 없어요.
                                <p>
                                  프로젝트를 진행하면서
                                  <br />
                                  다양한 소식을 받아보세요!
                                </p>
                              </li>
                            </ul>
                          )}
                        </button>
                      )}
                      <button
                        onClick={() => setMyMenu(!myMenu)}
                        type="button"
                        className={`btn profile ${
                          match("mypage") ? "active" : ""
                        }`}
                      >
                        <div className="image-wrap">
                          <div
                            className={`image ${userProfileImg ? "set" : ""}`}
                          >
                            {userProfileImg && (
                              <img src={userProfileImg} alt="" />
                            )}
                          </div>
                          {/* <span
                            className={`grade type-${GRADE_TYPE.indexOf(
                              userGrade
                            )}`}
                          ></span> */}
                        </div>
                        <div className="text-wrap">
                          {userRole?.includes("USER") && (
                            <span className={`badge badge-sm grey2`}>
                              클라이언트
                            </span>
                          )}
                          {userRole?.includes("HEROS") &&
                            userRole?.includes("PERSONAL") && (
                              <span className={`badge badge-sm blue`}>
                                개인 파트너
                              </span>
                            )}
                          {userRole?.includes("HEROS") &&
                            userRole?.includes("BUSINESS") && (
                              <span className={`badge badge-sm primary`}>
                                기업 파트너
                              </span>
                            )}
                          {userRole?.includes("ADMIN") && (
                            <span className={`badge badge-sm primary`}>
                              매니저
                            </span>
                          )}
                          <p>
                            <span className="name">{userNickName}</span>
                            <span className="user-nickname">님</span>
                          </p>
                        </div>
                      </button>
                      <button
                        type="button"
                        className={`my-menu ${myMenu ? "show" : ""}`}
                        onBlur={() => setMyMenu(false)}
                        ref={myMenuRef}
                      >
                        <Link
                          to="/mypage/profile"
                          className={`btn menu ${
                            userRole?.includes("USER") ? "guide" : ""
                          }`}
                        >
                          프로필 수정
                          {userRole?.includes("USER") && (
                            <span className="text">
                              파트너로 등록해 보세요!
                            </span>
                          )}
                        </Link>
                        <Link to="/mypage/aiprojectc" className="btn menu c">
                          프로젝트 관리
                        </Link>
                        {Boolean(userRole.length) &&
                          !userRole?.includes("USER") && (
                            <Link
                              to="/mypage/aiprojecth"
                              className="btn menu h"
                            >
                              프로젝트 관리
                            </Link>
                          )}
                        {Boolean(userRole.length) &&
                          !userRole?.includes("USER") && (
                            <Link to="/mypage/revenue" className="btn menu">
                              정산 관리
                            </Link>
                          )}
                        <Link to="/mypage/myinfo" className="btn menu">
                          계정 관리
                        </Link>
                        <Link to="/help" className="btn menu">
                          고객센터
                        </Link>
                        <div
                          onClick={() => signOut()}
                          type="button"
                          className="btn bottom"
                        >
                          로그아웃
                        </div>
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        openModal({ type: "loginModal" });
                      }}
                      type="button"
                      className="btn btn-md btn-black btn-login"
                    >
                      로그인/회원가입
                    </button>
                  )}
                </div>
              </>
            )}
            {!match("join") && (
              <>
                <div className={`mobile ${mobileMyMenu ? "active" : ""}`}>
                  {auth.isLoggedin && userRole?.includes("USER") && (
                    <Link
                      to={`/mypage/profile?type=register`}
                      type="button"
                      className="btn btn-md btn-black type3 add-partner"
                    >
                      파트너 등록
                    </Link>
                  )}
                  {auth.isLoggedin ? (
                    <button
                      type="button"
                      className={`btn menu ${
                        alarmList?.unread?.length ? "on" : ""
                      }`}
                      onClick={() => setMobileMyMenu(true)}
                    ></button>
                  ) : (
                    <button
                      onClick={() => {
                        openModal({ type: "loginModal" });
                      }}
                      type="button"
                      className="btn btn-md btn-black btn-login"
                    >
                      로그인/회원가입
                    </button>
                  )}
                  <div
                    className="dim"
                    onClick={() => setMobileMyMenu(false)}
                  ></div>
                  <div className="right">
                    <button
                      type="button"
                      className="btn close"
                      onClick={() => setMobileMyMenu(false)}
                    ></button>
                    {auth.isLoggedin ? (
                      <>
                        <div className="profile mobile">
                          <div className="image-wrap">
                            <div
                              className={`image ${userProfileImg ? "set" : ""}`}
                            >
                              {userProfileImg && (
                                <img src={userProfileImg} alt="" />
                              )}
                            </div>
                            <span
                              className={`grade type-${GRADE_TYPE.indexOf(
                                userGrade
                              )}`}
                            ></span>
                          </div>
                          <div className="text-wrap">
                            {userRole?.includes("USER") && (
                              <span className={`badge badge-sm grey2`}>
                                클라이언트
                              </span>
                            )}
                            {userRole?.includes("HEROS") &&
                              userRole?.includes("PERSONAL") && (
                                <span className={`badge badge-sm blue`}>
                                  개인 파트너
                                </span>
                              )}
                            {userRole?.includes("HEROS") &&
                              userRole?.includes("BUSINESS") && (
                                <span className={`badge badge-sm primary`}>
                                  기업 파트너
                                </span>
                              )}
                            {userRole?.includes("ADMIN") && (
                              <span className={`badge badge-sm primary`}>
                                매니저
                              </span>
                            )}
                            <p>
                              <span className="name">{userNickName}</span>
                              <span className="user-nickname">님</span>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="profile">
                        <button
                          onClick={() => {
                            openModal({ type: "loginModal" });
                          }}
                          type="button"
                          className="btn btn-md btn-black"
                        >
                          로그인/회원가입
                        </button>
                      </div>
                    )}
                    {auth.isLoggedin && (
                      <ul className="menu-list my">
                        <li className="menu-list--item">
                          <Link
                            to="/mypage/profile"
                            className="btn"
                            onClick={() => {
                              setMobileMyMenu(false);
                            }}
                          >
                            프로필 수정
                          </Link>
                          {userRole?.includes("USER") && (
                            <span className="text">
                              파트너로 등록해 보세요!
                            </span>
                          )}
                        </li>

                        <li className="menu-list--item">
                          <Link
                            to="/mypage/aiprojectc"
                            className="btn c"
                            onClick={() => {
                              setMobileMyMenu(false);
                            }}
                          >
                            프로젝트 관리
                          </Link>
                        </li>
                        {Boolean(userRole.length) &&
                          !userRole.includes("USER") && (
                            <li className="menu-list--item">
                              <Link
                                to="/mypage/aiprojecth"
                                className="btn h"
                                onClick={() => {
                                  setMobileMyMenu(false);
                                }}
                              >
                                프로젝트 관리
                              </Link>
                            </li>
                          )}
                        {Boolean(userRole.length) &&
                          !userRole.includes("USER") && (
                            <li className="menu-list--item">
                              <Link
                                to="/mypage/revenue"
                                className="btn"
                                onClick={() => {
                                  setMobileMyMenu(false);
                                }}
                              >
                                정산 관리
                              </Link>
                            </li>
                          )}
                        <li className="menu-list--item">
                          <Link
                            to="/mypage/myinfo"
                            className="btn"
                            onClick={() => {
                              setMobileMyMenu(false);
                            }}
                          >
                            계정 관리
                          </Link>
                        </li>
                        <li className="menu-list--item">
                          <Link
                            to="/help"
                            className="btn"
                            onClick={() => {
                              setMobileMyMenu(false);
                            }}
                          >
                            고객센터
                          </Link>
                        </li>
                        <li className="menu-list--item">
                          <button
                            className={`btn ${
                              alarmList?.unread?.length ? "new" : ""
                            }`}
                            onClick={() => {
                              openModal({
                                type: "alarmMobileModal",
                              });
                              setMobileMyMenu(false);
                            }}
                          >
                            알림센터
                          </button>
                        </li>
                      </ul>
                    )}
                    <ul className="menu-list etc">
                      <li className="menu-list--item">
                        {auth.isLoggedin && (
                          <button
                            onClick={() => {
                              signOut();
                              setMobileMyMenu(false);
                            }}
                            type="button"
                            className="btn logout"
                          >
                            로그아웃
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
          {location.pathname.split("/")[1] !== "mypage" && (
            <div className="m-header--menu">
              <Link
                to="/aiproject"
                className={`btn ${match("aiproject") ? "active" : ""}`}
              >
                진행 중인 프로젝트
                {hasNew && (
                  <NewBadge>
                    <img src={TextNewIcon} alt="new" />
                  </NewBadge>
                )}
              </Link>
              <Link
                to="/heroes"
                className={`btn ${match("heroes") ? "active" : ""}`}
                onClick={(e) => handleLinkClick(e)}
              >
                실시간 파트너
              </Link>
              <Link
                to="/community"
                className={`btn ${match("community") ? "active" : ""}`}
              >
                블로그
              </Link>
              <Link
                to="/solutionstore"
                className={`btn ${match("solutionstore") ? "active" : ""}`}
              >
                솔루션 스토어
                <img src={BetaIcon} alt="icon-beta" />
              </Link>
            </div>
          )}
        </StyledHeader>
      )}
      {type2 && (
        <div className="header type2">
          <div className="header-wrap">
            <div className="left">
              <Link
                to="/"
                preventScrollReset={true}
                className="btn header-logo"
              ></Link>
              <ul className="header-menu">
                <li className="header-menu--item">
                  {match("idinquiry") && (
                    <Link to="/idinquiry" className="btn">
                      계정 안내
                    </Link>
                  )}
                  {match("corpintro") && (
                    <Link to="/corpintro" className="btn">
                      기업소개
                    </Link>
                  )}
                  {match("help") && (
                    <Link to="/help" className="btn">
                      고객센터
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            <div className="right">
              {match("help") && location.pathname !== "/help/counsel" && (
                <button
                  type="button"
                  to="/help/counsel"
                  className="btn btn-md btn-primary btn-counsel"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    window.open(
                      "https://few-honeycrisp-bf0.notion.site/AI-ver-1-1-0-1a7a5e751b16443888f4a6b9f59f0ca4"
                    );
                  }}
                >
                  사용 가이드
                </button>
              )}
              {match("help") &&
                location.pathname !== "/help/counsel" &&
                !isMobile && (
                  <button
                    type="button"
                    to="/help/counsel"
                    className="btn btn-md btn-primary btn-counsel counsel-btn"
                    onClick={() => {
                      if (auth.isLoggedin) {
                        navigate("/help/counsel");
                      } else {
                        openModal({
                          type: "loginModal",
                          contents: {
                            message: "1:1 문의는 회원가입 후 문의가 가능해요.",
                          },
                        });
                      }
                    }}
                  >
                    1:1 문의하기
                  </button>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

const StyledHeader = styled.div`
  & .cursor-default {
    cursor: default;
  }
  & .user-nickname {
    margin-left: 2px;
  }
  & .my-menu {
    border: none;
    padding: 0;
  }
  & .add-partner {
    margin-right: 35px;
  }
  & .counsel-btn {
    margin-left: 10px;
  }
`;

const NewBadge = styled.div`
  margin-left: 4px;
  padding: 0 4.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --secondary-s-gradation01,
    linear-gradient(135deg, #00ff85 0%, #0a7aff 100%)
  );
`;
