import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import { interestedAiTaskData } from "utils/data";
import useModal from "hooks/useModal";
import Idelete from "assets/images/icon/icon_delete.svg";
import { convertKeysToLowerCase } from "utils/utils";
import Badge from "./Badge";
import { calculateWidth, addLeadingZero } from "utils/utils";
const extractYearAndMonth = (dateString) => {
  if (!dateString) {
    return { yyyy: "", mm: "", dd: "" };
  }
  const [yyyy, mm, dd] = dateString.split("-");
  if (yyyy && mm) {
    return { yyyy, mm };
  } else {
    return { yyyy, mm, dd };
  }
};

const DateInput = ({ handleDate, data }) => {
  const { openModal, closeModal } = useModal();

  const { yyyy, mm, dd } = extractYearAndMonth(data);
  const [year, setYear] = useState(yyyy || "");
  const [month, setMonth] = useState(mm || "");
  const [inputMessage, setInputMessage] = useState("");

  const updateDate = (inputYear, inputMonth) => {
    handleDate(inputYear, addLeadingZero(inputMonth));
  };

  return (
    <div>
      <InputWrapper>
        <Input
          type="text"
          maxLength={4}
          className="input y"
          placeholder="YYYY"
          value={year}
          style={{ width: `${year.length * 12}px` }}
          onChange={(e) => {
            const newYear = e.target.value.replace(/\D/, "");
            setYear(newYear);
            updateDate(newYear, month);
          }}
        />
        .
        <Input
          type="text"
          maxLength={2}
          className="input m"
          placeholder="MM"
          value={month}
          style={{ width: `${month.length * 12}px` }}
          onChange={(e) => {
            const newMonth = e.target.value.replace(/\D/, "");
            setMonth(newMonth);
            updateDate(year, newMonth);
          }}
        />
      </InputWrapper>
    </div>
  );
};
const CareerCard = ({
  seq,
  handleCareerDelete,
  handleCareerData,
  data,
  isFirst,
  isMobile,
}) => {
  const [performance, setPerformance] = useState(data.performance || "");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [companyName, setCompanyName] = useState(data.companyName || "");
  const [projectName, setProjectName] = useState(data.projectName || "");
  const [tempStack, setTempStack] = useState(null);
  const [projectDate, setProjectDate] = useState({
    startAt: data.startAt || "",
    endAt: data.endAt || "",
  });
  let tempSkillStack = null;
  const handleStartDate = (yyyy, mm) => {
    setProjectDate((prev) => ({ ...prev, startAt: `${yyyy}-${mm}` }));
  };
  const handleEndDate = (yyyy, mm) => {
    setProjectDate((prev) => ({ ...prev, endAt: `${yyyy}-${mm}` }));
  };
  const changeArray = () => {
    interestedAiTaskData.forEach((item) => {
      tempSkillStack = {
        ...tempSkillStack,
        [item.id]: selectedOptions.includes(item.id),
      };
    });
    return tempSkillStack;
  };
  //초기 렌더링 시작
  useEffect(() => {
    if (data.interestedAiTask) {
      setSelectedOptions(
        Object.entries(convertKeysToLowerCase(data.interestedAiTask))
          .filter(
            ([key, value]) => typeof value === "boolean" && value === true
          )
          .map(([key]) => key)
      );
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(selectedOptions)) {
      setTempStack(changeArray());
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (performance === undefined && performance === null) {
      setPerformance("");
    }
    handleCareerData({
      seq: seq,
      companyName,
      performance,
      projectName,
      startAt: projectDate.startAt,
      endAt: projectDate.endAt,
      interestedAiTask: tempStack,
    });
  }, [projectDate, projectName, companyName, performance, tempStack]);

  return (
    <StyledWrapper>
      <InfoBox>
        <div className="card-header">
          <Input
            type="text"
            className={`project-title ${
              calculateWidth(companyName) > 150 ? "ellipsis" : ""
            }`}
            placeholder="클라이언트 이름"
            value={companyName}
            style={{
              width: `${calculateWidth(companyName)}px`,
              minWidth: companyName.length === 0 && "90px",
              maxWidth: "150px",
            }}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          /
          <Input
            type="text"
            className={`project-title ${
              calculateWidth(projectName) > 500 ? "ellipsis" : ""
            }`}
            placeholder="프로젝트 이름"
            value={projectName}
            style={{
              width: `${calculateWidth(projectName)}px`,
              minWidth: projectName.length === 0 && "76px",
              maxWidth: "500px",
            }}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <div className="date-group">
            <div className="flex">
              <DateInput
                data={projectDate.startAt}
                handleDate={handleStartDate}
              />
              <span style={{ fontSize: "16px", margin: "0 4px" }}>~</span>
              <DateInput data={projectDate.endAt} handleDate={handleEndDate} />
            </div>
          </div>
        </div>
        <button onClick={handleCareerDelete}>
          <img src={Idelete} alt="delete" />
        </button>
      </InfoBox>
      <InputBox>
        <div className="textarea-wrapper">
          <textarea
            placeholder="이전에 진행하신 프로젝트 내용에 대해 간단히 남겨주세요."
            value={performance ? performance : ""}
            onChange={(e) => {
              setPerformance(e.target.value);
            }}
          />
        </div>
        <Divider />

        <div>
          <div
            className="section-tit"
            style={{ marginBottom: "10px", paddingTop: 0 }}
          >
            적용된 AI 기술
          </div>
          {interestedAiTaskData.map((item, index) => {
            return (
              <Badge
                key={`${item.id}_${index}`}
                className="input-checkbox"
                badgeData={item}
                checkedArray={selectedOptions}
                setCheckArray={setSelectedOptions}
                activeColor={"#C5FFEA"}
              />
            );
          })}
        </div>
      </InputBox>
    </StyledWrapper>
  );
};

export default React.memo(CareerCard);

const StyledWrapper = styled.section`
  display: flex;
  width: 100%;
  margin: 5px 0;
  padding: 6px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 5px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  position: relative;
  z-index: 0;
  & input {
    font-weight: 700;
    &::placeholder {
      color: var(--mono-m-gray-01, #999);
    }
  }
  & .flex {
    display: flex;
  }
  &:hover {
    & button {
      opacity: 1;
    }
  }
`;
const InfoBox = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  & .card-header {
    z-index: 1;
    display: inline-flex;
    width: inherit;
    align-items: center;
    padding-top: 15px;
    gap: 6px;
    align-self: stretch;
    border: none;
    flex-wrap: wrap;
    padding-right: 20px;
    & input {
      width: 90px;
      &:first-child {
        margin-left: 0;
      }
      /* Body 600 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.6px; /* 132.857% */
      &::placeholder {
        color: var(--mono-m-gray-01, #999);
      }
    }
  }
  & button {
    cursor: pointer;
    position: absolute;
    z-index: 999;
    border: none;
    padding: 0;
    right: 0;
    line-height: 65px;
    background-color: transparent;
  }

  /* Body 400 */
  & .date-group {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: space-between;
    & input {
      margin: 0 !important;
      &::placeholder {
        color: var(--mono-m-gray-01, #999);
      }
    }
  }
  &:last-child {
    margin-top: 12px;
  }
`;

const InputBox = styled.section`
  width: 100%;
  & .textarea-wrapper {
    border-radius: 5px;
    display: flex;
    height: 95px;
    padding: 10px 0;
    align-items: center;
    & textarea {
      height: 64px;
      overflow-y: auto;
      resize: none;
      width: 100%;

      /* Body 400 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.6px; /* 132.857% */
      &::placeholder {
        color: var(--mono-m-gray-01, #999);
      }
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & input {
    width: 35px !important;
    margin: 0;
    color: var(--black100, #000);

    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100%;
    &:last-child {
      width: 24px !important;
    }
    &::placeholder {
      color: var(--mono-m-gray-01, #999);
    }
  }
`;
const Divider = styled.div`
  border-top: 1px solid var(--mono-m-gray03-stroke, #eee);
  width: 100%;
  margin: 10px 0;
`;
