import useModal from 'hooks/useModal';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormatType2NoTime } from 'utils/utils';

const FoundEmail = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { openModal } = useModal();
	const [email, setEmail] = useState('')

	useEffect(() => {
		// 4번 인 경우 {username, result: 'found_email'} = state;
		if (!state) return;
		if (state.username) {
			setEmail(state.username);
		}
	}, [state])

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<div className="section-tit lg">가입된 이메일 계정이에요.</div>
					<label className="input-label sm">이메일</label>
					<div className={`sns-exist ${state.socialType}`}>
						<input type="text" className="input-text" placeholder={email} value={email ? email : ''} disabled />
					</div>
					<p className="input-guide">마지막 접속일: {dateFormatType2NoTime(state.lastLoginAt)}</p>
					<button type="button" className="btn btn-full btn-black type2 mt-30" onClick={() => {
						openModal({ type: 'loginModal', contents: { forceEmail: email } })
						navigate('/')
					}}>기존 계정으로 로그인하기</button>
				</div>
			</div>
		</div>
	)
}

export default FoundEmail