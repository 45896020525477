import axios from "axios";

let jwt;
window.addEventListener("storage", () => {
  jwt = localStorage.getItem("jwt");
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    Authorization: jwt,
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const regex = /^https?:\/\/[^\/]+/;
    if (
      config.url.match(regex) &&
      config.url.match(regex)[0] !== process.env.REACT_APP_API_HOST
    )
      return config;
    config.headers.withCredentials = true;
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      config.headers.Authorization = jwt;
    }
    return config;
  },
  (error) => {
    // console.log('요청 에러:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
  },

  (error) => {
    // console.log('응답 에러:', error.response);
    if (error.response && error.response.status === 404) {

      const mainMenuUrl = window.location.pathname.split("/")[1];
      window.location.href = `/${mainMenuUrl}`;
    }
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("jwt");
      window.dispatchEvent(new Event("storage"));
    }
    return Promise.reject(error);
  }
);

export default api;
