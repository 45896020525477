import { useDispatch } from "react-redux";
import { openModal, closeModal } from "store/modalSlice";

const useModal = () => {
  const dispatch = useDispatch();

  const handleOpenModal = ({ type, contents = null }) => {
    dispatch(openModal({ type, contents }));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return { openModal: handleOpenModal, closeModal: handleCloseModal };
};

export default useModal;
