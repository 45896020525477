import React from "react";
import styled from "styled-components";

const FloatBox = ({ children, count, isSelf }) => {
  return (
    <StyledFloatBox>
      {!isSelf && (
        <p>
          원하시는 파트너와 일치하나요? <br />
          지금 바로 AI프로젝트를 제안해 보세요!
        </p>
      )}
      {children}
      <div>
        총 <span>{count}</span> 명의 클라이언트가 탐색 중이에요.
      </div>
    </StyledFloatBox>
  );
};

export default FloatBox;

const StyledFloatBox = styled.div`
  width: 100%;
  text-align: start;

  & p {
    color: var(--mono-m-black01, #333);

    /* Body 600 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.6px; /* 132.857% */
    margin-bottom: 20px;
  }
  & div {
    margin-top: 20px;
    color: var(--mono-m-black01, #333);

    /* Caption 500 */
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.9px; /* 132.5% */
    & span {
      color: var(--secondary-s-red00, #df2e47);

      /* Caption 500 */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.9px;
    }
  }

  @media (max-width: 660px) {
    text-align: center;
  }
`;
