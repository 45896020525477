import arrow_down from "assets/images/icon/arrow_bottom_fill.svg";
import arrow_up from "assets/images/icon/arrow_top_fill.svg";
import Ibusiness from "assets/images/icon/icon_business.svg";
import Icareer from "assets/images/icon/icon_career.svg";
import Ilocation from "assets/images/icon/icon_location.svg";
import Itype from "assets/images/icon/icon_type.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
const SelectComponent = ({
  className,
  label = "라벨명",
  isLabeled = false,
  icon = "type",
  isIcon = false,
  main,
  sub,
  value,
  defaultValue,
  onChange,
  selectable = false,
  readOnly = false,
  disabled = false,
  handleEvent,
  handleSubEvent,
  openState,
  handleToggle,
  mobile,
  ...props
}) => {
  if (value === undefined || value === null) value = "";
  const [toggleMain, setToggleMain] = useState(false);
  const [toggleSub, setToggleSub] = useState(false);
  const [selectMain, setSelectMain] = useState(null);
  const [selectSub, setSelectSub] = useState(null);
  const [subArr, setSubArr] = useState([]);
  useEffect(() => {
    if (defaultValue.city) {
      const defaultCity = value.find((item) => item.city === defaultValue.city);
      if (defaultCity) {
        setSelectMain(defaultCity.city);
        setSubArr(defaultCity.details);
        handleEvent(defaultCity.city);

        if (defaultValue.detail) {
          const defaultDetail = defaultCity.details.find(
            (item) => item === defaultValue.detail
          );
          if (defaultDetail) {
            setSelectSub(defaultDetail);
            handleSubEvent(defaultDetail);
          }
        }
      }
    }
  }, [defaultValue, value]);
  useEffect(() => {
    if (
      openState.simple ||
      openState.career ||
      openState.workMethod ||
      openState.location ||
      openState.locationDetail ||
      openState.capacity
    ) {
      setToggleMain(false);
      setToggleSub(false);
    }
  }, [openState]);
  useEffect(() => {
    handleEvent(selectMain);
    handleSubEvent(selectSub);
  }, [selectMain, selectSub]);
  const handleMain = (value) => {
    if (value === null) {
      setSelectMain(null);
      setSelectSub(null);
      return;
    }

    setSelectMain(value.city);
    setSubArr(value.details);

    setSelectSub(null);
  };

  const handleSub = (value) => {
    if (value === null) {
      setSelectSub(null);
      return;
    }
    setSelectSub(value);
  };
  const handleIcon = (icon) => {
    switch (icon) {
      case "business":
        return Ibusiness;
      case "career":
        return Icareer;
      case "type":
        return Itype;
      case "location":
        return Ilocation;
      default:
        return Itype;
    }
  };
  useEffect(() => {
    if (toggleMain && toggleSub) {
      setToggleSub(false);
    }
  }, [toggleMain]);

  useEffect(() => {
    if (toggleMain && toggleSub) {
      setToggleMain(false);
    }
  }, [toggleSub]);
  return (
    <StyledWrapper mobile={mobile} {...props}>
      {isIcon && <Icon mobile={mobile} src={handleIcon(icon)} alt={icon} />}

      {isLabeled && <label htmlFor="input">{label}</label>}
      <StyledSelectWrapper mobile={mobile} {...props}>
        <Select
          mobile={mobile}
          onClick={() => {
            setToggleMain((prev) => !prev);
          }}
        >
          <span className="select-name">{selectMain ? selectMain : main}</span>
          <button>
            <img src={toggleMain ? arrow_up : arrow_down} alt="chev_down" />
          </button>
          {toggleMain && (
            <Option>
              {value.map((item, idx) => {
                return (
                  <div key={idx} onClick={() => handleMain(item)}>
                    {item.city}
                  </div>
                );
              })}
            </Option>
          )}
        </Select>
        <Divider mobile={mobile} />
        <Select mobile={mobile} onClick={() => setToggleSub((prev) => !prev)}>
          <span>{selectSub ? selectSub : sub}</span>
          {selectMain && (
            <button className="sub-button">
              <img src={toggleSub ? arrow_up : arrow_down} alt="chev_down" />
            </button>
          )}
          {toggleSub && (
            <Option>
              {!selectMain && <div>먼저 소재지를 선택해 주세요.</div>}
              {subArr.map((item, idx) => {
                return (
                  <div key={idx} onClick={() => handleSub(item)}>
                    {item}
                  </div>
                );
              })}
            </Option>
          )}
        </Select>
      </StyledSelectWrapper>
    </StyledWrapper>
  );
};

export default SelectComponent;

const StyledWrapper = styled.div`
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  /* margin-bottom: 14px; */
  border-radius: 5px;
  border: 1px solid var(--stroke_gray04, #eee);
  width: ${(props) => (props.mobile ? "100%" : "auto")};
  position: ${(props) => (props.mobile ? "relative" : "")};
  label {
    width: 70px;
    color: #999;
    text-align: start;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    margin-right: 20px;
  }
`;
const Select = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  height: 50px;
  min-width: 160px;
  width: ${(props) => (props.mobile ? "100%" : "245px")};
  padding: 0 18px;
  align-items: center;
  flex-shrink: 0;
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  & span.select-name {
    text-indent: ${(isIcon) => (isIcon ? "18px" : "")};
  }
  &:last-child {
    width: ${(props) => (props.mobile ? "100%" : "245px")};
    border-top: ${(props) => (props.mobile ? "1px solid #eee" : "none")};

    justify-content: space-between;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 26px;
  }
  &.disabled {
    background: #f7f7f7;
    cursor: not-allowed;
  }

  span {
    width: ${(props) => (props.mobile ? "100%" : "calc(100% - 30px)")};
  }
  button {
    background: transparent;
    border: none;
    padding: 0;
    text-align: right;
    &.sub-button {
      padding-right: 18px;
    }
  }
  &:disabled {
    border-radius: 5px;
    color: 1px solid var(--stroke_gray04, #eee);
    cursor: not-allowed;
  }
`;
const Option = styled.div`
  background: #fff;
  z-index: 9999;
  position: absolute;
  top: 60px;
  min-width: 245px;
  width: 100%;
  max-height: 212px;
  padding: 0;
  gap: 114px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  left: 0;
  overflow-y: auto;
  div {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    list-style: none;
    color: var(--mono-m-black-01, #333);
    text-align: start;

    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    padding: 0 18px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
const StyledSelectWrapper = styled.div`
  display: ${(props) => (props.mobile ? "flex" : "inline-flex")};
  align-items: center;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  width: 100%;
`;
const Icon = styled.img`
  position: absolute;
  left: ${(props) => (props.mobile ? "16px" : "16px")};
  top: ${(props) => (props.mobile ? "16px" : "unset")};
`;
const Divider = styled.div`
  display: ${(props) => (props.mobile ? "none" : "flex")};
  border-radius: 5px;
  height: 18px;
  width: 1px;
  background-color: #eee;
  margin: 0 8px;
`;
