import React from "react";
import styled from "styled-components";
import add from "assets/images/icon/add_line.svg";
const SmallButton = ({
  onClick,
  isMobile = false,
  text,
  isIcon,
  icon,
  style,
  ...restProps
}) => {
  return (
    <StyledButton style={style} onClick={onClick}>
      {isIcon && <img src={add} alt={"icon"} />}
      {text}
    </StyledButton>
  );
};

export default SmallButton;

const StyledButton = styled.button`
  display: flex;
  width: 100px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--primary-p-green-00, #00ff85);
  border: none;
  color: var(--mono-m-black-00, #000);
  text-align: center;

  /* Caption 600 */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.9px; /* 132.5% */
  cursor: pointer;
`;
