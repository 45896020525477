import React from "react";
import styled from "styled-components";

const Input = ({
  type = "text",
  className,
  placeholder = "",
  value,
  onChange,
  minLength = 1,
  maxLength = 99999,
  readOnly = false,
  disabled = false,
  ...restProps
}) => {
  return (
    <StyledInput
      type={type}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      minLength={minLength}
      maxLength={maxLength}
      readOnly={readOnly}
      disabled={disabled}
      {...restProps}
    />
  );
};

export default Input;

const StyledInput = styled.input`
  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
