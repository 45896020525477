import React, { useState } from "react";
import arrow_down from "assets/images/icon/arrow_bottom_fill.svg";
import arrow_up from "assets/images/icon/arrow_top_fill.svg";
import styled from "styled-components";
const ToggleButton = ({ toggleOption }) => {
  return (
    <Button>
      <img src={toggleOption ? arrow_up : arrow_down} alt="toggle" />
    </Button>
  );
};

export default ToggleButton;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;
