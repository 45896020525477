import LabelInput from "components/Input/LabelInput";
import Select from "components/Input/Select";
import SelectSub from "components/Input/SelectSub";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  businessGuideText,
  capArr,
  guide_bus,
  guide_indi,
  indivisualGuideText,
  interestedAiTaskData,
  localInfo,
  workTypeArr,
} from "utils/data";
import AiSkills from "./AiSkills";
import SkillBadge from "./SkillBadge";

const ProfileDetail = React.memo(
  ({
    userType,
    isMobile,
    partnerData,
    handlePartnerData,
    interestedAiTask,
    setInterestedAiTask,
    setBusinessInfo,
    setBusinessName,
    setBusinessScale,
    setBusinessLocation,
    setBusinessLocationDetails,
    businessInfo,
    keyInput,
    ...restProps
  }) => {
    const [name, setName] = useState(partnerData.businessInfo?.name);
    const [simple, setSimple] = useState(partnerData.introduceSimple);
    const [detail, setDetail] = useState(
      partnerData
        ? partnerData.introduceDetails !== null // Check if introduceDetails is not null
          ? partnerData.introduceDetails
          : userType === "business"
          ? guide_bus
          : guide_indi
        : userType === "business"
        ? guide_bus
        : guide_indi
    );
    const [capacity, setCapacity] = useState(partnerData.businessInfo?.scale);
    const [workType, setWorkType] = useState(partnerData.workMethod);
    const [career, setCareer] = useState(
      partnerData.career === null ? 1 : partnerData.career
    );
    const [location, setLocation] = useState(
      partnerData.businessInfo?.location
    );
    const [locationDetail, setLocationDetail] = useState(
      partnerData.businessInfo?.locationDetails
    );
    const nameRef = useRef(null);
    const introduceSimpleRef = useRef(null);
    const introduceDetailRef = useRef(null);
    const careerRef = useRef(null);

    // 기술스택 신규개발
    const handleBusName = useCallback(
      (value) => {
        setName(value);
      },
      [name]
    );
    const handleSimple = useCallback(
      (value) => {
        setSimple(value);
      },
      [simple]
    );
    const handleDetail = useCallback(
      (value) => {
        setDetail(value);
      },
      [detail]
    );
    const handleCapacity = useCallback(
      (value) => {
        setCapacity(value.id);
      },
      [capacity]
    );
    const handleLocal = useCallback(
      (value) => {
        setLocation(value);
      },
      [location]
    );
    const handleLocalDetail = useCallback(
      (value) => {
        setLocationDetail(value);
      },
      [locationDetail]
    );
    const handleWorkType = useCallback(
      (value) => {
        setWorkType(value.id);
      },
      [workType]
    );
    const handleCareer = useCallback(
      (value) => {
        setCareer(Number(value));
      },
      [career]
    );
    const [openState, setOpenState] = useState({
      simple: false,
      career: false,
      workMethod: false,
      location: false,
      capacity: false,
    });
    const handleToggle = (id) => {
      const updatedStates = Object.keys(openState).reduce((acc, key) => {
        acc[key] = key === id ? !openState[key] : false;
        return acc;
      }, {});

      setOpenState(updatedStates);
    };
    // 기존데이터가 있을 경우
    useEffect(() => {
      const fetchDataGradually = () => {
        setSimple(partnerData.introduceSimple);
        setWorkType(partnerData.workMethod);
        setDetail(partnerData.introduceDetails);
        setCareer(partnerData.career);
        if (!partnerData.businessInfo) return;
        setName(partnerData.businessInfo.name);
        setCapacity(partnerData.businessInfo.scale);
        setLocation(partnerData.businessInfo.location);
        setLocationDetail(partnerData.businessInfo.locationDetails);
      };
      const timeoutId = setTimeout(() => {
        fetchDataGradually();
      }, 100);
      return () => clearTimeout(timeoutId);
    }, [partnerData]);
    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const partnerObject = useMemo(() => {
      return {
        //...partnerData,
        businessInfo: {
          ...businessInfo,
          name: name,
          location: location,
          locationDetails: locationDetail,
          scale: capacity,
        },
        introduceSimple: simple,
        introduceDetails: detail,
        workMethod: workType,
        career: career,
      };
    }, [
      name,
      capacity,
      location,
      locationDetail,
      career,
      workType,
      detail,
      simple,
    ]);
    useEffect(() => {
      handlePartnerData(partnerObject);
      setBusinessInfo({
        ...businessInfo,
        name: name,
        location: location,
        locationDetails: locationDetail,
        scale: capacity,
      });
    }, [partnerObject, name, location, locationDetail, capacity]);
    return (
      <StyledWrapper mobile={isMobile} {...restProps}>
        <section className="mt-40">
          <div className="section-tit" style={{ marginBottom: "10px" }}>
            파트너 프로필
          </div>
          <h3 className="title required">파트너 소개</h3>
          {userType === "business" && (
            <LabelInput
              mobile={isMobile}
              ref={nameRef}
              isLabeled={false}
              label="회사명"
              placeholder="회사명을 입력해 주세요."
              maxLength="30"
              errorMsg="회사명은 최대 30자까지 가능해요."
              value={name}
              handleEvent={handleBusName}
              {...restProps}
            />
          )}
          <div className="mt-10">
            <LabelInput
              mobile={isMobile}
              ref={introduceSimpleRef}
              isLabeled={false}
              type="input-select"
              label="한 줄 문구"
              placeholder="파트너 소개의 메인 타이틀로 노출되는 영역이니, 임팩트 있는 한 줄을 남겨주세요."
              maxLength="40"
              errorMsg="한 줄 소개는 최대 40자까지 가능해요."
              selectable
              value={simple}
              inputArr={
                userType === "business"
                  ? businessGuideText
                  : indivisualGuideText
              }
              handleEvent={handleSimple}
              id="simple"
              toggleOpt={openState.simple}
              handleToggle={() => handleToggle("simple")}
              {...restProps}
            />
          </div>
          <LabelInput
            mobile={isMobile}
            ref={introduceDetailRef}
            isLabeled={false}
            type="textarea"
            label="상세 설명"
            placeholder={`${
              userType === "business" ? "기업" : "자기"
            } 상세 설명을 작성해 주세요.`}
            maxLength="500"
            errorMsg="상세 설명은 최대 500자까지 가능해요."
            value={detail}
            handleEvent={handleDetail}
            {...restProps}
          />
        </section>
        <section className="mt-20">
          <h3 className="title required">파트너 정보</h3>
          <MultiInput mobile={isMobile} {...restProps}>
            <LabelInput
              mobile={isMobile}
              ref={careerRef}
              isLabeled={false}
              type="number"
              label="업력"
              isIcon={true}
              icon={"career"}
              countType="년"
              placeholder={"총 경력을 입력해 주세요."}
              value={career}
              defaultValue={partnerData.career}
              handleEvent={handleCareer}
              id="career"
              toggleOpt={openState.career}
              handleToggle={() => handleToggle("career")}
              minValue={1}
              {...restProps}
            />
            <Select
              mobile={isMobile}
              isLabeled={false}
              isIcon={true}
              label="근무형태"
              icon={"type"}
              value={workType}
              defaultValue={partnerData.workMethod}
              selectOption={workTypeArr}
              placeholder={"선호하는 근무 형태를 선택해 주세요."}
              handleEvent={handleWorkType}
              id="workMethod"
              toggleOpt={openState.workMethod}
              handleToggle={() => handleToggle("workMethod")}
              {...restProps}
            />
          </MultiInput>
          <MultiInput mobile={isMobile} {...restProps} className="mt-10">
            {userType === "business" && (
              <>
                <SelectSub
                  mobile={isMobile}
                  label="위치"
                  isLabeled={false}
                  isIcon={true}
                  icon={"location"}
                  value={localInfo}
                  defaultValue={{
                    city: location,
                    detail: locationDetail,
                  }}
                  main={"소재지를 선택해 주세요."}
                  sub={"세부 지역을 선택해 주세요."}
                  handleEvent={handleLocal}
                  handleSubEvent={handleLocalDetail}
                  id="location"
                  openState={openState}
                  handleToggle={() => handleToggle("location")}
                  {...restProps}
                />
                <Select
                  mobile={isMobile}
                  isIcon={true}
                  isLabeled={false}
                  icon={"business"}
                  label="규모"
                  value={capacity}
                  selectOption={capArr}
                  defaultValue={partnerData.businessInfo.scale}
                  placeholder={"기업의 규모를 선택해 주세요."}
                  handleEvent={handleCapacity}
                  id="capacity"
                  toggleOpt={openState.capacity}
                  handleToggle={() => handleToggle("capacity")}
                  {...restProps}
                />
              </>
            )}
          </MultiInput>
        </section>
        <section className="mt-20">
          <div className="title-wrapper">
            <h3 className="title required">AI 기술</h3>
            <span className="guide">보유한 모든 역량을 선택해 주세요.</span>
          </div>
          {!isMobile ? (
            <>
              <BadgeWrapper>
                {interestedAiTaskData.map((item, index) => {
                  const lineBreak = index + 1 === 9 ? <br /> : null;
                  return (
                    <React.Fragment key={index}>
                      <SkillBadge
                        isMobile={isMobile}
                        index={index}
                        className={"input-checkbox"}
                        setCheckArray={setInterestedAiTask}
                        checkedArray={interestedAiTask}
                        badgeData={item}
                      />
                      {lineBreak}
                    </React.Fragment>
                  );
                })}
              </BadgeWrapper>
            </>
          ) : (
            <AiSkills
              isMobile={isMobile}
              partnerData={partnerData}
              options={interestedAiTaskData}
              interestedAiTask={interestedAiTask}
              setInterestedAiTask={setInterestedAiTask}
              {...restProps}
            />
          )}
        </section>
      </StyledWrapper>
    );
  }
);

export default ProfileDetail;

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  & .title-wrapper {
    display: inline-flex;
    align-items: center;
    & .guide {
      font-size: 12px;
      margin-bottom: 6px;
      height: 16px;
      color: #666;
      margin-left: 4px;
    }
  }
  & .title {
    color: var(--black02, #333);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    margin-bottom: 10px;

    &.required::after {
      content: "*";
      margin-left: 3px;
      color: var(--red00, #df2e47);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }
  & .caption {
    margin-bottom: 15px;
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
`;

const MultiInput = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  gap: 10px;

  /* & > :first-child {
    margin-right: ${(props) => (props.mobile ? "0" : "5px")};
  }
  & > :last-child {
    margin-left: ${(props) => (props.mobile ? "0" : "5px")};
  } */
`;

const BadgeWrapper = styled.div`
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  border-radius: 5px;
  padding: 11px 18px;
`;
