import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useModal from "hooks/useModal";
import useAuth from "hooks/useAuth";

const MyInfo = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { openModal } = useModal();
  const { userInfo } = useAuth();

  const signOut = () => {
    auth.signout(() => {
      navigate("/");
    });
  };

  return (
    <div className="container">
      <div className="section h-100">
        <div className="input-wrap h-100">
          <div className="section-tit lg">
            계정 보호를 위해
            <br />
            개인정보를 관리하세요.
          </div>
          <label className="input-label sm" style={{ color: "#999" }}>
            로그인 계정 정보
          </label>
          <div className={`sns-exist ${auth.userInfo.SOCIAL_TYPE}`}>
            <input
              type="text"
              className="input-text"
              placeholder="이름을 입력해 주세요."
              value={auth.userInfo.USERNAME}
              disabled
              style={{ opacity: 1 }}
            />
          </div>
          <div className="btn-wrap type2 mt-30">
            {userInfo.SOCIAL_TYPE === "DEFAULT" && (
              <button
                onClick={() => {
                  openModal({
                    type: "changePasswordModal",
                  });
                }}
                type="button"
                className="btn btn-half btn-black type2"
              >
                비밀번호 변경하기
              </button>
            )}
            <button
              onClick={() => {
                signOut();
              }}
              type="button"
              className={`btn btn-half btn-l-grey`}
              style={
                userInfo.SOCIAL_TYPE === "DEFAULT" ? {} : { width: "100%" }
              }
            >
              로그아웃
            </button>
          </div>
          <Link
            to="/mypage/leaveid"
            className="btn txt-c--grey btn-underline btn-bottomfix"
            style={{ fontSize: "12px" }}
          >
            AI 히어로즈 탈퇴하기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
