import { getPreSignedUrl, uploadFile } from "../services/file";

export const fileAttach = (e, path, callback) => {
  const file = e.target.files[0];
  if (!file) return;
  const randomStr = Math.random().toString(36).split(".")[1];


  getPreSignedUrl(`/${path}${randomStr + file.name}`)
    .then((res) => {
      uploadFile(res.presignedUrl, file)
        .then(() => callback(res.realUrl, file.name))
    });
};
