import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNoticeList } from 'services/user';


import Pagination from 'components/common/Pagination';
import { dateFormatType2NoTime } from 'utils/utils';
import Select from 'components/common/Select';
import useViewCheck from 'hooks/useViewCheck';

const Notice = () => {
	const navigate = useNavigate();
	const { isMobile } = useViewCheck();

	const [noticeList, setNoticeList] = useState([]);
	const [keyword, setKeyword] = useState('');
	const [keywordInput, setKeywordInput] = useState('');
	const [page, setPage] = useState(1);
	const [paginationData, setPaginationData] = useState(null);
	const [category, setCategory] = useState('ALL')

	useEffect(() => {
		getNotice();
	}, [page, keyword, category])

	const getNotice = useCallback(() => {
		const params = {
			page,
			category
		}
		if (keyword) {
			params.keyword = keyword
		}
		getNoticeList(params, (res) => {
			setNoticeList(res.response.list)
			const { navigateFirstPage, navigateLastPage, navigatepageNums, nextPage, pageNum, prePage, isFirstPage, isLastPage, hasPreviousPage, hasNextPage, pages } = res.response
			setPaginationData({
				navigateFirstPage,
				navigateLastPage,
				navigatepageNums,
				nextPage,
				pageNum,
				prePage,
				isFirstPage,
				isLastPage,
				hasPreviousPage,
				hasNextPage, pages
			})
		})
	}, [page, keyword, category])

	return (
		<div className="container">
			<button type="button" className="btn top-back" onClick={() => { navigate('/help') }}>고객센터로 돌아가기</button>
			<div className="search-top" style={isMobile ? { marginTop: 0 } : { marginTop: 20 }}>
				<div className="count">
					공지사항
				</div>
			</div>

			<div className="search-top community-category--top type-flex">
				<div className="left">
					{!isMobile ?
						<>
							<label className="input-checkbox">
								<input type="radio" name="categoryRadio" value="ALL" checked={category === "ALL"} onChange={(e) => setCategory(e.target.value)} />
								<span className="label wide">전체</span>
							</label>
							<label className="input-checkbox">
								<input type="radio" name="categoryRadio" value="NOTICE" checked={category === "NOTICE"} onChange={(e) => setCategory(e.target.value)} />
								<span className="label wide">공지</span>
							</label>
							<label className="input-checkbox">
								<input type="radio" name="categoryRadio" value="UPDATE" checked={category === "UPDATE"} onChange={(e) => setCategory(e.target.value)} />
								<span className="label wide">업데이트</span>
							</label>
							<label className="input-checkbox">
								<input type="radio" name="categoryRadio" value="EVENT" checked={category === "EVENT"} onChange={(e) => setCategory(e.target.value)} />
								<span className="label wide">이벤트</span>
							</label>
							<label className="input-checkbox">
								<input type="radio" name="categoryRadio" value="ETC" checked={category === "ETC"} onChange={(e) => setCategory(e.target.value)} />
								<span className="label wide">기타</span>
							</label>
						</>
						:
						<>
							<Select
								className={['sm text-start']}
								width={118}
								option={[
									{
										id: 'ALL',
										name: '전체'
									},
									{
										id: 'NOTICE',
										name: '공지'
									},
									{
										id: 'UPDATE',
										name: '업데이트'
									},
									{
										id: 'EVENT',
										name: '이벤트'
									},
									{
										id: 'ETC',
										name: '기타'
									},
								]}
								selected={category}
								setSelect={(value) => {
									setCategory(value)
								}}

							/>
						</>
					}

				</div>
				<div className="input" style={isMobile ? {
					width: '100%'
				} : {}}>
					<div className="right">
						<div className="input-wrap search">
							<button type="button" className="btn btn-search"></button>
							<input type="text" className="input-text" placeholder="공지사항을 검색해 주세요." value={keywordInput} onChange={(e) => setKeywordInput(e.target.value)} onKeyDown={(e) => { if (e.keyCode === 13) setKeyword(e.target.value) }} onBlur={(e) => { setKeyword(e.target.value) }} />
						</div>
					</div>
				</div>
			</div>
			<ul className="board-list">
				{Boolean(noticeList.length) && noticeList.map((item) => (
					<li className="board-list--item" key={`board-list--item=${item.SEQ}`} onClick={() => navigate(`${item.SEQ}`)}>
						<div className="badge-wrap">
							{item.CATEGORY === 'NOTICE' && <span className="badge badge-md primary">공지</span>}
							{item.CATEGORY === 'EVENT' && <span className="badge badge-md pink">이벤트</span>}
							{item.CATEGORY === 'UPDATE' && <span className="badge badge-md blue">업데이트</span>}
							{item.CATEGORY === 'ETC' && <span className="badge badge-md grey2">기타</span>}
						</div>
						<button type="button" className="btn tit">{item.TITLE}</button>
						<span className="date">{dateFormatType2NoTime(item.CREATED_AT)}</span>
					</li>
				))}
			</ul>
			{paginationData &&
				<Pagination paginationData={paginationData} page={page} setPage={setPage} />
			}
		</div>
	)
}

export default Notice