import React from "react";
import { Helmet } from "react-helmet-async";
const MetaTag = ({ title, desc, imgSrc, robot }) => {
  const currentURL = window.location.href;
  return (
    <Helmet>
      <title>{title}</title>
      {/* 로봇 => noindex 는 스크롤 비활성화 */}
      {robot ? (
        <meta name="robots" content="index,follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="description" content={desc} />
      {/* <meta name="keywords" content={keywords} /> */}
      <link rel="cannonical" href={currentURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imgSrc} />
      <meta property="og:url" content={currentURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imgSrc} />
      {/* <link rel="canonical" href={url} /> */}
    </Helmet>
  );
};

export default MetaTag;
