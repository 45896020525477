import React from "react";
import useModal from "hooks/useModal";
import { useNavigate } from "react-router-dom";

import main_popup_si from "assets/images/main_popup_si.png";
import main_popup_si_mo from "assets/images/main_popup_si_mo.png";

import useViewCheck from "hooks/useViewCheck";
import { homePopupHits } from "services/home";
import styled, { css } from "styled-components";

const moStyle = css`
  ${(props) =>
    props.isMobile &&
    css`
      bottom: 55px;
    `}

  ${(props) =>
    !props.isMobile &&
    css`
      bottom: 42px;
      height: 80px;
      width: 400px;
      left: calc(50% - 200px);
    `}
`;
const StyledModal = styled.div`
  & .modal-con {
    overflow: hidden;
    width: ${(props) => (props.isMobile ? "460px" : "354px")};
    & img {
      object-fit: cover;
      width: 100%;
    }
    & .btn-direct {
      background: transparent;
      color: transparent;
      ${moStyle}
    }
  }
`;

const EventPopupModal = () => {
  const { closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const navigate = useNavigate();
  return (
    <StyledModal className="modal show">
      <div className="modal-dim"></div>
      <div isMobile={isMobile} className="modal-con main-modal">
        <button
          type="button"
          className="btn btn-close"
          onClick={() => {
            let expires = new Date();
            expires = expires.setHours(23, 59, 59, 0);
            localStorage.setItem("homeVisited", expires);
            closeModal();
          }}
        ></button>
        <img
          src={!isMobile ? main_popup_si : main_popup_si_mo}
          width={!isMobile ? 428 : 354}
          height={!isMobile ? 651 : 554}
          alt=""
        />
        <button
          type="button"
          className="btn btn-lg btn-primary btn-direct"
          onClick={() => {
            homePopupHits("SI", (res) => {
              if (res.code === 0) {
                navigate("/community/119");
                closeModal();
              }
            });
          }}
        >
          오프라인 이벤트 신청하기
        </button>
      </div>
    </StyledModal>
  );
};

export default EventPopupModal;
