import React, { useState, useEffect } from "react";
import styled from "styled-components";
const Badge = ({
  className,
  checkedArray,
  setCheckArray,
  badgeData,
  activeColor,
  style,
  index,
  ...restProps
}) => {
  const [previousType, setPreviousType] = useState(null);
  const changeBoolean = () => {
    let tempArr = [...checkedArray];
    if (tempArr.includes(badgeData.id)) {
      tempArr = tempArr.filter((a) => {
        return a !== badgeData.id;
      });
    } else {
      tempArr.push(badgeData.id);
    }
    setCheckArray([...tempArr]);
  };
  useEffect(() => {
    const currentType = Array.isArray(checkedArray) ? "array" : "object";
    setPreviousType(currentType);
  }, [checkedArray, previousType]);

  return (
    <Label
      style={(index + 1) % 10 === 1 ? { marginLeft: "0px" } : {}}
      className={className}
      key={`skillStack-${badgeData?.id}`}
      {...restProps}
    >
      <input
        type="checkbox"
        className="hidden"
        checked={
          Array.isArray(checkedArray) && checkedArray.includes(badgeData?.id)
        }
        onChange={changeBoolean}
      />
      <span className="label">{badgeData?.name}</span>
    </Label>
  );
};

export default Badge;
const Label = styled.label`
  margin-bottom: 6px;
  margin-right: 6px;
  &:first-child {
    margin-left: 0;
  }
  & span.label {
    border-radius: 10px;
    border: 1px solid var(--mono-m-gray-03-stroke, #eee);
    background: var(--mono-m-bg, #f6f6f6);
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;
    padding: 0 14px;
  }
  & input:checked + .label {
    background-color: ${(props) =>
      props.activeColor ? props.activeColor : "#F4ECFF"} !important;
    color: #000;
  }
  & input.hidden {
    display: none;
  }
`;
