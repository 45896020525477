import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useModal from 'hooks/useModal';
import { getParameterByName } from 'utils/utils';

const AdminLogin = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		userAccessToken()
	}, [])

	const userAccessToken = () => {
		const code = getParameterByName(location.search, 'code');
		console.log(code)
		localStorage.setItem('jwt', code);
		navigate('/', { replace: true });
	}


	return (
		<div className='container'>
			<LoadingSpinner />
		</div>
	)
}

export default AdminLogin;