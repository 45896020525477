import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useModal from "hooks/useModal";
import { nickNameRegEx, onlyAlphabet, passwordRegEx } from "utils/regex";
import { userRegister, userNickNameCheck, kmcTrCert } from "services/user";
import useAuth from "hooks/useAuth";
import { termsDetailView, termsListView } from "services/home";

const Join = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { state } = useLocation();
  const { openModal, closeModal } = useModal();

  const [email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    phoneNum: "",
    birthday: "",
  });
  const [nickname, setNickname] = useState("");
  const [socialType, setSocialType] = useState("");
  const [socialId, setSocialId] = useState("");

  const [nicknameError, setNicknameError] = useState({
    error: false,
    msg: "",
  });
  const [nicknameChecked, setNicknameChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordHide, setPasswordHide] = useState(false);

  const [terms, setTerms] = useState({
    all: false,
    service: false,
    use: false,
    provision: false,
  });
  const [marketing, setMarketing] = useState(false);

  const [inputDone, setInputDone] = useState(false);

  const [useTerms, setUseTerms] = useState(null);
  const [marketingTerms, setMarketingTerms] = useState(null);
  const [policyTerms, setPolicyTerms] = useState(null);

  const [isPopupBlock, setPopupBlock] = useState(false);

  const reqKMCISFormRef = useRef();
  const [trCert, setTrCert] = useState("");

  const trUrl =
    process.env.REACT_APP_API_HOST + process.env.REACT_APP_KMC_REDIRECT_URI;

  useEffect(() => {
    CheckPopupBlock();
  }, []);

  useEffect(() => {
    setEmail(state.userEmail);
    if (state.socialType) {
      setSocialType(state.socialType);
      setSocialId(state.socialId);
      setPassword("social1234!");
    }
  }, [state]);

  useEffect(() => {
    termsListView((res) => {
      const useList = res.response.filter((a) => a.CATEGORY === "USE");
      const marketingList = res.response.filter(
        (a) => a.CATEGORY === "MARKETING"
      );
      const policyList = res.response.filter((a) => a.CATEGORY === "POLICY");

      const useTermsTemp = useList.reduce((max, p) => {
        const applyAt = Number(p.APPLY_AT.split("-").join(""));
        const maxApplyAt = Number(max.APPLY_AT.split("-").join(""));
        return applyAt > maxApplyAt ? p : max;
      }, useList[0]);

      const marketingTermsTemp = marketingList.reduce((max, p) => {
        const applyAt = Number(p.APPLY_AT.split("-").join(""));
        const maxApplyAt = Number(max.APPLY_AT.split("-").join(""));
        return applyAt > maxApplyAt ? p : max;
      }, marketingList[0]);

      const policyTermsTemp = policyList.reduce((max, p) => {
        const applyAt = Number(p.APPLY_AT.split("-").join(""));
        const maxApplyAt = Number(max.APPLY_AT.split("-").join(""));
        return applyAt > maxApplyAt ? p : max;
      }, policyList[0]);

      termsDetailView(useTermsTemp.SEQ, (res) => {
        setUseTerms(res.response.DETAILS);
      });

      termsDetailView(marketingTermsTemp.SEQ, (res) => {
        setMarketingTerms(res.response.DETAILS);
      });
      termsDetailView(policyTermsTemp.SEQ, (res) => {
        setPolicyTerms(res.response.DETAILS);
      });
    });
  }, []);

  useEffect(() => {
    setNicknameError({
      error: false,
      msg: "",
    });
    if (!nicknameChecked) return;
    setNicknameChecked(false);
  }, [nickname]);

  useEffect(() => {
    if (terms.all) return;
    if (terms.service && terms.use && terms.provision && marketing)
      setTerms({ ...terms, all: true });
  }, [terms, marketing]);

  useEffect(() => {
    window.addEventListener("message", kmcResult, false);
    return () => {
      window.removeEventListener("message", kmcResult);
    };
  }, []);

  const kmcResult = (e) => {
    if (e.origin !== process.env.REACT_APP_API_HOST) return;
    const {
      DI,
      birth,
      existUser,
      name,
      mNumber,
      username,
      socialType,
      lastLoginAt,
    } = e.data;
    if (existUser) {
      userCertificateCheck(username, socialType, lastLoginAt);
      return;
    }
    setUserInfo({
      DI,
      name,
      phoneNum: mNumber,
      birthday: birth.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3"),
    });
    window.newPopup.close();
    setTimeout(() => {
      delete window.newPopup;
    }, 100);
  };

  useEffect(() => {
    if (!trCert) return;
    openKMCISWindow();
  }, [trCert]);

  useEffect(() => {
    if (
      terms.use &&
      terms.service &&
      terms.provision &&
      nicknameChecked &&
      !passwordError &&
      userInfo.name
    ) {
      setInputDone(true);
    } else {
      setInputDone(false);
    }
  }, [terms, nicknameChecked, passwordError, userInfo]);

  const userCertificateCheck = (username, socialType, lastLoginAt) => {
    openModal({
      type: "messageModal",
      contents: {
        title: "가입된 이메일 계정이 있어요.",
        message: `아래의 기존 계정으로 로그인해 주세요.\n[${username}]`,
        confirmText: "기존 계정으로 로그인하기",
        confirmCallback: () => {
          navigate("/idinquiry/foundemail", {
            state: { username, result: "found_email", socialType, lastLoginAt },
          });
          closeModal();
        },
      },
    });
  };

  const openKMCISWindow = () => {
    window.newPopup = window.open(
      "",
      "KMCISWindow",
      "width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0"
    );

    reqKMCISFormRef.current.target = "KMCISWindow";

    reqKMCISFormRef.current.action =
      "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    reqKMCISFormRef.current.submit();
  };

  const smsAuth = () => {
    let randomStr = Math.random().toString(36).split(".")[1];
    kmcTrCert(randomStr, "JOIN", (res) => {
      setTrCert(res.response);
    });
  };

  const duplicateCheck = useCallback(() => {
    if (nickNameRegEx(nickname)) {
      userNickNameCheck(nickname, (res) => {
        if (res.response) {
          setNicknameError({
            error: true,
            msg: `닉네임을 사용할 수 없어요.`,
          });
        } else {
          setNicknameChecked(true);
        }
      });
    } else {
      setNicknameError({
        error: true,
        msg: `한글,영문,숫자 조합 최대 20자리 닉네임이\n아니에요.`,
      });
    }
  }, [nickname]);

  const signup = () => {
    const body = {
      di: userInfo.DI,
      username: email,
      password: password,
      name: userInfo.name,
      phone: userInfo.phoneNum,
      nickname,
      consentMarketing: marketing,
      birthday: userInfo.birthday,
      socialType: socialType || "DEFAULT",
      socialId: socialId || null,
    };

    userRegister(body, (res) => {
      if (!res.response) return; // 회원가입 에러
      auth.signin({ email, password }, () => {});
      navigate("/");
    });
  };

  const CheckPopupBlock = () => {
    const newWin = window.open("about:blank");
    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      setPopupBlock(true);
    } else {
      newWin.close();
      setPopupBlock(false);
    }
  };

  return (
    <div className="container">
      <div className="section">
        <div className="join-wrap">
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              if (socialId) {
                navigate("/");
              } else {
                navigate(-1);
              }
            }}
          ></button>
          <div className="join-tit">회원가입</div>
          <div className="input-wrap">
            <label className="input-label sm">이메일</label>
            <input type="text" className="input-text" readOnly value={email} />
          </div>
          {userInfo.name ? (
            <>
              <div className="input-wrap">
                <label className="input-label sm">휴대폰 번호</label>
                <input
                  type="text"
                  className="input-text"
                  readOnly
                  value={userInfo.phoneNum}
                />
              </div>
              <div className="input-wrap">
                <label className="input-label sm">이름</label>
                <input
                  type="text"
                  className="input-text"
                  readOnly
                  value={userInfo.name}
                />
              </div>
            </>
          ) : (
            <>
              <div className="btn-wrap">
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={() => {
                    smsAuth();
                  }}
                >
                  휴대폰 본인 인증하기
                </button>
              </div>
              {isPopupBlock ? (
                <p className="input-guide error" style={{ marginTop: -8 }}>
                  팝업 차단 기능이 설정되어 있습니다. 설정&gt; 팝업 차단을
                  해제해주세요.
                </p>
              ) : (
                <></>
              )}
            </>
          )}
          <div className="input-wrap">
            <label className="input-label sm required">닉네임</label>
            <div className="btn-exist">
              <input
                type="text"
                className={`input-text ${nicknameError.error ? "error" : ""}`}
                placeholder="한글, 영문, 숫자 조합 최대 20자"
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
                onBlur={() => {
                  if (nickNameRegEx(nickname)) {
                    setNicknameError({
                      error: false,
                      msg: "",
                    });
                  } else {
                    setNicknameError({
                      error: true,
                      msg: `한글,영문,숫자 조합 최대 20자리 닉네임이\n아니에요.`,
                    });
                  }
                }}
              />
              {nicknameChecked ? (
                <span className="check right"></span>
              ) : (
                <button
                  onClick={() => {
                    duplicateCheck();
                  }}
                  type="button"
                  className="btn btn-black type2"
                  disabled={nicknameError.error}
                >
                  중복 확인
                </button>
              )}
            </div>
            {!nicknameChecked && !nicknameError.error && (
              <p className="input-guide">
                회사명은 닉네임으로 사용할 수 없어요.
              </p>
            )}
            {nicknameChecked && (
              <p className="input-guide">사용할 수 있는 닉네임이에요.</p>
            )}
            {nicknameError.error && (
              <>
                <p className="input-guide error">{nicknameError.msg}</p>
              </>
            )}
          </div>
          {!socialId && (
            <div className="input-wrap">
              <label className="input-label sm required">비밀번호</label>
              <div className="input-password">
                <input
                  type={passwordHide ? "password" : "text"}
                  className={`input-text ${passwordError ? "error" : ""}`}
                  placeholder="영문+숫자+특수문자 조합 8자~16자 이하"
                  autoComplete={passwordHide ? "current-password" : "off"}
                  value={password}
                  onChange={(e) => {
                    setPassword(onlyAlphabet(e));
                  }}
                  onBlur={() => {
                    if (passwordRegEx(password)) {
                      setPasswordError(false);
                    } else {
                      setPasswordError(true);
                    }
                  }}
                />
                <button
                  onClick={() => {
                    setPasswordHide(!passwordHide);
                  }}
                  type="button"
                  className={`btn ${passwordHide ? "hide" : "show"}`}
                  tabIndex="-1"
                ></button>
              </div>
              {passwordError && (
                <>
                  <p className="input-guide error">
                    영문+숫자+특수문자 조합 8~16자리 비밀번호가 아니에요.
                  </p>
                </>
              )}
            </div>
          )}
          <div className="join-bottom">
            <div className="join-bottom--con top">
              <label className="input-checkbox blue">
                <input
                  type="checkbox"
                  checked={terms.all}
                  onChange={(e) => {
                    setTerms({
                      all: e.target.checked,
                      service: e.target.checked,
                      use: e.target.checked,
                      provision: e.target.checked,
                    });
                    setMarketing(e.target.checked);
                  }}
                />
                <span className="box"></span>
                <span className="text">
                  <span>모두 동의합니다.</span>
                </span>
              </label>
            </div>
            <div className="join-bottom--con">
              <label className="input-checkbox blue">
                <input
                  type="checkbox"
                  checked={terms.service}
                  onChange={(e) =>
                    setTerms({
                      ...terms,
                      all: e.target.checked && terms.all,
                      service: e.target.checked,
                    })
                  }
                />
                <span className="box"></span>
                <span className="text underline">
                  <span>서비스 이용약관 </span>
                  <span className="txt-c--red">(필수)</span>
                </span>
              </label>
              <button
                type="button"
                className="btn btn-underline txt-c--grey"
                onClick={() => {
                  openModal({
                    type: "policyModal",
                    contents: {
                      type: "service", // service, use, provision, marketing
                      data: useTerms,
                      callback: () => {
                        setTerms({
                          ...terms,
                          service: true,
                        });
                      },
                    },
                  });
                }}
              >
                보기
              </button>
            </div>
            <div className="join-bottom--con">
              <label className="input-checkbox blue">
                <input
                  type="checkbox"
                  checked={terms.use}
                  onChange={(e) =>
                    setTerms({
                      ...terms,
                      all: e.target.checked && terms.all,
                      use: e.target.checked,
                    })
                  }
                />
                <span className="box"></span>
                <span className="text underline">
                  <span>개인정보 수집 및 이용 </span>
                  <span className="txt-c--red">(필수)</span>
                </span>
              </label>
              <button
                type="button"
                className="btn btn-underline txt-c--grey"
                onClick={() => {
                  openModal({
                    type: "policyModal",
                    contents: {
                      type: "use", // service, use, provision, marketing
                      data: policyTerms,
                      callback: () => {
                        setTerms({
                          ...terms,
                          use: true,
                        });
                      },
                    },
                  });
                }}
              >
                보기
              </button>
            </div>
            <div className="join-bottom--con">
              <label className="input-checkbox blue">
                <input
                  type="checkbox"
                  checked={terms.provision}
                  onChange={(e) =>
                    setTerms({
                      ...terms,
                      all: e.target.checked && terms.all,
                      provision: e.target.checked,
                    })
                  }
                />
                <span className="box"></span>
                <span className="text underline">
                  <span>개인정보 제3자에게 제공 동의</span>{" "}
                  <span className="txt-c--red">(필수)</span>
                </span>
              </label>
              <button
                type="button"
                className="btn btn-underline txt-c--grey"
                onClick={() => {
                  openModal({
                    type: "policyModal",
                    contents: {
                      type: "provision", // service, use, provision, marketing
                      callback: () => {
                        setTerms({
                          ...terms,
                          provision: true,
                        });
                      },
                    },
                  });
                }}
              >
                보기
              </button>
            </div>
            <div className="join-bottom--con">
              <label className="input-checkbox blue">
                <input
                  type="checkbox"
                  checked={marketing}
                  onChange={(e) => {
                    setMarketing(e.target.checked);
                    setTerms({
                      ...terms,
                      all: e.target.checked && terms.all,
                    });
                  }}
                />
                <span className="box"></span>
                <span className="text underline">
                  <span>마케팅 수신 동의 (선택)</span>
                </span>
              </label>
              <button
                type="button"
                className="btn btn-underline txt-c--grey"
                onClick={() => {
                  openModal({
                    type: "policyModal",
                    contents: {
                      type: "marketing",
                      data: marketingTerms,
                      callback: () => {
                        setMarketing(true);
                      },
                    },
                  });
                }}
              >
                보기
              </button>
            </div>
            <button
              type="button"
              className="btn btn-full btn-black type2"
              disabled={!inputDone}
              onClick={() => signup()}
            >
              가입하기
            </button>
            {/* <label className="input-checkbox">
							<input
								type="checkbox"
								value={terms}
								onChange={(e) => setTerms(e.target.checked)}
							/>
							<span className="box"></span>
							<span className="text underline"><span>이용약관, 개인정보 수집 및 이용 동의 </span><span className="txt-c--red">(필수)</span></span>
						</label>
						<br />
						<label className="input-checkbox">
							<input
								type="checkbox"
								value={marketing}
								onChange={(e) => setMarketing(e.target.checked)}
							/>
							<span className="box"></span>
							<span className="text underline"><span>마케팅 수신 동의 (선택)</span></span>
						</label>
						<button
							type="button"
							className="btn btn-full btn-black type2"
							disabled={!(terms && nicknameChecked && !passwordError.error && userInfo.name)}
							onClick={() => signup()}
						>가입하기</button> */}
          </div>
        </div>

        <form
          name="reqKMCISForm"
          method="post"
          action="#"
          ref={reqKMCISFormRef}
          style={{ display: "none" }}
        >
          <input type="hidden" name="tr_cert" value={trCert} />
          <input type="hidden" name="tr_url" value={trUrl} />
          <input type="submit" value="KMC" />
        </form>
      </div>
    </div>
  );
};

export default Join;
