import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import useAuth from 'hooks/useAuth';
import useModal from 'hooks/useModal';
import { emailRFC5322RegEx, onlyAlphabet } from 'utils/regex';
import { emailAuthCheck } from 'services/user';

const LoginModal = ({ message, forceEmail }) => {
	const { naver } = window;
	const auth = useAuth();
	const navigate = useNavigate();
	const { closeModal } = useModal();

	const [passwordInput, setPasswordInput] = useState();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordHide, setPasswordHide] = useState(true);
	const [emailError, setEmailError] = useState({
		error: false,
		msg: ''
	});
	const [passwordError, setPasswordError] = useState({
		error: false,
		msg: ''
	});

	const passwordInputRef = useRef();
	const naverRef = useRef();

	useEffect(() => {
		initializeNaverLogin()
	}, [])

	useEffect(() => {
		if (!forceEmail) return;
		setEmail(forceEmail)
	}, [forceEmail])

	useEffect(() => {
		setEmailError({
			error: false,
			msg: ''
		})
	}, [email]);

	useEffect(() => {
		setPasswordError({
			error: false,
			msg: ''
		})
	}, [password]);

	useEffect(() => {
		if (!passwordInput) return;
		passwordInputRef.current.focus();
	}, [passwordInput])

	const emailRegCheck = useCallback(() => {
		if (emailRFC5322RegEx(email)) {
			setEmailError({
				error: false,
				msg: ''
			});
			return true;
		} else {
			setEmailError({
				error: true,
				msg: '이메일 정보가 없어요.'
			});
			return false;
		}
	}, [email])

	const emailCheck = (userEmail) => {
		if (emailRegCheck()) {
			emailAuthCheck(userEmail, (res) => {
				// console.log(res)
				if (res.response.result) {
					if (res.response.withdrawal) {
						setEmailError({
							error: true,
							msg: '계정 정보를 찾고 다시 시도해 주세요.'
						});
						return;
					}
					setPasswordInput(true);
				} else {
					navigate('/join', { state: { userEmail: userEmail } });
					closeModal();
				}
			});
		} else {
			setEmailError({
				error: true,
				msg: '이메일 정보가 없어요.'
			});
		}
	};

	const loginContinue = useCallback(() => {
		if (email && !emailError.error) {
			emailCheck(email);
		} else {
			// console.log('이메일 입력안함')
			setEmailError({
				error: true,
				msg: '이메일 정보가 없어요.'
			});
		}
	}, [email, emailError])

	const login = useCallback((e) => {
		e.preventDefault();
		if (!password) {
			setPasswordError({
				error: true,
				msg: ''
			});
			return;
		}
		// 로그인
		auth.signin({ email, password }, (res) => {
			// console.log(res)
			if (res.code === 0) {
				// console.log('로그인 성공');
				closeModal();
			} else {
				setPasswordError({
					error: true,
					msg: ''
				})
			}
			// navigate('/', { replace: true });
		});
	}, [auth, email, password])

	// 네이버 ------------
	const initializeNaverLogin = () => {
		const naverLogin = new naver.LoginWithNaverId({
			clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
			callbackUrl: process.env.REACT_APP_SERVICE_URI + process.env.REACT_APP_NAVER_REDIRECT_URI,
			isPopup: false,
			loginButton: { color: 'green', type: 3, height: 58 },
			callbackHandle: true,
		})
		naverLogin.init()
	}
	const handleNaverLogin = () => {
		naverRef.current.children[0].click()
	}

	// 구글 --------------
	const handleGoogleLogin = useGoogleLogin({
		onSuccess: tokenResponse => { },
		flow: "auth-code",
		ux_mode: "redirect",
		redirect_uri: process.env.REACT_APP_SERVICE_URI + process.env.REACT_APP_GOOGLE_REDIRECT_URI
	});

	// 카카오 ----------------
	const handleKakaoLogin = () => {
		const kakaoLoginUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_SERVICE_URI + process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
		window.location.href = kakaoLoginUrl;
	}

	return (
		<div className="modal show">
			<div className="modal-dim"></div>
			<div className="modal-con m-full">
				<button onClick={() => { closeModal() }} type="button" className="btn btn-close"></button>
				<div className="login-wrap">
					<div className="login-logo"></div>
					<div className="login-guide">{message}</div>

					<div className="input-wrap">
						<label className="input-label sm">이메일</label>
						<input
							type="text"
							className={`input-text ${emailError.error ? 'error' : ''}`}
							placeholder="이메일을 입력해 주세요."
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							autoComplete='email'
							onKeyDown={(e) => {
								if (e.keyCode === 13 && !passwordInput) loginContinue();
							}}
							onBlur={() => {
								emailRegCheck();
							}}
						/>
						{emailError.error && <p className="input-guide error">{emailError.msg}</p>}
					</div>
					{
						!passwordInput ?
							<>
								<button type="button" className="btn btn-full btn-black type2" onClick={() => { loginContinue() }}>로그인/회원가입</button>
							</>
							:
							<>
								<div className="input-wrap">
									<label className="input-label sm">비밀번호</label>
									<div className="input-password">
										<input
											type={passwordHide ? 'password' : 'text'}
											className={`input-text ${passwordError.error ? 'error' : ''}`}
											placeholder="비밀번호를 입력해 주세요."
											value={password}
											onChange={(e) => { setPassword(onlyAlphabet(e)) }}
											autoComplete={passwordHide ? 'current-password' : 'off'}
											onKeyDown={(e) => {
												if (e.keyCode === 13) login(e)
											}}
											ref={passwordInputRef}
										/>
										<button onClick={() => { setPasswordHide(!passwordHide) }} type="button" className={`btn ${passwordHide ? 'hide' : 'show'}`} tabIndex='-1'></button>
										{passwordError.error && <>
											<p className="input-guide error">비밀번호가 일치하지 않아요. 다시 확인해 주세요.</p>
											<p className="input-guide error">계정 정보를 찾고 다시 시도해 주세요.</p>
										</>}
									</div>
								</div>
								<button onClick={(e) => { login(e) }} type="button" className="btn btn-full btn-black type2">로그인</button>
							</>
					}

					<Link to="/idinquiry" className="btn login-find textalign_center" onClick={() => closeModal()}>계정 정보 찾기</Link>
					<div className="login-sns">
						<div className="top">또는</div>
						<div className="login-sns--btn">
							<button type="button" className="btn kakao" onClick={() => handleKakaoLogin()}><div className="image"></div>카카오</button>
							<button type="button" className="btn naver" onClick={() => handleNaverLogin()}><div className="image"></div>네이버</button>
							<button type="button" className="btn google"><div className="image" onClick={() => { handleGoogleLogin() }}></div>구글</button>
							<div id="naverIdLogin" ref={naverRef} style={{ display: 'none' }}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginModal