import api from "api";

export const calculateInfoSave = (body, callback) => {
  console.log(body);
  api.post("/calculate/auth/change", body).then((res) => {
    callback(res);
  });
};

export const calculateTopView = (callback) => {
  api.get("/calculate/auth/view").then((res) => {
    callback(res);
  });
};

export const calculateListView = (params, callback) => {
  api.get("/calculate/auth/view/list", { params }).then((res) => {
    callback(res);
  });
};

export const calculateInfoView = (callback) => {
  api.get("/calculate/auth/view/accountNumber").then((res) => {
    callback(res);
  });
};

export const authUserNameCheck = (body, callback, catchCallback) => {
  api
    .post("/nice/name-check", body)
    .then((res) => {
      callback(res);
    })
    .catch(() => {
      catchCallback();
    });
};

export const authUserAccountCheck = (body, callback, catchCallback) => {
  api
    .post("/nice/account-check", body)
    .then((res) => {
      callback(res);
    })
    .catch(() => {
      catchCallback();
    });
};

export const getExcelCalculate = (params, callback) => {
  api
    .get("/excel/auth/calculate", { params, responseType: "blob" })
    .then((res) => {
      callback(res);
    });
};
