import React, { useState, useEffect, useCallback } from 'react'
import {
	Routes,
	Route,
	useLocation,
	useNavigate,
} from "react-router-dom";
import useModal from 'hooks/useModal';
import useAuth from 'hooks/useAuth';
import { userWithdrawal } from 'services/user';
import useViewCheck from 'hooks/useViewCheck';

const reasonItemList = [
	{
		id: 1,
		name: '필요한 기능이 지원되지 않아요.'
	},
	{
		id: 2,
		name: '사이트 이용이 불편해요.'
	},
	{
		id: 3,
		name: '다른 서비스를 이용하려고 해요.'
	},
	{
		id: 4,
		name: '프로젝트 매칭이 어려워요.'
	},
	{
		id: 5,
		name: '서비스 장애/ 오류가 있어요.'
	},
	{
		id: 6,
		name: '기타 사유'
	}
]

const Page1 = () => {
	const navigate = useNavigate();
	const [agreement, setAgreement] = useState(false);
	const { isMobile } = useViewCheck();

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					{/* <button type="button" className="btn top-back" onClick={() => navigate(-1)}>
						이전으로 돌아가기
					</button> */}
					<div className="section-tit lg">
						떠난다니 아쉬워요.

						<p className="sub">
							탈퇴 후 AI 히어로즈 서비스를 더 이상 이용할 수없어요. {isMobile && <br />}
							최종 확인 후 탈퇴를 진행해 주세요.
						</p>
					</div>
					<div className="quit-list">
						<span className="quit-list--box">모든 정산금액이 정산된 후 계정이 최종 삭제돼요.</span>
						<span className="quit-list--box">사용했던 닉네임은 재사용할 수 없어요.</span>
						<span className="quit-list--box">참여 중인 프로젝트와 히스토리가 모두 삭제돼요.</span>
						<span className="quit-list--box">작성한 글과 댓글은 삭제되지 않아요.</span>
					</div>
					<div className="btn-wrap">
						<label className="input-checkbox blue">
							<input type="radio" name="quit" checked={agreement} onClick={(e) => setAgreement(!agreement)} />
							<span className="box"></span>
							<span className="text"
							>안내 사항을 모두 확인했고, 동의해요.
								<span className="txt-c--red">(필수)</span></span>
						</label>
						<button
							type="button"
							className="btn btn-full btn-black type2 mt-30"
							disabled={!agreement}
							onClick={() => { navigate('/mypage/leaveid/page2') }}
						>
							확인
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const Page2 = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const { openModal, closeModal } = useModal();
	const { isMobile } = useViewCheck();

	const [radioInput, setRadioInput] = useState(0);
	const [textInput, setTextInput] = useState('');
	const [password, setPassword] = useState('');
	const [passwordHide, setPasswordHide] = useState(true);
	const [passwordError, setPasswordError] = useState(false);
	const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

	useEffect(() => {
		if (radioInput === 6) return;
		setTextInput('')
	}, [radioInput])

	useEffect(() => {
		if (!radioInput) {
			setConfirmBtnDisabled(true);
			return;
		}
		if (auth.userInfo.SOCIAL_TYPE === 'DEFAULT' && password.length < 1) {
			setConfirmBtnDisabled(true);
			return;
		}
		if (radioInput > 0 && radioInput < 6) {
			setConfirmBtnDisabled(false);
			return;
		}
		if (radioInput === 6) {
			if (textInput.length > 0) {
				setConfirmBtnDisabled(false);
			} else {
				setConfirmBtnDisabled(true);
			}
		}
	}, [radioInput, password, textInput])

	const handleChange = (e) => {
		setRadioInput(Number(e.target.value));
	};

	const signOut = () => {
		auth.signout(() => {
			// console.log('로그아웃 성공');
			navigate('/');
		});
	}

	const reaveIdConfirm = useCallback(() => {
		let reasonText;
		if (radioInput && radioInput !== 6) {
			const findReasonItem = reasonItemList.find((item) => item.id === radioInput)
			reasonText = findReasonItem ? findReasonItem.name : false
		}
		const body = {
			reason: reasonText || textInput,
			password,
		}
		userWithdrawal(body, (res) => {
			if (res.code === 0) {
				openModal({
					type: 'messageModal',
					contents: {
						title: 'AI 히어로즈 탈퇴 완료',
						message: 'AI 히어로즈 탈퇴가 정상적으로 처리되었어요.\n그동안 이용해 주셔서 감사해요.',
						confirmText: '확인',
						confirmCallback: () => {
							signOut();
							closeModal();
						}
					}
				});
			} else if (res.code === 2) {
				setPasswordError(true);
			} else if (res.code === 3) {
				openModal({
					type: 'messageModal',
					contents: {
						title: '탈퇴가 불가능해요.',
						message: '현재 진행중인 프로젝트가 있어 탈퇴가 불가능해요.\n프로젝트 완료 처리 이후, 다시 시도해 주세요.',
						confirmText: '확인',
						confirmCallback: () => {
							closeModal();
						}
					}
				});
			} else {
				openModal({
					type: 'messageModal',
					contents: {
						title: '요청에 실패했습니다',
						message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
						confirmText: '확인',
						confirmCallback: () => {
							closeModal();
						}
					}
				});
			}
		})
	}, [textInput, radioInput, password])

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<button type="button" className="btn top-back" onClick={() => navigate(-1)}>
						이전으로 돌아가기
					</button>
					<div className="section-tit lg">
						어떤 점이 불편하셨나요?
						<p className="sub">
							AI 히어로즈팀의 서비스 개선을 위해 탈퇴 사유를 알려주세요. {isMobile && <br />}
							&#40;1개 선택 필수<span className='txt-c--red'>*</span>&#41;
						</p>
					</div>
					<div className="quit-list">
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={1} onChange={(e) => { handleChange(e) }} checked={radioInput === 1} />
							<span className="box"></span>
							<span className="text"
							>필요한 기능이 지원되지 않아요.</span>
						</label>
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={2} onChange={(e) => { handleChange(e) }} checked={radioInput === 2} />
							<span className="box"></span>
							<span className="text"
							>사이트 이용이 불편해요.</span>
						</label>
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={3} onChange={(e) => { handleChange(e) }} checked={radioInput === 3} />
							<span className="box"></span>
							<span className="text"
							>다른 서비스를 이용하려고 해요.</span>
						</label>
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={4} onChange={(e) => { handleChange(e) }} checked={radioInput === 4} />
							<span className="box"></span>
							<span className="text"
							>프로젝트 매칭이 어려워요.</span>
						</label>
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={5} onChange={(e) => { handleChange(e) }} checked={radioInput === 5} />
							<span className="box"></span>
							<span className="text"
							>서비스 장애/ 오류가 있어요.</span>
						</label>
						<label className="input-checkbox quit-list--box">
							<input style={{ display: 'none' }} type="radio" name="quit" value={6} onChange={(e) => { handleChange(e) }} checked={radioInput === 6} />
							<span className="box"></span>
							<span className="text">기타 사유</span>
						</label>
						{radioInput === 6 && <textarea
							className="input-textarea"
							placeholder="직접 입력해 주세요.(100자 이내)"
							value={textInput}
							maxLength={100}
							onChange={e => setTextInput(e.target.value)}
						></textarea>}
					</div>
					{
						console.log(auth)
					}
					{auth.userInfo.SOCIAL_TYPE === 'DEFAULT' && <div className="input-wrap">
						<label className="input-label sm"
						>비밀번호 입력</label>
						<div className="input-password">
							<input
								type={passwordHide ? 'password' : 'text'}
								className={`input-text ${passwordError ? 'error' : ''}`}
								placeholder="본인 확인을 위해 비밀번호를 입력해 주세요."
								value={password}
								onChange={(e) => {
									setPassword(e.target.value)
									setPasswordError(false)
								}}
								autoComplete={passwordHide ? 'current-password' : 'off'}
							/>
							<button
								onClick={() => { setPasswordHide(!passwordHide) }}
								type="button"
								className={`btn ${passwordHide ? 'hide' : 'show'}`}
								tabIndex='-1'
							></button>
						</div>
						{passwordError && <p className="input-guide error">비밀번호가 일치하지 않아요. 다시 확인해 주세요.</p>}
					</div>}
					<button
						type="button"
						className="btn btn-full btn-black type2 mt-30"
						onClick={() => {
							reaveIdConfirm();
						}}
						disabled={confirmBtnDisabled}
					>
						AI 히어로즈 탈퇴하기
					</button>
				</div>
			</div>
		</div>
	)
}

const LeaveId = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateDefaultPage = () => {
		navigate('/mypage/leaveid/page1', { replace: true });
	}

	useEffect(() => {
		if (location.pathname === '/mypage/leaveid' || location.pathname === '/mypage/leaveid/') {
			navigateDefaultPage();
		}
	}, [location])

	return (
		<Routes>
			<Route path="/page1" element={<Page1 />}></Route>
			<Route path="/page2" element={<Page2 />}></Route>
		</Routes>
	)
}

export default LeaveId