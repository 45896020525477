import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { kmcTrCert } from "services/user";

const SnsAuth = () => {
  const navigate = useNavigate();
  const reqKMCISFormRef = useRef();
  const [trCert, setTrCert] = useState("");

  const trUrl =
    process.env.REACT_APP_API_HOST + process.env.REACT_APP_KMC_REDIRECT_URI;

  useEffect(() => {
    window.addEventListener("message", kmcResult, false);
    return () => {
      window.removeEventListener("message", kmcResult);
    };
  }, []);

  useEffect(() => {
    if (!trCert) return;
    openKMCISWindow();
  }, [trCert]);

  const kmcResult = (e) => {
    if (e.origin !== process.env.REACT_APP_API_HOST) return;
    const {
      DI,
      birth,
      existUser,
      name,
      result,
      mNumber,
      username,
      socialType,
      lastLoginAt,
    } = e.data;
    if (existUser) {
      navigate("/idinquiry/alreadysignedup", {
        state: { username, DI, result: "found_email", socialType, lastLoginAt },
      });
      return;
    } else {
      navigate("/idinquiry/notsignedup");
    }
  };

  const openKMCISWindow = () => {
    window.newPopup = window.open(
      "",
      "KMCISWindow",
      "width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0"
    );

    reqKMCISFormRef.current.target = "KMCISWindow";

    reqKMCISFormRef.current.action =
      "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    reqKMCISFormRef.current.submit();
  };

  const smsAuth = () => {
    // let date = dateFormat(new Date(), 'yyyyMMddhhmmss');

    let randomStr = Math.random().toString(36).split(".")[1];
    kmcTrCert(randomStr, "SNS", (res) => {
      setTrCert(res.response);
    });
  };

  return (
    <div className="container">
      <div className="section">
        {/* <div className="input-wrap">
					<div className="section-tit lg">본인 인증 후<br />가입한 이메일 정보를 안내해 드릴게요.</div>
					<label className="input-label sm">이름</label>
					<input type="text" className="input-text" placeholder="이름을 입력해 주세요." />
				</div>
				<div className="input-wrap">
					<label className="input-label sm">휴대폰 본인 인증</label>
					<div className="btn-exist">
						<select className="input-select sm" id="selectElement">
							<option>+82</option>
							<option>+81</option>
							<option>+80</option>
						</select>
						<input type="text" className="input-text" placeholder="‘-’제외 숫자 입력" />
						<button
							onClick={() => { snsAuth() }}
							type="button"
							className="btn btn-black type2"
							disabled
						>인증요청</button>
					</div>
				</div>
				<div className="input-wrap mt-4">
					<input type="text" className="input-text" placeholder="인증번호를 입력해 주세요." />
				</div> */}
        <div className="input-wrap">
          <div className="section-tit lg">
            본인 인증 후<br />
            가입한 이메일 정보를 안내해 드릴게요.
          </div>
        </div>
        <div className="input-wrap">
          <button
            type="button"
            className="btn btn-full btn-primary mt-30"
            onClick={() => {
              smsAuth();
            }}
          >
            휴대폰 본인 인증하기
          </button>
        </div>
        <form
          name="reqKMCISForm"
          method="post"
          action="#"
          ref={reqKMCISFormRef}
          style={{ display: "none" }}
        >
          <input type="hidden" name="tr_cert" value={trCert} />
          <input type="hidden" name="tr_url" value={trUrl} />
          <input type="submit" value="KMC" />
        </form>
      </div>
    </div>
  );
};

export default SnsAuth;
