import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import IWorkType from "assets/images/icon/icon_type.svg";
import IScale from "assets/images/icon/icon_business.svg";
import IReference from "assets/images/icon/icon_reference.svg";
import IProfile from "assets/images/icon/icon_profile.svg";
import { capArr } from "utils/data";
import { interestedAiTaskData } from "utils/data";

const PartnerCard = ({ children, item, ...restProps }) => {
  const [capacity, setCapacity] = useState("");
  const badgeTrailRef = useRef(null);
  useEffect(() => {
    const scale = capArr.filter((data) => data.id === item.SCALE);
    setCapacity(scale[0]);
  }, [item]);
  useEffect(() => {
    badgeTrailRef.current.addEventListener("wheel", handleWheel, {
      passive: false,
    });
  }, []);

  const toCamelCase = (str) =>
    str.toLowerCase().replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

  const findAiTaskByResponseValue = (responseValue) => {
    const camelCaseId = toCamelCase(responseValue);
    return interestedAiTaskData.find((task) => task.id === camelCaseId)?.name;
  };
  const trueValues = Object.entries(item)
    .filter(([key, value]) => typeof value === "boolean" && value === true)
    .map(([key]) => key);

  const handleWheel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { deltaY } = e;
    badgeTrailRef.current.scrollLeft += deltaY;
  };
  return (
    <StyldPartnerCard {...restProps}>
      <div className="image-box">
        {/* 이미지, 파트너뱃지 */}
        {/* {Boolean(item.NEW) && (
          <span className="badge m5 badge-sm black type2">NEW</span>
        )} */}
        <img
          width={100}
          height={100}
          src={item.PROFILE_IMG ? item.PROFILE_IMG : IProfile}
          alt="사용자 이미지"
          title={item.NICKNAME}
        />
        {item.ROLE === "ROLE_HEROS_PERSONAL" ? (
          <span className="badge m5 badge-sm blue" style={{ color: "#000" }}>
            개인 파트너
          </span>
        ) : (
          <span className="badge m5 badge-sm primary" style={{ color: "#000" }}>
            기업 파트너
          </span>
        )}
      </div>
      <div className="context-box">
        <StyledTitle {...restProps}>
          <div className="title">
            {/* 이름, 근무스타일, 레퍼런스 뱃지 */}
            <h2>
              {item.HEROES_TYPE === "BUSINESS" ? item.NICKNAME : item.NICKNAME}
            </h2>
            <div className="badge">
              <img src={IWorkType} alt={"근무타입"} title={"근무타입"} />
              {item.WORK_METHOD === "RESIDENT" && "상주 근무 가능"}
              {item.WORK_METHOD === "REMOTE" && "원격 근무 가능"}
              {item.WORK_METHOD === "BOTH" && "상주, 원격 모두 가능"}
            </div>
            {item.HEROES_TYPE === "BUSINESS" && item.SCALE !== null && (
              <div className="badge">
                <img src={IScale} alt={"근무인원"} title={"근무인원"} />
                {capacity !== undefined && capacity.name}
              </div>
            )}
            {item.IS_EXIST_CAREER === 1 && (
              <span className="badge reference">
                <img src={IReference} alt={"레퍼런스"} title={"레퍼런스보유"} />
                레퍼런스 보유
              </span>
            )}
          </div>
          {children}
        </StyledTitle>
        <StyledContent>
          {/* 소개 */}
          <div className="simple">{item.INTRODUCE_SIMPLE}</div>
          <div className="badges" ref={badgeTrailRef}>
            {/* 기술뱃지 */}
            {trueValues.map((item, idx) => (
              <span key={idx} className="badge m5 badge-md skill">
                {findAiTaskByResponseValue(item)}
              </span>
            ))}
          </div>
        </StyledContent>
      </div>
    </StyldPartnerCard>
  );
};

export default PartnerCard;

const StyldPartnerCard = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 20px;
  border: 1px solid var(--mono-m-gray03-stroke, #eee);
  display: flex;
  width: ${(props) => (props.isMobile ? "100%" : "740px")};
  padding: ${(props) => (props.isMobile ? "15px" : "30px")};
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  height: ${(props) => (props.isMobile ? "auto" : "160px")};
  margin-bottom: 15px;
  & .image-box {
    position: relative;
    width: ${(props) => (props.isMobile ? "40%" : "auto")};
    & img {
      border-radius: 50%;
    }
    & .badge {
      position: absolute;
      bottom: 0;
      left: calc(25px / 2);
      padding: 4px 10px;
      gap: 4px;
      width: 75px;
      height: 24px;
      text-align: center;
    }
  }
  & .context-box {
    display: ${(props) => (props.isMobile ? "flex" : "block")};
    flex-direction: ${(props) => (props.isMobile ? "column" : "none")};
    width: ${(props) => (props.isMobile ? "60%" : "auto")};
  }
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
  width: ${(props) => (props.isMobile ? "auto" : "560px")};
  & .title {
    gap: 6px;
    display: ${(props) => (props.isMobile ? "flex" : "inline-flex")};
    align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    & h2 {
      color: var(--mono-m-black01, #333);

      /* Title4 500 */
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 23.9px; /* 132.778% */
      max-width: 100px;
      margin-right: ${(props) => (props.isMobile ? "5px" : "0")};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .badge {
      & img {
        width: 12px;
        height: 12px;
      }
      border-radius: 5px;
      background: var(--sub-u-purple01, #f4ecff);
      display: flex;
      padding: 4px 10px;
      align-items: center;
      gap: 4px;
      color: var(--mono-m-black00, #000);

      /* Caption 400 */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.9px; /* 132.5% */
      &.reference {
        background: var(--primary-p-green01, #cf0);
      }
    }
  }
`;
const StyledContent = styled.div`
  display: flex;
  max-width: ${(props) => (props.isMobile ? "100%" : "560px")};
  flex-direction: column;
  & .simple {
    margin-top: 6px;
    color: var(--mono-m-black01, #333);

    /* Title4 600 */
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.9px; /* 132.778% */
    max-width: 560px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & .badges {
    margin-top: 15px;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%; /* Add a fixed width */
    & .skill {
      border-radius: 10px;
      border: 1px solid var(--mono-m-gray03-stroke, #eee);
      background: var(--mono-m-bg, #f6f6f6);
      display: flex;
      align-items: flex-start;
    }
    &::-webkit-scrollbar {
      height: 0px; /* Set the width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Set the color of the scrollbar thumb */
      border-radius: 6px; /* Set the border radius of the thumb */
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Set the color of the scrollbar track */
    }
  }
`;
