import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const modalSelector = (state) => state.modal;

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      if (state.length === 0) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.top = `0px`;
        document.body.style.width = "100%";
      }

      const { type, contents } = action.payload;
      return state.concat({ type, contents });
    },
    closeModal: (state) => {
      if (state.length === 1) {
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("top");
        document.body.style.removeProperty("width");
      }

      state.pop();
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
