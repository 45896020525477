import React from "react";

const ConfirmModal = ({
  title,
  message,
  confirmText,
  confirmCallback,
  cancelText,
  cancelCallback,
  warnMessage,
  closedBtn = false,
}) => {
  return (
    <div className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con message">
        {closedBtn && (
          <button type="button" className="btn btn-close wh"></button>
        )}
        <div className="text">
          <p className="strong">{title}</p>
          <p>{message}</p>
        </div>
        <div className="btn-wrap">
          {cancelText ? (
            <>
              <button
                onClick={() => {
                  confirmCallback();
                }}
                type="button"
                className="btn btn-half btn-primary"
              >
                {confirmText}
              </button>
              <button
                onClick={() => {
                  cancelCallback();
                }}
                type="button"
                className="btn btn-half btn-grey"
              >
                {cancelText}
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                confirmCallback();
              }}
              type="button"
              className="btn btn-lg btn-primary"
            >
              {confirmText}
            </button>
          )}
        </div>
        {warnMessage && (
          <div className="text mt-30 txt-c--red">{warnMessage}</div>
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
