import api from 'api';

const getUseBToken = (callback) => {
	api.get('/use-b/jwt', { params: { req: process.env.REACT_APP_USEB_REQ } }).then((res) => callback(res.response.jwt))
}

export const getUseBBusinessRegistration = (body, callback) => {
	getUseBToken((useBToken) => {
		api.post('https://api3.useb.co.kr/status-doc/business-registration', body, {
			headers: {
				Authorization: useBToken,
			}
		}).then((res) => { callback(res) })
			.catch(() => {
				console.log(callback('error'))
			})
	})
}