import React, { useEffect, useState } from 'react'
import { userProjectDetailTaskHisroryList } from 'services/projectManagement';
import useModal from 'hooks/useModal';
import { dateFormatType2 } from 'utils/utils';

const ProjectHistoryModal = ({ id }) => {
	const { closeModal } = useModal();
	const [data, setData] = useState([]);

	useEffect(() => {
		userProjectDetailTaskHisroryList(id, (res) => {
			setData(res.response)
		})
	}, [id])
	return (
		<div className="modal show">
			<div className="modal-dim"></div>
			<div className="modal-con lg p-20 history">
				<button type="button" className="btn btn-close" onClick={() => closeModal()}></button>
				<div className="modal-tit">히스토리</div>
				{Boolean(data.length) &&
					<ul className="text-history">
						{data.map((item) => (
							<li className="text-history--item" key={`history-item-${item.SEQ}`}>
								{
									item.WRITER_ROLE === 'H' ?
										<span className="badge badge-h"></span>
										:
										<span className="badge badge-c"></span>
								}
								<div className="text">
									{(item.STATUS === 'SUGGEST' && !item.BEFORE_NAME) && <div className="top">{item.WRITER_NAME}님이 신규 TASK를 요청했어요.</div>}
									{(item.STATUS === 'SUGGEST' && item.BEFORE_NAME) && <div className="top">{item.WRITER_NAME}님이 TASK 수정을 요청했어요.</div>}
									{item.STATUS === 'APPROVAL' && <div className="top">{item.WRITER_NAME}님이 TASK 수정을 수락했어요.</div>}
									{item.STATUS === 'REFUSE' && <div className="top">{item.WRITER_NAME}님이 TASK 수정을 거절했어요.</div>}
									<div className={`row ${item.STATUS === 'REFUSE' ? 'stroke' : ''}`}>
										<label className="label">TASK 이름</label>
										{item.NAME}
									</div>
									<div className={`row ${item.STATUS === 'REFUSE' ? 'stroke' : ''}`}>
										<label className="label">완료 예정일</label>
										{
											item.BEFORE_NAME ?
												<>
													{item.BEFORE_EXPECTED_COMPLETED_AT} &#8594; {item.EXPECTED_COMPLETED_AT}

												</>
												:
												item.EXPECTED_COMPLETED_AT
										}
									</div>
								</div>
								<div className="date">
									업데이트 일시 ㅣ {dateFormatType2(item.CREATED_AT)}
								</div>
							</li>
						))}
						{/* 거절 
						<li className="text-history--item">
							<span className="badge badge-h"></span>
							<div className="text">
								<div className="top">홍길동님이 TASK 수정을 거절했어요.</div>
								<div className="row stroke">
									<label className="label">TASK 이름</label>
									수정되는 TASK의 이름이 노출됨
								</div>
								<div className="row stroke">
									<label className="label">완료 예정일</label>
									YYYY-MM-DD -&gt; YYYY-MM-DD
								</div>
							</div>
							<div className="date">
								업데이트 일시 ㅣ 2023-02-21 09:30
							</div>
						</li> */}
					</ul>}
			</div>
		</div>
	)
}

export default ProjectHistoryModal