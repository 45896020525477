import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  aiStoryDetail,
  storyReaction,
  storyReactionApply,
} from "services/topic";
import { dateFormatType2NoTime } from "utils/utils";
import { Editor } from "@tinymce/tinymce-react";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import MetaTag from "MetaTag";
import CannonicalTag from "Cannonical";
const AiStoryDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoggedin } = useAuth();
  const { openModal } = useModal();
  const [data, setData] = useState(null);
  const [dataBottomList, setDataBottomList] = useState([]);
  const [reaction, setReaction] = useState(null);

  useEffect(() => {
    if (!Number.isInteger(Number(id))) {
      navigate("/community");
      return;
    }

    aiStoryDetail(id, (res) => {
      setData(res.response.now);
      setDataBottomList(res.response.list);
      storyReaction(id, (res) => {
        setReaction(res.response);
      });
    });
  }, [id]);

  const categoryName = (category) => {
    switch (category) {
      case "INTERVIEW":
        return "인터뷰";
      case "TREND":
        return "트렌드";
      case "VIDEO":
        return "동영상";
      case "IMAGE":
        return "이미지";
      case "SOUND":
        return "음성";
      case "TEXT":
        return "텍스트";
      case "BIG_DATA":
        return "빅데이터";
      case "ETC":
        return "기타";
      default:
        return "";
    }
  };

  const reactionToggle = (type) => {
    if (!isLoggedin) {
      openModal({ type: "loginModal" });
      return;
    }
    const params = {
      seq: id,
      type,
    };

    storyReactionApply(id, params, (res) => {
      if (res.code !== 0) return;
      aiStoryDetail(id, (res) => {
        setData(res.response.now);
        setDataBottomList(res.response.list);
        storyReaction(id, (res) => {
          setReaction(res.response);
        });
      });
    });
  };
  return (
    <>
      <MetaTag
        title={data?.TITLE ? data.TITLE : "AI 블로그 | AI 히어로즈"}
        desc={data?.DESCRIPTION ? data.DESCRIPTION : ""}
        imgSrc={data?.BANNER ? data.BANNER : ""}
        robot={true}
      />
      {data && (
        <>
          <CannonicalTag
            title={data.TITLE}
            desc={data.DESCRIPTION}
            imgSrc={data.BANNER}
          />
          <button
            type="button"
            className="btn top-back mb-0 mt-13"
            onClick={() => navigate("/community")}
          >
            다른 글 살펴보기
          </button>
          <article
            className="edit-box web-basic tbArticle"
            style={{ padding: "0 2px" }}
          >
            <Editor
              disabled={true}
              toolbar={false}
              inline={true}
              initialValue={data.CONTENTS}
              init={{
                height: 500,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                automatic_uploads: true,
                file_browser_callback_types: "image",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image") {
                    let input = document.getElementById("my-file");
                    if (!input) return;
                    input.click();
                    input.onchange = function () {
                      let file = input?.files[0];
                      let reader = new FileReader();
                      reader.onload = function (e) {
                        callback(e.target.result, {
                          alt: file.name,
                        });
                      };
                      reader.readAsDataURL(file);
                    };
                  }
                },
              }}
              apiKey={process.env.REACT_APP_TINYMCE_API_KET}
              cloudChannel="5-testing"
            />
          </article>
          <div className="community-story--bottom">
            <div className="left">
              <button
                className={`btn heart ${
                  reaction?.reaction?.HEART ? "active" : ""
                }`}
                onClick={() => {
                  reactionToggle("HEART");
                }}
              >
                {reaction?.count.HEART || 0}
              </button>
              <button
                className={`btn like ${
                  reaction?.reaction?.GREAT ? "active" : ""
                }`}
                onClick={() => {
                  reactionToggle("GREAT");
                }}
              >
                {reaction?.count.GREAT || 0}
              </button>
              <button
                className={`btn pro ${
                  reaction?.reaction?.PROFESSIONAL ? "active" : ""
                }`}
                onClick={() => {
                  reactionToggle("PROFESSIONAL");
                }}
              >
                {reaction?.count.PROFESSIONAL || 0}
              </button>
            </div>
            <div className="right date">
              {dateFormatType2NoTime(data.CREATED_AT)}
            </div>
          </div>
          <div className="community-other">
            <div className="tit">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  navigate("/community");
                }}
              >
                AI 블로그
              </button>
              <span style={{ margin: "0 6px" }}>/</span>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  navigate(`/community?tab=${data.CATEGORY}`);
                }}
              >
                {categoryName(data.CATEGORY)}
              </button>{" "}
              카테고리의 다른 글
            </div>
            <ul className="list">
              {Boolean(dataBottomList.length) &&
                dataBottomList.map((item) => (
                  <li
                    className="list-item"
                    key={`bottom-list-item-${item.SEQ}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/community/${item.SEQ}`);
                    }}
                  >
                    <button type="button" className="btn tit">
                      {item.TITLE}
                    </button>
                    <span className="date">
                      {dateFormatType2NoTime(item.CREATED_AT)}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default AiStoryDetail;
