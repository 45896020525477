import React from "react";
import Input from "components/Input/Input";
import styled from "styled-components";
import useViewCheck from "hooks/useViewCheck";
import CheckIcon from "assets/images/icon/check_green.svg";
const CompanyProfile = ({
  numCheckDone,
  numError,
  businessNum,
  businessFile,
  businessNumCheck,
  handleBusinessNum,
  handleBusNumCheck,
  handleBusinessFile,
  fileNameFormat,
}) => {
  const { isMobile } = useViewCheck();
  return (
    <InlineFlex>
      <div className={isMobile ? "mobile " : "default"}>
        <div className={"input-num"}>
          <div>
            <div className="btn-exist">
              {numCheckDone && <img className="check-icon" src={CheckIcon} />}
              <Input
                type="text"
                className={`input-text ${numError ? "error" : ""} bus-num`}
                style={numCheckDone && !isMobile ? { paddingLeft: "38px" } : {}}
                placeholder="‘-’제외 사업자번호 입력"
                value={businessNum}
                onChange={(e) => handleBusinessNum(e.target.value)}
                maxLength={10}
                readOnly={numCheckDone}
              />
              {!numCheckDone ? (
                <button
                  type="button"
                  className="btn btn-black type2"
                  disabled={!(!numError && businessNum?.length >= 10)}
                  onClick={() => businessNumCheck()}
                >
                  인증 요청
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-black type2"
                  onClick={() => handleBusNumCheck(false)}
                >
                  재인증
                </button>
              )}
            </div>
            {numError && (
              <p className="input-guide error">올바른 사업자번호가 아니에요.</p>
            )}
          </div>
        </div>
        <label className="input-file">
          <input
            type="file"
            onChange={(e) => {
              handleBusinessFile(e);
              e.target.value = "";
            }}
          />
          <span
            className={`input-text ${
              businessFile.file && businessFile.file ? "correct" : ""
            }`}
          >
            {businessFile.file ? (
              fileNameFormat(businessFile.fileName)
            ) : (
              <>
                사업자등록증 첨부
                <span className="txt-c--grey">
                  {isMobile
                    ? "(jpg, png, pdf 최대 5MB 첨부)"
                    : "(jpg, png, pdf 최대 5MB 첨부 가능)"}
                </span>
              </>
            )}
          </span>
        </label>
      </div>
    </InlineFlex>
  );
};

export default CompanyProfile;

const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  & .input-num {
    width: calc(50% - 9px);
    margin-right: 9px;
  }
  & .input-file {
    width: 50%;
    justify-content: flex-start;
  }
  & .btn-exist {
    position: relative;
    & button {
      height: 31px;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 10px;
      border-radius: 5px;
    }
    img.check-icon {
      height: 34px;
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
  & .default {
    width: 100%;
    display: inline-flex;

    & .input-text:read-only,
    .input-text:disabled {
      padding: 0 18px;
      /* padding-left: 40px; */
    }
  }
  & .mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    & .input-num,
    .input-file {
      width: 100%;
      &:first-child {
        margin-bottom: 6px;
      }
    }
    & .input-file {
      text-align: center;
    }
    & .bus-num:read-only {
      text-indent: 30px;
    }
  }
`;
