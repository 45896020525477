export const GRADE_TYPE = ["", "IRON", "BRONZE", "SILVER", "GOLD", "DIAMOND"];

export const aiIndustryData = [
  {
    id: "education",
    name: "교육",
  },
  {
    id: "service",
    name: "서비스",
  },
  {
    id: "manufacturing",
    name: "제조, 화학, 에너지",
  },
  {
    id: "vehicle",
    name: "자동차",
  },
  {
    id: "sales",
    name: "판매, 유통",
  },
  {
    id: "media",
    name: "미디어, 엔터테인먼트",
  },
  {
    id: "tour",
    name: "관광, 스포츠, 레저",
  },
  {
    id: "construct",
    name: "부동산, 건설, 건축",
  },
  {
    id: "finance",
    name: "금융",
  },
  {
    id: "medical",
    name: "의료, 제약, 바이오, 헬스케어",
  },
  {
    id: "it",
    name: "IT, 통신",
  },
  {
    id: "government",
    name: "정부, 기관",
  },
  {
    id: "food",
    name: "식품",
  },
  {
    id: "agriculture",
    name: "농업",
  },
  {
    id: "game",
    name: "게임",
  },
  {
    id: "etc",
    name: "기타",
  },
];

export const aiSkillData = [
  {
    id: "video",
    name: "동영상",
  },
  {
    id: "image",
    name: "이미지",
  },
  {
    id: "sound",
    name: "음성",
  },
  {
    id: "text",
    name: "텍스트",
  },
  {
    id: "bigData",
    name: "빅데이터",
  },
  {
    id: "fusion",
    name: "융합",
  },
  {
    id: "etc",
    name: "기타",
  },
];

export const interestedAiTaskData = [
  { id: "chatGpt", name: "Chat GPT" },
  { id: "generativeAi", name: "생성형 AI" },
  {
    id: "machineLearning",
    name: "Machine Learning",
  },
  {
    id: "deepLearning",
    name: "Deep Learning",
  },
  {
    id: "classification",
    name: "Classification",
  },
  {
    id: "convolution",
    name: "Convolution",
  },
  {
    id: "anomalyDetection",
    name: "Anomaly Detection",
  },
  {
    id: "objectDetection",
    name: "Object Detection",
  },
  {
    id: "segmentation",
    name: "Segmentation",
  },
  {
    id: "deepFake",
    name: "Deep Fake",
  },
  {
    id: "multiTask",
    name: "Multi-task",
  },
  {
    id: "cnn",
    name: "CNN",
  },
  {
    id: "rnn",
    name: "RNN",
  },
  {
    id: "cam",
    name: "CAM",
  },
  {
    id: "ocr",
    name: "OCR",
  },

  {
    id: "stt",
    name: "STT",
  },
  {
    id: "tts",
    name: "TTS",
  },
  {
    id: "nlp",
    name: "NLP",
  },
  {
    id: "gan",
    name: "GAN",
  },
];

export const frameworkData = [
  {
    id: "r",
    name: "R",
  },
  {
    id: "python",
    name: "Python",
  },
  {
    id: "c",
    name: "C",
  },
  {
    id: "cpp",
    name: "C++",
  },
  {
    id: "aos",
    name: "AOS",
  },
  {
    id: "ios",
    name: "iOS",
  },
  {
    id: "tensorflow",
    name: "Tensorflow",
  },
  {
    id: "keras",
    name: "Keras",
  },
  {
    id: "caffe",
    name: "Caffe",
  },
  {
    id: "cntk",
    name: "CNTK",
  },
  {
    id: "pytorch",
    name: "PyTorch",
  },
  {
    id: "david",
    name: "David",
  },
  {
    id: "webPpl",
    name: "WebPPL",
  },
  {
    id: "church",
    name: "Church",
  },
  {
    id: "figaro",
    name: "Figaro",
  },
  {
    id: "openaiGym",
    name: "OpenAI Gym",
  },
  {
    id: "pyro",
    name: "Pyro",
  },
  {
    id: "tensorForce",
    name: "TensorForce",
  },
  {
    id: "rlib",
    name: "Rlib",
  },
];

export const ALARM_CASE_LIST = [
  {
    TYPE: "PROJECT_APPLY",
    NAME: "프로젝트 등록완료 안내",
    URL: "/mypage/aiprojectc/",
  },
  {
    TYPE: "PROJECT_DENY",
    NAME: "프로젝트 등록반려 안내",
    URL: "/mypage/aiprojectc/",
  },
  {
    TYPE: "PROJECT_APPLICANT",
    NAME: "파트너 지원 안내",
    URL: "/mypage/aiprojectc/",
  },
  {
    TYPE: "PROJECT_OFFER",
    NAME: "클라이언트 제안 안내",
    URL: "/mypage/aiprojecth/",
  },
  {
    TYPE: "PROJECT_APPLICANT_REFUSE",
    NAME: "프로젝트 지원거절 안내",
    URL: "/mypage/aiprojecth/", //+seq
  },
  {
    TYPE: "PROJECT_MEETING",
    NAME: "미팅 진행 안내",
    URL: "/mypage/aiprojecth/", //+seq
  },
  {
    TYPE: "PROJECT_CONTRACT",
    NAME: "계약 진행 안내",
    URL: "/mypage/aiprojecth/", //+seq
  },
  {
    TYPE: "PROJECT_INFERENCE",
    NAME: "인퍼런스 코드 공유 안내",
    URL: "/mypage/aiprojectc/", // +seq?tab=MODEL
    TAB: "?tab=MODEL",
  },
  {
    TYPE: "PROJECT_AI_VERIFICATION_CLIENT",
    NAME: "AI 모델 검증 결과 안내",
    URL: "/mypage/aiprojectc/", // +seq?tab=MODEL
    TAB: "?tab=MODEL",
  },
  {
    TYPE: "PROJECT_AI_VERIFICATION_HEROES",
    NAME: "AI 모델 검증 결과 안내",
    URL: "/mypage/aiprojecth/", // +seq?tab=MODEL
    TAB: "?tab=MODEL",
  },
  {
    TYPE: "PROJECT_TASK_COMPLETE",
    NAME: "파트너 TASK 완료 안내",
    URL: "/mypage/aiprojectc/", //+seq?tab=PROGRESS
    TAB: "?tab=PROGRESS",
  },
  {
    TYPE: "PROJECT_TASK_MODIFY_REQUEST",
    NAME: "TASK 수정요청 안내",
    URL: "/mypage/aiprojecth/", //+seq?tab=PROGRESS
    TAB: "?tab=PROGRESS",
  },
  {
    TYPE: "PROJECT_COMPLETE",
    NAME: "프로젝트 최종 완료 안내",
    URL: "/mypage/aiprojecth/",
  },
  {
    TYPE: "HEROES_REVIEW",
    NAME: "리뷰 등록 안내",
    URL: "/heroes/", //+userId
  },
  {
    TYPE: "TAX_RECEIPT",
    NAME: "정산완료 안내",
    URL: "/mypage/revenue/",
  },
  {
    TYPE: "CALCULATE_DONE",
    NAME: "정산완료 안내",
    URL: "/mypage/revenue/",
  },
  {
    TYPE: "PROJECT_Q",
    NAME: "프로젝트 관련 QnA",
    URL: "/aiproject/", //+seq
  },
  {
    TYPE: "PROJECT_A",
    NAME: "프로젝트 관련 QnA",
    URL: "/aiproject/", //+seq
  },
  {
    TYPE: "PROJECT_MSG_CLIENT",
    NAME: "프로젝트 메시지",
    URL: "/mypage/aiprojectc/", //+seq?tabV2=MESSAGE
    TAB: "?tabV2=MESSAGE",
  },
  {
    TYPE: "PROJECT_MSG_HEROES",
    NAME: "프로젝트 메시지",
    URL: "/mypage/aiprojecth/", //+seq?tabV2=MESSAGE
    TAB: "?tabV2=MESSAGE",
  },
  {
    TYPE: "TERMS_REVISIO",
    NAME: "이용약관 변경 전 안내",
    URL: "/help/notice/",
  },
];

export const capArr = [
  { id: "UNDER_10", name: "0 ~ 10명 미만" },
  { id: "UNDER_50", name: "10 ~ 50명 미만" },
  { id: "UNDER_100", name: "50 ~ 100명 미만" },
  { id: "UNDER_200", name: "100 ~ 200명 미만" },
  { id: "UNDER_500", name: "200 ~ 500명 미만" },
  { id: "OVER_1000", name: "1000명 이상" },
];
export const workTypeArr = [
  {
    id: "DEFAULT",
    name: "선호하는 형태를 선택해 주세요",
  },
  {
    id: "BOTH",
    name: "상주, 원격 모두 가능",
  },
  {
    id: "RESIDENT",
    name: "상주 근무",
  },
  {
    id: "REMOTE",
    name: "원격 근무",
  },
];
export const localArr = [
  { id: 1, name: "서울", child: ["강남", "강북"] },
  { id: 2, name: "서울", child: ["강남", "강북"] },
];

export const guide_bus = `ex) 안녕하세요, AI 솔루션 전문 업체입니다.
AI ○○○ 모델 맞춤 개발을 진행하고 있으며 현재는 다양한 기업들을 대상으로 프로젝트 개발을 수행하고 있습니다.
프로젝트 진행에 앞서 요구사항을 명확히 정의한 후 착수하고, 프로젝트가 진행된 이후에도
클라이언트가 진행 상황에 대해 명확히 알 수 있도록 보다 적극적인 커뮤니케이션을 진행하고 있습니다.
문의 주시면, 자세한 상담과 가이드라인을 제공해 드리겠습니다.
감사합니다.`;
export const guide_indi = `ex) 안녕하세요, Chat GPT 모델 개발 경험이 있는 프리랜서입니다.
AI ○○○ 모델 맞춤 개발을 진행하고 있으며 현재는 다양한 기업들을 대상으로 프로젝트 개발을 수행하고 있습니다.
프로젝트 진행에 앞서 요구사항을 명확히 정의한 후 착수하고, 프로젝트가 진행된 이후에도 
클라이언트가 진행 상황에 대해 명확히 알 수 있도록 보다 적극적인 커뮤니케이션을 진행하고 있습니다.
문의 주시면, 자세한 상담과 가이드라인을 제공해 드리겠습니다.
감사합니다.`;

export const businessGuideText = [
  "귀사는 ~의 가치를 실현 할 수 있는 ~형식의 솔루션을 제공합니다.",
  "AI 전문가 집단으로서, 다양한 프로젝트 경험을 보유하고 있습니다.",
  "다양한 업종 및 도메인에서 프로젝트 수행 경험을 보유하고있습니다.",
  "AI 모델을 활용하여 비즈니스 과제를 이해하고 해결하는 능력을 지녔습니다.",
  "다양한 경험 레퍼런스를 보유하고 있어, 구체적인 컨설팅이 가능합니다.",
];
export const indivisualGuideText = [
  "귀사는 ~의 가치를 실현 할 수 있는 ~형식의 솔루션을 제공해요.",
  "AI 전문가 집단으로서, 다양한 프로젝트 경험을 보유하고 있어요.",
  "다양한 업종 및 도메인에서 프로젝트 수행 경험을 보유하고있어요.",
  "AI 모델을 활용하여 비즈니스 과제를 이해하고 해결하는 능력을 지녔어요.",
  "다양한 경험 레퍼런스를 보유하고 있어, 구체적인 컨설팅이 가능해요.",
];

export const formFields = [
  "desiredSalary",
  "career",
  "workMethod",
  "projectType",
  "introduceSimple",
  "introduceDetails",
  "file",
  "fileName",
];
export const careerFields = [
  "companyName",
  "department",
  "role",
  "performance",
  "startAt.yyyy",
  "endAt.yyyy",
  "startAt.MM",
  "endAt.MM",
];
export const eduFields = [
  "schoolName",
  "major",
  "degree",
  "performance",
  "startAt.yyyy",
  "endAt.yyyy",
  "startAt.MM",
  "endAt.MM",
];
export const certificateFields = ["name", "certificationAuthority", "issuedAt"];

export const clientTypeText = [
  "법인 및 개인사업자가 있는 프리랜서일 경우 ‘기업(사업자)’을 선택해 주세요.",
  "‘기업(사업자)’ 선택 시 인증 절차가 필수로 진행돼요.",
  "사업자가 없는 프리랜서일 경우 ‘개인’을 선택해 주세요.",
];
export const localInfo = [
  {
    city: "서울특별시",
    details: [
      "강남구",
      "강동구",
      "강북구",
      "강서구",
      "관악구",
      "광진구",
      "구로구",
      "금천구",
      "노원구",
      "도봉구",
      "동대문구",
      "동작구",
      "마포구",
      "서대문구",
      "서초구",
      "성동구",
      "성북구",
      "송파구",
      "양천구",
      "영등포구",
      "용산구",
      "은평구",
      "종로구",
      "중구",
      "중랑구",
    ],
  },
  {
    city: "부산광역시",
    details: [
      "강서구",
      "금정구",
      "기장군",
      "남구",
      "동구",
      "동래구",
      "부산진구",
      "북구",
      "사상구",
      "사하구",
      "서구",
      "수영구",
      "연제구",
      "영도구",
      "중구",
      "해운대구",
    ],
  },
  {
    city: "대구광역시",
    details: [
      "남구",
      "달서구",
      "달성군",
      "동구",
      "북구",
      "서구",
      "수성구",
      "중구",
    ],
  },
  {
    city: "인천광역시",
    details: [
      "계양구",
      "남구",
      "남동구",
      "동구",
      "부평구",
      "서구",
      "연수구",
      "중구",
    ],
  },
  {
    city: "광주광역시",
    details: ["광산구", "남구", "동구", "북구", "서구"],
  },
  {
    city: "대전광역시",
    details: ["대덕구", "동구", "서구", "유성구", "중구"],
  },
  {
    city: "울산광역시",
    details: ["남구", "동구", "북구", "울주군", "중구"],
  },
  {
    city: "세종특별자치시",
    details: ["세종시"],
  },
  {
    city: "강원도",
    details: [
      "강릉시",
      "고성군",
      "동해시",
      "삼척시",
      "속초시",
      "양구군",
      "양양군",
      "영월군",
      "원주시",
      "인제군",
      "정선군",
      "철원군",
      "춘천시",
      "태백시",
      "평창군",
      "홍천군",
      "화천군",
      "횡성군",
    ],
  },
  {
    city: "경기도",
    details: [
      "가평군",
      "고양시 덕양구",
      "고양시 일산동구",
      "고양시 일산서구",
      "과천시",
      "광명시",
      "광주시",
      "구리시",
      "군포시",
      "김포시",
      "남양주시",
      "동두천시",
      "부천시 소사구",
      "부천시 오정구",
      "부천시 원미구",
      "성남시 분당구",
      "성남시 수정구",
      "성남시 중원구",
      "수원시 권선구",
      "수원시 영통구",
      "수원시 장안구",
      "수원시 팔달구",
      "시흥시",
      "안산시 단원구",
      "안산시 상록구",
      "안성시",
      "안양시 동안구",
      "안양시 만안구",
      "양주시",
      "양평군",
      "여주시",
      "연천군",
      "오산시",
      "용인시 기흥구",
      "용인시 수지구",
      "용인시 처인구",
      "의왕시",
      "의정부시",
      "이천시",
      "파주시",
      "평택시",
      "포천시",
      "하남시",
      "화성시",
    ],
  },
  {
    city: "충청북도",
    details: [
      "괴산군",
      "단양군",
      "보은군",
      "영동군",
      "옥천군",
      "음성군",
      "제천시",
      "증평군",
      "진천군",
      "청원구, 청주시 상당구",
      "청주시 흥덕구",
      "충주시",
      "청원구, 청주시 서원구",
    ],
  },
  {
    city: "충청남도",
    details: [
      "계룡시",
      "공주시",
      "금산군",
      "논산시",
      "당진시",
      "보령시",
      "부여군",
      "서산시",
      "서천군",
      "아산시",
      "예산군",
      "천안시 동남구",
      "천안시 서북구",
      "청양군",
      "태안군",
      "홍성군",
    ],
  },
  {
    city: "전라북도",
    details: [
      "고창군",
      "군산시",
      "김제시",
      "남원시",
      "무주군",
      "부안군",
      "순창군",
      "완주군",
      "익산시",
      "임실군",
      "장수군",
      "전주시 덕진구",
      "전주시 완산구",
      "정읍시",
      "진안군",
    ],
  },
  {
    city: "전라남도",
    details: [
      "강진군",
      "고흥군",
      "곡성군",
      "광양시",
      "구례군",
      "나주시",
      "담양군",
      "목포시",
      "무안군",
      "보성군",
      "순천시",
      "신안군",
      "여수시",
      "영광군",
      "영암군",
      "완도군",
      "장성군",
      "장흥군",
      "진도군",
      "함평군",
      "해남군",
      "화순군",
      "흑산도",
    ],
  },
  {
    city: "경상북도",
    details: [
      "경산시",
      "경주시",
      "고령군",
      "구미시",
      "군위군",
      "김천시",
      "문경시",
      "봉화군",
      "상주시",
      "성주군",
      "안동시",
      "영덕군",
      "영양군",
      "영주시",
      "영천시",
      "예천군",
      "울릉군",
      "울진군",
      "의성군",
      "청도군",
      "청송군",
      "칠곡군",
      "포항시 남구",
      "포항시 북구",
    ],
  },
  {
    city: "경상남도",
    details: [
      "거제시",
      "거창군",
      "고성군",
      "김해시",
      "남해군",
      "마산합포구, 창원시 마산합포구",
      "마산회원구, 창원시 마산회원구",
      "서산구, 창원시 서산구",
      "성산구, 창원시 성산구",
      "의창구, 창원시 의창구",
      "진해구, 창원시 진해구",
      "창녕군",
      "창원시",
      "통영시",
      "하동군",
      "함안군",
      "함양군",
      "합천군",
    ],
  },
  {
    city: "제주특별자치도",
    details: ["서귀포시", "제주시"],
  },
];
