import LoadingSpinner from 'components/common/LoadingSpinner';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userPasswordResetChange } from 'services/user';
import { getParameterByName } from 'utils/utils';

const EmailAuth = () => {
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const key = getParameterByName(location.search, 'key')

		//TODO 추후 만료키 체크 필요하면 수정
		navigate('/idinquiry/changepassword', { state: { key } })
	}, [location])
	return (
		<div className='container'>
			<LoadingSpinner />
		</div>
	)
}

export default EmailAuth