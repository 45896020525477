import React from "react";
import styled from "styled-components";
const BadgeWrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

export default BadgeWrapper;

const StyledWrapper = styled.div`
  display: flex;
  padding: 15px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  & p {
    color: var(--mono-m-gray-01, #999);

    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.6px; /* 132.857% */
    margin-bottom: 6px;
  }
`;
