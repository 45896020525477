import React, { useCallback, useEffect, useState } from 'react';
import useModal from 'hooks/useModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { userPasswordResetMailSend } from 'services/user';
import { dateFormatType2NoTime } from 'utils/utils';

const AlreadySignedup = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { openModal, closeModal } = useModal();
	const [email, setEmail] = useState('');
	const [DI, setDI] = useState('');

	useEffect(() => {
		// if (!state) return;
		if (state.username) {
			setEmail(state.username);
			setDI(state.DI)
		}
	}, [state])

	const findPassword = useCallback(() => {
		userPasswordResetMailSend(DI, (res) => {
			if (res.code === 0) {
				openModal({
					type: 'messageModal',
					contents: {
						title: '안내 이메일 발송',
						message: '이메일이 오지 않는다면, 가입된 이메일 계정이 맞는지 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.',
						confirmText: '확인',
						confirmCallback: () => {
							closeModal();
							navigate('/')
						},
					}
				});
			} else {
				openModal({
					type: 'messageModal',
					contents: {
						title: '요청에 실패했습니다',
						message: '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요',
						confirmText: '확인',
						confirmCallback: () => {
							closeModal();
						},
					}
				});
			}
		})
	}, [DI])

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<div className="section-tit lg">가입된 이메일 계정이에요.</div>
					<label className="input-label sm">이메일</label>
					<div className={`sns-exist ${state.socialType}`}>
						<input type="text" className="input-text" placeholder="이름을 입력해 주세요." value={email} disabled />
					</div>
					<p className="input-guide">마지막 접속일: {dateFormatType2NoTime(state.lastLoginAt)}</p>
					<div className="col-2">
						<button type="button" className={`btn ${state.socialType === 'DEFAULT' ? 'btn-half' : 'btn-full'} btn-black type2 mt-30`} onClick={() => {
							openModal({ type: 'loginModal', contents: { forceEmail: email } })
							navigate('/')
						}
						}>로그인하기</button>
						{state.socialType === 'DEFAULT' &&
							<button onClick={() => {
								findPassword()
							}}
								type="button"
								className="btn btn-half btn-black type2 mt-30"
							>
								비밀번호 찾기
							</button>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AlreadySignedup