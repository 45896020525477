import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "styles/reset.scss";
import "styles/common.scss";
import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (rootElement.hasChildNodes()) {
  root.hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}
