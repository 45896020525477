import useViewCheck from "hooks/useViewCheck";
import React from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();

  return (
    <div className="container ">
      <div className="section register">
        <div className="register-top">
          <div className="tit">
            파트너 등록
            {isMobile && (
              <button
                type="button"
                className="btn btn-close"
                onClick={() => {
                  navigate(-1);
                }}
              ></button>
            )}
          </div>
          회원 유형을 선택해 주세요.
        </div>
        <div className="register-btn">
          <button
            onClick={() => {
              navigate("/mypage/profile", { state: { register: "business" } });
            }}
            type="button"
            className="btn"
          >
            <div className="tit">기업 파트너</div>
            법인 및 개인사업자가 있는 프리랜서일 경우
            <br /> 선택해 주세요.
          </button>
          <button
            onClick={() => {
              navigate("/mypage/profile", {
                state: { register: "individual" },
              });
            }}
            type="button"
            className="btn"
          >
            <div className="tit">개인 파트너</div>
            사업자가 없는 프리랜서일 경우
            <br /> 선택해 주세요.
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
