import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  authUserAccountCheck,
  authUserNameCheck,
  calculateInfoSave,
  calculateInfoView,
  calculateListView,
  calculateTopView,
  getExcelCalculate,
} from "services/revenue";
import { getUseBBusinessRegistration } from "services/useb";

import { authBusinessInfo, authProfile, kmcTrCert } from "services/user";
import { emailRFC5322RegEx, juminNumRegEx } from "utils/regex";
import { birthdayFormat, comma, getBirthday, uncomma } from "utils/utils";
import LoadingSpinner from "components/common/LoadingSpinner";
import Select from "components/common/Select";
import useModal from "hooks/useModal";
import Pagination from "components/common/Pagination";
import { dateFormat } from "utils/utils";
import useViewCheck from "hooks/useViewCheck";

const StyledWrapper = styled.div`
  .input-wrap {
    height: 104px;
  }
`;
const StyledBtnWrapper = styled.div`
  @media (max-width: 660px) {
    & button {
      width: 100%;
    }
    & button:last-child {
      margin-top: 6px;
    }
  }
`;
const currentYear = new Date().getFullYear();
const yearList = [];

for (let i = 0; i < 3; i++) {
  yearList.push({
    id: currentYear - i,
    name: currentYear - i + "년",
  });
}
const bnkCodeList = [
  { id: "002", name: "산업은행" },
  { id: "003", name: "기업은행" },
  { id: "004", name: "국민은행" },
  { id: "007", name: "수협은행/수협중앙회" },
  { id: "011", name: "농협은행" },
  { id: "012", name: "농협중앙회" },
  { id: "020", name: "우리은행" },
  { id: "023", name: "SC제일은행" },
  { id: "027", name: "한국씨티은행" },
  { id: "031", name: "대구은행" },
  { id: "032", name: "부산은행" },
  { id: "034", name: "광주은행" },
  { id: "035", name: "제주은행" },
  { id: "037", name: "전북은행" },
  { id: "039", name: "경남은행" },
  { id: "045", name: "새마을금고중앙회" },
  { id: "048", name: "신협중앙회" },
  { id: "050", name: "상호저축은행" },
  { id: "054", name: "HSBC은행" },
  { id: "055", name: "도이치은행" },
  { id: "057", name: "제이피모간체이스은행" },
  { id: "060", name: "BOA은행" },
  { id: "062", name: "중국공상은행" },
  { id: "064", name: "산림조합중앙회" },
  { id: "071", name: "우체국" },
  { id: "081", name: "KEB하나은행" },
  { id: "088", name: "신한은행" },
  { id: "089", name: "K뱅크" },
  { id: "090", name: "카카오뱅크" },
  { id: "209", name: "유안타증권" },
  { id: "218", name: "KB증권" },
  { id: "238", name: "미래에셋대우" },
  { id: "240", name: "삼성증권" },
  { id: "243", name: "한국투자증권" },
  { id: "247", name: "NH투자증권" },
  { id: "261", name: "교보증권" },
  { id: "262", name: "하이투자증권" },
  { id: "263", name: "현대차투자증권" },
  { id: "264", name: "키움증권" },
  { id: "265", name: "이베스트투자증권" },
  { id: "266", name: "SK증권" },
  { id: "267", name: "대신증권" },
  { id: "269", name: "한화투자증권" },
  { id: "270", name: "하나금융투자" },
  { id: "278", name: "신한금융투자" },
  { id: "279", name: "동부증권" },
  { id: "280", name: "유진투자증권" },
  { id: "287", name: "메리츠종합금융증권" },
  { id: "290", name: "부국증권" },
  { id: "291", name: "신영증권" },
  { id: "292", name: "케이프투자증권" },
  { id: "103", name: "SBI 저축은행" },
];

const Revenue = () => {
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const [isLoading, setLoading] = useState(true);
  const [revenueUserInfoSaved, setRevenueUserInfoSaved] = useState(false);
  const [revenueTopInfo, setRevenueTopInfo] = useState(null);
  const [revenueInfoList, setRevenueInfoList] = useState([]);
  const [revenueInfoListSort, setRevenueInfoListSort] = useState([]);
  const [isInfoInputOn, setInfoInputOn] = useState(false);
  const [userRevenueInfoType, setUserRevenueInfoType] = useState("personal");
  const [userBusinessInfo, setUserBusinessInfo] = useState({});
  // const [isAccountNumberInputOn, setAccountNumberInputOn] = useState(false);
  const [isAccountError, setAccountError] = useState(false);
  const [bankCode, setBankCode] = useState(null);
  const [accountNo, setAccountNo] = useState("");
  const [emailError, setEmailError] = useState(false);
  // const [isUserNameCheckInputOn, setUserNameCheckInputOn] = useState(false);
  const [userNameCheckNumFront, setUserNameCheckNumFront] = useState(null);
  const [userNameCheckNumBack, setUserNameCheckNumBack] = useState(null);
  const [userNameCheckNumError, setUserNameCheckNumError] = useState({
    error: false,
    msg: "",
  });
  const [formDone, setFormDone] = useState(false);
  const [sortType, setSortType] = useState("quarter"); // quarter, direct
  const [quarterY, setQuarterY] = useState(currentYear);
  const [quarter, setQuarter] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);

  const [orderName, setOrderName] = useState("");
  const [statusOrder, setStatusOrder] = useState(null);
  const [nameOrder, setNameOrder] = useState(null);
  const [finalOrder, setFinalOrder] = useState(null);
  const [payMethodOrder, setPayMethodOrder] = useState(null);

  const [userInfoForm, setUserInfoForm] = useState({
    name: "",
    phone: "",
    email: "",
    birthday: "",
    bank: "",
    accountNumber: "",
    identificationNo: null,
  });
  const [prevUserInfoForm, setPrevUserInfoForm] = useState({
    name: "",
    phone: "",
    email: "",
    birthday: "",
    bank: "",
    accountNumber: "",
    identificationNo: null,
  });

  const [businessNum, setBusinessNum] = useState("");
  const [businessNumCheckDone, setBusinessNumCheckDone] = useState(false);
  const [businessNumError, setBusinessNumError] = useState(false);

  const reqKMCISFormRef = useRef();
  const [trCert, setTrCert] = useState("");
  const [userBusInfo, setBusInfo] = useState();
  const trUrl =
    process.env.REACT_APP_API_HOST + process.env.REACT_APP_KMC_REDIRECT_URI;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (userRevenueInfoType) {
      authBusinessInfo((res) => {
        setBusInfo(res.response);
        setUserBusinessInfo(res.response);
      });
    }
  }, [userRevenueInfoType]);
  useEffect(() => {
    if (userBusInfo && userBusInfo.ROLE.includes("PERSONAL")) {
      setUserRevenueInfoType("personal");
    } else {
      setUserRevenueInfoType("business");
    }
  }, [userBusInfo]);
  useEffect(() => {
    getDataList();
  }, [page, sortType, start, end, quarterY, quarter]);

  useEffect(() => {
    if (
      userInfoForm.phone &&
      userInfoForm.name &&
      userInfoForm.email &&
      userInfoForm.bank &&
      userInfoForm.accountNumber &&
      userInfoForm.identificationNo
    ) {
      if (userRevenueInfoType === "personal") {
        if (userInfoForm.birthday) {
          setFormDone(true);
          return;
        }
        if (userInfoForm.identificationNo.length === 0) {
          setFormDone(true);
        }
      } else {
        if (
          userBusinessInfo.IDENTIFICATION_NO ||
          userInfoForm.identificationNo
        ) {
          setBusinessNumCheckDone(true);
          setFormDone(true);
          return;
        }
      }
    }
    setFormDone(false);
    if (
      userInfoForm.identificationNo === null ||
      userInfoForm.identificationNo === undefined ||
      userInfoForm.accountNumber.length !== 0
    ) {
      setFormDone(true);
    }
  }, [userInfoForm]);

  useEffect(() => {
    if (sortType !== "quarter") return;
    const today = new Date();
    let currentMonth = today.getMonth() + 1;
    let currentQuarter = Math.ceil(currentMonth / 3);
    setQuarter(currentQuarter);
    switch (currentQuarter) {
      case 1:
        setStart(`${quarterY}-01-01`);
        setEnd(`${quarterY}-03-31`);

        break;
      case 2:
        setStart(`${quarterY}-04-01`);
        setEnd(`${quarterY}-06-30`);

        break;
      case 3:
        setStart(`${quarterY}-07-01`);
        setEnd(`${quarterY}-09-30`);

        break;
      case 4:
        setStart(`${quarterY}-10-01`);
        setEnd(`${quarterY}-12-31`);

        break;

      default:
        break;
    }
    getDataList();
  }, [sortType]);

  useEffect(() => {
    if (!orderName) {
      setRevenueInfoListSort(revenueInfoList);
      return;
    }

    let sortList = [...revenueInfoList];

    switch (orderName) {
      case "status":
        if (statusOrder === "asc") {
          sortList = sortList.sort((a, b) => {
            return a.STATUS < b.STATUS ? -1 : 1;
          });
        } else if (statusOrder === "des") {
          sortList = sortList.sort((a, b) => {
            return a.STATUS > b.STATUS ? -1 : 1;
          });
        }
        setRevenueInfoListSort(sortList);
        return;
      case "name":
        if (nameOrder === "asc") {
          sortList = sortList.sort((a, b) => {
            return a.PROJECT_NAME < b.PROJECT_NAME ? -1 : 1;
          });
        } else if (nameOrder === "des") {
          sortList = sortList.sort((a, b) => {
            return a.PROJECT_NAME > b.PROJECT_NAME ? -1 : 1;
          });
        }
        setRevenueInfoListSort(sortList);
        return;
      case "final":
        if (finalOrder === "asc") {
          sortList = sortList.sort((a, b) => {
            const aFinal = Number(uncomma(a.FINAL));
            const bFinal = Number(uncomma(b.FINAL));
            return aFinal < bFinal ? -1 : 1;
          });
        } else if (finalOrder === "des") {
          sortList = sortList.sort((a, b) => {
            const aFinal = Number(uncomma(a.FINAL));
            const bFinal = Number(uncomma(b.FINAL));
            return aFinal > bFinal ? -1 : 1;
          });
        }
        setRevenueInfoListSort(sortList);
        return;
      case "payMethod":
        if (payMethodOrder === "asc") {
          sortList = sortList.sort((a, b) => {
            let payMethodOrder = ["BALANCE", "INTERIM_PAYMENT", "DOWN_PAYMENT"];
            let aPayMethodName = a.PAY_METHOD;
            let bPayMethodName = b.PAY_METHOD;
            return (
              payMethodOrder.indexOf(aPayMethodName) -
              payMethodOrder.indexOf(bPayMethodName)
            );
          });
        } else if (payMethodOrder === "des") {
          sortList = sortList.sort((a, b) => {
            let payMethodOrder = ["BALANCE", "INTERIM_PAYMENT", "DOWN_PAYMENT"];
            let aPayMethodName = a.PAY_METHOD;
            let bPayMethodName = b.PAY_METHOD;
            return (
              payMethodOrder.indexOf(aPayMethodName) +
              payMethodOrder.indexOf(bPayMethodName)
            );
          });
        }
        setRevenueInfoListSort(sortList);
        return;
      case null:
        setRevenueInfoListSort(revenueInfoList);
        return;
      default:
        setRevenueInfoListSort(revenueInfoList);
        return;
    }
  }, [
    revenueInfoList,
    orderName,
    statusOrder,
    nameOrder,
    finalOrder,
    payMethodOrder,
  ]);

  const getData = () => {
    calculateInfoView((res) => {
      if (res.code !== 0) return;
      setLoading();

      if (res.response.businessInfo) {
        // 비지니스 정보가 있으면 비지니스다.
        setUserRevenueInfoType("business");
      }
      if (res.response.accountNumber) {
        setUserInfoForm({
          ...userInfoForm,
          name: res.response.accountNumber.NAME,
          phone: res.response.accountNumber.PHONE,
          email: res.response.accountNumber.EMAIL,
          birthday: res.response.accountNumber.BIRTHDAY,
          accountNumber: res.response.accountNumber.ACCOUNT_NUMBER,
          bank: res.response.accountNumber.BANK,
          identificationNo: res.response.businessInfo.IDENTIFICATION_NO,
        });
        setPrevUserInfoForm({
          ...userInfoForm,
          name: res.response.accountNumber.NAME,
          phone: res.response.accountNumber.PHONE,
          email: res.response.accountNumber.EMAIL,
          birthday: res.response.accountNumber.BIRTHDAY,
          accountNumber: res.response.accountNumber.ACCOUNT_NUMBER,
          bank: res.response.accountNumber.BANK,
          identificationNo: res.response.businessInfo.IDENTIFICATION_NO,
        });
        setAccountNo(res.response.accountNumber.ACCOUNT_NUMBER);
        res.response.accountNumber.ACCOUNT_NUMBER.length > 0 &&
          setAccountCheckDone(true);
        setBankCode(res.response.accountNumber.BANK);
        setRevenueUserInfoSaved(true);
        getDataList();
        calculateTopView((res) => {
          if (res.code !== 0) return;
          setRevenueTopInfo(res.response);
        });
      } else {
        authProfile((res) => {
          setUserInfoForm({
            ...userInfoForm,
            name: res.response.NAME,
            phone: res.response.PHONE,
            email: res.response.USERNAME,
          });
          setPrevUserInfoForm({
            ...prevUserInfoForm,
            name: res.response.NAME,
            phone: res.response.PHONE,
            email: res.response.USERNAME,
          });
        });
      }
    });
  };

  const getDataList = useCallback(() => {
    const params = {
      page,
    };
    if (sortType === "quarter") {
      if (!quarter) return;
      switch (quarter) {
        case 1:
          params.start = `${quarterY}-01-01`;
          params.end = `${quarterY}-03-31`;
          break;
        case 2:
          params.start = `${quarterY}-04-01`;
          params.end = `${quarterY}-06-30`;
          break;
        case 3:
          params.start = `${quarterY}-07-01`;
          params.end = `${quarterY}-09-30`;
          break;
        case 4:
          params.start = `${quarterY}-10-01`;
          params.end = `${quarterY}-12-31`;
          break;
        default:
          break;
      }
    } else {
      params.start = dateFormat(start);
      params.end = dateFormat(end);
    }
    calculateListView(params, (res) => {
      if (res.code !== 0) return;
      setRevenueInfoList(res.response.list);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
  }, [page, sortType, start, end, quarterY, quarter]);

  const emailRegCheck = (email) => {
    if (!emailRFC5322RegEx(email)) {
      setEmailError(true);
    }
  };
  const businessNumCheck = useCallback(() => {
    const body = {
      biz_no: businessNum,
    };
    getUseBBusinessRegistration(body, (res) => {
      if (res === "error") {
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
        return;
      }
      if (
        !(
          Number(res.tax_type_code) === 4 ||
          Number(res.tax_type_code) === 5 ||
          Number(res.tax_type_code) === 99
        )
      ) {
        setBusinessNumCheckDone(true);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 완료",
            message: `기업 인증이 완료됐어요.`,
            confirmText: "확인",
            confirmCallback: () => {
              setUserInfoForm({
                ...userInfoForm,
                identificationNo: businessNum,
              });
              closeModal();
            },
          },
        });
      } else {
        setBusinessNumCheckDone(false);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      }
    });
  }, [businessNum]);
  const userNameCheck = useCallback(() => {
    const userNameCheckNum = userNameCheckNumFront + userNameCheckNumBack;
    juminNumRegEx(userNameCheckNum);
    const body = {
      no: userNameCheckNum,
      // name: userInfoForm.name
    };
    authUserNameCheck(
      body,
      (res) => {
        switch (res.code) {
          case 0:
            setUserInfoForm({
              ...userInfoForm,
              birthday: getBirthday(userNameCheckNum),
            });
            setUserNameCheckNumError({
              error: false,
              msg: "",
            });
            return;
          case 1:
            setUserNameCheckNumError({
              error: true,
              msg: "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
            });
            return;
          case 2:
            setUserNameCheckNumError({
              error: true,
              msg: "인증에 실패했어요. 다시 진행해 주세요.", //성명 불일치
            });
            return;
          case 3:
            setUserNameCheckNumError({
              error: true,
              msg: "인증에 실패했어요. 다시 진행해 주세요.",
            });
            return;
          case 4:
            setUserNameCheckNumError({
              error: true,
              msg: "인증에 실패했어요. 다시 진행해 주세요.",
            });
            return;
          case 5:
            setUserNameCheckNumError({
              error: true,
              msg: "인증에 실패했어요. 다시 진행해 주세요.",
            });
            return;
          default:
            return;
        }
      },
      () => {
        setUserNameCheckNumError({
          error: true,
          msg: "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
        });
      }
    );
  }, [userNameCheckNumFront, userNameCheckNumBack]);
  const [accountCheck, setAccountCheckDone] = useState(false);
  const userAccountCheck = () => {
    const body = {
      accountNo: accountNo,
      // type: userRevenueInfoType === 'personal' ? 1 : 2,
      bankCode: bankCode,
      // birthday: userRevenueInfoType === 'personal' ? birthdayFormat(userInfoForm.birthday) : userBusinessInfo.IDENTIFICATION_NO,
      // name: userInfoForm.name
      identificationNo: businessNum === "" ? null : businessNum,
    };
    authUserAccountCheck(
      body,
      (res) => {
        if (res.code === 0) {
          setUserInfoForm({
            ...userInfoForm,
            accountNumber: accountNo,
            bank: bankCode,
          });
          setAccountError(false);
          setAccountCheckDone(true);
        } else {
          setAccountError(true);
          setAccountCheckDone(false);
          // setAccountNumberInputOn(false)
        }
      },
      () => {
        setAccountError(true);
        // setAccountNumberInputOn(false)
      }
    );
  };

  const userRevenueInfoSave = () => {
    const body = {
      ...userInfoForm,
    };
    if (!userInfoForm.birthday) {
      delete body.birthday;
    }
    calculateInfoSave(body, (res) => {
      if (res.code !== 0) return;
      getData();
      setInfoInputOn(false);
      setEmailError(false);
      // setAccountNumberInputOn(false);
      // setUserNameCheckInputOn(false);
      setUserNameCheckNumFront(null);
      setUserNameCheckNumBack(null);
    });
  };

  const getPayMethodName = (PayMethod) => {
    switch (PayMethod) {
      case "DOWN_PAYMENT":
        return "선금";
      case "INTERIM_PAYMENT":
        return "중도금";
      case "BALANCE":
        return "잔금";
      case "VAT":
        return "수수료";
      default:
        return "-";
    }
  };

  // kmc
  useEffect(() => {
    window.addEventListener("message", kmcResult, false);
    return () => {
      window.removeEventListener("message", kmcResult);
    };
  }, []);

  useEffect(() => {
    if (!trCert) return;
    openKMCISWindow();
  }, [trCert]);

  const kmcResult = (e) => {
    if (e.origin !== process.env.REACT_APP_API_HOST) return;
    const { mNumber } = e.data;
    setUserInfoForm({
      ...userInfoForm,
      phone: mNumber,
    });
  };
  useEffect(() => {
    setBusinessNumError(false);
    const regex = /^[0-9]+$/;
    if (businessNum.length > 0 && !regex.test(businessNum)) {
      setBusinessNumError(true);
      return;
    }
  }, [businessNum]);
  const openKMCISWindow = () => {
    window.newPopup = window.open(
      "",
      "KMCISWindow",
      "width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0"
    );

    reqKMCISFormRef.current.target = "KMCISWindow";

    reqKMCISFormRef.current.action =
      "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    reqKMCISFormRef.current.submit();
  };

  const smsAuth = () => {
    let randomStr = Math.random().toString(36).split(".")[1];
    kmcTrCert(randomStr, "REVENUE", (res) => {
      setTrCert(res.response);
    });
  };

  return (
    <div className="mypage-con">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="section-tit">
            정산 요약
            {revenueUserInfoSaved && !isInfoInputOn && (
              <div className="right">
                {/* <button type="button" className="btn btn-underline txt-c--grey">세금계산서 조회하기</button> */}
                <button
                  type="button"
                  className="btn btn-underline txt-c--grey ml-10"
                  onClick={() => {
                    setInfoInputOn(true);
                  }}
                >
                  정산 정보 수정하기
                </button>
              </div>
            )}
          </div>
          <div className="calculate-top">
            <div className="left">
              <div className="tit">
                입금 예정 금액
                <div className="tooltip default right">
                  <button type="button" className="btn i"></button>
                  <div className="tooltip-message">
                    중개 수수료(10%)를 제외한 총 입금 예정 금액이에요.
                  </div>
                </div>
              </div>
              {revenueTopInfo ? (
                <>
                  {/* <div className="date">
                    계약 기간 | {revenueTopInfo.project.START_AT} ~{" "}
                    {revenueTopInfo.project.END_AT}
                  </div> */}
                  <div className="num">
                    {comma(revenueTopInfo.calculate.CHARGE_SUPPLY_PRICE)}
                    <span className="text">원</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="date">-</div>
                  <div className="num">-</div>
                </>
              )}
            </div>
            <div className="right">
              <div className="tit">
                정산 예정일
                <div className="tooltip default right">
                  <button type="button" className="btn i"></button>
                  <div className="tooltip-message">
                    정산이 수행되는 확정 날짜예요.
                  </div>
                </div>
              </div>
              {revenueTopInfo ? (
                <>
                  <div className="date"></div>
                  <div className="num">
                    {revenueTopInfo.calculate.EXPECTED_PAYMENT_DATE}
                  </div>
                </>
              ) : (
                <>
                  <div className="date"></div>
                  <div className="num">-</div>
                </>
              )}
            </div>
          </div>
          {(!revenueUserInfoSaved || isInfoInputOn) && (
            <>
              <div className="section-tit mt-40">정산 정보</div>

              {isInfoInputOn && (
                <>
                  {userRevenueInfoType === "business" ? (
                    <div className="col-2">
                      <StyledWrapper>
                        <div className="input-wrap">
                          <label className="input-label">
                            기업(사업자) 이름
                          </label>
                          <input
                            type="text"
                            className="input-text"
                            defaultValue={userBusinessInfo.BUSINESS_NAME}
                            readOnly
                          />
                        </div>
                        {isMobile && (
                          <div className="ml-20">
                            <div className="input-wrap">
                              <label className="input-label required ">
                                사업자 번호
                              </label>
                              <input
                                type="text"
                                className="input-text"
                                defaultValue={
                                  userBusinessInfo.IDENTIFICATION_NO
                                }
                              />
                            </div>
                            <div className="input-wrap">
                              <label className="input-label">담당자</label>
                              <input
                                type="text"
                                className="input-text"
                                defaultValue={userInfoForm.name}
                                readOnly
                              />
                            </div>

                            <div className="input-wrap">
                              <label className="input-label required">
                                이메일
                              </label>
                              <input
                                type="text"
                                className={`input-text ${
                                  emailError ? "error" : ""
                                }`}
                                value={userInfoForm.email}
                                onChange={(e) => {
                                  setEmailError(false);
                                  setUserInfoForm({
                                    ...userInfoForm,
                                    email: e.target.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  emailRegCheck(e.target.value);
                                }}
                              />
                              {emailError && (
                                <p className="input-guide error">
                                  올바른 이메일 형식이 아니에요.
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        {!isMobile && (
                          <div className="input-wrap">
                            <label className="input-label">담당자</label>
                            <input
                              type="text"
                              className="input-text"
                              defaultValue={userInfoForm.name}
                              readOnly
                            />
                          </div>
                        )}
                        {!isMobile && emailError && (
                          <p className="input-guide">&nbsp;</p>
                        )}
                        <div className="input-wrap">
                          <label className="input-label required">
                            담당자 연락처
                          </label>
                          <div className="btn-exist">
                            <span className="check"></span>
                            <input
                              type="text"
                              className="input-text"
                              value={userInfoForm.phone}
                              readOnly
                            />
                            <button
                              type="button"
                              className="btn btn-black type2"
                              onClick={() => {
                                smsAuth();
                              }}
                            >
                              재인증
                            </button>
                          </div>
                        </div>
                      </StyledWrapper>
                      {!isMobile && (
                        <StyledWrapper className="ml-20">
                          <div className="input-wrap">
                            <label className="input-label required">
                              사업자 번호
                            </label>
                            <div className="btn-exist">
                              {businessNumCheckDone && (
                                <span className="check"></span>
                              )}
                              <input
                                type="text"
                                className={`input-text ${
                                  businessNumError ? "error" : ""
                                }`}
                                placeholder="‘-’제외 사업자번호 입력"
                                defaultValue={
                                  userBusinessInfo.IDENTIFICATION_NO
                                }
                                onChange={(e) => setBusinessNum(e.target.value)}
                                maxLength={10}
                                readOnly={businessNumCheckDone}
                              />
                              {!businessNumCheckDone ? (
                                <button
                                  type="button"
                                  className="btn btn-black type2"
                                  disabled={
                                    !(
                                      !businessNumError &&
                                      businessNum.length >= 10
                                    )
                                  }
                                  onClick={() => businessNumCheck()}
                                >
                                  인증하기
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-black type2"
                                  onClick={() => {
                                    setBusinessNumCheckDone(false);
                                    setUserInfoForm({
                                      ...userInfoForm,
                                      identificationNo: null,
                                    });
                                  }}
                                >
                                  재인증
                                </button>
                              )}
                            </div>
                            {businessNumError && (
                              <p className="input-guide error">
                                올바른 사업자번호가 아니에요.
                              </p>
                            )}
                          </div>
                          <div className="input-wrap">
                            <label className="input-label required">
                              이메일
                            </label>
                            <input
                              type="text"
                              className={`input-text ${
                                emailError ? "error" : ""
                              }`}
                              value={userInfoForm.email}
                              onChange={(e) => {
                                setEmailError(false);
                                setUserInfoForm({
                                  ...userInfoForm,
                                  email: e.target.value,
                                });
                              }}
                              onBlur={(e) => {
                                emailRegCheck(e.target.value);
                              }}
                            />
                            {emailError && (
                              <p className="input-guide error">
                                올바른 이메일 형식이 아니에요.
                              </p>
                            )}
                          </div>
                          <div className="input-wrap">
                            <label className="input-label required">
                              수익금 입금 계좌
                            </label>
                            <div className="col-2 mt-10">
                              {!userInfoForm.accountNumber && (
                                <div>
                                  <Select
                                    className={[
                                      `sm project-request ${
                                        isAccountError ? "error" : ""
                                      }`,
                                    ]}
                                    style={{ width: "180px", height: "50px" }}
                                    option={[
                                      {
                                        id: null,
                                        name: isMobile
                                          ? `은행`
                                          : `은행을 선택하세요.`,
                                      },
                                      ...bnkCodeList,
                                    ]}
                                    selected={bankCode}
                                    setSelect={(value) => {
                                      setBankCode(value);
                                      setAccountError(false);
                                    }}
                                    disabled={userInfoForm.accountNumber}
                                  />
                                </div>
                              )}
                              <div
                                className={`${
                                  userInfoForm.accountNumber ? "" : "ml-10"
                                } flex2"`}
                              >
                                <div className="btn-exist">
                                  {userInfoForm.accountNumber ? (
                                    <>
                                      <span className="check"></span>
                                      <input
                                        type="text"
                                        className="input-text"
                                        value={
                                          bnkCodeList.find(
                                            (item) =>
                                              userInfoForm.bank === item.id
                                          )
                                            ? bnkCodeList.find(
                                                (item) =>
                                                  userInfoForm.bank === item.id
                                              )?.name +
                                              " " +
                                              userInfoForm.accountNumber
                                            : "은행정보가 없어요" +
                                              userInfoForm.accountNumber
                                        }
                                        disabled={userInfoForm.accountNumber}
                                        onChange={(e) => {
                                          setAccountNo(e.target.value);
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-black type2"
                                        onClick={() => {
                                          // setAccountNumberInputOn(true)
                                          setAccountNo(
                                            userInfoForm.accountNumber
                                          );
                                          setUserInfoForm({
                                            ...userInfoForm,
                                            accountNumber: "",
                                          });
                                        }}
                                      >
                                        재인증
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="text"
                                        className={`input-text ${
                                          isAccountError ? "error" : ""
                                        }`}
                                        style={{
                                          width: "319px",
                                          height: "50px",
                                        }}
                                        value={accountNo}
                                        placeholder={
                                          isMobile
                                            ? "‘-’ 없이 숫자 입력"
                                            : "‘-’ 없이 숫자만 입력해 주세요."
                                        }
                                        disabled={userInfoForm.accountNumber}
                                        onChange={(e) => {
                                          setAccountNo(e.target.value);
                                          setAccountError(false);
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-black type2"
                                        onClick={() => {
                                          userAccountCheck();
                                        }}
                                        disabled={
                                          !accountNo || accountNo.length >= 20
                                        }
                                      >
                                        인증하기
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {isAccountError && (
                              <p className="input-guide error">
                                인증에 실패했어요. 다시 진행해 주세요.
                              </p>
                            )}
                          </div>
                        </StyledWrapper>
                      )}
                      {isMobile && (
                        <div className="input-wrap">
                          <label className="input-label required">
                            수익금 입금 계좌
                          </label>
                          <div className="col-2 mt-10">
                            {!userInfoForm.accountNumber && (
                              <div>
                                <Select
                                  className={[
                                    `sm project-request ${
                                      isAccountError ? "error" : ""
                                    }`,
                                  ]}
                                  style={{ width: "180px", height: "50px" }}
                                  option={[
                                    {
                                      id: null,
                                      name: isMobile
                                        ? `은행`
                                        : `은행을 선택하세요.`,
                                    },
                                    ...bnkCodeList,
                                  ]}
                                  selected={bankCode}
                                  setSelect={(value) => {
                                    setBankCode(value);
                                    setAccountError(false);
                                  }}
                                  disabled={userInfoForm.accountNumber}
                                />
                              </div>
                            )}
                            <div
                              className={`${
                                userInfoForm.accountNumber ? "" : "ml-10"
                              } flex2"`}
                            >
                              <div className="btn-exist">
                                {userInfoForm.accountNumber ? (
                                  <>
                                    <span className="check"></span>
                                    <input
                                      type="text"
                                      className="input-text"
                                      value={
                                        bnkCodeList.find(
                                          (item) =>
                                            userInfoForm.bank === item.id
                                        ).name +
                                        " " +
                                        userInfoForm.accountNumber
                                      }
                                      disabled={userInfoForm.accountNumber}
                                      onChange={(e) => {
                                        setAccountNo(e.target.value);
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-black type2"
                                      onClick={() => {
                                        // setAccountNumberInputOn(true)
                                        setAccountNo(
                                          userInfoForm.accountNumber
                                        );
                                        setUserInfoForm({
                                          ...userInfoForm,
                                          accountNumber: "",
                                        });
                                      }}
                                    >
                                      재인증
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      className={`input-text ${
                                        isAccountError ? "error" : ""
                                      }`}
                                      value={accountNo}
                                      style={{ width: "319px", height: "50px" }}
                                      placeholder={
                                        isMobile
                                          ? "‘-’ 없이 숫자 입력"
                                          : "‘-’ 없이 숫자만 입력해 주세요."
                                      }
                                      disabled={userInfoForm.accountNumber}
                                      onChange={(e) => {
                                        setAccountNo(e.target.value);
                                        setAccountError(false);
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-black type2"
                                      onClick={() => {
                                        userAccountCheck();
                                      }}
                                      disabled={
                                        !accountNo || accountNo.length >= 20
                                      }
                                    >
                                      인증하기
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {isAccountError && (
                            <p className="input-guide error">
                              인증에 실패했어요. 다시 진행해 주세요.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-2">
                      <div>
                        <div className="input-wrap mt-10">
                          <label className="input-label">이름</label>

                          <input
                            type="text"
                            className="input-text"
                            defaultValue={userInfoForm.name}
                            readOnly
                          />
                          {!isMobile && emailError && (
                            <p className="input-guide">&nbsp;</p>
                          )}
                        </div>
                        {isMobile && (
                          <>
                            <div className="input-wrap">
                              <label className="input-label">사업자 번호</label>
                              <div className="btn-exist">
                                {businessNumCheckDone && (
                                  <span className="check"></span>
                                )}
                                <input
                                  type="text"
                                  className={`input-text ${
                                    businessNumError ? "error" : ""
                                  }`}
                                  placeholder="‘-’제외 사업자번호 입력"
                                  defaultValue={
                                    userBusinessInfo.IDENTIFICATION_NO
                                  }
                                  onChange={(e) =>
                                    setBusinessNum(e.target.value)
                                  }
                                  maxLength={10}
                                  readOnly={businessNumCheckDone}
                                />
                                {!businessNumCheckDone ? (
                                  <button
                                    type="button"
                                    className="btn btn-black type2"
                                    disabled={
                                      !(
                                        !businessNumError &&
                                        businessNum.length >= 10
                                      )
                                    }
                                    onClick={() => businessNumCheck()}
                                  >
                                    인증하기
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-black type2"
                                    onClick={() => {
                                      setBusinessNumCheckDone(false);
                                      setUserInfoForm({
                                        ...userInfoForm,
                                        identificationNo: null,
                                      });
                                    }}
                                  >
                                    재인증
                                  </button>
                                )}
                              </div>
                              {businessNumError && (
                                <p className="input-guide error">
                                  올바른 사업자번호가 아니에요.
                                </p>
                              )}
                            </div>
                            <div className="input-wrap">
                              <label className="input-label required">
                                이메일
                              </label>
                              <input
                                type="text"
                                className={`input-text ${
                                  emailError ? "error" : ""
                                }`}
                                value={userInfoForm.email}
                                onChange={(e) => {
                                  setEmailError(false);
                                  setUserInfoForm({
                                    ...userInfoForm,
                                    email: e.target.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  emailRegCheck(e.target.value);
                                }}
                              />
                              {emailError && (
                                <p className="input-guide error">
                                  올바른 이메일 형식이 아니에요.
                                </p>
                              )}
                            </div>
                          </>
                        )}
                        <div className="input-wrap mt-20">
                          <label className="input-label required">연락처</label>
                          <div className="btn-exist">
                            <span className="check"></span>
                            <input
                              type="text"
                              className="input-text"
                              value={userInfoForm.phone}
                              readOnly
                            />
                            <button
                              type="button"
                              className="btn btn-black type2"
                              onClick={() => {
                                smsAuth();
                              }}
                            >
                              재인증
                            </button>
                          </div>
                        </div>
                        {!isMobile && isAccountError && (
                          <p className="input-guide">&nbsp;</p>
                        )}
                        {isMobile && (
                          <div className="input-wrap">
                            <label className="input-label required">
                              수익금 입금 계좌
                            </label>

                            {!userInfoForm.accountNumber && (
                              <div>
                                <Select
                                  className={[
                                    `sm project-request ${
                                      isAccountError ? "error" : ""
                                    }`,
                                  ]}
                                  style={{ width: "180px", height: "50px" }}
                                  option={[
                                    {
                                      id: null,
                                      name: isMobile
                                        ? `은행`
                                        : `은행을 선택하세요.`,
                                    },
                                    ...bnkCodeList,
                                  ]}
                                  selected={bankCode}
                                  setSelect={(value) => {
                                    setBankCode(value);
                                    setAccountError(false);
                                  }}
                                  disabled={userInfoForm.accountNumber}
                                />
                              </div>
                            )}
                            <div
                              className={`${
                                userInfoForm.accountNumber ? "" : "ml-10"
                              } flex2"`}
                            >
                              <div className="btn-exist">
                                {userInfoForm.accountNumber ? (
                                  <>
                                    <span className="check"></span>
                                    <input
                                      type="text"
                                      className="input-text"
                                      value={
                                        bnkCodeList.find(
                                          (item) =>
                                            userInfoForm.bank === item.id
                                        ).name +
                                        " " +
                                        userInfoForm.accountNumber
                                      }
                                      disabled={userInfoForm.accountNumber}
                                      onChange={(e) => {
                                        setAccountNo(e.target.value);
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-black type2"
                                      onClick={() => {
                                        // setAccountNumberInputOn(true)
                                        setAccountNo(
                                          userInfoForm.accountNumber
                                        );
                                        setUserInfoForm({
                                          ...userInfoForm,
                                          accountNumber: "",
                                        });
                                      }}
                                    >
                                      재인증
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      className={`input-text ${
                                        isAccountError ? "error" : ""
                                      }`}
                                      value={accountNo}
                                      style={{ width: "319px", height: "50px" }}
                                      placeholder={
                                        isMobile
                                          ? "‘-’ 없이 숫자 입력"
                                          : "‘-’ 없이 숫자만 입력해 주세요."
                                      }
                                      disabled={userInfoForm.accountNumber}
                                      onChange={(e) => {
                                        setAccountNo(e.target.value);
                                        setAccountError(false);
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-black type2"
                                      onClick={() => {
                                        userAccountCheck();
                                      }}
                                      disabled={
                                        !accountNo || accountNo.length >= 20
                                      }
                                    >
                                      인증하기
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>

                            {isAccountError && (
                              <p className="input-guide error">
                                인증에 실패했어요. 다시 진행해 주세요.
                              </p>
                            )}
                          </div>
                        )}
                        <div className="input-wrap mt-20">
                          <label className="input-label required">
                            주민등록번호
                          </label>
                          {/* <p className="jumin-replace" onClick={() => { }} >{juminNumFormat(userNameCheckNum)}</p> */}
                          {!userInfoForm.birthday ? (
                            <div className="btn-exist">
                              <input
                                type="text"
                                className={`input-text ${
                                  userNameCheckNumError.error ? "error" : ""
                                }`}
                                maxLength={6}
                                value={userNameCheckNumFront}
                                onChange={(e) => {
                                  setUserNameCheckNumError({
                                    error: false,
                                    msg: "",
                                  });
                                  setUserNameCheckNumFront(e.target.value);
                                }}
                                placeholder="주민번호 앞 6자리"
                                disabled={userInfoForm.birthday}
                                autoComplete="nope"
                              />
                              <span
                                className="text"
                                style={{
                                  flex: "none",
                                  fontSize: 14,
                                  margin: "0 5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                -
                              </span>
                              <input
                                type="password"
                                className={`input-text ${
                                  userNameCheckNumError.error ? "error" : ""
                                }`}
                                maxLength={7}
                                value={userNameCheckNumBack}
                                onChange={(e) => {
                                  setUserNameCheckNumError({
                                    error: false,
                                    msg: "",
                                  });
                                  setUserNameCheckNumBack(e.target.value);
                                }}
                                placeholder="*******"
                                disabled={userInfoForm.birthday}
                                autoComplete="new-password"
                              />
                              <button
                                type="button"
                                className="btn btn-black type2"
                                onClick={() => {
                                  userNameCheck();
                                }}
                                disabled={
                                  userNameCheckNumFront === null ||
                                  userNameCheckNumBack === null ||
                                  userNameCheckNumFront?.length === 0 ||
                                  userNameCheckNumBack?.length === 0
                                }
                              >
                                인증하기
                              </button>
                            </div>
                          ) : (
                            <div className="btn-exist">
                              <span className="check"></span>
                              <input
                                type="text"
                                className={`input-text`}
                                maxLength={13}
                                value={
                                  birthdayFormat(userInfoForm.birthday) +
                                  "-*******"
                                }
                                onChange={(e) => {}}
                                placeholder="주민번호 앞 6자리"
                                disabled={userInfoForm.birthday}
                              />
                              <button
                                type="button"
                                className="btn btn-black type2"
                                onClick={() => {
                                  setUserInfoForm({
                                    ...userInfoForm,
                                    birthday: "",
                                  });
                                }}
                              >
                                재인증
                              </button>
                            </div>
                          )}
                          {userNameCheckNumError.error && (
                            <p className="input-guide error">
                              {userNameCheckNumError.msg}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="ml-20">
                        {!isMobile && (
                          <>
                            <div className="input-wrap mt-10">
                              <label className="input-label">사업자 번호</label>
                              <div className="btn-exist">
                                {businessNumCheckDone && (
                                  <span className="check"></span>
                                )}
                                <input
                                  type="text"
                                  className={`input-text ${
                                    businessNumError ? "error" : ""
                                  }`}
                                  placeholder="‘-’제외 사업자번호 입력"
                                  defaultValue={
                                    userBusinessInfo.IDENTIFICATION_NO
                                  }
                                  onChange={(e) =>
                                    setBusinessNum(e.target.value)
                                  }
                                  maxLength={10}
                                  readOnly={businessNumCheckDone}
                                />
                                {!businessNumCheckDone ? (
                                  <button
                                    type="button"
                                    className="btn btn-black type2"
                                    disabled={
                                      !(
                                        !businessNumError &&
                                        businessNum.length >= 10
                                      )
                                    }
                                    onClick={() => businessNumCheck()}
                                  >
                                    인증하기
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-black type2"
                                    onClick={() => {
                                      setBusinessNumCheckDone(false);
                                      setUserInfoForm({
                                        ...userInfoForm,
                                        identificationNo: null,
                                      });
                                    }}
                                  >
                                    재인증
                                  </button>
                                )}
                              </div>
                              {businessNumError && (
                                <p className="input-guide error">
                                  올바른 사업자번호가 아니에요.
                                </p>
                              )}
                            </div>
                            <div className="input-wrap mt-20">
                              <label className="input-label required">
                                이메일
                              </label>
                              <input
                                type="text"
                                className={`input-text ${
                                  emailError ? "error" : ""
                                }`}
                                value={userInfoForm.email}
                                onChange={(e) => {
                                  setEmailError(false);
                                  setUserInfoForm({
                                    ...userInfoForm,
                                    email: e.target.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  emailRegCheck(e.target.value);
                                }}
                              />
                              {emailError && (
                                <p className="input-guide error">
                                  올바른 이메일 형식이 아니에요.
                                </p>
                              )}
                            </div>
                          </>
                        )}
                        {!isMobile && (
                          <div className="input-wrap mt-20">
                            <label className="input-label required">
                              수익금 입금 계좌
                            </label>
                            <div
                              className="col-2 mt-10"
                              style={{ display: "flex" }}
                            >
                              {!userInfoForm.accountNumber && (
                                <div>
                                  <Select
                                    className={[
                                      `sm project-request ${
                                        isAccountError ? "error" : ""
                                      }`,
                                    ]}
                                    style={{ width: "180px", height: "50px" }}
                                    option={[
                                      {
                                        id: null,
                                        name: isMobile
                                          ? `은행`
                                          : `은행을 선택하세요.`,
                                      },
                                      ...bnkCodeList,
                                    ]}
                                    selected={bankCode}
                                    setSelect={(value) => {
                                      setBankCode(value);
                                      setAccountError(false);
                                    }}
                                    disabled={userInfoForm.accountNumber}
                                  />
                                </div>
                              )}
                              <div
                                className={`${
                                  userInfoForm.accountNumber ? "" : "ml-10"
                                } flex2"`}
                              >
                                <div className="btn-exist">
                                  {userInfoForm.accountNumber ? (
                                    <>
                                      <span className="check"></span>
                                      <input
                                        type="text"
                                        className="input-text"
                                        value={
                                          bnkCodeList.find(
                                            (item) =>
                                              userInfoForm.bank === item.id
                                          ) !== undefined
                                            ? bnkCodeList.find(
                                                (item) =>
                                                  userInfoForm.bank === item.id
                                              ).name +
                                              " " +
                                              userInfoForm.accountNumber
                                            : "존재하지 않는 은행정보 " +
                                              userInfoForm.accountNumber
                                        }
                                        disabled={userInfoForm.accountNumber}
                                        onChange={(e) => {
                                          setAccountNo(e.target.value);
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-black type2"
                                        onClick={() => {
                                          // setAccountNumberInputOn(true)
                                          setAccountNo(
                                            userInfoForm.accountNumber
                                          );
                                          setUserInfoForm({
                                            ...userInfoForm,
                                            accountNumber: "",
                                          });
                                          setAccountCheckDone(false);
                                        }}
                                      >
                                        재인증
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="text"
                                        className={`input-text ${
                                          isAccountError ? "error" : ""
                                        }`}
                                        value={accountNo}
                                        style={{
                                          width: "319px",
                                          height: "50px",
                                        }}
                                        placeholder={
                                          isMobile
                                            ? "‘-’ 없이 숫자 입력"
                                            : "‘-’ 없이 숫자만 입력해 주세요."
                                        }
                                        disabled={userInfoForm.accountNumber}
                                        onChange={(e) => {
                                          setAccountNo(e.target.value);
                                          setAccountError(false);
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-black type2"
                                        onClick={() => {
                                          userAccountCheck();
                                        }}
                                        disabled={
                                          !accountNo || accountNo.length >= 20
                                        }
                                      >
                                        인증하기
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {isAccountError && (
                              <p className="input-guide error">
                                인증에 실패했어요. 다시 진행해 주세요.
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {isInfoInputOn ? (
                <StyledBtnWrapper
                  className={`${isMobile ? "mt-30" : "btn-wrap  mt-40"}`}
                >
                  {revenueUserInfoSaved && (
                    <button
                      type="button"
                      className="btn btn-lg btn-grey type2"
                      style={
                        isMobile
                          ? { marginTop: 14 }
                          : { marginRight: "6px", width: "94px" }
                      }
                      disabled={false}
                      onClick={() => {
                        setInfoInputOn(false);
                        setUserInfoForm(prevUserInfoForm);
                        setEmailError(false);
                        // setAccountNumberInputOn(false);
                        // setUserNameCheckInputOn(false);
                      }}
                    >
                      취소
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-lg btn-black type2"
                    disabled={
                      // 폼이 완료되지 않았을 때
                      !formDone ||
                      JSON.stringify(userInfoForm) ===
                        JSON.stringify(prevUserInfoForm) ||
                      !accountCheck
                    }
                    onClick={() => {
                      userRevenueInfoSave();
                    }}
                  >
                    저장하기
                  </button>
                </StyledBtnWrapper>
              ) : (
                <div className="btn-wrap">
                  <button
                    type="button"
                    className="btn btn-lg btn-black type2"
                    onClick={() => setInfoInputOn(true)}
                  >
                    본인 인증 및 정보 입력하기
                  </button>
                </div>
              )}
            </>
          )}
          <div className="section-tit mt-40">정산 내역</div>

          {revenueUserInfoSaved && (
            <div className="history-list--top dropdown">
              <Select
                className={["sm first revenue m-margin"]}
                width={110}
                option={[
                  {
                    id: "quarter",
                    name: "분기",
                  },
                  {
                    id: "direct",
                    name: "직접 선택",
                  },
                ]}
                selected={sortType}
                setSelect={(value) => {
                  setSortType(value);
                  setStart(null);
                  setEnd(null);
                }}
              />

              {sortType === "quarter" && (
                <>
                  <Select
                    className={[
                      `sm revenue m-margin ${isMobile ? "" : "mr-none"}`,
                    ]}
                    option={yearList}
                    selected={quarterY}
                    setSelect={(value) => {
                      setQuarterY(value);
                    }}
                  />
                  <div className="radio-list">
                    <label className="input-radio">
                      <input
                        type="radio"
                        name="radio"
                        checked={quarter === 1}
                        onChange={() => {
                          setQuarter(1);
                          setStart(`${quarterY}-01-01`);
                          setEnd(`${quarterY}-03-31`);
                        }}
                      />
                      <span className="box">1분기</span>
                    </label>
                    <label className="input-radio">
                      <input
                        type="radio"
                        name="radio"
                        checked={quarter === 2}
                        onChange={() => {
                          setQuarter(2);
                          setStart(`${quarterY}-04-01`);
                          setEnd(`${quarterY}-06-30`);
                        }}
                      />
                      <span className="box">2분기</span>
                    </label>
                    <label className="input-radio">
                      <input
                        type="radio"
                        name="radio"
                        checked={quarter === 3}
                        onChange={() => {
                          setQuarter(3);
                          setStart(`${quarterY}-07-01`);
                          setEnd(`${quarterY}-09-30`);
                        }}
                      />
                      <span className="box">3분기</span>
                    </label>
                    <label className="input-radio">
                      <input
                        type="radio"
                        name="radio"
                        checked={quarter === 4}
                        onChange={() => {
                          setQuarter(4);
                          setStart(`${quarterY}-10-01`);
                          setEnd(`${quarterY}-12-31`);
                        }}
                      />
                      <span className="box">4분기</span>
                    </label>
                  </div>
                </>
              )}

              {sortType === "direct" && (
                <>
                  <div>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={start === null ? "" : start}
                      onChange={(date) => {
                        setStart(date);
                      }}
                      dateFormatCalendar="yyyy년 MM월"
                      locale={ko}
                      customInput={
                        <input
                          type="text"
                          className="input-text input-date"
                          readOnly
                        />
                      }
                      placeholderText={"YYYY-MM-DD"}
                    />
                  </div>
                  <span className="text">~</span>
                  <div>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={end === null ? "" : end}
                      onChange={(date) => {
                        setEnd(date);
                      }}
                      dateFormatCalendar="yyyy년 MM월"
                      locale={ko}
                      customInput={
                        <input
                          type="text"
                          className="input-text input-date"
                          readOnly
                        />
                      }
                      placeholderText={"YYYY-MM-DD"}
                    />
                  </div>
                </>
              )}
              <div className="right">
                {!isMobile && (
                  <button
                    type="button"
                    className="btn btn-down btn-sm btn-prupleline"
                    onClick={() => {
                      const payload = {
                        page: page,
                        start: start,
                        end: end,
                      };
                      function datetimeFormat(date) {
                        let month = date.getMonth() + 1;
                        let day = date.getDate();
                        const time = date.toLocaleTimeString("ko-KR", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        });
                        day = day.toString().padStart(2, "0");
                        return (
                          date.getFullYear() +
                          "-" +
                          month +
                          "-" +
                          day +
                          " " +
                          time
                        );
                      }
                      getExcelCalculate(payload, (res) => {
                        const blob = new Blob([res]);
                        const blobUrl = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = blobUrl;
                        const filename = `${datetimeFormat(
                          new Date()
                        )}_list.xls`;
                        link.setAttribute("download", filename);
                        link.style.cssText = "display: none";
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        URL.revokeObjectURL(blobUrl);
                      });
                    }}
                  >
                    상세내역 다운로드
                  </button>
                )}
              </div>
            </div>
          )}
          {revenueUserInfoSaved && Boolean(revenueInfoList.length) ? (
            <>
              <ul className="history-list type3">
                <li className="history-list--item top">
                  <div className="text-left">
                    구분
                    <button
                      type="button"
                      className={`btn sort ${
                        statusOrder === "asc" ? "asc" : "des"
                      }`}
                      onClick={() => {
                        statusOrder
                          ? statusOrder === "asc"
                            ? setStatusOrder("des")
                            : setStatusOrder("asc")
                          : setStatusOrder("asc");
                        setOrderName("status");
                      }}
                    ></button>
                  </div>
                  <div className="text-left">
                    프로젝트 이름
                    <button
                      type="button"
                      className={`btn sort ${
                        nameOrder === "asc" ? "asc" : "des"
                      }`}
                      onClick={() => {
                        nameOrder
                          ? nameOrder === "asc"
                            ? setNameOrder("des")
                            : setNameOrder("asc")
                          : setNameOrder("asc");
                        setOrderName("name");
                      }}
                    ></button>
                  </div>
                  <div className="text-left">계약 기간</div>
                  <div className="text-left">정산 예정일</div>
                  <div className="text-left">정산 완료일</div>
                  <div className="text-left">정산 금액</div>
                  <div className="text-left">수수료</div>
                  <div className="text-left">
                    최종 정산 금액
                    <button
                      type="button"
                      className={`btn sort ${
                        finalOrder === "asc" ? "asc" : "des"
                      }`}
                      onClick={() => {
                        finalOrder
                          ? finalOrder === "asc"
                            ? setFinalOrder("des")
                            : setFinalOrder("asc")
                          : setFinalOrder("asc");
                        setOrderName("final");
                      }}
                    ></button>
                  </div>
                  <div className="text-left">
                    정산 형태
                    <button
                      type="button"
                      className={`btn sort ${
                        payMethodOrder === "asc" ? "asc" : "des"
                      }`}
                      onClick={() => {
                        payMethodOrder
                          ? payMethodOrder === "asc"
                            ? setPayMethodOrder("des")
                            : setPayMethodOrder("asc")
                          : setPayMethodOrder("asc");
                        setOrderName("payMethod");
                      }}
                    ></button>
                  </div>
                  <div className="text-left">정산 방식</div>
                </li>
                {revenueInfoListSort.map((item, i) => (
                  <li
                    className="history-list--item"
                    key={`revenue-info-list-item-${i}`}
                  >
                    <div className="text-left">
                      {item.STATUS === "입금예정" ? (
                        <span className="badge badge-sm d-grey">입금 예정</span>
                      ) : (
                        <span className="badge badge-sm deeppurple">
                          입금 완료
                        </span>
                      )}
                    </div>
                    <div className="text-left tit">{item.PROJECT_NAME}</div>
                    <div className="text-left">
                      {item.START_AT} ~ {item.END_AT}
                    </div>
                    {item.EXPECTED_PAYMENT_DATE ? (
                      <div className="text-left">
                        {item.EXPECTED_PAYMENT_DATE}
                      </div>
                    ) : (
                      <div className="text-right" style={{ paddingRight: 20 }}>
                        -
                      </div>
                    )}
                    {item.PAYMENT_DATE ? (
                      <div className="text-left">
                        {item.PAYMENT_DATE.split(" ")[0]}
                      </div>
                    ) : (
                      <div className="text-right" style={{ paddingRight: 20 }}>
                        -
                      </div>
                    )}
                    <div className="text-left strong">
                      {comma(item.TOTAL || 0)}원
                    </div>
                    <div className="text-left strong">
                      {comma(item.TOTAL_VAT || 0)}원
                    </div>
                    <div className="text-left strong">
                      {comma(item.FINAL || 0)}원
                    </div>
                    <div className="text-left">
                      {getPayMethodName(item.PAY_METHOD)}
                    </div>
                    <div className="text-left">에스크로</div>
                  </li>
                ))}
              </ul>
              {paginationData && (
                <Pagination
                  paginationData={paginationData}
                  page={page}
                  setPage={setPage}
                />
              )}
            </>
          ) : (
            <div className="mypage-none">
              {businessNum && revenueUserInfoSaved
                ? "아직 정산 데이터가 없어요."
                : !businessNum && revenueUserInfoSaved
                ? "정산 정보 저장 후 정산 내역 확인이 가능해요."
                : "본인인증 진행 후 정산 내역 확인이 가능해요."}
            </div>
          )}
          <div className="mypage-guide" style={{ fontWeight: 500 }}>
            <div className="tit">잊지 말고 꼭 확인해 주세요!</div>
            <div className="sub">대금지급 관련 안내</div>
            <ul className="text">
              <li className="text-item">
                <strong>
                  정산 정보를 꼭 입력해야 정상적으로 대금 지급이 완료돼요.
                </strong>
              </li>
              <li className="text-item">
                기업 파트너의 경우 ‘정산 정보’ 중 ‘수익금 입금 계좌’의 계좌명이
                기업(사업자)명과 달라도 대금 지급은 완료되지만,
                <strong>
                  사업용 계좌 거래로 인정되지 않아 발생할 수 있는
                  <br />
                  가산세 부과에 대해서 AI 히어로즈는 책임이 없음을 알려드려요.
                </strong>
              </li>
              <li className="text-item">
                계약서에 따라 지급 형태가 선금 / 중도금 / 잔금으로 나누어질
                경우, 세금계산서는 각각 별도 발행돼요.
              </li>
              <li className="text-item">
                선금 / 중도금 / 잔금 지급과 일정은 계약서 기반으로 실행돼요.
              </li>
              <li className="text-item">
                <strong>
                  AI 히어로즈의 중개 수수료는 10%이며, 정산 시 수수료와 세금을
                  제외한 나머지 금액이 입금돼요.
                </strong>
              </li>
              {/* <li className="text-item">
								지급 형태와 관계없이, AI 히어로즈는 선금
								단계에서 전체 금액의 수수료 10%를 취득해요.
							</li> */}
            </ul>
          </div>
          <form
            name="reqKMCISForm"
            method="post"
            action="#"
            ref={reqKMCISFormRef}
            style={{ display: "none" }}
          >
            <input type="hidden" name="tr_cert" value={trCert} />
            <input type="hidden" name="tr_url" value={trUrl} />
            <input type="submit" value="KMC" />
          </form>
        </>
      )}
    </div>
  );
};

export default Revenue;
