import React from "react";
import CareerCard from "./CareerCard";
import EducationCard from "./CareerCard";
import styled from "styled-components";
const ArticleBox = ({ title, children }) => {
  return (
    <StyledArticleBox>
      <label>{title}</label>
      {children}
    </StyledArticleBox>
  );
};
const keywordBox = ({ data, ...restProps }) => {
  const checkSkills = (target) => {
    let hasTrueValue = false;
    for (let key in target) {
      if (target[key] === true) {
        hasTrueValue = true;
        break;
      }
    }
    return hasTrueValue;
  };
  return (
    (checkSkills(data.SKILL_RETENTION) ||
      checkSkills(data.BAILIWICK) ||
      checkSkills(data.FRAMEWORK)) && (
      <ArticleBox title="파트너 키워드">
        <div className="keyword-box">
          {data.SKILL_RETENTION !== null &&
            checkSkills(data.SKILL_RETENTION) && (
              <div>
                <label>적용 유형</label>
                <div className="badges">
                  {data?.SKILL_RETENTION?.VIDEO && (
                    <span className="badge m5 badge-md purple">동영상</span>
                  )}
                  {data?.SKILL_RETENTION?.IMAGE && (
                    <span className="badge m5 badge-md purple">이미지</span>
                  )}
                  {data?.SKILL_RETENTION?.SOUND && (
                    <span className="badge m5 badge-md purple">음성</span>
                  )}
                  {data?.SKILL_RETENTION?.TEXT && (
                    <span className="badge m5 badge-md purple">텍스트</span>
                  )}
                  {data?.SKILL_RETENTION?.BIG_data && (
                    <span className="badge m5 badge-md purple">빅데이터</span>
                  )}
                  {data?.SKILL_RETENTION?.FUSION && (
                    <span className="badge m5 badge-md purple">융합</span>
                  )}
                  {data?.SKILL_RETENTION?.SKILL_ETC && (
                    <span className="badge m5 badge-md purple">기타</span>
                  )}
                </div>
              </div>
            )}
          {data.BAILIWICK !== null && checkSkills(data.BAILIWICK) && (
            <div>
              <label>적용 산업 분야</label>
              <div className="badges">
                {data?.BAILIWICK?.EDUCATION && (
                  <span className="badge m5 badge-md purple">교육</span>
                )}
                {data?.BAILIWICK?.SERVICE && (
                  <span className="badge m5 badge-md purple">서비스</span>
                )}
                {data?.BAILIWICK?.MANUFACTURING && (
                  <span className="badge m5 badge-md purple">
                    제조, 화학, 에너지
                  </span>
                )}
                {data?.BAILIWICK?.VEHICLE && (
                  <span className="badge m5 badge-md purple">자동차</span>
                )}
                {data?.BAILIWICK?.SALES && (
                  <span className="badge m5 badge-md purple">판매, 유통</span>
                )}
                {data?.BAILIWICK?.MEDIA && (
                  <span className="badge m5 badge-md purple">
                    미디어, 엔터테인먼트
                  </span>
                )}
                {data?.BAILIWICK?.TOUR && (
                  <span className="badge m5 badge-md purple">
                    관광, 스포츠, 레저
                  </span>
                )}
                {data?.BAILIWICK?.CONSTRUCT && (
                  <span className="badge m5 badge-md purple">
                    부동산, 건설, 건축
                  </span>
                )}
                {data?.BAILIWICK?.FINANCE && (
                  <span className="badge m5 badge-md purple">금융</span>
                )}
                {data?.BAILIWICK?.MEDICAL && (
                  <span className="badge m5 badge-md purple">
                    의료, 제약, 바이오, 헬스케어
                  </span>
                )}
                {data?.BAILIWICK?.IT && (
                  <span className="badge m5 badge-md purple">IT, 통신</span>
                )}
                {data?.BAILIWICK?.GOVERNMENT && (
                  <span className="badge m5 badge-md purple">정부, 기관</span>
                )}
                {data?.BAILIWICK?.FOOD && (
                  <span className="badge m5 badge-md purple">식품</span>
                )}
                {data?.BAILIWICK?.AGRICULTURE && (
                  <span className="badge m5 badge-md purple">농업</span>
                )}
                {data?.BAILIWICK?.GAME && (
                  <span className="badge m5 badge-md purple">게임</span>
                )}
                {data?.BAILIWICK?.ETC && (
                  <span className="badge m5 badge-md purple">기타</span>
                )}
              </div>
            </div>
          )}
          {data.FRAMEWORK !== null && checkSkills(data.FRAMEWORK) && (
            <div>
              <label>사용 가능한 기술</label>
              <div className="badges">
                {data?.FRAMEWORK?.R && (
                  <span className="badge m5 badge-md purple">R</span>
                )}
                {data?.FRAMEWORK?.PYTHON && (
                  <span className="badge m5 badge-md purple">Python</span>
                )}
                {data?.FRAMEWORK?.C && (
                  <span className="badge m5 badge-md purple">C</span>
                )}
                {data?.FRAMEWORK?.CPP && (
                  <span className="badge m5 badge-md purple">C++</span>
                )}
                {data?.FRAMEWORK?.AOS && (
                  <span className="badge m5 badge-md purple">AOS</span>
                )}
                {data?.FRAMEWORK?.IOS && (
                  <span className="badge m5 badge-md purple">iOS</span>
                )}
                {data?.FRAMEWORK?.TENSORFLOW && (
                  <span className="badge m5 badge-md purple">Tensorflow</span>
                )}
                {data?.FRAMEWORK?.TENSOR_FORCE && (
                  <span className="badge m5 badge-md purple">TensorForce</span>
                )}
                {data?.FRAMEWORK?.KERAS && (
                  <span className="badge m5 badge-md purple">Keras</span>
                )}
                {data?.FRAMEWORK?.CAFFE && (
                  <span className="badge m5 badge-md purple">Caffe</span>
                )}
                {data?.FRAMEWORK?.CNTK && (
                  <span className="badge m5 badge-md purple">CNTK</span>
                )}
                {data?.FRAMEWORK?.PYTORCH && (
                  <span className="badge m5 badge-md purple">PyTorch</span>
                )}
                {data?.FRAMEWORK?.DAVID && (
                  <span className="badge m5 badge-md purple">David</span>
                )}
                {data?.FRAMEWORK?.WEB_PPL && (
                  <span className="badge m5 badge-md purple">WebPPL</span>
                )}
                {data?.FRAMEWORK?.CHURCH && (
                  <span className="badge m5 badge-md purple">Church</span>
                )}
                {data?.FRAMEWORK?.FIGARO && (
                  <span className="badge m5 badge-md purple">Figaro</span>
                )}
                {data?.FRAMEWORK?.OPENAI_GYM && (
                  <span className="badge m5 badge-md purple">OpenAI Gym</span>
                )}
                {data?.FRAMEWORK?.PYRO && (
                  <span className="badge m5 badge-md purple">Pyro</span>
                )}
                {data?.FRAMEWORK?.RLIB && (
                  <span className="badge m5 badge-md purple">Rlib</span>
                )}
              </div>
            </div>
          )}
        </div>
      </ArticleBox>
    )
  );
};

export default keywordBox;
const StyledArticleBox = styled.div`
  margin-top: 20px;
  label {
    color: var(--mono-m-black01, #333);

    /* Title5 600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.3px; /* 133.125% */
  }
  div.keyword-box {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    padding: 15px 18px;
    & div {
      display: flex;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
      & label {
        color: var(--mono-m-gray01, #999);
        width: 94px;
        /* Caption 400 */
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.9px; /* 132.5% */
        margin-top: 5px;
      }
      & .badges {
        flex-wrap: wrap;
        width: calc(100% - 94px);
        & span {
          margin-bottom: 10px;
        }
      }
    }
  }
  article {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 15.5px 18px;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    color: var(--mono-m-black01, #333);
    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.6px; /* 132.857% */
    &.flex-wrap {
      display: flex;
      padding: 15px 18px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 6px;
      align-self: stretch;
      flex-wrap: wrap;
    }
    & .info-input {
      display: flex;
      align-items: center;
      width: 50%;
      /* Body 500 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18.6px; /* 132.857% */
      label {
        color: var(--mono-m-gray01, #999);
        font-size: 14px;
        margin-right: 6px;
      }
      span {
      }
      img {
        margin-right: 6px;
      }
    }
  }
`;
