import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { getParameterByName } from 'utils/utils';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useModal from 'hooks/useModal';

const KakaoLogin = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const auth = useAuth();
	const { openModal, closeModal } = useModal();

	useEffect(() => {
		userAccessToken();
	}, [])

	const userAccessToken = () => {
		const code = getParameterByName(location.search, 'code');
		const params = {
			code,
			type: 0,
		}
		auth.socialLogin(params, (res) => {
			if (res.code === 0) {
				if (res.response.jwt) {
					navigate('/', { replace: true });
					// console.log('로그인 성공');
				} else {
					const { EMAIL, KAKAO_ID, SOCIAL_TYPE } = res.response;
					navigate('/join', { state: { userEmail: EMAIL, socialId: KAKAO_ID, socialType: SOCIAL_TYPE } });
				}
			} else {
				if (typeof res.response === 'string') {
					if (res.code === 1) {
						openModal({
							type: 'messageModal',
							contents: {
								title: '로그인 요청 실패',
								message: '탈퇴한 회원입니다.',
								confirmText: '확인',
								confirmCallback: () => {
									navigate('/');
									closeModal();
								},
							}
						});
					} else if (res.code === 2) {
						openModal({
							type: 'messageModal',
							contents: {
								title: '로그인 요청 실패',
								message: '이미 가입된 이메일 계정이에요.',
								confirmText: '확인',
								confirmCallback: () => {
									navigate('/');
									closeModal();
								},
							}
						});
					}
				}
			}
		});
	}

	return (
		<div className='container'>
			<LoadingSpinner />
		</div>
	)
}

export default KakaoLogin