import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useAuth from "hooks/useAuth";
import {
  getHerosList,
  getHerosDibsList,
  userHerosDibsChange,
  getBannerList,
} from "services/heros";
import useModal from "hooks/useModal";

import Select from "components/common/Select";
import MultiSelect from "components/common/MultiSelect";
import Pagination from "components/common/Pagination";

import LoadingSpinner from "components/common/LoadingSpinner";
import PartnerCard from "./components/PartnerCard";
import BannerCard from "./components/BannerCard";
import useViewCheck from "hooks/useViewCheck";

import heartLine from "assets/images/icon/heart_line.svg";
import heartFill from "assets/images/icon/heart_fill.svg";
const Wrapper = styled.section`
  display: inline-flex;
  position: relative;
  width: ${(props) => (props.mobile ? "calc(100vw - 50px)" : "100%")};
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};

  & .btn-like {
    width: 100%;
    height: 100%;
    min-width: 26px;
    background-size: 100%;
    background-image: none;
    & img {
      transition: opacity 0.3s ease-in-out;
    }
    /* Define styles for heartFill and heartLine */
    .heartFill {
      opacity: 1;
    }

    .heartLine {
      opacity: 0.4;
    }

    /* Apply transition to opacity for a smooth fade effect */
  }
`;
const LeftContainer = styled.section`
  width: ${(props) => (props.mobile ? "100%" : "740px")};
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  & .pagination-wrapper {
    & .pagination {
      padding-top: 15px !important;
    }
  }
`;

const RightContainer = styled.section`
  width: calc(100% - 740px);
  flex-direction: column;
  display: flex;
  & div.list-wrapper {
    position: sticky;
    top: 100px;
    margin: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 160px;
  }
`;

const orderList = [
  {
    id: 1,
    name: "최신순",
  },
  {
    id: 2,
    name: "경력높은 순",
  },
  {
    id: 3,
    name: "평점높은 순",
  },
];

const partnerOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "기업 파트너",
    apiName: "BUSINESS",
    state: true,
  },
  {
    name: "개인 파트너",
    apiName: "PERSONAL",
    state: true,
  },
];
const workMethodOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "상주,원격 모두 가능",
    apiName: "BOTH",
    state: true,
  },
  {
    name: "원격 근무 가능",
    apiName: "REMOTE",
    state: true,
  },
  {
    name: "상주 근무 가능",
    apiName: "RESIDENT",
    state: true,
  },
];

const Heroes = () => {
  const { isMobile } = useViewCheck();
  const navigate = useNavigate();
  const { isLoggedin, userInfo } = useAuth();
  const [selectPartner, setSelectPartner] = useState(
    partnerOptions.map((a) => a.name)
  );
  const { openModal } = useModal();

  const [selectMethod, setSelectWorkMethod] = useState(
    workMethodOptions.map((a) => a.name)
  );
  const [order, setOrder] = useState(1);
  const [userGrade, setUserGrade] = useState("");
  const [heroesList, setHeroesList] = useState([]);
  const [heroseDibsList, setHeroesDibsList] = useState([]);
  const [heroesTotal, setHeroesTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [generativeList, setGenerativeList] = useState([]);
  const [newList, setNewList] = useState([]);
  useEffect(() => {
    getList();
  }, [page, selectPartner, selectMethod, order]);
  useEffect(() => {
    getBannerUserList("GENERATIVE_AI", 4);
    getBannerUserList("RECENT", 4);
  }, []);
  useEffect(() => {
    // 기존 isLoggedin은 한번 렌더링이 된 후에만 정상 동작하게끔 구현되어 있어 임시로 대체합니다
    if (localStorage.getItem('jwt')) {
      getHerosDibsList((res) => {
        setHeroesDibsList([...res.response.map((item) => item.HEROS)]);
      });
      setUserGrade(userInfo.ROLE);
    } else {
      navigate("/");
    }
  }, [userInfo, isLoggedin]);
  const getBannerUserList = (type, limit) => {
    getBannerList(type, limit, (res) => {
      if (res.code !== 0) return;
      if (type === "GENERATIVE_AI") {
        setGenerativeList(res.response);
      } else {
        setNewList(res.response);
      }
    });
  };
  const getList = useCallback(() => {
    const params = {
      sortType: order,
      page,
    };

    let businessPersonalList = null;
    partnerOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectPartner.includes("전체")) {
        businessPersonalList = ["PERSONAL", "BUSINESS"];
      } else if (selectPartner.length === 0) {
        businessPersonalList = null;
      } else if (selectPartner.includes(item.name)) {
        businessPersonalList = [];
        businessPersonalList.push(item.apiName);
      }
    });
    let workMethodList = null;
    workMethodOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectMethod.includes("전체")) {
        workMethodList = ["BOTH", "REMOTE", "RESIDENT"];
      } else if (selectMethod.includes(item.name)) {
        workMethodList = [];
        workMethodList.push(item.apiName);
      } else if (selectMethod.length === 0) {
        workMethodList = null;
      }
    });

    getHerosList(params, { businessPersonalList, workMethodList }, (res) => {
      if (res.code !== 0) return;

      // 24.02.02 임시 리스트 중복 제거 필터링
      const arr = res.response.list;
      const filteredList = arr.filter((obj, idx) => {
        const isFirstFindIdx = arr.findIndex((obj2) => obj2.SEQ === obj.SEQ);
        return isFirstFindIdx === idx;
      });
      setHeroesList(filteredList);
      // 24.02.02 임시 리스트 중복 제거 필터링

      setHeroesTotal(res.response.total);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
      setLoading(false);
    });
  }, [page, selectPartner, selectMethod, order]);

  const onClickHeroseDibs = (e, id) => {
    e.stopPropagation();
    userHerosDibsChange(id, (res) => {
      getHerosDibsList((res) => {
        setHeroesDibsList([...res.response.map((item) => item.HEROS)]);
      });
    });
  };

  return (
    <div className="container">
      <Wrapper mobile={isMobile}>
        <LeftContainer mobile={isMobile}>
          {(userGrade === "ROLE_USER" || !isLoggedin) && (
            <div className="mt-40 m-none">
              <button
                type="button"
                className="btn btn-md btn-black type2 link"
                onClick={() => {
                  if (isLoggedin) {
                    navigate("/mypage/profile?type=register");
                  } else {
                    openModal({ type: "loginModal" });
                  }
                }}
              >
                파트너 등록하기
              </button>
            </div>
          )}
          <div className={`search-top mt-40`}>
            {/* 회원수가 100 명이 될때까지 비활성화 되야함 */}
            {heroesTotal >= 100 && (
              <div className="count">전체 {heroesTotal}명</div>
            )}
            <div className="input">
              <div className="left">
                <MultiSelect
                  width={"200px"}
                  value="파트너 구분"
                  options={partnerOptions}
                  selected={selectPartner}
                  setSelect={setSelectPartner}
                  zIndex={1}
                />
                <MultiSelect
                  width={"235px"}
                  value="근무 형태"
                  options={workMethodOptions}
                  selected={selectMethod}
                  setSelect={setSelectWorkMethod}
                />
                <Select
                  className={[`text-start m-full`]}
                  width={"135px"}
                  option={orderList}
                  selected={order}
                  setSelect={setOrder}
                />
              </div>
            </div>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {Boolean(heroesList.length) &&
                heroesList.map((item, idx) => (
                  <div
                    onClick={() => {
                      if (isLoggedin) {
                        navigate(`/heroes/${item.SEQ}`);
                      } else {
                        openModal({ type: "loginModal" });
                      }
                    }}
                    key={`heroes-list--item-${item.SEQ}_${idx}`}
                  >
                    <PartnerCard isMobile={isMobile} item={item}>
                      {isLoggedin && item.SEQ !== userInfo.SEQ && (
                        <div
                          style={
                            isMobile
                              ? {
                                  position: "absolute",
                                  right: "14px",
                                  width: "26px",
                                  height: "26px",
                                }
                              : { width: "26px", height: "26px" }
                          }
                        >
                          <button
                            type="button"
                            className={`btn btn-like ${
                              heroseDibsList.includes(item.SEQ) ? "active" : ""
                            }`}
                            onClick={(e) => {
                              onClickHeroseDibs(e, item.SEQ);
                            }}
                          >
                            <img
                              alt="like"
                              className={
                                heroseDibsList.includes(item.SEQ)
                                  ? "heartFill"
                                  : "heartLine"
                              }
                              src={
                                heroseDibsList.includes(item.SEQ)
                                  ? heartFill
                                  : heartLine
                              }
                            />
                          </button>
                        </div>
                      )}
                    </PartnerCard>
                  </div>
                ))}
            </>
          )}
          <div className="pagination-wrapper">
            {paginationData && (
              <Pagination
                paginationData={paginationData}
                page={page}
                setPage={setPage}
                floatingPage={userGrade === "ROLE_USER" || !isLoggedin}
              />
            )}
          </div>
        </LeftContainer>
        {!isMobile && (
          <RightContainer mobile={isMobile} login={isLoggedin}>
            {userInfo.ROLE !== null && userInfo.ROLE.includes("HEROS")
              ? !isMobile && <div style={{ marginTop: "106px" }}></div>
              : !isMobile && <div style={{ marginTop: "180px" }}></div>}

            <div className="list-wrapper">
              {newList.length > 0 && (
                <BannerCard isMobile={isMobile} item={newList} type={"new"} />
              )}
              {generativeList.length > 0 && (
                <BannerCard
                  isMobile={isMobile}
                  item={generativeList}
                  type={"genAi"}
                />
              )}
            </div>
          </RightContainer>
        )}
      </Wrapper>
    </div>
  );
};

export default Heroes;
