import api from 'api';

export const topicHome = (callback) => {
	api.get(`/topic/view/list/home`).then(res => callback(res))
}

export const topicPowerOn = (callback) => {
	api.get(`/topic/view/power-on`).then(res => callback(res))
}

export const topicRanking = (callback) => {
	api.get(`/topic/view/ranking`).then(res => callback(res))
}

export const topicSearch = (params, callback) => {
	api.get(`/topic/view/search`, { params }).then(res => callback(res))
}

export const aiStoryHome = (callback) => {
	api.get(`/ai/view/list/home`).then(res => callback(res))
}

export const aiStoryList = (params, callback) => {
	api.get(`/ai/view/list`, { params }).then(res => callback(res))
}

export const aiStoryDetail = (seq, callback) => {
	api.get(`/ai/view/${seq}`, { params: { seq } }).then(res => callback(res))
}

export const topicList = (params, callback) => {
	api.get(`/topic/view/list`, { params }).then(res => callback(res))
}

export const topicWrite = (body, callback) => {
	api.post(`/topic/auth/write`, body).then(res => callback(res))
}

export const topicModify = (seq, body, callback) => {
	api.post(`/topic/auth/modify/${seq}`, body).then(res => callback(res))
}

export const topicDetail = (seq, callback) => {
	api.get(`/topic/view/${seq}`).then(res => callback(res))
}

export const topicDetailReport = (seq, body, callback) => {
	api.post(`/topic/auth/report/${seq}`, body, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplySelect = (seq, callback) => {
	api.post(`/topic/auth/reply/select/${seq}`, null, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReply = (params, callback) => {
	api.get(`/topic/view/reply/${params.seq}`, { params }).then(res => callback(res))
}

export const topicDetailReplyHot = (params, callback) => {
	api.get(`/topic/view/reply/hot/${params.seq}`, { params }).then(res => callback(res))
}


export const topicDetailReReply = (seq, callback) => {
	api.get(`/topic/view/re-reply/${seq}`, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplyWrite = (seq, body, callback) => {
	api.post(`/topic/auth/reply/write/${seq}`, body, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplyModify = (seq, body, callback) => {
	api.post(`/topic/auth/reply/modify/${seq}`, body, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplyDelete = (seq, callback) => {
	api.delete(`/topic/auth/reply/delete/${seq}`, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplyReport = (seq, body, callback) => {
	api.post(`/topic/auth/reply/report/${seq}`, body, { params: { seq } }).then(res => callback(res))
}

export const topicDetailReplyReaction = (seq, params, callback) => {
	api.post(`/topic/auth/reply/reaction/${seq}`, null, { params }).then(res => callback(res))
}

export const topicDetailReReplyWrite = (seq, body, callback) => {
	api.post(`/topic/auth/re-reply/write/${seq}`, body, { params: { seq } }).then(res => callback(res))
}

export const consultViewList = (params, callback) => {
	api.get(`/consult/view/list/represent`, { params }).then((res) => callback(res))
}

export const consultViewDetail = (seq, callback) => {
	api.get(`/consult/view/${seq}`).then((res) => callback(res))
}

export const storyReaction = (seq, callback) => {
	api.post(`/ai/reaction/view/${seq}`, null, { params: { seq } }).then((res) => callback(res))
}

export const storyReactionApply = (seq, params, callback) => {
	api.post(`/ai/auth/reaction/${seq}`, null, { params }).then((res) => callback(res))
}
