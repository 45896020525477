import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { aiStoryList } from "services/topic";

import Select from "components/common/Select";
import { useIntersect } from "hooks/useIntersect";
import useViewCheck from "hooks/useViewCheck";
import { dateFormatType2NoTime, getParameterByName } from "utils/utils";

const orderList = [
  {
    id: 1,
    name: "최신순",
  },
  {
    id: 2,
    name: "인기순",
  },
];

const AiStory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();
  const [sortType, setSortType] = useState(1);
  const [category, setCategory] = useState("ALL");
  const [aistory, setAistory] = useState([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [isFetching, setFetching] = useState(false);
  const [hasNextPage, setNextPage] = useState(true);

  useEffect(() => {
    if (!isFetching) {
      setFetching(true);
      getAiStoryType2();
    }
  }, [category, sortType]);

  useEffect(() => {
    const tab = getParameterByName(location.search, "tab");
    setCategory(tab ? tab : "ALL");
  }, [location]);

  const ref = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      setFetching(true);
      getAiStory();
    }
  });

  const getAiStory = useCallback(() => {
    const params = {
      category,
      page,
      sortType,
    };
    if (!isMobile && page === 1) {
      params.page = 2;
    }
    aiStoryList(params, (res) => {
      if (page === 1) {
        setPage(3);
      } else {
        setPage(page + 1);
      }
      setAistory([...aistory, ...res.response.list]);
      setTotal(res.response.total);
      setNextPage(!res.response.isLastPage);
      setFetching(false);
    });
  }, [aistory, page, category, sortType]);

  const getAiStoryType2 = useCallback(() => {
    const params = {
      category,
      page: 1,
      sortType,
    };
    // console.log(page)
    aiStoryList(params, (res) => {
      setPage(1);
      setAistory(res.response.list);
      setTotal(res.response.total);
      setNextPage(!res.response.isLastPage);
      setFetching(false);
    });
  }, [category, sortType]);

  const categoryName = (category) => {
    switch (category) {
      case "INTERVIEW":
        return "인터뷰";
      case "TREND":
        return "트렌드";
      case "VIDEO":
        return "동영상";
      case "IMAGE":
        return "이미지";
      case "SOUND":
        return "음성";
      case "TEXT":
        return "텍스트";
      case "BIG_DATA":
        return "빅데이터";
      case "ETC":
        return "기타";
      case "NOTICE":
        return "공지";
      default:
        return "";
    }
  };

  const categoryColor = (category) => {
    switch (category) {
      case "INTERVIEW":
        return "primary";
      case "TREND":
        return "pink";
      case "VIDEO":
        return "lime";
      case "IMAGE":
        return "orange fill";
      case "SOUND":
        return "yellow";
      case "TEXT":
        return "purple2";
      case "BIG_DATA":
        return "blue";
      case "ETC":
        return "grey2";
      case "NOTICE":
        return "skyblue";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="search-top">
        {aistory && <div className="count">전체 {total}개</div>}
      </div>
      <div className="community-category--top type-flex ju-s">
        <div className="left">
          {!isMobile ? (
            <>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"ALL"}
                  checked={category === "ALL"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">전체</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"INTERVIEW"}
                  checked={category === "INTERVIEW"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">인터뷰</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"TREND"}
                  checked={category === "TREND"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">트렌드</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"VIDEO"}
                  checked={category === "VIDEO"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">동영상</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"IMAGE"}
                  checked={category === "IMAGE"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">이미지</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"SOUND"}
                  checked={category === "SOUND"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">음성</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"TEXT"}
                  checked={category === "TEXT"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">텍스트</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"BIG_DATA"}
                  checked={category === "BIG_DATA"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">빅데이터</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value={"ETC"}
                  checked={category === "ETC"}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <span className="label wide">기타</span>
              </label>
            </>
          ) : (
            <>
              <Select
                className={["sm text-start"]}
                width={148}
                option={[
                  {
                    id: "ALL",
                    name: "전체",
                  },
                  {
                    id: "INTERVIEW",
                    name: "인터뷰",
                  },
                  {
                    id: "TREND",
                    name: "트렌드",
                  },
                  {
                    id: "VIDEO",
                    name: "동영상",
                  },
                  {
                    id: "IMAGE",
                    name: "이미지",
                  },
                  {
                    id: "SOUND",
                    name: "음성",
                  },
                  {
                    id: "TEXT",
                    name: "텍스트",
                  },
                  {
                    id: "BIG_DATA",
                    name: "빅데이터",
                  },
                  {
                    id: "ETC",
                    name: "기타",
                  },
                ]}
                selected={category}
                setSelect={(value) => {
                  setCategory(value);
                }}
              />
            </>
          )}
        </div>
        <div className="right">
          <Select
            option={orderList}
            selected={sortType}
            setSelect={setSortType}
            width={105}
          />
        </div>
      </div>
      <ul className="community-list type2">
        {Boolean(aistory.length) && (
          <>
            {aistory.map((item) => (
              <li
                className="community-list--item"
                key={`community-list--item-${item.SEQ}`}
                onClick={() => navigate(`${item.SEQ}`)}
              >
                <div className="top">
                  <span
                    className={`badge badge-md ${categoryColor(item.CATEGORY)}`}
                  >
                    {categoryName(item.CATEGORY)}
                  </span>
                </div>
                <div className="tit">{item.TITLE}</div>
                <div className="con">
                  <div className="left">
                    <img src={item.THUMBNAIL} alt="" />
                  </div>
                  <div className="right">
                    <div className="tag">{item.ORGANIZATION}</div>
                    <div className="name">{item.RESEARCHER}</div>
                    <div className="hash">
                      {item.KEYWORD.split(",").map((keyword, i) => (
                        <span key={`keyword-list-${i}`}>{keyword}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="left">
                    <span className="like">{item.REACTION_CNT}</span>
                    <span className="view">{item.VIEW}</span>
                  </div>
                  <span className="date">
                    {dateFormatType2NoTime(item.CREATED_AT)}
                  </span>
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
      {page > 2 && (
        <button
          type="button"
          className="btn btn-floating"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        ></button>
      )}
      <div style={{ height: 1 }} ref={ref} />
    </>
  );
};

export default AiStory;
