import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";

import { userPasswordChange } from "services/user";
import { passwordRegEx } from "utils/regex";
import useViewCheck from "hooks/useViewCheck";

import styled from "styled-components";
const StyledModal = styled.div`
  & .ml-10 {
    margin-left: 10px;
  }
`;
const ChangePasswordModal = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const [password, setPassword] = useState({
    curPassword: "",
    newPassword: "",
  });

  const [curPasswordHide, setCurPasswordHide] = useState(true);
  const [newPasswordHide, setNewPasswordHide] = useState(false);
  const [curPasswordError, setCurPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [passwordErrorType2, setPasswordErrorType2] = useState("");

  const changeInput = (type, value) => {
    setPassword({
      ...password,
      [type]: value,
    });
  };

  const signOut = () => {
    auth.signout(() => {
      // console.log('로그아웃 성공');
      navigate("/");
    });
  };

  const changePassword = useCallback(() => {
    userPasswordChange(
      {
        oldPassword: password.curPassword,
        newPassword: password.newPassword,
      },
      (res) => {
        if (res.code === 0) {
          openModal({
            type: "messageModal",
            contents: {
              title: "비밀번호 변경 완료",
              message: "정상적으로 비밀번호가 변경됐어요.",
              confirmText: "로그인하기",
              confirmCallback: () => {
                signOut();
                openModal({ type: "loginModal" });
              },
            },
          });
        } else {
          setPasswordErrorType2(true);
        }
      }
    );
  }, [password]);

  return (
    <StyledModal className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con message">
        <div className="text">
          <p className="strong">비밀번호 변경</p>
        </div>
        <div className="input">
          <div className="input-wrap">
            <label className="input-label sm mt-0">현재 비밀번호</label>
            <div className="input-password">
              <input
                type={curPasswordHide ? "password" : "text"}
                className={`input-text ${
                  curPasswordError || passwordErrorType2 ? "error" : ""
                }`}
                placeholder="현재 비밀번호를 입력해 주세요."
                value={password.curPassword}
                onChange={(e) => {
                  if (passwordErrorType2) setPasswordErrorType2(false);
                  setCurPasswordError(false);
                  changeInput("curPassword", e.target.value);
                }}
                onBlur={(e) => {
                  if (!e.target.value) return;
                  if (passwordRegEx(e.target.value)) {
                    setCurPasswordError(false);
                  } else {
                    setCurPasswordError(true);
                  }
                }}
                autoComplete="nope"
              />
              <button
                onClick={() => {
                  setCurPasswordHide(!curPasswordHide);
                }}
                type="button"
                className={`btn ${curPasswordHide ? "hide" : "show"}`}
              ></button>
            </div>
            {passwordErrorType2 ? (
              <p className="input-guide error ml-10">
                비밀번호가 일치하지 않아요. 다시 확인해 주세요. 계정 정보를 찾고
                다시 시도해 주세요.
              </p>
            ) : curPasswordError ? (
              <p className="input-guide error ml-10">
                영문+숫자+특수문자 조합 8~16자리 비밀번호가 아니에요.
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="input mt-20">
          <div className="input-wrap">
            <label className="input-label sm mt-0">새 비밀번호</label>
            <div className="input-password">
              <input
                type={newPasswordHide ? "password" : "text"}
                className={`input-text ${newPasswordError ? "error" : ""}`}
                placeholder={
                  isMobile
                    ? "영문+숫자+특수문자 조합 8~16자 이하"
                    : "영문+숫자+특수문자 조합 8~16자리의 새로운 비밀번호를 입력해 주세요."
                }
                value={password.newPassword}
                onChange={(e) => {
                  setNewPasswordError(false);
                  changeInput("newPassword", e.target.value);
                }}
                onBlur={(e) => {
                  if (!e.target.value) return;
                  if (passwordRegEx(e.target.value)) {
                    setNewPasswordError(false);
                  } else {
                    setNewPasswordError(true);
                  }
                }}
                autoComplete="nope"
              />
              <button
                onClick={() => {
                  setNewPasswordHide(!newPasswordHide);
                }}
                type="button"
                className={`btn ${newPasswordHide ? "hide" : "show"}`}
              ></button>
            </div>
            {newPasswordError && (
              <p className="input-guide error ml-10">
                영문+숫자+특수문자 조합 8~16자리 비밀번호가 아니에요.
              </p>
            )}
          </div>
        </div>
        <div className="btn-wrap">
          <button
            onClick={() => {
              changePassword();
            }}
            type="button"
            className="btn btn-half btn-primary"
          >
            변경하기
          </button>
          <button
            onClick={() => closeModal()}
            type="button"
            className="btn btn-half btn-grey"
          >
            돌아가기
          </button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ChangePasswordModal;
