import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import Idelete from "assets/images/icon/icon_delete.svg";

import { calculateWidth, addLeadingZero } from "utils/utils";

const extractYearAndMonth = (dateString) => {
  if (!dateString) {
    return { yyyy: "", mm: "", dd: "" };
  }

  const [yyyy, mm, dd] = dateString.split("-");

  if (yyyy && mm) {
    return { yyyy, mm };
  } else {
    // Handle the case where dateString does not contain both year and month
    return { yyyy, mm, dd };
  }
};

const DateInput = ({ handleDate, data }) => {
  const { yyyy, mm, dd } = extractYearAndMonth(data);
  const [year, setYear] = useState(yyyy || "");
  const [month, setMonth] = useState(mm || "");
  const [inputMessage, setInputMessage] = useState("");

  const updateDate = (inputYear, inputMonth) => {
    const numericYear = parseInt(inputYear, 10);
    const numericMonth = parseInt(inputMonth, 10);

    if (!isNaN(numericYear) && !isNaN(numericMonth)) {
      setInputMessage("");
      handleDate(numericYear, numericMonth);
    } else {
      setInputMessage("날짜는 YYYY-MM 형식으로 입력해 주세요.");
    }
  };

  return (
    <InputWrapper>
      <Input
        type="text"
        maxLength={4}
        className="input y"
        placeholder="YYYY"
        value={year}
        onChange={(e) => {
          const newYear = e.target.value.replace(/\D/, "");
          setYear(newYear);
          updateDate(newYear, month);
        }}
      />
      .
      <Input
        type="text"
        maxLength={2}
        className="input m"
        placeholder="MM"
        value={addLeadingZero(month)}
        onChange={(e) => {
          const newMonth = e.target.value.replace(/\D/, "");
          setMonth(newMonth);
          updateDate(year, newMonth);
        }}
      />
    </InputWrapper>
  );
};
const EducationCard = ({
  seq,
  handleEduDelete,
  handleEduData,
  data,
  isFirst,
}) => {
  // form => careerForm
  const [schoolName, setSchoolName] = useState(data.schoolName || "");
  const [degreeName, setDegreeName] = useState(data.educationInfo || "");
  const [eduDate, setEduDate] = useState({
    startAt: data.startAt || "",
    endAt: data.endAt || "",
  });
  const handleStartDate = (yyyy, mm) => {
    setEduDate({ ...eduDate, startAt: `${yyyy}-${mm}` });
  };
  const handleEndDate = (yyyy, mm) => {
    setEduDate({ ...eduDate, endAt: `${yyyy}-${mm}` });
  };
  useEffect(() => {
    if (degreeName === undefined && degreeName === null) {
      setDegreeName("");
    }
    handleEduData({
      seq: seq,
      schoolName,
      startAt: eduDate.startAt,
      endAt: eduDate.endAt,
      educationInfo: degreeName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eduDate, schoolName, degreeName]);
  return (
    <StyledWrapper>
      <InfoBox>
        <div className="header">
          <Input
            type="text"
            className={`project-title ${
              calculateWidth(schoolName) > 150 ? "ellipsis" : ""
            }`}
            placeholder="학교 이름"
            style={{
              width: `${calculateWidth(schoolName)}px`,
              minWidth: schoolName.length === 0 && "52px",
              maxWidth: "150px",
            }}
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          /
          <Input
            type="text"
            className={`project-title ${
              calculateWidth(degreeName) > 500 ? "ellipsis" : ""
            }`}
            placeholder="전공 및 학위"
            style={{
              width: `${calculateWidth(degreeName)}px`,
              minWidth: degreeName.length === 0 && "68px",
              maxWidth: "500px",
            }}
            value={degreeName}
            onChange={(e) => setDegreeName(e.target.value)}
          />
          <div className="date-group">
            <div className="flex">
              <DateInput data={eduDate.startAt} handleDate={handleStartDate} />
              <span style={{ fontSize: "16px", margin: "0 4px" }}>~</span>
              <DateInput data={eduDate.endAt} handleDate={handleEndDate} />
            </div>
          </div>
        </div>
        <button onClick={handleEduDelete}>
          <img src={Idelete} alt="delete" style={{ cursor: "pointer" }} />
        </button>
      </InfoBox>
    </StyledWrapper>
  );
};

export default React.memo(EducationCard);

const StyledWrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 0px 18px;
  margin: 5px 0;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  position: relative;
  z-index: 0;
  & input {
    font-weight: 700;
  }
  & .flex {
    display: flex;
  }
`;
const InfoBox = styled.div`
  color: var(--gray02, #999);
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  & .header {
    z-index: 1;
    display: inline-flex;
    width: inherit;
    align-items: center;
    border: none;
    gap: 6px;
    align-self: stretch;
    flex-wrap: wrap;
    padding-right: 20px;
    & input {
      &:first-child {
        margin-left: 0;
      }
      /* Body 600 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.6px; /* 132.857% */
    }
  }
  & button {
    position: absolute;
    z-index: 999;
    border: none;
    padding: 0;
    right: 0;
    line-height: 55px;
    background-color: transparent;
  }
  /* Body 400 */
  & .date-group {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: space-between;
    & input {
      margin: 0 !important;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & input {
    width: 35px !important;
    margin: 0;
    color: var(--black100, #000);

    /* Body 400 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100%;
    &:last-child {
      width: 24px !important;
    }
  }
`;
