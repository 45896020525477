import React, { useRef, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import BadgeCard from "./BadgeCard";

import sliderArrowLeftIcon from "assets/images/icon/slider_arrow_left.svg";
import sliderArrowRightIcon from "assets/images/icon/slider_arrow_right.svg";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import { useNavigate } from "react-router-dom";

const Category = ({
  type, // partner, plain_detail, plain_simple
  items,
  title,
  slidesToShow,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { isLoggedin } = useAuth();

  const [dragging, setDragging] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: items.length < slidesToShow ? items.length : slidesToShow,
    slidesToScroll: items.length < slidesToShow ? items.length : slidesToShow,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };

  /** url 이동 */
  const move2URL = (url) => {
    if (dragging || !url) return;
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else {
      if (url === "/aiproject/request" || url.includes("heroes")) {
        if (isLoggedin) {
          navigate(url);
        } else {
          openModal({ type: "loginModal" });
        }
      } else {
        navigate(url);
      }
    }
  };

  return (
    <ComponentWrapper
      $type={type}
      className={items.length < slidesToShow && "align-left"}
    >
      <TitleWrapper>
        <h1>{title}</h1>

        {type === "partner" && items.length > slidesToShow && (
          <div className="arrow-wrapper">
            <img
              src={sliderArrowLeftIcon}
              alt="icon-prev"
              onClick={() => ref.current.slickPrev()}
            />
            <img
              src={sliderArrowRightIcon}
              alt="icon-next"
              onClick={() => ref.current.slickNext()}
            />
          </div>
        )}
      </TitleWrapper>

      <Slider ref={ref} {...settings}>
        {items.map((item, i) => (
          <BadgeCard
            key={`${item.SEQ}_${i}`}
            type={type}
            card={item}
            move2URL={(url) => move2URL(url)}
          />
        ))}
      </Slider>
    </ComponentWrapper>
  );
};

export default Category;

const ComponentWrapper = styled.div`
  overflow: hidden;

  & .slick-track {
    & > .slick-slide {
      display: flex;
      justify-content: center;
    }
  }

  &.align-left {
    & .slick-track {
      display: flex;

      & > .slick-slide {
        width: unset !important;

        &:nth-child(2) {
          margin: ${(props) =>
            props.type === "plain_simple" ? "0 16px" : "0 19px"};
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  & > h1 {
    font-size: 20px;
    font-weight: 800;
    line-height: 133%;
    text-align: left;
  }

  & > .arrow-wrapper {
    display: flex;
    justify-content: end;
    gap: 10px;

    & > img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
`;
