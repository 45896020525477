import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import {
	userProjectDetailTaskApply,
	userProjectDetailTaskModify,
	userProjectDetailTaskRefuse,
	userProjectDetailTaskApproval,
} from "services/projectManagement";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from "utils/utils";

const TaskModal = ({ type, seq, callback = () => { }, taskData = null }) => {
	const { closeModal } = useModal();
	const location = useLocation();
	const [text, setText] = useState("");
	const [date, setDate] = useState(new Date());
	const [dateError, setDateError] = useState(false);
	const [textError, setTextError] = useState(false);

	useEffect(() => {
		if (!taskData) return;
		if (taskData.date) {
			setDate(new Date(taskData.date));
		}
	}, [taskData]);
	return (
		<div className="modal show">
			<div className="modal-dim"></div>
			<div className="modal-con message">
				{type === "APPLY" && (
					<div className="text" style={{ color: "#d6d6d6" }}>
						<p className="strong" style={{ color: "#fff" }}>
							TASK 추가
						</p>
						<p>새로운 TASK의 목표 및 완료 예정일을 입력해 주세요.</p>
					</div>
				)}
				{type === "SUGGEST" && (
					<div className="text" style={{ color: "#d6d6d6" }}>
						<p className="strong" style={{ color: "#fff" }}>
							수정 요청
						</p>
						<p>
							TASK 목표 및 완료 예정일에 대해 수정을 요청하세요.
						</p>
					</div>
				)}

				{type === "ACCREJ" && (
					<>
						<button
							type="button"
							className="btn btn-close wh"
							onClick={() => closeModal()}
						></button>
						<div className="text" style={{ color: "#d6d6d6" }}>
							<p className="strong" style={{ color: "#fff" }}>
								요청 수락/거절
							</p>
							<span
								style={{ fontSize: "14px", lineHeight: 1.5, fontWeight: 200 }}
							>
								{location.pathname?.split("/")[2] === "aiprojecth"
									? "클라이언트"
									: "파트너"}
								와 논의된 사항인지 확인 후 답해 주세요.
								<br />
								요청을 수락하면 다른 TASK 일정에 영향이 생길 수 있어요.
							</span>
						</div>
					</>
				)}
				{type !== "ACCREJ" ? (
					<div className="input">
						<input
							type="text"
							className={`input-text type2 ${textError ? "error" : ""}`}
							placeholder="요청하실 내용을 입력해 주세요."
							value={text}
							onChange={(e) => {
								if (textError) setTextError(false);
								setText(e.target.value);
							}}
						/>
						<div
							className="sm"
							style={{
								height: 50,
							}}
						>
							<DatePicker
								selected={date}
								onChange={(res) => {
									if (dateError) setDateError(false);
									setDate(res);
								}}
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								dateFormatCalendar="yyyy년 MM월"
								locale={ko}
								customInput={
									<input
										className={`input-text input-date ${dateError ? "error" : ""
											}`}
										readOnly
									/>
								}
								placeholderText="YYYY-MM-DD"
							/>
						</div>
					</div>
				) : (
					<div className="input">
						<input
							className="input-text type2"
							value={taskData.text}
							readOnly
						/>
						<div
							className="sm"
							style={{
								height: 50,
							}}
						>
							<input
								className="input-text input-date"
								value={taskData.date}
								readOnly
							/>
						</div>
					</div>
				)}
				<div className="btn-wrap">
					{type === "ACCREJ" ? (
						<>
							<button
								type="button"
								className="btn btn-half btn-primary"
								onClick={() => {
									userProjectDetailTaskApproval(seq, { reason: "" }, (res) => {
										if (res.code !== 0) return;
										callback();
										closeModal();
									});
								}}
							>
								수락하기
							</button>
							<button
								type="button"
								className="btn btn-half btn-grey"
								onClick={() => {
									userProjectDetailTaskRefuse(seq, { reason: "" }, (res) => {
										if (res.code !== 0) return;
										callback();
										closeModal();
									});
								}}
							>
								거절하기
							</button>
						</>
					) : (
						<>
							<button
								type="button"
								className="btn btn-half btn-primary"
								onClick={() => {
									if (!date) {
										setDateError(true);
										if (!text) {
											setTextError(true);
										}
										return;
									}
									if (!text) {
										setTextError(true);
										return;
									}
									if (type === "APPLY") {
										const body = {
											name: text,
											expectedCompletedAt: dateFormat(date),
										};
										userProjectDetailTaskApply(seq, body, (res) => {
											if (res.code !== 0) return;
											callback();
											closeModal();
										});
									}
									if (type === "SUGGEST") {
										const body = {
											name: text,
											expectedCompletedAt: dateFormat(date),
										};
										userProjectDetailTaskModify(seq, body, (res) => {
											if (res.code !== 0) return;
											callback();
											closeModal();
										});
									}
								}}
							>
								요청하기
							</button>
							<button
								type="button"
								className="btn btn-half btn-grey"
								onClick={() => closeModal()}
							>
								돌아가기
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TaskModal;
