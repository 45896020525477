import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  alarmListView,
  authAlarmDelete,
  authAlarmDeleteAll,
  authAlarmDeleteCheckedAlarm,
  authAlarmRead,
} from "services/alarm";
import { alarmName, alarmUrl, dateFormatType2 } from "utils/utils";
import styled from "styled-components";
const StyledModal = styled.div`
  & .cursor-default {
    cursor: default;
  }
`;
const AlarmModal = () => {
  const { closeModal } = useModal();
  const { isLoggedin } = useAuth();
  const navigate = useNavigate();
  const [alarmList, setAlarmList] = useState(null);

  useEffect(() => {
    getAlarmList();
  }, []);

  const getAlarmList = () => {
    if (!isLoggedin) return;
    alarmListView((res) => {
      setAlarmList(res.response);
    });
  };

  return (
    <StyledModal className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con m-full">
        <div className="tit">알림센터</div>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="btn btn-close"
        ></button>
        {alarmList && (
          <div className="mobile alarm cursor-default">
            {alarmList.read.length || alarmList.unread.length ? (
              <ul className="alarm-list">
                <li className="alarm-list--item top">
                  {/* <div className="tit">알림센터</div> */}
                  <div className="right">
                    <button
                      type="button"
                      className="btn"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        authAlarmDeleteCheckedAlarm((res) => {
                          if (res.code === 0) getAlarmList();
                        });
                      }}
                    >
                      읽은 알림 삭제
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        authAlarmDeleteAll((res) => {
                          if (res.code === 0) getAlarmList();
                        });
                      }}
                    >
                      전체 삭제
                    </button>
                  </div>
                </li>
                {/* <li className="alarm-list--item notice">
									<div className="alarm-wrap new">
										<button type="button" className="btn btn-delete"></button>
										<div className="top">AI 히어로즈 공지</div>
										<div className="tit">히어로즈가 프로젝트에 지원했어요.</div>
										<div className="date">2022-01-11 23:00</div>
									</div>
									<div className="alarm-wrap new">
										<button type="button" className="btn btn-delete"></button>
										<div className="top">AI 히어로즈 공지</div>
										<div className="tit">히어로즈가 프로젝트에 지원했어요.</div>
										<div className="date">2022-01-11 23:00</div>
									</div>
								</li> */}

                <li className="alarm-list--item">
                  {alarmList.unread.map((item, i) => (
                    <React.Fragment key={`alarm-list-new-item-${i}`}>
                      <div
                        className="alarm-wrap new"
                        onClick={() => {
                          navigate(`${alarmUrl(item)}`);
                          closeModal();
                          if (!item.CHECKED) {
                            authAlarmRead(item.SEQ, (res) => {
                              if (res.code === 0) getAlarmList();
                            });
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            authAlarmDelete(item.SEQ, (res) => {
                              if (res.code === 0) getAlarmList();
                            });
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                        ></button>
                        <div className="top">{alarmName(item)}</div>
                        <div className="tit">{item.MSG}</div>
                        <div className="date">
                          {dateFormatType2(item.CREATED_AT)}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  {alarmList.read.map((item, i) => (
                    <React.Fragment key={`alarm-list-read-item-${i}`}>
                      <div
                        className="alarm-wrap read"
                        onClick={() => {
                          navigate(`${alarmUrl(item)}`);
                          closeModal();
                          if (!item.CHECKED) {
                            authAlarmRead(item.SEQ, (res) => {
                              if (res.code === 0) getAlarmList();
                            });
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            authAlarmDelete(item.SEQ, (res) => {
                              if (res.code === 0) getAlarmList();
                            });
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                        ></button>
                        <div className="top">파트너 지원 안내</div>
                        <div className="tit">{item.MSG}</div>
                        <div className="date">
                          {dateFormatType2(item.CREATED_AT)}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </li>
              </ul>
            ) : (
              <ul className="alarm-list">
                <li className="alarm-list--item none">
                  받은 알림이 없어요.
                  <p>
                    프로젝트를 진행하면서
                    <br />
                    다양한 소식을 받아보세요!
                  </p>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </StyledModal>
  );
};

export default AlarmModal;
