import React from "react";
import styled from "styled-components";
const Badge = ({
  className,
  checkedArray,
  setCheckArray,
  badgeData,
  activeColor,
  style,
  index,
  isMobile,
  ...restProps
}) => {
  const changeBoolean = () => {
    let tempArr = [...checkedArray];
    if (tempArr.includes(badgeData.id)) {
      tempArr = tempArr.filter((a) => a !== badgeData.id);
    } else {
      tempArr.push(badgeData.id);
    }
    setCheckArray([...tempArr]);
  };

  const colors = ["#CCFF00", "#FFBC27", "#43F4FF", "#FFB2B2", "#D8BAFF"];
  const colorIndex = index % colors.length;

  return (
    <Label
      style={isMobile ? {} : index + 1 === 10 ? { marginLeft: "0px" } : {}}
      className={className}
      key={`skillStack-${badgeData.id}`}
      color={colors[colorIndex]}
      {...restProps}
    >
      <input
        type="checkbox"
        style={{ display: "none" }}
        checked={checkedArray.includes(badgeData.id)}
        onChange={changeBoolean}
      />
      <span className="label">{badgeData.name}</span>
    </Label>
  );
};

export default Badge;
const Label = styled.label`
  margin-bottom: ${(props) => (props.index >= 9 ? "0" : "6px")};
  margin-left: 6px;
  &:first-child {
    margin-left: 0;
  }
  & span.label {
    border: 1px solid var(--mono-m-gray-03-stroke, #eee);

    border-radius: 10px;
    background: var(--mono-m-bg, #f6f6f6);
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;
    padding: 0 14px;
  }
  & input:checked + .label {
    background-color: ${(props) => props.color && props.color} !important;
    color: #000;
  }
`;
