import api from "api"

export const userProjectList = (params, callback) => {
	api.get('/project/auth/my/view/list', {
		params
	}).then(res => callback(res))
}

export const userProjectHeroesInfo = (seq, callback) => {
	api.get(`/project/auth/completeInfo/${seq}`, {
		params: { seq }
	}).then(res => callback(res))
}



export const projectOffer = (params, callback) => {
	api.post('/project-application/auth/offer', null, {
		params
	}).then(res => callback(res))
}

export const userProjectDetail = (seq, callback) => {
	api.get(`/project/auth/my/view/${seq}`).then(res => callback(res))
}

export const userProjectDetailMessage = (seq, callback) => {
	api.get(`/project-message/auth/view/list`, { params: { seq } }).then(res => callback(res))
}

export const userProjectDetailMessageIsNew = (seq, callback) => {
	api.get(`/project-message/auth/newMessage`, { params: { seq } }).then(res => callback(res))
}


export const userProjectMessageWrite = (seq, body, callback) => {
	api.post(`/project-message/auth/write`, body, { params: { seq } }).then((res) => callback(res));
}

export const userLikeHerosList = (params, callback) => {
	api.get('/heros-dibs/auth/my/view/list', {
		params
	}).then(res => callback(res))
}

export const userProjectDetailApplication = (params, callback) => {
	api.get('/project-application/auth/view/list', {
		params
	}).then(res => callback(res))
}

export const userProjectApplicationHeroes = (params, callback) => {
	api.get('/project-application/auth/view/list/heroes', {
		params
	}).then(res => callback(res))
}

export const userProjectApplicationHeroesDetail = (seq, callback) => {
	api.get(`/project-application/auth/view/heroes/${seq}`, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectApplicationHeroesDetailCancel = (seq, callback) => {
	api.delete(`/project-application/auth/cancel`, {
		params: { seq }
	}).then(res => callback(res))
}


export const userProjectLikeProjectHeroes = (params, callback) => {
	api.get('/project-application/auth/view/likeProjectList/heroes', {
		params
	}).then(res => callback(res))
}

export const userProjectDetailApplicationCheck = (seq, callback) => {
	api.get(`/project-application/auth/view/${seq}`, {
		params: {
			seq
		}
	}).then(res => callback(res))
}

export const userProjectDetailHeroesRefuse = (seq, callback) => {
	api.post(`/project-application/auth/refuse`, null, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailMeeting = (params, callback) => {
	api.get('/project-meeting/auth/view/list', {
		params
	}).then(res => callback(res))
}

export const userProjectDetailMeetingCheck = (seq, callback) => {
	api.get(`/project-meeting/auth/view/${seq}`, {
		params: {
			seq
		}
	}).then(res => callback(res))
}

export const userProjectDetailMeetingApply = (seq, callback) => {
	api.post(`/project-meeting/auth/apply/meeting`, null, {
		params: {
			seq
		}
	}).then(res => callback(res))
}


export const userProjectDetailContract = (seq, callback) => {
	api.get('/project-contract/auth/view/list', {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailContractCheck = (seq, callback) => {
	api.get(`/project-contract/auth/view/${seq}`, {
		params: {
			seq
		}
	}).then(res => callback(res))
}

// TASK
export const userProjectDetailTaskRefuse = (seq, body, callback) => {
	api.post(`/project-task/auth/refuse/${seq}`, body, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskModify = (seq, body, callback) => {
	api.post(`/project-task/auth/modify/${seq}`, body, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskComplete = (seq, callback) => {
	api.post(`/project-task/auth/complete/${seq}`, null, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskApproval = (seq, body, callback) => {
	api.post(`/project-task/auth/approval/${seq}`, body, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskApply = (seq, body, callback) => {
	api.post(`/project-task/auth/apply`, body, { params: { seq } }).then(res => callback(res))
}

export const userProjectDetailTaskList = (seq, callback) => {
	api.get(`/project-task/auth/view/list`, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskHisroryList = (seq, callback) => {
	api.get(`/project-task/auth/view/historyList`, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskCount = (seq, callback) => {
	api.get(`/project-task/auth/count`, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailTaskRemove = (seq, callback) => {
	api.delete(`/project-task/auth/remove/${seq}`, {
		params: { seq }
	}).then(res => callback(res))
}

export const userProjectDetailComplete = (seq, callback) => {
	api.post(`/project/auth/complete/${seq}`, null, {
		params: { seq }
	}).then(res => callback(res))
}

export const projectOfferList = (heroes, callback) => {
	api.get(`/project/auth/my/view/list/modal/${heroes}`, { params: { heroes } }).then(res => callback(res))
}

export const heroesViewMeetingList = (seq, callback) => {
	api.get(`/project-meeting/auth/view/heros/${seq}`, { params: { seq } }).then((res) => callback(res))
}

export const heroesViewContractList = (seq, callback) => {
	api.get(`/project-contract/auth/view/heros/${seq}`, { params: { seq } }).then((res) => callback(res))
}

export const userContractApply = (seq, callback) => {
	api.post(`/project-contract/auth/apply/contract`, null, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const projectTaskResultListView = (seq, callback) => {
	api.get(`/project-task-result/auth/view/list`, { params: { seq } }).then((res) => callback(res))
}



export const projectTaskResultTestDateWrite = (seq, body, callback) => {
	api.post(`/project-task-result/auth/write/testDate/${seq}`, body, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const projectTaskResultInferenceCodeWrite = (seq, body, callback) => {
	api.post(`/project-task-result/auth/write/InferenceCode/${seq}`, body, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const projectReviewWrite = (seq, body, callback) => {
	api.post(`/project-review/auth/write`, body, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const herosReviewWrite = (seq, projectSeq, body, callback) => {
	api.post(`/heros/auth/review/write/${seq}`, body, { params: { seq, projectSeq } }).then((res) => {
		callback(res)
	})
}

export const heroesDibsTotal = (heros, callback) => {
	api.get(`/heros-dibs/auth/total`, { params: { heros } }).then((res) => callback(res))
}

export const heroesCompleteProjectInfo = (seq, callback) => {
	api.get(`/project/auth/completeProjectInfo/${seq}`, { params: { seq } }).then((res) => callback(res))
}

export const projectMeetingCancel = (seq, callback) => {
	api.post(`/project-meeting/auth/cancel/meeting`, null, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const projectCompleteBefore = (seq, callback) => {
	api.post(`/project/auth/complete/before/${seq}`, null, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const projectReviewPossibleWrite = (seq, callback) => {
	api.get(`/project-review/auth/possibleWrite/${seq}`, { params: { seq } }).then((res) => {
		callback(res)
	})
}

export const heroesReviewPossibleWrite = (seq, projectSeq, callback) => {
	api.get(`/heros/auth/review/possibleWrite/${seq}`, { params: { seq, projectSeq } }).then((res) => {
		callback(res)
	})
}

export const projectTaskResultCnt = (seq, callback) => {
	api.get(`/project-task-result/auth/count`, { params: { seq } }).then((res) => {
		callback(res)
	})
}
