import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { modalSlice } from "./modalSlice";
import { userSlice } from "./userSlice";
const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  partner: userSlice.reducer,

  // ...reducer 추가
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
