import React, { useEffect, useState } from "react";
import styled from "styled-components";
const MultiSelect = ({
  value,
  options,
  selected,
  setSelect,
  width = 0,
  zIndex = 0,
  ...restProps
}) => {
  const [toggleActive, setToggleActive] = useState(false);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    switch (selected.length) {
      case options.length:
        setSelectedText("전체");
        return;
      case 0:
        setSelectedText("");
        return;
      case 1:
        setSelectedText(selected[0]);
        return;
      default:
        setSelectedText(`${selected[0]} 외 ${selected.length - 1}개`);
        return;
    }
  }, [selected, options]);

  const selectHandler = (e, item) => {
    if (item === "전체") {
      if (e.target.checked) {
        setSelect(options.map((a) => a.name));
      } else {
        setSelect([]);
      }
      return;
    }

    if (e.target.checked) {
      if (selected.length >= options.length - 2 && !selected.includes("전체")) {
        setSelect([...selected, item, "전체"]);
        return;
      }

      setSelect([...selected, item]);
    } else {
      let tempArr = [...selected];
      tempArr = tempArr.filter((a) => {
        return a !== item && a !== "전체";
      });
      setSelect([...tempArr]);
    }
  };

  return (
    <StyledSelect className="select-multi" width={width}>
      <button
        type="button"
        className={`btn select ${toggleActive ? "active" : ""}`}
        onClick={() => setToggleActive(!toggleActive)}
      >
        <span className="value">{value}</span>
        <span className="all">{selectedText}</span>
      </button>
      <ul className="select-list" {...restProps}>
        {options &&
          options.map((item, i) => (
            <li
              className={`select-list--item ${i === 0 ? "top" : ""}`}
              key={`select-list--item-1__${i}`}
            >
              <label className="input-checkbox">
                <input
                  type="checkbox"
                  checked={selected.includes(item.name)}
                  onChange={(e) => {
                    selectHandler(e, item.name);
                  }}
                />
                <span className="box"></span>
                <span className="text">{item.name}</span>
              </label>
            </li>
          ))}
      </ul>
    </StyledSelect>
  );
};
const StyledSelect = styled.div`
  &.select-multi {
    min-width: ${(props) => `${props.width}`};
  }
  & .select-list {
    z-index: ${(props) => 11 + props.zIndex};
  }
`;
export default MultiSelect;
