import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Itype from "assets/images/icon/icon_type.svg";
import IReference from "assets/images/icon/icon_reference.svg";
import IFrofile from "assets/images/icon/icon_profile.svg";

import { interestedAiTaskData } from "utils/data";

const RecomendBox = ({ data, isMobile, targetSkill, ...restProps }) => {
  const navigate = useNavigate();
  const toCamelCase = (str) => {
    if (str === undefined) {
      return;
    }
    return str
      .toLowerCase()
      .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  };

  const findAiTaskByResponseValue = (responseValue) => {
    const camelCaseId = toCamelCase(responseValue);
    return interestedAiTaskData.find((task) => task.id === camelCaseId)?.name;
  };

  const dataTask = Object.entries(targetSkill)
    .filter(([key, value]) => typeof value === "boolean" && value === true)
    .map(([key]) => key);

  const filterSkills = (item) => {
    const trueValues = Object.entries(item)
      .filter(
        ([key, value]) =>
          key !== "CAREER" && key !== "IS_EXIST_CAREER" && value === 1
      )
      .map(([key]) => key);
    const matchingValue = trueValues.find((value) => dataTask.includes(value));
    return findAiTaskByResponseValue(matchingValue);
  };

  const skillLength = (data) => {
    const trueValues = Object.entries(data).filter(
      ([key, value]) =>
        key !== "CAREER" && key !== "IS_EXIST_CAREER" && value === 1
    );
    return trueValues.length;
  };
  const unMatchedSkill = (item) => {
    const unMatchSkill = Object.entries(item)
      .filter(([key, value]) => key !== "IS_EXIST_CAREER" && value === 1)
      .map(([key]) => key);
    if (unMatchSkill.length === 0) return null;
    return unMatchSkill[0]
      .toLowerCase()
      .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  };
  return (
    <StyledRecomendBox {...restProps} isMobile={isMobile}>
      {data.map((item, idx) => (
        <div
          className="profile-card"
          key={idx}
          onClick={() => navigate(`/heroes/${item.USER_SEQ}`)}
        >
          <div className="img-box">
            {/* 프로필 이미지 */}
            <img
              src={item.PROFILE_IMG ? item.PROFILE_IMG : IFrofile}
              alt="사용자 프로필 사진"
              title={item.NICKNAME}
            />
            <span
              className={`badge ${
                item.ROLE === "ROLE_HEROS_PERSONAL" ? "blue" : "primary"
              }`}
            >
              {item.ROLE === "ROLE_HEROS_PERSONAL"
                ? "개인 파트너"
                : "기업 파트너"}
            </span>
          </div>
          <div className="title-box">
            <div className="title-line">
              {/* 프로필 이름 */}
              <h1>{item.NICKNAME}</h1>
              {/* 프로필 특징 뱃지 */}
            </div>
            {/* 프로필 심플설명 */}
            <StyledBadges>
              <StyledBadge>
                <img src={Itype} alt={"근무형태"} title={"근무형태"} />
                {item.WORK_METHOD === "BOTH" && "상주, 원격 모두 가능"}
                {item.WORK_METHOD === "RESIDENT" && "상주"}
                {item.WORK_METHOD === "REMOTE" && "원격"}
              </StyledBadge>
              {item.IS_EXIST_CAREER === 1 && (
                <StyledBadge className="reference">
                  <img
                    src={IReference}
                    alt={"레퍼런스"}
                    title={"레퍼런스보유"}
                  />
                  레퍼런스
                </StyledBadge>
              )}
            </StyledBadges>
            {Number(skillLength(item)) !== 0 && (
              <div className="badges">
                <span key={idx} className="badge m5 badge-md skill">
                  {filterSkills(item) !== undefined
                    ? filterSkills(item)
                    : unMatchedSkill(item)}
                </span>
                <span className="skill-count">
                  {Number(skillLength(item) - 1) === 0
                    ? ""
                    : `+ ${Number(skillLength(item) - 1)}`}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </StyledRecomendBox>
  );
};

export default RecomendBox;

const StyledRecomendBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: ${(props) => (props.isMobile ? "wrap" : "unset")};
  gap: 10px;
  margin-top: 10px;

  & .profile-card {
    cursor: pointer;
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
    height: 130px;
    padding: 20px 22px;
    border-radius: 20px;
    border: 1px solid var(--mono-m-gray03-stroke, #eee);
    display: inline-flex;
  }
  & .img-box {
    position: relative;
    display: flex;
    margin-right: 20px;
    img {
      min-width: 90px;
      min-height: 90px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
    span.badge {
      position: absolute;
      left: 0;
      bottom: -4px;
      margin-left: 10px;
      font-size: 12px;
      padding: 0 10px;
      width: 75px;
    }
  }
  & .title-box {
    width: calc(100% - 120px);
    & .title-line {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      line-height: 24px;
      margin-bottom: 6px;
      width: 100%;
    }
    h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 132.778%;
    }
    .badges {
      display: inline-flex;
      align-items: center;
      span.badge {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        height: 24px;
        padding: 0 10px;

        & img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
        &.skills {
          border-radius: 5px;
          background: var(--sub-u-purple01, #f4ecff);
        }
        &.reference {
          border-radius: 5px;
          background: var(--primary-p-green01, #cf0);
        }
        &.skill {
          border-radius: 10px;
          border: 1px solid var(--mono-m-gray03-stroke, #eee);
          background: var(--mono-m-bg, #f6f6f6);
          display: flex;
          align-items: flex-start;
        }
      }
      & .skill-count {
        color: var(--mono-m-black00, #000);
        text-align: center;

        /* Caption 400 */
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.9px; /* 132.5% */
      }
    }
    p {
      color: var(--mono-m-black01, #333);

      /* Title4 600 */
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.9px; /* 132.778% */
    }
  }
`;
const StyledBadges = styled.div`
  display: inline-flex;
  gap: 4px;
  width: 100%;
  margin-bottom: 10px;
`;
const StyledBadge = styled.span`
  border-radius: 5px;
  background: var(--sub-u-purple01, #f4ecff);
  display: flex;
  padding: 4px 10px;
  align-items: center;
  gap: 6px;
  max-width: 219px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  & img {
    width: 12px;
    height: 12px;
    color: #333;
  }
  &.reference {
    background: var(--primary-p-green01, #cf0);
  }
`;
