
import api from 'api';

export const alarmListView = (callback) => {
	api.get('/alarm/view').then((res) => { callback(res) })
}

export const authAlarmRead = (seq, callback) => {
	api.get(`/alarm/read/${seq}`, { params: { seq } }).then((res) => { callback(res) })
}


export const authAlarmDelete = (seq, callback) => {
	api.get(`/alarm/delete/${seq}`, { params: { seq } }).then((res) => { callback(res) })
}


export const authAlarmDeleteAll = (callback) => {
	api.delete('/alarm/delete').then((res) => { callback(res) })
}


export const authAlarmDeleteCheckedAlarm = (callback) => {
	api.delete('/alarm/delete/checked').then((res) => { callback(res) })
}

