import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
	let count = 0;
	return (
		<div className="footer">
			<div className="footer-wrap">
				<div className="footer-logo"></div>
				<div className="footer-info">
					<div className="footer-menu">
						<Link to="/corpintro" className="btn">기업소개</Link>
						<Link to="/service" className="btn">이용약관</Link>
						<Link to="/privacy" className="btn">개인정보처리방침</Link>
						<Link to="/help" className="btn">고객센터</Link>
					</div>
					<div className="text">
						(주)알체라 (대표이사: 황영규) <span className="m-none">|</span><br className="m-block" /> 경기도 성남시 분당구 판교로256번길 25 GB2빌딩 C동 7층<br />
						통신판매업신고: 2018-성남분당-0205 <span className="m-none">|</span><br className="m-block" /> 사업자등록번호: 643-87-00337
					</div>
					<div className="text" onClick={() => {
						count++;
						if (count > 20) {
							console.log('version: ', process.env.REACT_APP_VERSION)
						}
					}}>
						ⓒ 2023 Alchera Inc.
					</div>
				</div>
				<div className="footer-sns">
					<button type="button" className="btn sns-1" onClick={() => window.open(`https://www.linkedin.com/company/ai-heroes`)}></button>
					<button type="button" className="btn sns-2" onClick={() => window.open(`https://blog.naver.com/alcherads`)}></button>
					<button type="button" className="btn sns-3" onClick={() => window.open(`https://www.youtube.com/@aiheroes2880`)}></button>
				</div>
			</div>
		</div>
	)
}

export default Footer