import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFAQList } from "services/user";

import Pagination from "components/common/Pagination";
import { dateFormatType2NoTime } from "utils/utils";
import useViewCheck from "hooks/useViewCheck";
import Select from "components/common/Select";

const FAQ = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();

  const [FAQList, setFAQList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [category, setCategory] = useState("ALL");

  useEffect(() => {
    getFAQ();
  }, [page, keyword, category]);

  const getFAQ = useCallback(() => {
    const params = {
      page,
      category,
    };
    if (keyword) {
      params.keyword = keyword;
    }
    getFAQList(params, (res) => {
      setFAQList(res.response.list);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
  }, [page, keyword, category]);

  return (
    <div className="container">
      <button
        type="button"
        className="btn top-back"
        onClick={() => {
          navigate("/help");
        }}
      >
        고객센터로 돌아가기
      </button>
      <div
        className="search-top"
        style={isMobile ? { marginTop: 0 } : { marginTop: 20 }}
      >
        <div className="count">FAQ</div>
      </div>
      <div className="search-top community-category--top type-flex">
        <div className="left">
          {!isMobile ? (
            <>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="ALL"
                  checked={category === "ALL"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">전체</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="CLIENT"
                  checked={category === "CLIENT"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">클라이언트</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="HEROES"
                  checked={category === "HEROES"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">파트너</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="PROJECT"
                  checked={category === "PROJECT"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">프로젝트</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="MEETING"
                  checked={category === "MEETING"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">미팅</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="CONTRACT"
                  checked={category === "CONTRACT"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">계약</span>
              </label>
              <label className="input-checkbox">
                <input
                  type="radio"
                  name="categoryRadio"
                  value="FEE"
                  checked={category === "FEE"}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <span className="label wide">요금</span>
              </label>
            </>
          ) : (
            <>
              <Select
                className={["sm  text-start"]}
                width={118}
                option={[
                  {
                    id: "ALL",
                    name: "전체",
                  },
                  {
                    id: "CLIENT",
                    name: "클라이언트",
                  },
                  {
                    id: "HEROES",
                    name: "파트너",
                  },
                  {
                    id: "PROJECT",
                    name: "프로젝트",
                  },
                  {
                    id: "MEETING",
                    name: "미팅",
                  },
                  {
                    id: "CONTRACT",
                    name: "계약",
                  },
                  {
                    id: "FEE",
                    name: "요금",
                  },
                ]}
                selected={category}
                setSelect={(value) => {
                  setCategory(value);
                }}
              />
            </>
          )}
        </div>

        <div
          className="input"
          style={
            isMobile
              ? {
                  width: "100%",
                }
              : {}
          }
        >
          <div className="right">
            <div className="input-wrap search">
              <button type="button" className="btn btn-search"></button>
              <input
                type="text"
                className="input-text"
                placeholder="FAQ를 검색해 주세요."
                value={keywordInput}
                onChange={(e) => setKeywordInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) setKeyword(e.target.value);
                }}
                onBlur={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ul className="board-list">
        {Boolean(FAQList.length) &&
          FAQList.map((item) => (
            <li
              className="board-list--item"
              key={`faq-list-item-${item.SEQ}`}
              onClick={() => navigate(`${item.SEQ}`)}
            >
              <div className="badge-wrap">
                {item.CATEGORY === "CLIENT" && (
                  <span className="badge badge-md lime">클라이언트</span>
                )}
                {item.CATEGORY === "HEROES" && (
                  <span className="badge badge-md primary2">파트너</span>
                )}
                {item.CATEGORY === "PROJECT" && (
                  <span className="badge badge-md purple2">프로젝트</span>
                )}
                {item.CATEGORY === "MEETING" && (
                  <span className="badge badge-md blue">미팅</span>
                )}
                {item.CATEGORY === "CONTRACT" && (
                  <span className="badge badge-md primary">계약</span>
                )}
                {item.CATEGORY === "FEE" && (
                  <span className="badge badge-md pink">요금</span>
                )}
              </div>
              <button type="button" className="btn tit">
                {item.TITLE}
              </button>
              <span className="date">
                {dateFormatType2NoTime(item.CREATED_AT)}
              </span>
            </li>
          ))}
      </ul>
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  );
};

export default FAQ;
