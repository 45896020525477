import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import Button from "components/Button/Button";
import styled from "styled-components";
import closeIcon from "../../../assets/images/icon/close.svg";
import CareerCard from "pages/Mypage/components/CareerCard";
import SmallButton from "components/Button/SmallButton";
import EducationCard from "pages/Mypage/components/EducationCard";
import { fileAttach } from "utils/storage";
import useViewCheck from "hooks/useViewCheck";
import { fileNameFormat } from "utils/utils";
import CompanyProfile from "pages/Mypage/components/CompanyProfile";
import BadgeWrapper from "components/Ui/BadgeWrapper";
import Badge from "components/Button/Badge";
import { aiSkillData, frameworkData, aiIndustryData } from "utils/data";
import { getUseBBusinessRegistration } from "services/useb";
import OfferInput from "../OfferInput";
import { userHeroesView } from "services/user";

const INIT_CAREER_DATA = {
  seq: 1,
  projectName: "",
  companyName: "",
  performance: "",
  interestedAiTask: null,
  startAt: "",
  endAt: "",
};
const INIT_EDU_DATA = {
  seq: 1,
  schoolName: "",
  educationInfo: "",
  startAt: "",
  endAt: "",
};

const AiProjectAddInfoModal = ({
  type, // 파트너 타입 (기업, 개인)
  offer, // 금액 제안받기 여부
  applyCallback, // 지원하기 콜백함수
}) => {
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();

  const [partnerData, setPartner] = useState({
    file: "",
    fileName: "",
    bailiwick: {},
    skillRetention: {},
    framework: {},
    careerInfoList: [],
    educationInfoList: [],
    businessInfo: {
      identificationNo: "",
      file: "",
      fileName: "",
    },
  });

  const [bailiwick, setBailiwick] = useState([]);
  const [skillRetention, setSkillRetention] = useState([]);
  const [framework, setFramework] = useState([]);

  const [businessNum, setBusinessNum] = useState("");
  const [businessNumCheckDone, setBusinessNumCheckDone] = useState(false);

  const [amount, setAmount] = useState("");
  const [payMethod, setPayMethod] = useState("SALARY");

  const [applyBtnDisabled, setApplyBtnDisabled] = useState(false);

  /** init */
  useEffect(() => {
    userHeroesView((res) => {
      if (res.code === 0) {
        setInitPartnerData(res.response);
        setAiKeywordData(res.response);
      }
    });
  }, []);

  useEffect(() => {
    setApplyBtnDisabled(validateData());
  }, [
    partnerData,
    bailiwick,
    skillRetention,
    framework,
    amount,
    businessNum,
    businessNumCheckDone,
  ]);

  /** 초기 파트너 데이터 설정 */
  const setInitPartnerData = (data) => {
    setPartner({
      file: data.FILE,
      fileName: data.FILE_NAME,
      bailiwick: data.BAILIWICK || {},
      skillRetention: data.SKILL_RETENTION || {},
      framework: data.FRAMEWORK || {},

      careerInfoList: (data.CAREER_LIST &&
        data.CAREER_LIST.map((item) => {
          return {
            seq: item.SEQ,
            projectName: item.PROJECT_NAME,
            companyName: item.COMPANY_NAME,
            performance: item.PERFORMANCE,
            interestedAiTask: item.INTERESTED_AI_TASK,
            startAt: item.START_AT,
            endAt: item.END_AT,
          };
        })) || [INIT_CAREER_DATA],

      educationInfoList:
        type === "individual"
          ? (data.EDUCATION_LIST &&
              data.EDUCATION_LIST.map((item) => {
                return {
                  seq: item.SEQ,
                  schoolName: item.SCHOOL_NAME,
                  educationInfo: item.EDUCATION_INFO,
                  startAt: item.START_AT,
                  endAt: item.END_AT,
                };
              })) || [INIT_EDU_DATA]
          : [],

      businessInfo: {
        identificationNo: data.BUSINESS_INFO.IDENTIFICATION_NO || "",
        file: data.BUSINESS_INFO.FILE || "",
        fileName: data.BUSINESS_INFO.FILE_NAME || "",
      },
    });

    if (data.BUSINESS_INFO.IDENTIFICATION_NO) {
      setBusinessNum(data.BUSINESS_INFO.IDENTIFICATION_NO);
      setBusinessNumCheckDone(true);
    }
  };

  /** 초기 AI 키워드 데이터 설정 */
  const setAiKeywordData = (data) => {
    if (data.BAILIWICK) {
      Object.keys(data.BAILIWICK).forEach((key) => {
        if (data.BAILIWICK[key]) {
          setBailiwick((prev) => [...prev, key.toLowerCase()]);
        }
      });
    }

    if (data.SKILL_RETENTION) {
      Object.keys(data.SKILL_RETENTION).forEach((key) => {
        if (data.SKILL_RETENTION[key]) {
          setSkillRetention((prev) => [...prev, key.toLowerCase()]);
        }
      });
    }

    if (data.FRAMEWORK) {
      Object.keys(data.FRAMEWORK).forEach((key) => {
        if (data.FRAMEWORK[key]) {
          setFramework((prev) => [...prev, key.toLowerCase()]);
        }
      });
    }
  };

  /** 경력 추가 */
  const handleCreateCareer = () => {
    const newCardSeq =
      partnerData.careerInfoList[partnerData.careerInfoList.length - 1].seq;

    setPartner((prev) => {
      return {
        ...prev,
        careerInfoList: [
          ...prev.careerInfoList,
          { ...INIT_CAREER_DATA, seq: newCardSeq + 1 },
        ],
      };
    });
  };
  /** 학력 추가 */
  const handleCreateEdu = () => {
    const newCardSeq =
      partnerData.educationInfoList[partnerData.educationInfoList.length - 1]
        .seq;

    setPartner((prev) => {
      return {
        ...prev,
        educationInfoList: [
          ...prev.educationInfoList,
          { ...INIT_EDU_DATA, seq: newCardSeq + 1 },
        ],
      };
    });
  };

  /** 경력 삭제 */
  const handleCareerDelete = (targetId) => {
    openModal({
      type: "messageModal",
      contents: {
        title:
          type === "business"
            ? `프로젝트 레퍼런스 제거`
            : "경력・프로젝트 레퍼런스 제거",
        message: `제거된 내용은 다시 복구할 수 없어요. 정말 제거하시겠어요?`,
        confirmText: "제거하기",
        confirmCallback: () => {
          const filteringArr = partnerData.careerInfoList.filter(
            (card) => card.seq !== targetId
          );
          setPartner((prev) => {
            return { ...prev, careerInfoList: filteringArr };
          });
          closeModal();
        },
        cancelText: "돌아가기",
        cancelCallback: () => {
          closeModal();
        },
      },
    });
  };
  /** 학력 삭제 */
  const handleEduDelete = (targetId) => {
    openModal({
      type: "messageModal",
      contents: {
        title: "학력 제거",
        message: `제거된 내용은 다시 복구할 수 없어요. 정말 제거하시겠어요?`,
        confirmText: "제거하기",
        confirmCallback: () => {
          const filteringArr = partnerData.educationInfoList.filter(
            (card) => card.seq !== targetId
          );
          setPartner((prev) => {
            return { ...prev, educationInfoList: filteringArr };
          });
          closeModal();
        },
        cancelText: "돌아가기",
        cancelCallback: () => {
          closeModal();
        },
      },
    });
  };

  /** 경력 데이터 변경 handler */
  const handleCareerData = (value) => {
    setPartner((prev) => {
      return {
        ...prev,
        careerInfoList: prev.careerInfoList.map((card) =>
          card.seq === value.seq ? { ...card, ...value } : card
        ),
      };
    });
  };
  /** 학력 데이터 변경 handler */
  const handleEduData = (value) => {
    setPartner((prev) => {
      return {
        ...prev,
        educationInfoList: prev.educationInfoList.map((card) =>
          card.seq === value.seq ? { ...card, ...value } : card
        ),
      };
    });
  };

  /** 포트폴리오 등록 */
  const portfolioAttach = (e) => {
    if (e.target.files[0].size > 5000000) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "profilePortfolio/", (downloadURL, fileName) => {
      setPartner({
        ...partnerData,
        file: downloadURL,
        fileName: fileName,
      });
    });
  };

  /** 사업자번호 인증 */
  const businessNumCheck = () => {
    const body = {
      biz_no: businessNum,
    };
    getUseBBusinessRegistration(body, (res) => {
      if (res === "error") {
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => closeModal(),
          },
        });
        return;
      }
      if (
        !(
          Number(res.tax_type_code) === 4 ||
          Number(res.tax_type_code) === 5 ||
          Number(res.tax_type_code) === 99
        )
      ) {
        setBusinessNumCheckDone(true);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 완료",
            message: `기업 인증이 완료됐어요.`,
            confirmText: "확인",
            confirmCallback: () => {
              setPartner((prev) => {
                return {
                  ...prev,
                  businessInfo: {
                    ...prev.businessInfo,
                    identificationNo: businessNum,
                  },
                };
              });
              closeModal();
            },
          },
        });
      } else {
        setBusinessNumCheckDone(false);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => closeModal(),
          },
        });
      }
    });
  };
  /** 사업자정보 설정 */
  const handleBusinessNum = (value) => {
    setBusinessNum(value);
  };
  /** 사업자등록증 첨부 */
  const onBusinessFileChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => closeModal(),
        },
      });
      return;
    }
    fileAttach(e, "profileBusinessDoc/", (downloadURL, fileName) => {
      setPartner((prev) => {
        return {
          ...prev,
          businessInfo: {
            ...prev.businessInfo,
            file: downloadURL,
            fileName: fileName,
          },
        };
      });
    });
  };

  /** 유효성검사 */
  const validateData = () => {
    let flag = false;

    // 경력, 프로젝트 검사
    partnerData.careerInfoList.forEach((career) => {
      if (
        !career.companyName.trim() ||
        !career.projectName.trim() ||
        !career.performance.trim() ||
        !career.interestedAiTask ||
        !career.startAt.trim() ||
        !career.endAt.trim()
      ) {
        flag = true;
        return;
      }

      const idx = Object.keys(career.interestedAiTask).findIndex(
        (item) => career.interestedAiTask[item] === true
      );
      if (idx === -1) {
        flag = true;
        return;
      }
    });

    // 학력 검사
    partnerData.educationInfoList.forEach((edu) => {
      if (
        !edu.schoolName.trim() ||
        !edu.educationInfo.trim() ||
        !edu.startAt.trim() ||
        !edu.endAt.trim()
      ) {
        flag = true;
        return;
      }
    });

    if (businessNum && !businessNumCheckDone) {
      flag = true;
    }

    // AI키워드 1, 2 (skillRetention, bailiwick)
    if (skillRetention.length === 0 || bailiwick.length === 0) {
      flag = true;
    }

    // 금액제안
    if (offer && !amount) {
      flag = true;
    }

    return flag;
  };

  /** callback 보내기 전 : 파트너 데이터 정제 */
  const regeneratePartnerData = () => {
    let skillRetentionObject = {};
    let bailiwickObject = {};
    let frameworkObject = {};

    aiSkillData.forEach((item) => {
      skillRetentionObject = {
        ...skillRetentionObject,
        [item.id]: skillRetention.includes(item.id),
      };
    });

    aiIndustryData.forEach((item) => {
      bailiwickObject = {
        ...bailiwickObject,
        [item.id]: bailiwick.includes(item.id),
      };
    });

    frameworkData.forEach((item) => {
      frameworkObject = {
        ...frameworkObject,
        [item.id]: framework.includes(item.id),
      };
    });

    return {
      ...partnerData,
      skillRetention: skillRetentionObject,
      bailiwick: bailiwickObject,
      framework: frameworkObject,
    };
  };

  /** 지원하기 버튼 클릭 이벤트 */
  const onApplyBtnClickHandler = () => {
    if (validateData()) {
      openModal({
        type: "messageModal",
        contents: {
          title: "필수값 입력",
          message: `필수값을 모두 입력해 주세요.`,
          confirmText: "확인",
          confirmCallback: () => closeModal(),
        },
      });
      return;
    }

    const data = regeneratePartnerData();
    applyCallback(data);
    closeModal();
  };

  return (
    <div className="modal show">
      <div className="modal-dim"></div>
      <ModalContainer>
        <img
          className="btn-close"
          src={closeIcon}
          onClick={() => closeModal()}
        />

        {/* 모달 content start */}
        <ModalContent
          style={{ height: `calc(100% - ${offer ? "174px" : "65px"})` }}
        >
          <div className="content-title">
            <label>상세 정보 추가 입력</label>
            <span>
              {!isMobile && "클라이언트에게"} 추가적으로 제공할 정보를 입력해
              주세요. {isMobile && <br />}
              최초 한번만 입력하면 끝!
            </span>
          </div>

          {/* 개인 파트너: 경력, 프로젝트 레퍼런스 */}
          {/* 기업 파트너: 프로젝트 레퍼런스 */}
          <ContentBody>
            <label>
              {type === "business"
                ? "프로젝트 레퍼런스"
                : "경력・프로젝트 레퍼런스"}
              <span className="required">&nbsp;*</span>
            </label>
            <span className="guide">
              최근 진행한 프로젝트부터 순차적으로 작성해 주세요.
            </span>
            {partnerData.careerInfoList.map((card, idx) => (
              <CardWrapper key={`${card.seq}_${idx}`}>
                <CareerCard
                  isFirst={idx === 0}
                  seq={card.seq}
                  data={card}
                  handleCareerDelete={() => handleCareerDelete(card.seq)}
                  handleCareerData={handleCareerData}
                />
              </CardWrapper>
            ))}
            {partnerData.careerInfoList.length < 30 && (
              <SmallButton
                style={{ marginTop: "10px", width: "120px" }}
                text={"레퍼런스 추가"}
                isIcon={true}
                icon="add"
                onClick={handleCreateCareer}
              />
            )}
          </ContentBody>

          {type === "individual" && (
            <>
              {/* 개인 파트너: 학력 */}
              <ContentBody>
                <label>
                  학력
                  <span className="required">&nbsp;*</span>
                </label>
                <span className="guide">최신 순으로 작성해 주세요.</span>
                {partnerData.educationInfoList.map((card, idx) => (
                  <CardWrapper key={`${card.seq}_${idx}`}>
                    <EducationCard
                      isFirst={idx === 0}
                      seq={card.seq}
                      data={card}
                      handleEduDelete={() => handleEduDelete(card.seq)}
                      handleEduData={handleEduData}
                    />
                  </CardWrapper>
                ))}
                {partnerData.educationInfoList.length < 10 && (
                  <SmallButton
                    style={{ marginTop: "10px" }}
                    text="학력 추가"
                    isIcon={true}
                    icon="add"
                    onClick={handleCreateEdu}
                  />
                )}
              </ContentBody>

              {/* 개인 파트너: 포트폴리오 */}
              <ContentBody>
                <label>포트폴리오</label>
                <label className="input-file mt-10 m-mt-16">
                  <input
                    type="file"
                    onChange={(e) => {
                      portfolioAttach(e);
                    }}
                  />
                  <span
                    className={`input-text ${
                      partnerData.file ? "correct" : ""
                    }`}
                  >
                    {partnerData.file
                      ? fileNameFormat(partnerData.fileName)
                      : isMobile
                      ? "포트폴리오, 이력서"
                      : "포트폴리오 첨부 (jpg, png, pdf 최대 5MB 첨부가능)"}
                  </span>
                </label>
              </ContentBody>
            </>
          )}

          {/* 사업자 정보 */}
          <ContentBody>
            <label>사업자 정보</label>
            <span className="guide">
              {type === "individual"
                ? "사업자가 있는 개인일 경우, 사업자 인증을 진행해 주세요."
                : "사업자 인증을 진행해 주세요."}
            </span>
            <CardWrapper>
              <CompanyProfile
                businessNum={businessNum}
                businessNumCheck={businessNumCheck}
                numCheckDone={businessNumCheckDone}
                businessFile={partnerData.businessInfo}
                fileNameFormat={fileNameFormat}
                handleBusinessNum={handleBusinessNum}
                handleBusNumCheck={setBusinessNumCheckDone}
                handleBusinessFile={onBusinessFileChange}
                isMobile={isMobile}
              />
            </CardWrapper>
          </ContentBody>

          {/* 매칭을 높여주는 AI 키워드 */}
          <ContentBody>
            <label>매칭을 높여주는 AI 키워드</label>
            <CardWrapper>
              <BadgeWrapper>
                <div>
                  <p>
                    1. AI 기술을 적용했던 모든 유형을 선택해 주세요.
                    <span className="required">&nbsp;*</span>
                  </p>
                  {aiSkillData.map((item, idx) => (
                    <Badge
                      key={`${item.id}_${idx}`}
                      className="input-checkbox"
                      checkedArray={skillRetention}
                      setCheckArray={setSkillRetention}
                      badgeData={item}
                    />
                  ))}
                </div>
                <div>
                  <p>
                    2. AI 기술을 적용했던 모든 산업 분야를 선택해 주세요.
                    <span className="required">&nbsp;*</span>
                  </p>
                  {aiIndustryData.map((item, idx) => (
                    <Badge
                      key={`${item.id}_${idx}`}
                      className="input-checkbox"
                      checkedArray={bailiwick}
                      setCheckArray={setBailiwick}
                      badgeData={item}
                    />
                  ))}
                </div>
                <div>
                  <p>3. 사용 가능한 프로그래밍 프레임워크를 선택해 주세요.</p>
                  {frameworkData.map((item) => (
                    <Badge
                      key={item.id}
                      badgeData={item}
                      className="input-checkbox"
                      setCheckArray={setFramework}
                      checkedArray={framework}
                    />
                  ))}
                </div>
              </BadgeWrapper>
            </CardWrapper>
          </ContentBody>
        </ModalContent>
        {/* 모달 content end */}

        {/* 금액 제안 있는 경우 inputfield */}
        {offer && (
          <OfferContainer>
            <p>
              희망하는 급여 및 지급 방식을 제안하고 {isMobile && <br />}지원해
              보세요.
              <span className="required">&nbsp;*</span>
            </p>
            <OfferInput
              amount={amount}
              handleAmount={setAmount}
              payMethod={payMethod}
              handlePayMethod={setPayMethod}
            />
          </OfferContainer>
        )}

        {/* 버튼 wrapper start */}
        <div className="mt-20 text-center">
          <Button
            text="돌아가기"
            color="grey"
            type="half"
            className="mr-6"
            onClick={() => closeModal()}
          />
          <Button
            text="지원하기"
            color="primary"
            type="full"
            className="btn-primary"
            disabled={!applyBtnDisabled}
            onClick={() => onApplyBtnClickHandler()}
          />
        </div>
        {/* 버튼 wrapper end */}
      </ModalContainer>
    </div>
  );
};

export default AiProjectAddInfoModal;

const ModalContainer = styled.div`
  padding: 60px 20px 65px 20px;
  max-width: 800px;
  width: 90%;
  height: 80%;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.1);

  & > img.btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & label .required,
  & p .required {
    color: #df2e47;
  }
  & .text-center {
    text-align: center;
  }
`;

const ModalContent = styled.div`
  padding: 0 40px;
  overflow-y: auto;

  & > .content-title {
    display: flex;
    align-items: center;

    & > label {
      margin-right: 10px;
      font-size: 20px;
      font-weight: 600;
      line-height: 133%;
      color: #000;
    }

    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 133%;
      color: #333;
    }
  }

  @media (max-width: 660px) {
    padding: 0;

    & > .content-title {
      flex-direction: column;
      align-items: start;

      & > span {
        margin-top: 4px;
      }
    }
  }
`;

const ContentBody = styled.div`
  margin-top: 25px;

  & > label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 133%;
  }

  & > .guide {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 133%;
    color: #666;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;

    & > label {
      margin-bottom: 0;
    }

    & > .guide {
      margin-top: 4px;
      margin-left: 0;
    }
  }
`;

const CardWrapper = styled.div`
  margin-top: 10px;
`;

const OfferContainer = styled.div`
  margin: 20px auto 5px auto;
  max-width: 480px;
  height: 104px;

  & > p {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 133%;
    color: #333;
    text-align: center;
  }
`;
