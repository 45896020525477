import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import info_img1 from "assets/images/info_img1.png";
import info_img2 from "assets/images/info_img2.png";
import info_img3 from "assets/images/info_img3.png";
import info_img4 from "assets/images/info_img4.png";
import info_img1_mo from "assets/images/info_img1_mo.png";
import info_img2_mo from "assets/images/info_img2_mo.png";
import info_img3_mo from "assets/images/info_img3_mo.png";
import info_img4_mo from "assets/images/info_img4_mo.png";
import useViewCheck from "hooks/useViewCheck";

const CorpIntro = () => {
  const { isMobile } = useViewCheck();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3600,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container" style={isMobile ? { overflowX: "hidden" } : {}}>
      <div className="infopage-top">
        AI 모델 도입을 <br className="m-block" />
        <span>쉽고</span> 빠르게
        <br />
        모두의 일상 속 <strong>AI가 녹아들 수 있도록</strong>
      </div>
      <div className="infopage-text">
        <div className="infopage-tit">Vision</div>
        <strong>
          AI 히어로즈는 <br className="m-block" />
          대한민국 모든 프로젝트의 <br className="m-block" />
          시작을 책임집니다
        </strong>
        <p>
          AI 히어로즈는 뛰어난 인공지능 전문 인력 <br className="m-block" />
          클러스터로서 
          <br className="m-none" />
          개인과 기업, 그리고 세상이 <br className="m-block" />
          직면한 다양한 문제를 해결합니다.
        </p>
        <div className="infopage-tit">Team Mission</div>
        <strong>
          AI로 인간의 삶을 <br className="m-block" />
          더욱 가치있게 만들고 있습니다
        </strong>
        <p>
          우리가 상상하고 꿈꾸는 것들이 현실이 되고, <br />
          인공지능이 나의 삶을 풍요롭게 만들 수 있도록 우리는 노력합니다.
        </p>
      </div>
      <div className="big-hr" />
      <div className="infopage-img">
        <div className="infopage-tit">
          AI 히어로즈가 <br className="m-block" />
          만드는 세상
        </div>
        <div className="infopage-img--list">
          <div className="item m-block">
            <img src={info_img1_mo} alt="" />
            <span className="tit">누구나 손쉽게 도입 가능한 AI 기술</span>
            <span className="text">
              프로젝트를 등록해 보세요.
              <br />
              전문 매니저가 친절하게 상담해 드려요.
            </span>
          </div>
          <div className="item m-block">
            <img src={info_img2_mo} alt="" />
            <span className="tit">비즈니스 성장의 새로운 발판</span>
            <span className="text">
              현재 비즈니스를 AI로 혁신해 보세요.
              <br />
              우리는 기업의 성장을 돕고 있습니다.
            </span>
          </div>
          <div className="item m-block">
            <img src={info_img3_mo} alt="" />
            <span className="tit">집단지성으로 풀어가는 AI 이슈</span>
            <span className="text">
              AI 전문가에게 물어보고 상담 받아 보세요.
              <br />
              생각보다 쉽게 문제를 해결할 수 있어요.
            </span>
          </div>
          <div className="item m-block">
            <img src={info_img4_mo} alt="" />
            <span className="tit">AI 전문인력 일자리 창출</span>
            <span className="text">
              대한민국의 인공지능 전문가 육성을 위해
              <br />
              AI 히어로즈가 언제나 노력하고 있습니다.
            </span>
          </div>
          <div className="swiper-wrapper m-none">
            <Slider
              {...settings}
              className="swiper-slide"
              style={{ marginRight: -20 }}
            >
              <div className="swiper-slide" style={{ marginRight: 20 }}>
                <img src={info_img1} alt="" />
                <span className="tit">
                  누구나 손쉽게
                  <br />
                  도입 가능한 AI 기술
                </span>
                <span className="text">
                  프로젝트를 등록해 보세요.
                  <br />
                  전문 매니저가 친절하게 상담해 드려요.
                </span>
              </div>
              <div className="swiper-slide" style={{ marginRight: 20 }}>
                <img src={info_img2} alt="" />
                <span className="tit">
                  비즈니스 성장의
                  <br />
                  새로운 발판
                </span>
                <span className="text">
                  현재 비즈니스를 AI로 혁신해 보세요.
                  <br />
                  우리는 기업의 성장을 돕고 있습니다.
                </span>
              </div>
              <div className="swiper-slide" style={{ marginRight: 20 }}>
                <img src={info_img3} alt="" />
                <span className="tit">
                  집단지성으로
                  <br />
                  풀어가는 AI 이슈
                </span>
                <span className="text">
                  AI 전문가에게 물어보고 상담 받아 보세요.
                  <br />
                  생각보다 쉽게 문제를 해결할 수 있어요.
                </span>
              </div>
              <div className="swiper-slide" style={{ marginRight: 20 }}>
                <img src={info_img4} alt="" />
                <span className="tit">
                  AI 전문인력
                  <br />
                  일자리 창출
                </span>
                <span className="text">
                  대한민국의 인공지능 전문가 육성을 위해
                  <br />
                  AI 히어로즈가 언제나 노력하고 있습니다.
                </span>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="infopage-bottom">
        <div className="top">AI 히어로즈에 대해 더욱 자세히 알아보세요</div>
        <div className="bottom m-none">
          <label className="label">문의하기</label>
          <span className="text">helpdesk@aiheroes.ai</span>
          <label className="label">서비스 소개서</label>
          <button
            type="button"
            className="btn text"
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/ai-heroes-383507.appspot.com/o/files%2Fprofile%2Fuser%40test.com%7D%2F2023%20AI%20%E1%84%92%E1%85%B5%E1%84%8B%E1%85%A5%E1%84%85%E1%85%A9%E1%84%8C%E1%85%B3%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf_a5x6skweqkl?alt=media&token=378b0834-b314-4ecc-989a-2d7f1e92f665"
              );
            }}
          >
            서비스 소개서 다운로드
          </button>
        </div>
        <div className="bottom m-block">
          <label className="label">문의하기</label>
          <span className="text">helpdesk@aiheroes.ai</span>
          <br />
          <label className="label">서비스 소개서</label>
          <button
            type="button"
            className="btn text"
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/ai-heroes-383507.appspot.com/o/files%2Fprofile%2Fuser%40test.com%7D%2F2023%20AI%20%E1%84%92%E1%85%B5%E1%84%8B%E1%85%A5%E1%84%85%E1%85%A9%E1%84%8C%E1%85%B3%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf_a5x6skweqkl?alt=media&token=378b0834-b314-4ecc-989a-2d7f1e92f665"
              );
            }}
          >
            서비스 소개서 다운로드
          </button>
        </div>
      </div>
      {/* <div className='bg-bottom'></div> */}
    </div>
  );
};

export default CorpIntro;
