import React from "react";
import styled from "styled-components";
import { addLeadingZero } from "utils/utils";
const EducationCard = ({ data, ...restProps }) => {
  // form => careerForm
  return (
    <StyledWrapper>
      <InfoBox>
        <div className="header">
          <span>{data.SCHOOL_NAME}</span>/<span>{data.EDUCATION_INFO}</span>
          <div className="date-group">
            <div className="flex">
              <span>
                {data.START_AT?.length === 0 || data.START_AT === null
                  ? ""
                  : addLeadingZero(data.START_AT?.split("-")[0]) +
                    ". " +
                    addLeadingZero(data.START_AT?.split("-")[1])}
              </span>
              <span style={{ fontSize: "16px", margin: "0 4px" }}>~</span>
              <span>
                {data.END_AT?.length === 0 || data.END_AT === null
                  ? ""
                  : addLeadingZero(data.END_AT?.split("-")[0]) +
                    ". " +
                    addLeadingZero(data.END_AT?.split("-")[1])}
              </span>{" "}
            </div>
          </div>
        </div>
      </InfoBox>
    </StyledWrapper>
  );
};

export default React.memo(EducationCard);

const StyledWrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 0px 18px;
  margin: 5px 0;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--mono-m-gray-03-stroke, #eee);
  & input {
    font-weight: 700;
  }
  & .flex {
    display: flex;
  }
`;
const InfoBox = styled.div`
  color: var(--gray02, #999);
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  & .header {
    z-index: 1;
    display: inline-flex;
    width: inherit;
    align-items: center;
    border: none;
    gap: 6px;
    align-self: stretch;
    & span {
      &:first-child {
        margin-left: 0;
      }
      /* Body 600 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.6px; /* 132.857% */
      color: #333;
    }
  }
  & button {
    position: absolute;
    z-index: 999;
    border: none;
    padding: 0;
    right: 0;
    line-height: 55px;
    background-color: transparent;
  }
  /* Body 400 */
  & .date-group {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: space-between;
    & span {
      color: var(--mono-m-black01, #333);

      /* Caption 500 */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.9px; /* 132.5% */
      margin: 0 !important;
    }
  }
`;
