import api from 'api';

export const getSolutionList = (params, body, callback) => {
	api.post('/solution/search', body, {
		params
	}).then((res) => { callback(res) })
}

export const getSolutionVIPList = (callback) => {
	api.get('/solution/list/vip').then((res) => { callback(res) })
}

export const getSolutionDetail = (seq, callback) => {
	api.get(`/solution/view/${seq}`).then((res) => { callback(res) })
}

export const userSolutionApply = (body, callback) => {
	api.post(`/solution/auth/apply`, body).then((res) => { callback(res) })
}

export const userSolutionPurchase = (seq, callback) => {
	api.post(`/solution/auth/apply`, null, { params: { seq } }).then((res) => { callback(res) })
}
