import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getParameterByName } from "utils/utils";
import { helpSearch } from "services/user";
import Search from "components/common/Search";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";

const Help = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedin } = useAuth();
  const { openModal } = useModal();
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchList, setSearchList] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState(true);

  useEffect(() => {
    setSearchKeyword(getParameterByName(location.search, "search"));
  }, [location]);

  useEffect(() => {
    if (!searchKeyword) return;
    setSearchInput(searchKeyword);
    helpSearch({ keyword: searchKeyword }, (res) => {
      setSearchList({
        total: res.response.noticeTotal + res.response.faqTotal,
        data: [
          {
            dataName: "공지사항",
            url: "/help/notice",
            list: res.response.notice,
            total: res.response.noticeTotal,
            keyword: searchKeyword,
          },
          {
            dataName: "FAQ",
            url: "/help/faq",
            list: res.response.faq,
            total: res.response.faqTotal,
            keyword: searchKeyword,
          },
        ],
      });
    });
  }, [searchKeyword]);

  const onSearchHandler = useCallback(() => {
    if (!searchInput) return;
    navigate("/help?search=" + searchInput);
  }, [searchInput, navigate]);

  return (
    <div className="container">
      <div className="customer-top">
        <div className="tit">고객센터</div>
        <div className="customer-top--btn">
          <button
            onClick={() => {
              navigate("/help/notice");
            }}
            type="button"
            className="btn"
          >
            <span className="icon icon-1"></span>공지사항
          </button>
          <button
            onClick={() => {
              if (isLoggedin) {
                navigate("/help/counsel");
              } else {
                openModal({
                  type: "loginModal",
                  contents: {
                    message: "1:1 문의는 회원가입 후 문의가 가능해요.",
                  },
                });
              }
            }}
            type="button"
            className="btn"
          >
            <span className="icon icon-2"></span>1:1 문의하기
          </button>
          <button
            onClick={() => {
              navigate("/help/faq");
            }}
            type="button"
            className="btn"
          >
            <span className="icon icon-3"></span>FAQ
          </button>
        </div>
      </div>
      <div className="main-search type2">
        <input
          className="input-text"
          type="text"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onFocus={() => setSearchPlaceholder(false)}
          onBlur={() => setSearchPlaceholder(true)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) onSearchHandler();
          }}
        />
        {!searchInput && searchPlaceholder && (
          <span
            onClick={(e) => e.target.parentElement.children[0].focus()}
            className="text"
          >
            AI HEROES에게 무엇이든 물어보세요.
          </span>
        )}
        <button
          type="button"
          className="btn btn-search"
          onClick={() => {
            onSearchHandler();
          }}
        ></button>
      </div>
      {searchKeyword && searchList && <Search data={searchList} />}
      <div className="faq-list">
        <div className="faq-list--tit">자주 묻는 질문</div>
        <div className="col-2">
          <div className="qs">
            <div className="tit">클라이언트이신가요?</div>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/6")}
            >
              Q. 매칭 프로세스가 궁금해요!
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/7")}
            >
              Q. 프로젝트 의뢰는 누구나 할 수 있나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/8")}
            >
              Q. 프로젝트 적정 수준의 견적이 궁금해요!
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/9")}
            >
              Q. 정부지원사업도 의뢰 가능한가요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/10")}
            >
              Q. 프로젝트 의뢰 내용이 보안 사항일 경우는 어떻게 하나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/11")}
            >
              Q. 계약은 어떻게 진행 되나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/12")}
            >
              Q. 대금지급 절차는 어떻게 진행 되나요?
            </button>
          </div>
          <div className="qs">
            <div className="tit">파트너이신가요?</div>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/13")}
            >
              Q. 프로젝트에 지원하려면 어떻게 해야 하나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/14")}
            >
              Q. 개인으로도 프로젝트에 지원할 수 있나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/15")}
            >
              Q. 지원한 프로젝트에 명시된 금액과 기간은 변경이 불가능 한가요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/16")}
            >
              Q. 미팅에 선정되는 기준은 무엇인가요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/17")}
            >
              Q. 지원한 프로젝트가 마감됐는데 연락을 못 받았어요.
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/18")}
            >
              Q. 대금지급 절차는 어떻게 진행 되나요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/19")}
            >
              Q. 지급 금액은 어떻게 계산되는 건가요?
            </button>
            <button
              type="button"
              className="btn q"
              onClick={() => navigate("faq/20")}
            >
              Q. 프로젝트 진행중 분쟁이 발생하면 어떻게되나요?
            </button>
          </div>
        </div>
      </div>
      <div className="faq-bottom">
        AI 히어로즈 문의
        <p className="strong">helpdesk@aiheroes.ai</p>
      </div>
    </div>
  );
};

export default Help;
