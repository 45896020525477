import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { aiProjectlike, getAiProjectList } from "services/aiproject";
import { priceFormat } from "utils/utils";

import heartFill from "assets/images/icon/heart_fill.svg";
import heartLine from "assets/images/icon/heart_line.svg";
import LoadingSpinner from "components/common/LoadingSpinner";
import MultiSelect from "components/common/MultiSelect";
import Pagination from "components/common/Pagination";
import Select from "components/common/Select";
import useViewCheck from "hooks/useViewCheck";
import styled from "styled-components";
const StyledButtonWrapper = styled.div`
  & .btn-like {
    width: 100%;
    height: 100%;
    min-width: 26px;
    background-size: 100%;
    background-image: none;
    & img {
      transition: opacity 0.3s ease-in-out;
    }
    /* Define styles for heartFill and heartLine */
    .heartFill {
      opacity: 1;
    }

    .heartLine {
      opacity: 0.4;
    }

    /* Apply transition to opacity for a smooth fade effect */
  }
`;
const orderList = [
  {
    id: 1,
    name: "최신순",
  },
  {
    id: 2,
    name: "견적 높은 순",
  },
  {
    id: 3,
    name: "마감임박 순",
  },
];

const aiIndOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "교육",
    apiName: "education",
    state: true,
  },
  {
    name: "서비스",
    apiName: "service",
    state: true,
  },
  {
    name: "제조, 화학, 에너지",
    apiName: "manufacturing",
    state: true,
  },

  {
    name: "자동차",
    apiName: "vehicle",
    state: true,
  },
  {
    name: "판매, 유통",
    apiName: "sales",
    state: true,
  },
  {
    name: "미디어, 엔터테인먼트",
    apiName: "media",
    state: true,
  },
  {
    name: "관광, 스포츠, 레저",
    apiName: "tour",
    state: true,
  },
  {
    name: "부동산, 건설, 건축",
    apiName: "construct",
    state: true,
  },
  {
    name: "금융",
    apiName: "finance",
    state: true,
  },
  {
    name: "의료, 제약, 바이오, 헬스케어",
    apiName: "medical",
    state: true,
  },
  {
    name: "IT, 통신",
    apiName: "it",
    state: true,
  },
  {
    name: "정부, 기관",
    apiName: "government",
    state: true,
  },
  {
    name: "식품",
    apiName: "food",
    state: true,
  },
  {
    name: "농업",
    apiName: "agriculture",
    state: true,
  },
  {
    name: "게임",
    apiName: "game",
    state: true,
  },
  {
    name: "기타",
    apiName: "etc",
    state: true,
  },
];

const aiTecOptions = [
  {
    name: "전체",
    state: true,
  },
  {
    name: "영상",
    apiName: "video",
    state: true,
  },
  {
    name: "이미지",
    apiName: "image",
    state: true,
  },
  {
    name: "음성",
    apiName: "sound",
    state: true,
  },
  {
    name: "텍스트",
    apiName: "text",
    state: true,
  },
  {
    name: "빅데이터",
    apiName: "bigData",
    state: true,
  },
  {
    name: "융합",
    apiName: "fusion",
    state: true,
  },
  {
    name: "기타",
    apiName: "etc",
    state: true,
  },
];

const AiProject = () => {
  const navigate = useNavigate();
  const { userInfo, isLoggedin } = useAuth();
  const { openModal } = useModal();
  const { isMobile } = useViewCheck();

  const [selectInd, setSelectInd] = useState(aiIndOptions.map((a) => a.name));
  const [selectTec, setSelectTec] = useState(aiTecOptions.map((a) => a.name));
  const [order, setOrder] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchKeywordInput, setSearchKeywordInput] = useState("");
  const [userRole, setUserRole] = useState("");
  const [aiProjectList, setAiProjectList] = useState([]);
  const [aiProjectTotal, setAiProjectTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getList();
  }, [page, selectInd, selectTec, order, searchKeyword]);

  useEffect(() => {
    setUserRole(userInfo.ROLE);
  }, [userInfo]);

  const getList = useCallback(() => {
    const params = {
      sortType: order,
      keyword: searchKeyword || null,
      page,
    };

    let aiIndustry = {};
    aiIndOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectInd.includes("전체")) {
        aiIndustry = {
          ...aiIndustry,
          [item.apiName]: false,
        };
      } else {
        aiIndustry = {
          ...aiIndustry,
          [item.apiName]: selectInd.includes(item.name),
        };
      }
    });
    let aiSkill = {};
    aiTecOptions.forEach((item) => {
      if (item.name === "전체") return;
      if (selectTec.includes("전체")) {
        aiSkill = {
          ...aiSkill,
          [item.apiName]: false,
        };
      } else {
        aiSkill = {
          ...aiSkill,
          [item.apiName]: selectTec.includes(item.name),
        };
      }
    });

    getAiProjectList(params, { aiIndustry, aiSkill }, (res) => {
      if (res.code !== 0) return;
      setAiProjectList(res.response.list);
      setAiProjectTotal(res.response.total);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
      setLoading(false);
    });
  }, [page, selectInd, selectTec, order, searchKeyword]);

  return (
    <div className="container">
      <div className="section">
        <div className="mt-40  m-none">
          <button
            type="button"
            className="btn btn-md btn-black type2 link"
            onClick={() => {
              if (isLoggedin) {
                navigate(`/aiproject/request`);
              } else {
                openModal({ type: "loginModal" });
              }
            }}
          >
            프로젝트 의뢰하기
          </button>
          <Link
            to="https://few-honeycrisp-bf0.notion.site/AI-ver-1-1-0-1a7a5e751b16443888f4a6b9f59f0ca4"
            target="_blank"
          >
            <button
              type="button"
              className="btn btn-md btn-primary type2 link"
              style={{ minWidth: "155px", marginLeft: "10px" }}
            >
              의뢰 가이드 보기
            </button>
          </Link>
        </div>
        <div className="search-top">
          <div className="count">전체 {aiProjectTotal}개</div>
          <div className="input">
            <div className="left">
              <MultiSelect
                value="AI 산업"
                width={219}
                options={aiIndOptions}
                selected={selectInd}
                setSelect={setSelectInd}
                zIndex={1}
              />
              <MultiSelect
                value="AI 기술"
                width={186}
                options={aiTecOptions}
                selected={selectTec}
                setSelect={setSelectTec}
              />
              <div className="m-search-guide">
                <Select
                  className={["text-start"]}
                  option={orderList}
                  width={135}
                  selected={order}
                  setSelect={setOrder}
                />
                <div className="input-wrap search m-block">
                  <button type="button" className="btn btn-search"></button>
                  <input
                    type="text"
                    className="input-text"
                    placeholder="키워드를 검색해주세요"
                    value={searchKeywordInput}
                    onChange={(e) => setSearchKeywordInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setSearchKeyword(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="right m-none">
              <div className="input-wrap search">
                <button type="button" className="btn btn-search"></button>
                <input
                  type="text"
                  className="input-text"
                  placeholder="원하는 키워드를 검색해 주세요."
                  value={searchKeywordInput}
                  onChange={(e) => setSearchKeywordInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setSearchKeyword(e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ul className="project-list">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {aiProjectList.length ? (
                <>
                  {aiProjectList.map((item, i) => (
                    <li
                      className="project-list--item"
                      onClick={() => {
                        if (isLoggedin) {
                          navigate(`/aiproject/${item.SEQ}`);
                        } else {
                          openModal({ type: "loginModal" });
                        }
                      }}
                      key={`project-list-${i}`}
                    >
                      <div className="top">
                        {isMobile && (
                          <div style={{ marginBottom: 10 }}>
                            <span
                              className={`badge black auto ${
                                item.STATUS !== "MATCHING" || item.DDAY < 0
                                  ? "done"
                                  : ""
                              }`}
                            >
                              {!(item.STATUS !== "MATCHING" || item.DDAY <= 0)
                                ? `공고 마감일 D-${item.DDAY}`
                                : item.DDAY === 0
                                ? `공고 마감일 D-DAY`
                                : `공고 마감`}
                            </span>
                          </div>
                        )}
                        {/* {item.CLOSE_STAUTS === "COMPLETE" && (
                          <span className="badge badge-round done">완료</span>
                        )}
                        {item.CLOSE_STAUTS === "PROGRESS" && (
                          <span className="badge badge-round done">
                            진행 중
                          </span>
                        )}
                        {item.CLOSE_STAUTS === "MATCHING" && (
                          <span className="badge badge-round done">
                            매칭 중
                          </span>
                        )} */}
                        {item.TYPE === "BUSINESS" ? (
                          <span className="badge badge-round primary">
                            기업 프로젝트
                          </span>
                        ) : (
                          <span className="badge badge-round blue">
                            개인 프로젝트
                          </span>
                        )}
                        {(item.WORK_METHOD === "BOTH" ||
                          item.WORK_METHOD === "RESIDENT") && (
                          <span className="badge badge-round orange">상주</span>
                        )}
                        {(item.WORK_METHOD === "BOTH" ||
                          item.WORK_METHOD === "REMOTE") && (
                          <span className="badge badge-round blue-line">
                            원격
                          </span>
                        )}
                        {!isMobile && (
                          <div className="right">
                            <span
                              className={`badge black auto ${
                                item.STATUS !== "MATCHING" || item.DDAY < 0
                                  ? "done"
                                  : ""
                              }`}
                            >
                              {!(item.STATUS !== "MATCHING" || item.DDAY <= 0)
                                ? `공고 마감일 D-${item.DDAY}`
                                : item.DDAY === 0
                                ? `공고 마감일 D-DAY`
                                : `공고 마감`}
                            </span>
                          </div>
                        )}
                      </div>
                      {userRole?.includes("HEROS") ? (
                        <StyledButtonWrapper className="tit type-like">
                          <span className="text">{item.PROJECT_NAME}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              aiProjectlike(item.SEQ, (res) => {
                                if (res.code === 0) {
                                  getList();
                                }
                              });
                            }}
                            type="button"
                            className={`btn btn-like ${
                              item.PROJECT_LIKE ? "active" : ""
                            }`}
                          >
                            <img
                              alt="like"
                              className={
                                item.PROJECT_LIKE ? "heartFill" : "heartLine"
                              }
                              src={item.PROJECT_LIKE ? heartFill : heartLine}
                            />
                          </button>
                        </StyledButtonWrapper>
                      ) : (
                        <div className="tit">{item.PROJECT_NAME}</div>
                      )}
                      <div className="info">
                        <span className="text">
                          예산 및 급여 방식:
                          {item.PAY_NEGOTIABLE ? (
                            <span
                              className="badge badge-sm skyblue"
                              style={isMobile ? { marginLeft: 5 } : {}}
                            >
                              제안 가능
                            </span>
                          ) : (
                            <>
                              &nbsp;{priceFormat(item.BUDGET_MIN)}~
                              {priceFormat(item.BUDGET_MAX)}만 원
                              <br className="m-block" />
                              <span className="badge badge-sm skyblue">
                                {item.PAY_METHOD === "SALARY"
                                  ? "월급"
                                  : item.PAY_METHOD === "WAGE"
                                  ? "주급"
                                  : "프로젝트 완료 후"}
                              </span>
                            </>
                          )}
                        </span>
                        <span className="text">
                          기간:
                          {item.PERIOD_NEGOTIABLE ? (
                            <span
                              className="badge badge-sm skyblue"
                              style={isMobile ? { marginLeft: 5 } : {}}
                            >
                              협의 가능
                            </span>
                          ) : (
                            <>
                              &nbsp;
                              {item.PROJECT_PERIOD % 4 === 0
                                ? item.PROJECT_PERIOD / 4
                                : item.PROJECT_PERIOD}
                              {item.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                            </>
                          )}
                        </span>
                        <span className="text">
                          데이터 보유 현황:{" "}
                          {item.HAVE_DATA ? "데이터 있음" : "데이터 없음"}
                        </span>
                      </div>
                      <div className="option">
                        <label className="label">산업 분야</label>
                        <span>
                          {item.EDUCATION && (
                            <span className="badge badge-hide primary2">
                              교육
                            </span>
                          )}
                          {item.SERVICE && (
                            <span className="badge badge-hide primary2">
                              서비스
                            </span>
                          )}
                          {item.MANUFACTURING && (
                            <span className="badge badge-hide primary2">
                              제조, 화학, 에너지
                            </span>
                          )}
                          {item.VEHICLE && (
                            <span className="badge badge-hide primary2">
                              자동차
                            </span>
                          )}
                          {item.SALES && (
                            <span className="badge badge-hide primary2">
                              판매, 유통
                            </span>
                          )}
                          {item.MEDIA && (
                            <span className="badge badge-hide primary2">
                              미디어, 엔터테인먼트
                            </span>
                          )}
                          {item.TOUR && (
                            <span className="badge badge-hide primary2">
                              관광, 스포츠, 레저
                            </span>
                          )}
                          {item.CONSTRUCT && (
                            <span className="badge badge-hide primary2">
                              부동산, 건설, 건축
                            </span>
                          )}
                          {item.FINANCE && (
                            <span className="badge badge-hide primary2">
                              금융
                            </span>
                          )}
                          {item.MEDICAL && (
                            <span className="badge badge-hide primary2">
                              의료, 제약, 바이오, 헬스케어
                            </span>
                          )}
                          {item.IT && (
                            <span className="badge badge-hide primary2">
                              IT, 통신
                            </span>
                          )}
                          {item.GOVERNMENT && (
                            <span className="badge badge-hide primary2">
                              정부, 기관
                            </span>
                          )}
                          {item.FOOD && (
                            <span className="badge badge-hide primary2">
                              식품
                            </span>
                          )}
                          {item.AGRICULTURE && (
                            <span className="badge badge-hide primary2">
                              농업
                            </span>
                          )}
                          {item.GAME && (
                            <span className="badge badge-hide primary2">
                              게임
                            </span>
                          )}
                          {item.INDUSTRY_ETC && (
                            <span className="badge badge-hide primary2">
                              기타
                            </span>
                          )}
                          {item.INDUSTRY_OPTIONS > 3 && (
                            <span className="text">
                              +{item.INDUSTRY_OPTIONS - 3}
                            </span>
                          )}
                          <br className="m-block" />
                        </span>
                        <label className="label">기술 분야</label>
                        <span>
                          {item.VIDEO && (
                            <span className="badge badge-hide primary2">
                              동영상
                            </span>
                          )}
                          {item.IMAGE && (
                            <span className="badge badge-hide primary2">
                              이미지
                            </span>
                          )}
                          {item.SOUND && (
                            <span className="badge badge-hide primary2">
                              음성
                            </span>
                          )}
                          {item.TEXT && (
                            <span className="badge badge-hide primary2">
                              텍스트
                            </span>
                          )}
                          {item.BIG_DATA && (
                            <span className="badge badge-hide primary2">
                              빅데이터
                            </span>
                          )}
                          {item.FUSION && (
                            <span className="badge badge-hide primary2">
                              융합
                            </span>
                          )}
                          {item.SKILL_ETC && (
                            <span className="badge badge-hide primary2">
                              기타
                            </span>
                          )}
                          {item.SKILL_OPTIONS > 3 && (
                            <span className="text">
                              +{item.SKILL_OPTIONS - 3}
                            </span>
                          )}
                        </span>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <div className="container-none">검색 결과를 찾지 못했어요.</div>
              )}
            </>
          )}
        </ul>
        {paginationData && (
          <div style={{ marginBottom: "50px" }}>
            <Pagination
              paginationData={paginationData}
              page={page}
              setPage={setPage}
              floatingPage={isMobile}
            />
          </div>
        )}
      </div>

      <div className="m-flex m-floating">
        <button
          type="button"
          className="btn btn-md btn-black type2 link m-btn"
          style={{ width: "65%", height: "50px" }}
          onClick={() => {
            if (isLoggedin) {
              navigate(`/aiproject/request`);
            } else {
              openModal({ type: "loginModal" });
            }
          }}
        >
          <span>프로젝트 의뢰하기</span>
        </button>

        <button
          type="button"
          style={{ marginLeft: "10px", width: "35%", height: "50px" }}
          className="btn btn-md btn-primary type2 link m-btn"
          onClick={() => {
            window.open(
              `https://few-honeycrisp-bf0.notion.site/AI-ver-1-1-0-1a7a5e751b16443888f4a6b9f59f0ca4`,
              "_blank"
            );
          }}
        >
          가이드 보기
        </button>
      </div>
    </div>
  );
};

export default AiProject;
