import React from "react";
import styled from "styled-components";
const ToggleRadio = ({ children }) => {
  return <StyledRadioWrapper>{children}</StyledRadioWrapper>;
};

export default ToggleRadio;

const StyledRadioWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  & > label.input-label {
    display: flex;
    margin: 0;
    margin-right: 20px;
  }
  & div.radio {
    display: flex;
    background-color: #f6f6f6;
    border-radius: 100px;
    width: 200px;
    max-width: 200px;
    text-align: center;
    & .button {
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 18.6px; /* 132.857% */
      border-radius: 100px;
      width: 100px;
      text-align: center;
      & label {
        margin: 0 !important;
        padding: 0 !important;
        display: block;
        text-align: center;
        width: inherit;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        color: #d6d6d6 !important;
      }
      &.indi {
        background: var(--sub-u-blue-01, #43f4ff);
        & label {
          color: var(--mono-m-black-00, #000) !important;
        }
      }
      &.bus {
        background: var(--primary-p-green-00, #00ff85);
        & label {
          color: var(--mono-m-black-00, #000) !important;
        }
      }
    }
  }
`;
