import api from 'api';

export const auth = {
	signin({ email, password }, callback) {
		api.post('/user/login',
			{
				username: email,
				password,
			}
		).then((res) => {
			if (res.code === 0) {
				localStorage.setItem('jwt', res.response.jwt)
				localStorage.setItem('is-first', res.response.first)
			};
			callback(res)
		})
	},

	signout(callback) {
		localStorage.removeItem('jwt');
		window.dispatchEvent(new Event('storage'));
		setTimeout(callback, 100);
	},

	socialLogin(params, callback) {
		api.get('/user/social-login',
			{
				params
			}
		).then((res) => {
			if (res.code === 0 && res.response.jwt) {
				localStorage.setItem('jwt', res.response.jwt)
				localStorage.setItem('is-first', res.response.first)
			}
			callback(res)
		})
	}
};