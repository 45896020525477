import React, { useState } from "react";
import useModal from "hooks/useModal";
import { aiProjectApply } from "services/aiproject";
import { uncomma } from "utils/utils";
import OfferInput from "../OfferInput";

const OfferModal = ({ postId, postTitle, callback, clientPayMethod }) => {
  const { openModal, closeModal } = useModal();
  const [amount, setAmount] = useState(null);
  const [payMethod, setPayMethod] = useState(clientPayMethod || "SALARY");

  return (
    <div className="modal show">
      <div className="modal-dim"></div>
      <div className="modal-con message">
        <div className="text">
          <p className="strong">금액 제안</p>
          <p>
            희망하는 급여 및 지급 방식을 <br className="m-block" />
            제안하고 지원해 보세요.
          </p>
        </div>
        <OfferInput
          amount={amount}
          handleAmount={setAmount}
          payMethod={payMethod}
          handlePayMethod={setPayMethod}
          color="rgb(153, 153, 153)"
        />
        <div className="btn-wrap">
          <button
            style={{ width: "94px" }}
            onClick={() => closeModal()}
            type="button"
            className="btn btn-half btn-grey"
          >
            돌아가기
          </button>
          <button
            style={{ width: "200px" }}
            onClick={() => {
              const body = {
                offer: Number(uncomma(amount)),
                offerPayMethod: payMethod,
              };
              aiProjectApply(postId, body, (res) => {
                if (res.code === 0) {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "지원 완료",
                      message: `[${postTitle}]에 지원이 성공적으로 완료되었어요.\n지원 및 매칭에 대한 안내 메일을 보내드릴게요.`,
                      confirmText: "확인",
                      confirmCallback: () => {
                        callback();
                        closeModal();
                        closeModal();
                      },
                    },
                  });
                } else {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "요청에 실패했습니다",
                      message:
                        "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                      confirmText: "확인",
                      confirmCallback: () => {
                        closeModal();
                      },
                    },
                  });
                }
              });
            }}
            type="button"
            className="btn btn-half btn-primary"
          >
            지원하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferModal;
