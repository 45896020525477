import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { userPasswordResetChange } from 'services/user';
import { passwordRegEx } from 'utils/regex';

const ChangePassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { openModal } = useModal();
	const [key, setKey] = useState();
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordError, setNewPasswordError] = useState('');
	const [newPasswordHide, setNewPasswordHide] = useState(false);

	useEffect(() => {
		if (!location.state?.key) {
			navigate('/');
		} else {
			setKey(location.state.key)
		}
	}, [location])

	const changePassword = () => {
		const body = {
			newPassword
		}
		userPasswordResetChange(key, body, (res) => {
			if (res.code !== 0) {
				openModal({
					type: 'messageModal',
					contents: {
						title: '요청에 실패했습니다.',
						message: '유효하지 않은 요청입니다.',
						confirmText: '로그인하기',
						confirmCallback: () => {
							navigate('/');
							openModal({
								type: 'loginModal'
							});
						},
					}
				});
			} else {
				openModal({
					type: 'messageModal',
					contents: {
						title: '비밀번호 변경 완료',
						message: '정상적으로 비밀번호가 변경됐어요.',
						confirmText: '로그인하기',
						confirmCallback: () => {
							navigate('/');
							openModal({
								type: 'loginModal'
							});
						},
					}
				});
			}
		})
	}

	return (
		<div className="container">
			<div className="section">
				<div className="input-wrap">
					<div className="section-tit lg">
						비밀번호를 변경해 주세요.
					</div>
					<div className="input-wrap">
						<label className="input-label sm">새 비밀번호</label>
						<div className="input-password">
							<input
								type={newPasswordHide ? 'password' : 'text'}
								className={`input-text ${(newPasswordError) ? 'error' : ''}`}
								placeholder="영문+숫자+특수문자 조합 8~16자리의 새 비밀번호를 입력해 주세요."
								value={newPassword}
								onChange={(e) => {
									setNewPasswordError(false);
									setNewPassword(e.target.value)
								}}
								onBlur={(e) => {
									if (!e.target.value) return;
									if (passwordRegEx(e.target.value)) {
										setNewPasswordError(false);
									} else {
										setNewPasswordError(true);
									}
								}}

							/>
							<button
								onClick={() => { setNewPasswordHide(!newPasswordHide) }}
								type="button"
								className={`btn ${newPasswordHide ? 'hide' : 'show'}`}
							></button>
						</div>
						{newPasswordError &&
							<p className="input-guide error" style={{ marginLeft: 10 }}>
								영문+숫자+특수문자 조합 8~16자리 비밀번호가
								아니에요.
							</p>
						}
					</div>
					<button onClick={() => changePassword()} type="button" className="btn btn-full btn-black type2 mt-30" disabled={newPasswordError}>비밀번호 변경하기</button>
				</div>
			</div>
		</div>
	)
}

export default ChangePassword