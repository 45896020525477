import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import "styles/style.scss";
import useAuth from "hooks/useAuth";

import Layout from "components/common/Layout";
import Home from "pages/Home/index";
// import OHome from "pages/Home/_legacy/Home";
import Help from "pages/Help/Help";
import Notice from "pages/Help/Notice";
import NoticeDetail from "pages/Help/NoticeDetail";
import FAQ from "pages/Help/FAQ";
import FAQDetail from "pages/Help/FAQDetail";
import Counsel from "pages/Help/Counsel";
import Privacy from "pages/Policy/Privacy";
import Service from "pages/Policy/Service";
import CorpIntro from "pages/CorpIntro";
import Join from "pages/Join/Join";
import NaverLogin from "pages/SocialLogin/NaverLogin";
import GoogleLogin from "pages/SocialLogin/GoogleLogin";
import KakaoLogin from "pages/SocialLogin/KakaoLogin";
import Idinquiry from "pages/Idinquiry/Idinquiry";
import Mypage from "pages/Mypage/Mypage";
import MyInfo from "pages/Mypage/MyInfo";
import LeaveId from "pages/Mypage/LeaveId";
import Register from "pages/Mypage/Register";
import AiProject from "pages/AiProject/AiProject";
import AiProjectDetail from "pages/AiProject/AiProjectDetail";
import AiProjectRequest from "pages/AiProject/AiProjectRequest";
//import Heroes from "pages/Heroes/Heroes";
import NHeroes from "pages/Heroes/NHeroes";
//import HeroesDetail from "pages/Heroes/HeroesDetail";
import NHeroesDetail from "pages/Heroes/NHeroesDetail";
import Community from "pages/Community/Community";
import SolutionStore from "pages/SolutionStore/SolutionStore";
import SolutionStoreDetail from "pages/SolutionStore/SolutionStoreDetail";
import EmailAuth from "pages/EmailAuth";

import AuthProvider from "context/AuthProvider";
import ModalProvider from "context/ModalProvider";
import ViewCheckProvider from "context/ViewCheckProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

import ModalContainer from "components/common/modal/ModalContainer";
import MetaTag from "MetaTag";
import AdminLogin from "pages/SocialLogin/AdminLogin";

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      }),
    children
  );

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
};

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isLoggedin } = useAuth();

  useEffect(() => {
    if (!(localStorage.getItem("jwt") || isLoggedin)) {
      navigate("/", { replace: true });
    }
  }, [isLoggedin]);

  return children;
};

const menuUrls = ["aiproject",  "heroes", "community", "solutionstore"];
function App() {
  const { pathname } = useLocation();
  const mainMenu = pathname.split("/")[1];

  useEffect(() => {
    document.querySelectorAll(".modal.show").forEach((item) => item.remove());
  }, [pathname]);


  return (
    <AppProvider
      contexts={[
        AuthProvider,
        ModalProvider,
        GoogleOAuthProvider,
        ViewCheckProvider,
        HelmetProvider,
      ]}
    >
      <ScrollToTop>
        <MetaTag
          title={"AI 히어로즈"}
          desc={
            "전문 AI 기업/연구원에 직접 프로젝트를 의뢰하고 다양한 인공지능 기술을 활용한 서비스 도입을 시작하세요!"
          }
          robot={true}
        />
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <>
                  <MetaTag
                    title={"AI 히어로즈 | IT & AI 전문 외주 개발 플랫폼"}
                    desc={
                      "AI 히어로즈는 신뢰할 수 있는 개발자들과 뛰어난 기술력으로 IT & AI 외주 개발 프로젝트의 성공을 보장합니다. 당신의 프로젝트를 성공으로 이끌어보세요."
                    }
                    robot={true}
                  />
                  {/* <OHome /> */}
                  <Home />
                </>
              }
            />
            <Route path="/help" element={<Help />} />
            <Route path="/help/notice" element={<Notice />} />
            <Route path="/help/notice/:id" element={<NoticeDetail />} />
            <Route path="/help/faq" element={<FAQ />} />
            <Route path="/help/faq/:id" element={<FAQDetail />} />
            <Route
              path="/help/counsel"
              element={
                <ProtectedRoute>
                  <Counsel />
                </ProtectedRoute>
              }
            />
            <Route path="/corpintro" element={<CorpIntro />} />
            <Route path="/service" element={<Service />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/join" element={<Join />} />
            <Route path="/naver/callback" element={<NaverLogin />} />
            <Route path="/google/callback" element={<GoogleLogin />} />
            <Route path="/kakao/callback" element={<KakaoLogin />} />
            <Route path="/admin/callback" element={<AdminLogin />} />
            <Route path="/idinquiry/*" element={<Idinquiry />} />
            <Route
              path="/mypage/*"
              element={
                <ProtectedRoute>
                  <MetaTag
                    title={"마이페이지 | AI 히어로즈"}
                    desc={
                      "서비스 혁신을 위한 프로젝트 지금 바로 등록하세요! AI 전문가들을 통해 프로젝트를 안전하고 확실하게 진행해 보세요."
                    }
                    robot={false}
                  />
                  <Mypage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/myinfo"
              element={
                <ProtectedRoute>
                  <MetaTag
                    title={"마이페이지 | AI 히어로즈"}
                    desc={
                      "서비스 혁신을 위한 프로젝트 지금 바로 등록하세요! AI 전문가들을 통해 프로젝트를 안전하고 확실하게 진행해 보세요."
                    }
                    robot={false}
                  />
                  <MyInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/leaveid/*"
              element={
                <ProtectedRoute>
                  <MetaTag
                    title={"마이페이지 | AI 히어로즈"}
                    desc={
                      "서비스 혁신을 위한 프로젝트 지금 바로 등록하세요! AI 전문가들을 통해 프로젝트를 안전하고 확실하게 진행해 보세요."
                    }
                    robot={false}
                  />
                  <LeaveId />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/register"
              element={
                <>
                  <MetaTag
                    title={"마이페이지 | AI 히어로즈"}
                    desc={
                      "서비스 혁신을 위한 프로젝트 지금 바로 등록하세요! AI 전문가들을 통해 프로젝트를 안전하고 확실하게 진행해 보세요."
                    }
                    robot={false}
                  />
                  <Register />
                </>
              }
            />
            <Route
              path="/aiproject"
              element={
                <>
                  <MetaTag
                    title={"프로젝트 | AI 히어로즈"}
                    desc={
                      "당신의 프로젝트를 시작하세요. 검증된 각 분야 전문가와의 최적의 매칭을 경험하고 원하는 결과를 얻어보세요."
                    }
                    robot={true}
                  />
                  <AiProject />
                </>
              }
            />
            <Route
              path="/aiproject/:id"
              element={
                <>
                  <MetaTag
                    title={"프로젝트 | AI 히어로즈"}
                    desc={
                      "당신의 프로젝트를 시작하세요. 검증된 각 분야 전문가와의 최적의 매칭을 경험하고 원하는 결과를 얻어보세요."
                    }
                    robot={true}
                  />
                  <AiProjectDetail />
                </>
              }
            />
            <Route
              path="/aiproject/request"
              element={
                <ProtectedRoute>
                  <MetaTag
                    title={"프로젝트 | AI 히어로즈"}
                    desc={
                      "당신의 프로젝트를 시작하세요. 검증된 각 분야 전문가와의 최적의 매칭을 경험하고 원하는 결과를 얻어보세요."
                    }
                    robot={true}
                  />
                  <AiProjectRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/heroes"
              element={
                <>
                  <MetaTag
                    title={"파트너 | AI 히어로즈"}
                    desc={
                      "AI 히어로즈의 검증된 파트너를 만나보세요. 신뢰할 수 있는 파트너와 함께 성공적인 프로젝트를 진행하세요."
                    }
                    robot={true}
                  />
                  <NHeroes />
                </>
              }
            />
            <Route
              path="/heroes/:id"
              element={
                <>
                  <MetaTag
                    title={"파트너 | AI 히어로즈"}
                    desc={
                      "AI 히어로즈의 검증된 파트너를 만나보세요. 신뢰할 수 있는 파트너와 함께 성공적인 프로젝트를 진행하세요."
                    }
                    robot={true}
                  />
                  <NHeroesDetail />
                </>
              }
            />
            <Route
              path="/community/*"
              element={
                <>
                  <MetaTag
                    title={"AI 블로그 | AI 히어로즈"}
                    desc={
                      "AI & IT 관련 신규 콘텐츠를 확인해보세요. 최신 정보와 트렌드를 통해 당신의 프로젝트를 더욱 성공적으로 만들어보세요."
                    }
                    robot={true}
                  />
                  <Community />
                </>
              }
            />
            <Route
              path="/solutionstore"
              element={
                <>
                  <MetaTag
                    title={"솔루션 스토어 | AI 히어로즈"}
                    desc={
                      "구매 시 5% 할인 혜택 제공! 다양한 IT 솔루션을 확인하고, 당신에게 적합한 솔루션을 직접 구매해보세요."
                    }
                    robot={true}
                  />
                  <SolutionStore />
                </>
              }
            />
            <Route
              path="/solutionstore/:id"
              element={
                <>
                  <MetaTag
                    title={"솔루션 스토어 | AI 히어로즈"}
                    desc={
                      "구매 시 5% 할인 혜택 제공! 다양한 IT 솔루션을 확인하고, 당신에게 적합한 솔루션을 직접 구매해보세요."
                    }
                    robot={true}
                  />
                  <SolutionStoreDetail />
                </>
              }
            />
            <Route path="/emailauth" element={<EmailAuth />} />

            {/* <Route path="/mypage" element={<Register />} /> */}
          </Route>
          <Route
            path="*"
            element={
              <Navigate to={menuUrls.includes(mainMenu) ? `/${mainMenu}` : "/"} />
            }
          />
        </Routes>
      </ScrollToTop>
      <ModalContainer />
      {/* </GoogleOAuthProvider> */}
    </AppProvider>
  );
}

export default App;
