import api from "api";

// 이전버전 메인페이지 API
export const heroesTop = (callback) => {
  api.get(`/heros/heroes-top`).then((res) => {
    callback(res);
  });
};

export const homeBanner = (callback) => {
  api.post(`/banner/view/list`).then((res) => {
    callback(res);
  });
};
// 이전버전 메인페이지 API

export const homeBannerList = (seq, callback) => {
  api.get(`/banner/list?category=${seq}`).then((res) => {
    callback(res);
  });
};

export const homeBannerCategoryList = (location, callback) => {
  api.get(`/banner/category/list?location=${location}`).then((res) => {
    callback(res);
  });
};

export const projectHasNew = (callback) => {
  api.get(`/project/has-new`).then((res) => {
    callback(res);
  });
};

export const topicRecent = (callback) => {
  api.get(`/topic/view/recent`).then((res) => {
    callback(res);
  });
};

export const termsListView = (callback) => {
  api.get(`/user/terms`).then((res) => {
    callback(res);
  });
};

export const termsDetailView = (seq, callback) => {
  api.get(`/user/terms/${seq}`).then((res) => {
    callback(res);
  });
};

export const homePopupHits = (type, callback) => {
  api.post(`/hits?hitsType=${type}`).then((res) => {
    callback(res);
  });
};
