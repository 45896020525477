import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  userProjectApplicationHeroes,
  userProjectLikeProjectHeroes,
} from "services/projectManagement";
import { priceFormat } from "utils/utils";

import Pagination from "components/common/Pagination";

import folder_color from "assets/images/icon/folder_color.svg";
import heart_fill from "assets/images/icon/heart_fill.svg";
import useViewCheck from "hooks/useViewCheck";

const AiprojectH = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewCheck();

  const [apPage, setApPage] = useState(1);
  const [lpPage, setLpPage] = useState(1);
  const [apList, setApList] = useState([]);
  const [lpList, setLpList] = useState([]);
  const [apPaginationData, setApPaginationData] = useState(null);
  const [lpPaginationData, setLpPaginationData] = useState(null);

  useEffect(() => {
    userProjectApplicationHeroes({ page: apPage }, (res) => {
      if (res.code !== 0) {
        navigate("/");
      }
      setApList(res.response.list);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setApPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
    userProjectLikeProjectHeroes({ page: lpPage }, (res) => {
      setLpList(res.response.list);
      const {
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      } = res.response;
      setLpPaginationData({
        navigateFirstPage,
        navigateLastPage,
        navigatepageNums,
        nextPage,
        pageNum,
        prePage,
        isFirstPage,
        isLastPage,
        hasPreviousPage,
        hasNextPage,
        pages,
      });
    });
  }, [apPage, lpPage]);
  return (
    <div className="mypage-con">
      <div className="mypage-tit">
        <img src={folder_color} alt="" />
        지원된/제안한 프로젝트
      </div>
      <ul className="project-list">
        {apList?.length ? (
          <>
            {apList.map((item, i) => (
              <li
                className={`project-list--item ${
                  item.STATUS === "CANCEL" ||
                  item.STATUS === "REFUSE" ||
                  item.DELETED ||
                  item.STATUS === "CLOSED" ||
                  item.MEETING_DELETED
                    ? "done"
                    : ""
                }`}
                onClick={() => {
                  if (
                    item.STATUS === "CANCEL" ||
                    item.STATUS === "REFUSE" ||
                    item.DELETED ||
                    item.STATUS === "CLOSED" ||
                    item.MEETING_DELETED
                  )
                    return;
                  navigate(`/mypage/aiprojecth/${item.PROJECT_SEQ}`);
                }}
                key={`user-project-c-list-${i}`}
              >
                <div className="top">
                  {item.TYPE === "BUSINESS" ? (
                    <span className="badge badge-round primary">
                      기업 프로젝트
                    </span>
                  ) : (
                    <span className="badge badge-round blue">
                      개인 프로젝트
                    </span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "RESIDENT") && (
                    <span className="badge badge-round orange">상주</span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "REMOTE") && (
                    <span className="badge badge-round blue-line">원격</span>
                  )}
                  <div className="right">
                    {item.DELETED || item.MEETING_DELETED ? (
                      <>
                        <span className="badge blue">종료</span>
                      </>
                    ) : (
                      <>
                        {item.STATUS === "APPLY" && (
                          <span className="badge blue">매칭 진행</span>
                        )}
                        {item.STATUS === "SUGGEST" && (
                          <span className="badge purple2">받은 제안</span>
                        )}
                        {item.STATUS === "CONTRACT" && (
                          <span className="badge blue">매칭 진행</span>
                        )}
                        {item.STATUS === "MEETING" && (
                          <span className="badge blue">매칭 진행</span>
                        )}
                        {item.STATUS === "PROGRESS" && (
                          <span className="badge primary">진행 중</span>
                        )}
                        {item.STATUS === "CANCEL" && (
                          <span className="badge primary">종료</span>
                        )}
                        {item.STATUS === "REFUSE" && (
                          <span className="badge primary">종료</span>
                        )}
                        {item.STATUS === "COMPLETE" && (
                          <span className="badge primary">완료</span>
                        )}
                        {item.STATUS === "CLOSED" && (
                          <span className="badge primary">완료</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="tit">{item.PROJECT_NAME}</div>
                <div className="info">
                  <span className="text">
                    예산 및 급여 방식:
                    {item.PAY_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        제안 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;{priceFormat(item.BUDGET_MIN)}~
                        {priceFormat(item.BUDGET_MAX)}만 원
                        <br className="m-block" />
                        <span className="badge badge-sm skyblue">
                          {item.PAY_METHOD === "SALARY"
                            ? "월급"
                            : item.PAY_METHOD === "WAGE"
                            ? "주급"
                            : "프로젝트 완료 후"}
                        </span>
                      </>
                    )}
                  </span>
                  <span className="text">
                    기간:
                    {item.PERIOD_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        협의 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;
                        {item.PROJECT_PERIOD % 4 === 0
                          ? item.PROJECT_PERIOD / 4
                          : item.PROJECT_PERIOD}
                        {item.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                      </>
                    )}
                  </span>
                  <span className="text">
                    데이터 보유 현황: 데이터 {item.HAVE_DATA ? "있음" : "없음"}
                  </span>
                </div>
                <div className="option">
                  <label className="label">산업 분야</label>
                  <span>
                    {item.EDUCATION && (
                      <span className="badge badge-hide primary2">교육</span>
                    )}
                    {item.SERVICE && (
                      <span className="badge badge-hide primary2">서비스</span>
                    )}
                    {item.MANUFACTURING && (
                      <span className="badge badge-hide primary2">
                        제조, 화학, 에너지
                      </span>
                    )}
                    {item.VEHICLE && (
                      <span className="badge badge-hide primary2">자동차</span>
                    )}
                    {item.SALES && (
                      <span className="badge badge-hide primary2">
                        판매, 유통
                      </span>
                    )}
                    {item.MEDIA && (
                      <span className="badge badge-hide primary2">
                        미디어, 엔터테인먼트
                      </span>
                    )}
                    {item.TOUR && (
                      <span className="badge badge-hide primary2">
                        관광, 스포츠, 레저
                      </span>
                    )}
                    {item.CONSTRUCT && (
                      <span className="badge badge-hide primary2">
                        부동산, 건설, 건축
                      </span>
                    )}
                    {item.FINANCE && (
                      <span className="badge badge-hide primary2">금융</span>
                    )}
                    {item.MEDICAL && (
                      <span className="badge badge-hide primary2">
                        의료, 제약, 바이오, 헬스케어
                      </span>
                    )}
                    {item.IT && (
                      <span className="badge badge-hide primary2">
                        IT, 통신
                      </span>
                    )}
                    {item.GOVERNMENT && (
                      <span className="badge badge-hide primary2">
                        정부, 기관
                      </span>
                    )}
                    {item.FOOD && (
                      <span className="badge badge-hide primary2">식품</span>
                    )}
                    {item.AGRICULTURE && (
                      <span className="badge badge-hide primary2">농업</span>
                    )}
                    {item.GAME && (
                      <span className="badge badge-hide primary2">게임</span>
                    )}
                    {item.INDUSTRY_ETC && (
                      <span className="badge badge-hide primary2">기타</span>
                    )}
                    {item.OPTIONS > 3 && (
                      <span className="text">+{item.OPTIONS - 3}</span>
                    )}
                  </span>
                  <br className="m-block" />
                  <label className="label">기술 분야</label>
                  <span>
                    {item.VIDEO && (
                      <span className="badge badge-hide primary2">동영상</span>
                    )}
                    {item.IMAGE && (
                      <span className="badge badge-hide primary2">이미지</span>
                    )}
                    {item.SOUND && (
                      <span className="badge badge-hide primary2">음성</span>
                    )}
                    {item.TEXT && (
                      <span className="badge badge-hide primary2">텍스트</span>
                    )}
                    {item.BIG_DATA && (
                      <span className="badge badge-hide primary2">
                        빅데이터
                      </span>
                    )}
                    {item.FUSION && (
                      <span className="badge badge-hide primary2">융합</span>
                    )}
                    {item.SKILL_ETC && (
                      <span className="badge badge-hide primary2">기타</span>
                    )}
                    {item.SKILL_OPTIONS > 3 && (
                      <span className="text">+{item.SKILL_OPTIONS - 3}</span>
                    )}
                  </span>
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            <div className="container-none">아직 등록된 프로젝트가 없어요.</div>
          </>
        )}
      </ul>
      {apPaginationData && (
        <Pagination
          paginationData={apPaginationData}
          page={apPage}
          setPage={setApPage}
        />
      )}
      <div className="mypage-tit">
        <img src={heart_fill} alt="" />
        관심 프로젝트
      </div>
      <ul className="project-list">
        {lpList?.length ? (
          <>
            {lpList.map((item, i) => (
              <li
                className={`project-list--item ${
                  item.PROJECT_STATUS !== "MATCHING" || item.DDAY < 0
                    ? "done"
                    : ""
                }`}
                onClick={() => {
                  if (item.PROJECT_STATUS !== "MATCHING" || item.DDAY < 0)
                    return;
                  navigate(`/aiproject/${item.PROJECT_SEQ}`);
                }}
                key={`user-project-c-list-${i}`}
              >
                <div className="top">
                  {isMobile && (
                    <div style={{ marginBottom: 10 }}>
                      {!(
                        item.PROJECT_STATUS !== "MATCHING" || item.DDAY <= 0
                      ) ? (
                        <span className="badge black auto">
                          공고 마감일 D-{item.DDAY === 0 ? "DAY" : item.DDAY}
                        </span>
                      ) : (
                        <span className="badge black auto">공고 마감</span>
                      )}
                    </div>
                  )}
                  {item.TYPE === "BUSINESS" ? (
                    <span className="badge badge-round primary">
                      기업 프로젝트
                    </span>
                  ) : (
                    <span className="badge badge-round blue">
                      개인 프로젝트
                    </span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "RESIDENT") && (
                    <span className="badge badge-round orange">상주</span>
                  )}
                  {(item.WORK_METHOD === "BOTH" ||
                    item.WORK_METHOD === "REMOTE") && (
                    <span className="badge badge-round blue-line">원격</span>
                  )}
                  {!isMobile && (
                    <div className="right">
                      {!(
                        item.PROJECT_STATUS !== "MATCHING" || item.DDAY < 0
                      ) ? (
                        <span className="badge black auto">
                          공고 마감일 D-{item.DDAY === 0 ? "DAY" : item.DDAY}
                        </span>
                      ) : (
                        <span className="badge black auto">공고 마감</span>
                      )}
                    </div>
                  )}
                </div>
                <div className="tit">{item.PROJECT_NAME}</div>
                <div className="info">
                  <span className="text">
                    예산 및 급여 방식:
                    {item.PAY_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        제안 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;{priceFormat(item.BUDGET_MIN)}~
                        {priceFormat(item.BUDGET_MAX)}만 원
                        <br className="m-block" />
                        <span className="badge badge-sm skyblue">
                          {item.PAY_METHOD === "SALARY"
                            ? "월급"
                            : item.PAY_METHOD === "WAGE"
                            ? "주급"
                            : "프로젝트 완료 후"}
                        </span>
                      </>
                    )}
                  </span>
                  <span className="text">
                    기간:
                    {item.PERIOD_NEGOTIABLE ? (
                      <span
                        className="badge badge-sm skyblue"
                        style={isMobile ? { marginLeft: 5 } : {}}
                      >
                        협의 가능
                      </span>
                    ) : (
                      <>
                        &nbsp;
                        {item.PROJECT_PERIOD % 4 === 0
                          ? item.PROJECT_PERIOD / 4
                          : item.PROJECT_PERIOD}
                        {item.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                      </>
                    )}
                  </span>
                  <span className="text">
                    데이터 보유 현황: 데이터 {item.HAVE_DATA ? "있음" : "없음"}
                  </span>
                </div>
                <div className="option">
                  <label className="label">산업 분야</label>
                  <span>
                    {item.EDUCATION && (
                      <span className="badge badge-hide primary2">교육</span>
                    )}
                    {item.SERVICE && (
                      <span className="badge badge-hide primary2">서비스</span>
                    )}
                    {item.MANUFACTURING && (
                      <span className="badge badge-hide primary2">
                        제조, 화학, 에너지
                      </span>
                    )}
                    {item.VEHICLE && (
                      <span className="badge badge-hide primary2">자동차</span>
                    )}
                    {item.SALES && (
                      <span className="badge badge-hide primary2">
                        판매, 유통
                      </span>
                    )}
                    {item.MEDIA && (
                      <span className="badge badge-hide primary2">
                        미디어, 엔터테인먼트
                      </span>
                    )}
                    {item.TOUR && (
                      <span className="badge badge-hide primary2">
                        관광, 스포츠, 레저
                      </span>
                    )}
                    {item.CONSTRUCT && (
                      <span className="badge badge-hide primary2">
                        부동산, 건설, 건축
                      </span>
                    )}
                    {item.FINANCE && (
                      <span className="badge badge-hide primary2">금융</span>
                    )}
                    {item.MEDICAL && (
                      <span className="badge badge-hide primary2">
                        의료, 제약, 바이오, 헬스케어
                      </span>
                    )}
                    {item.IT && (
                      <span className="badge badge-hide primary2">
                        IT, 통신
                      </span>
                    )}
                    {item.GOVERNMENT && (
                      <span className="badge badge-hide primary2">
                        정부, 기관
                      </span>
                    )}
                    {item.FOOD && (
                      <span className="badge badge-hide primary2">식품</span>
                    )}
                    {item.AGRICULTURE && (
                      <span className="badge badge-hide primary2">농업</span>
                    )}
                    {item.GAME && (
                      <span className="badge badge-hide primary2">게임</span>
                    )}
                    {item.INDUSTRY_ETC && (
                      <span className="badge badge-hide primary2">기타</span>
                    )}
                    {item.INDUSTRY_OPTIONS > 3 && (
                      <span className="text">+{item.INDUSTRY_OPTIONS - 3}</span>
                    )}
                  </span>
                  <br className="m-block" />
                  <label className="label">기술 분야</label>
                  <span>
                    {item.VIDEO && (
                      <span className="badge badge-hide primary2">동영상</span>
                    )}
                    {item.IMAGE && (
                      <span className="badge badge-hide primary2">이미지</span>
                    )}
                    {item.SOUND && (
                      <span className="badge badge-hide primary2">음성</span>
                    )}
                    {item.TEXT && (
                      <span className="badge badge-hide primary2">텍스트</span>
                    )}
                    {item.BIG_DATA && (
                      <span className="badge badge-hide primary2">
                        빅데이터
                      </span>
                    )}
                    {item.FUSION && (
                      <span className="badge badge-hide primary2">융합</span>
                    )}
                    {item.SKILL_ETC && (
                      <span className="badge badge-hide primary2">기타</span>
                    )}
                    {item.SKILL_OPTIONS > 3 && (
                      <span className="text">+{item.SKILL_OPTIONS - 3}</span>
                    )}
                  </span>
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            <div className="container-none">
              아직 관심있는 프로젝트가 없어요.
            </div>
          </>
        )}
      </ul>
      {lpPaginationData && (
        <Pagination
          paginationData={lpPaginationData}
          page={lpPage}
          setPage={setLpPage}
        />
      )}
    </div>
  );
};

export default AiprojectH;
