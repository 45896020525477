import React, { useState, useEffect } from "react";
import styled from "styled-components";
const Select = ({
  option = [],
  selected,
  setSelect,
  color,
  className = [],
  width,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  const [selectText, setSelectText] = useState(false);

  useEffect(() => {
    option.forEach((item) => {
      if (item.id === selected) setSelectText(item.name);
    });
  }, [option, selected]);

  return (
    <StyledSelectWrapper
      className={`custom-select ${className}`}
      style={{ width: width ? width : "inherit" }}
    >
      <button
        className={`btn select ${active ? "active" : ""}`}
        style={{ color: color }}
        onClick={() => setActive(!active)}
        onBlur={() => setActive(false)}
        disabled={disabled}
      >
        {selectText}
      </button>
      <ul className="select-list">
        {option.map((item, i) => {
          if (!item.id) return;
          return (
            <li
              className={`select-list--item ${
                selected === item.id ? "select-list--selected" : ""
              }`}
              key={`select-list--item_${i}`}
              onClick={() => {
                setSelect(item.id);
                setActive(false);
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              <span className="text">{item.name}</span>
            </li>
          );
        })}
      </ul>
    </StyledSelectWrapper>
  );
};

export default Select;

const StyledSelectWrapper = styled.div`
  & .select-list {
    overflow: auto;
    & li {
      height: 45px;
    }
  }
  & .custom-select {
    min-width: ${(props) => `${props.width}`};
  }
`;
