import React from "react";
import styled from "styled-components";

const Button = ({
  type,
  color,
  text,
  onClick,
  className,
  style,
  isMobile,
  disabled = true,
  ...restProps
}) => {
  return (
    <StyledButton
      disabled={!disabled}
      className={`${className} ${!disabled && "disabled"}`}
      type={type}
      style={style}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      {text}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  &.mr-6 {
    margin-right: 6px;
  }
  &.disabled {
    background: var(--disabled_gray03, #d6d6d6);
    color: var(--white, #fff);
    pointer-events: none;
    cursor: not-allowed;
  }
  cursor: pointer;
  width: ${(props) =>
    props.type === "half" ? "94px" : props.isMobile ? "100%" : "200px"};
  height: 50px;
  background-color: ${(props) =>
    props.color === "black"
      ? "#000000"
      : props.color === "primary"
      ? "#00FF85"
      : "#D6D6D6"};
  color: ${(props) => (props.color === "black" ? "#00FF85" : "#000000")};
  border-radius: 5px;
  border: none;
  text-align: center;

  /* Body 600 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.6px; /* 132.857% */
`;
