import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  userProjectApplicationHeroesDetail,
  userProjectDetailMessage,
  userProjectApplicationHeroesDetailCancel,
  userProjectDetailTaskComplete,
  userProjectDetailTaskList,
  userProjectDetailTaskCount,
  userProjectDetailHeroesRefuse,
  heroesViewMeetingList,
  heroesViewContractList,
  userProjectMessageWrite,
  projectTaskResultInferenceCodeWrite,
  projectTaskResultListView,
  projectReviewWrite,
  heroesCompleteProjectInfo,
  userProjectDetailMessageIsNew,
  projectReviewPossibleWrite,
  projectTaskResultCnt,
} from "services/projectManagement";
import {
  priceFormat,
  dateFormatType2,
  dateFormatType2NoTime,
  getParameterByName,
  checkURL,
} from "utils/utils";
import useModal from "hooks/useModal";
import useAuth from "hooks/useAuth";
import { aiProjectApply } from "services/aiproject";

const STATUS = [
  "APPLY",
  "SUGGEST",
  "MATCHING",
  "MEETING",
  "CONTRACT",
  "PROGRESS",
  "COMPLETE",
  "CLOSE",
];

const AiprojectHDetail = ({ isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { id } = useParams();
  const { userInfo } = useAuth();
  const [tab, setTab] = useState("APPLY");
  const [tabV2, setTabV2] = useState("INFO"); //INFO, MESSAGE
  const [tabV2MobileHide, setTabV2MobileHide] = useState(true);
  const [data, setData] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [messageIsNew, setMessageIsNew] = useState(false);
  const [messageInputText, setMessageInputText] = useState("");
  const [meetingData, setMeetingData] = useState(null);
  const [manager, setManager] = useState(null);
  const [contractData, setContractData] = useState(null);
  const [completeData, setCompleteData] = useState(null);
  const [completeTaskResultData, setCompleteTaskResultData] = useState(null);

  // 프로젝트 진행
  const [isMessageOpenFirst, setMessageOpenFirst] = useState(false); //task호출 시 first여부 판단
  const [taskList, setTaskList] = useState([]);
  const [taskCount, setTaskCount] = useState({
    COMPLETED: 0,
    TOTAL: 0,
  });
  const [taskResList, setTaskResList] = useState([]);
  const [taskResInputMod, setTaskResInputMod] = useState(null);
  const [taskResInout, setTaskResInput] = useState("");

  const [reviewScore, setReviewScore] = useState(5);
  const [reviewInput, setReviewInput] = useState("");
  const [isReviewPossibleWrite, setReviewPossibleWrite] = useState(null);

  const messageListRef = useRef(null);

  useEffect(() => {
    const paramTab = getParameterByName(location.search, "tab");
    const paramTabV2 = getParameterByName(location.search, "tabV2");
    userProjectApplicationHeroesDetail(id, (res) => {
      if (res.code !== 0) return;
      setData(res.response);
      if (res.code !== 0) return;
      if (paramTab) {
        setTab(paramTab);
      } else {
        setTab(res.response.STATUS);
      }
      if (paramTabV2) setTabV2(paramTabV2);
    });
  }, [id, location]);

  useEffect(() => {
    userProjectDetailMessageIsNew(id, (res) => {
      if (res.code !== 0) return;
      setMessageIsNew(res.response);
    });
    if (tabV2 === "INFO") return;
    userProjectDetailMessage(id, (res) => {
      if (res.code !== 0) return;
      setMessageList(res.response);
    });
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [tabV2, messageListRef]);

  useEffect(() => {
    switch (tab) {
      case "MATCHING":
        return;
      case "MEETING":
        heroesViewMeetingList(data.PROJECT, (res) => {
          // console.log(res.response)
          if (res.code !== 0) return;
          setMeetingData(res.response.meetingList[0]);
          setManager(res.response.managerInfo);
        });
        return;
      case "CONTRACT":
        heroesViewContractList(data.PROJECT, (res) => {
          if (res.code !== 0) return;
          setContractData(res.response.contractDate);
          setManager(res.response.managerInfo);
        });
        return;
      case "PROGRESS":
        heroesViewContractList(data.PROJECT, (res) => {
          if (res.code !== 0) return;
          setContractData(res.response.contractDate);
          setManager(res.response.managerInfo);
        });
        userProjectDetailTaskList(id, (res) => {
          if (res.code !== 0) return;
          setTaskList(res.response.viewList);
          setMessageOpenFirst(res.response.first);
        });
        userProjectDetailTaskCount(id, (res) => {
          setTaskCount(res.response);
        });
        if (data.COMPLETE_BEFORE) {
          projectTaskResultCnt(id, (res) => {
            setCompleteTaskResultData(res.response);
          });
        }
        return;
      case "COMPLETE":
        heroesViewContractList(data.PROJECT, (res) => {
          if (res.code !== 0) return;
          setContractData(res.response.contractDate);
          setManager(res.response.managerInfo);
          projectReviewPossibleWrite(id, (hRes) => {
            setReviewPossibleWrite(hRes.response);
          });
        });
        heroesCompleteProjectInfo(data.PROJECT, (res) => {
          setCompleteData(res.response);
        });
        return;
      case "MODEL":
        projectTaskResultListView(id, (res) => {
          setTaskResList(res.response);
        });
        return;
      default:
        return;
    }
  }, [id, data, tab]);

  const messageSend = useCallback(() => {
    const body = {
      contents: messageInputText,
    };
    userProjectMessageWrite(id, body, (res) => {
      if (res.code !== 0) return;
      userProjectDetailMessageIsNew(id, (res) => {
        setMessageIsNew(res.response);
      });
      userProjectDetailMessage(id, (res) => {
        if (res.code !== 0) return;
        setMessageList(res.response);
      });
    });
  }, [id, messageInputText]);

  useEffect(() => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [messageList]);

  const applyProject = () => {
    let offer = data.PAY_NEGOTIABLE;
    if (offer) {
      openModal({
        type: "offerModal",
        contents: {
          postId: id,
          postTitle: data.PROJECT_NAME,
          clientPayMethod: data.PAY_METHOD,
          clientAmount: data.BUDGET_MIN,
          callback: () => {
            userProjectApplicationHeroesDetail(id, (res) => {
              if (res.code !== 0) return;
              setData(res.response);
              setTab(res.response.STATUS);
            });
            closeModal();
          },
        },
      });
    } else {
      aiProjectApply(id, {}, (res) => {
        if (res.code === 0) {
          openModal({
            type: "messageModal",
            contents: {
              title: "제안 완료",
              message: `[${data.PROJECT_NAME}]에 지원이 성공적으로 완료되었어요.\n지원 및 매칭에 대한 안내 메일을 보내드릴게요.`,
              confirmText: "확인",
              confirmCallback: () => {
                userProjectApplicationHeroesDetail(id, (res) => {
                  if (res.code !== 0) return;
                  setData(res.response);
                  setTab(res.response.STATUS);
                });
                closeModal();
              },
            },
          });
        }
      });
    }
  };

  const stateComPonent = () => {
    switch (tab) {
      case "APPLY":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-meatball"></div>
              <div className="text">
                매칭 대기 중이에요.
                <br />
                매칭 성공 시 미팅 안내를 드릴 예정이에요.
              </div>
              <button
                type="button"
                className="btn btn-lg btn-grey"
                onClick={() => {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "참고해 주세요!",
                      message:
                        "지원을 취소하면 해당 프로젝트에 다시 지원할 수 없어요.\n정말 취소하시겠어요?",
                      confirmText: "취소하기",
                      confirmCallback: () => {
                        userProjectApplicationHeroesDetailCancel(
                          data.SEQ,
                          (res) => {
                            if (res.code !== 0) return;
                            navigate(-1);
                            closeModal();
                          }
                        );
                      },
                      cancelText: "돌아가기",
                      cancelCallback: () => {
                        closeModal();
                      },
                    },
                  });
                }}
              >
                지원 취소
              </button>
            </div>
          </>
        );
      case "SUGGEST":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-meatball"></div>
              <div className="text">
                [{data.MANAGER_NAME}]님께서 제안을 보냈어요.
                <br />
                제안을 수락하고 해당 프로젝트에 지원해 보세요.
              </div>
              <div className="btn-wrap mt-40">
                <button
                  type="button"
                  className="btn btn-half btn-black type2"
                  onClick={() => applyProject()}
                >
                  지원하기
                </button>
                <button
                  type="button"
                  className="btn btn-half btn-grey"
                  onClick={() => {
                    userProjectDetailHeroesRefuse(data.SEQ, (res) => {
                      if (res.code !== 0) return;
                      navigate(-1);
                    });
                  }}
                >
                  거절하기
                </button>
              </div>
            </div>
          </>
        );
      case "REFUSE":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-close"></div>
              <div className="text">
                아쉽지만, 클라이언트의 요청으로 매칭이 종료되었어요.
                <br />
                다른 프로젝트에 지원해보세요!
              </div>
              <button
                type="button"
                className="btn btn-lg btn-black type2"
                onClick={() => navigate("/aiproject")}
              >
                다른 프로젝트 살펴보기
              </button>
            </div>
          </>
        );
      case "MATCHING":
        return <></>;
      case "MEETING":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-check"></div>
              <div className="text">
                {meetingData?.STATUS === "COMPLETE"
                  ? "좋은 소식이 있길 응원할게요."
                  : "클라이언트, 매니저와 미팅을 진행할게요."}
              </div>
              <div className="profile-detail">
                <div className="col-wrap">
                  <div className="col">
                    <div className="row">
                      <label className="label">미팅 일시</label>
                      {meetingData
                        ? dateFormatType2NoTime(meetingData.MEETING_AT) +
                          " " +
                          meetingData.MEETING_TIME
                        : "-"}
                      {meetingData?.STATUS === "COMPLETE" ? (
                        <span className="badge badge-sm primary">
                          미팅 완료
                        </span>
                      ) : (
                        (
                          <span className="badge badge-sm d-grey2">
                            미팅 예정
                          </span>
                        ) || "-"
                      )}
                    </div>
                    <div className="row">
                      <label className="label">장소/링크</label>
                      {meetingData?.PLACE ? (
                        <>
                          {checkURL(meetingData.PLACE) ? (
                            <a
                              href={meetingData.PLACE}
                              target="_blank"
                              className="a-link"
                              rel="noreferrer"
                            >
                              {meetingData.PLACE}
                            </a>
                          ) : (
                            meetingData.PLACE
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <label className="label">미팅 방식</label>
                      {meetingData?.MEETING_METHOD
                        ? meetingData?.MEETING_METHOD === "ONLINE"
                          ? "온라인"
                          : "오프라인"
                        : "-"}
                    </div>
                    <div className="row">
                      <label className="label">담당 매니저</label>
                      {manager?.NAME} / {manager?.PHONE}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "CONTRACT":
        return (
          <>
            <div className="state-guide">
              <div className="icon type-check"></div>
              <div className="text">
                {contractData?.COMPLETED
                  ? "계약이 완료되었어요."
                  : "프로젝트 진행을 위한 계약을 진행할게요."}
              </div>
              {contractData && (
                <div className="profile-detail">
                  <div className="col-wrap">
                    <div className="col">
                      <div className="row">
                        <label className="label">계약 금액</label>
                        {priceFormat(
                          contractData.CLIENT_CONTRACT_SUPPLY_PRICE +
                            contractData.CLIENT_CONTRACT_VAT
                        )}{" "}
                        원
                      </div>
                      <div className="row">
                        <label className="label">계약 기간</label>
                        {dateFormatType2NoTime(contractData.START_AT)}~
                        {dateFormatType2NoTime(contractData.END_AT)}
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <label className="label">계약일</label>
                        <span>{contractData.CONTRACT_AT || "-"}</span>
                        <span className={`badge badge-sm primary`}>
                          {contractData.COMPLETED ? "계약 완료" : "계약 예정"}
                        </span>
                      </div>
                      <div className="row">
                        <label className="label">대금 보호방식</label>
                        에스크로
                      </div>
                      {contractData.FILE && (
                        <div className="row">
                          <button
                            type="button"
                            className="btn btn-full btn-primary"
                            onClick={() => window.open(contractData.FILE)}
                          >
                            계약서 보기
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      case "PROGRESS":
        return (
          <>
            {!(data.COMPLETE_BEFORE && data.STATUS === "PROGRESS") ? (
              <>
                {!isMobile && (
                  <div className="state-tip">
                    <p>
                      요구사항 TASK의{" "}
                      <strong>
                        최초 세팅은 매니저가 계약서를 기반으로 진행
                      </strong>
                      해 드려요.
                    </p>
                    <p>
                      상호 간의 충분한 논의를 통해{" "}
                      <strong>요구사항 TASK와 일정을 변경</strong>할 수 있어요.
                    </p>
                  </div>
                )}
                <div className="percent-wrap">
                  <div className="top">
                    <strong>{taskCount.COMPLETED || 0}</strong> /{" "}
                    {taskCount.TOTAL}
                  </div>
                  <div className="percent">
                    <div className="bar-wrap">
                      <span
                        className="bar"
                        style={{
                          width: `${
                            Math.round(
                              ((taskCount.COMPLETED || 0) / taskCount.TOTAL) *
                                100
                            ) || 0
                          }%`,
                        }}
                      ></span>
                    </div>
                    <span
                      className={`num ${
                        Math.round(
                          ((taskCount.COMPLETED || 0) / taskCount.TOTAL) * 100
                        ) > 0
                          ? "active"
                          : ""
                      }`}
                    >
                      <strong>{`${
                        Math.round(
                          ((taskCount.COMPLETED || 0) / taskCount.TOTAL) * 100
                        ) || 0
                      }`}</strong>
                      %
                    </span>
                    <button
                      type="button"
                      className="btn btn-xsm btn-history"
                      onClick={() => {
                        openModal({
                          type: "projectHistoryModal",
                          contents: {
                            id: id,
                          },
                        });
                      }}
                    >
                      {!isMobile && "히스토리 보기"}
                    </button>
                  </div>
                </div>
                <ul className="history-list">
                  <li className="history-list--item top">
                    <div className="text-left">완료 여부</div>
                    <div className="text-left">TASK</div>
                    <div className="text-left">등록한 사람</div>
                    <div className="text-right">등록일</div>
                    <div className="text-right">완료 예정일</div>
                    <div className="text-right">완료 일자</div>
                    <div></div>
                  </li>
                  {Boolean(taskList.length) &&
                    taskList.map((item) => (
                      <li
                        className={`history-list--item ${
                          item.DELETED || item.STATUS === "CANCEL"
                            ? "stroke"
                            : ""
                        }  ${item.STATUS === "SUGGEST" ? "active" : ""}`}
                        key={`task-list-item-${item.SEQ}`}
                      >
                        <div className="text-left">
                          {item.DELETED ? (
                            <>
                              <div className="text-left">-</div>
                            </>
                          ) : (
                            <>
                              {(item.STATUS === "PROGRESS" ||
                                item.STATUS === "APPROVAL" ||
                                item.STATUS === "COMPLETE") && (
                                <div className="text-left">
                                  <label
                                    className="input-checkbox"
                                    onClick={() => {
                                      if (item.COMPLETED) return;
                                      openModal({
                                        type: "messageModal",
                                        contents: {
                                          title: "TASK 완료",
                                          message:
                                            "TASK를 완료할까요?\n완료된 TASK는 더 이상 진행할 수 없어요.",
                                          confirmText: "완료하기",
                                          confirmCallback: () => {
                                            userProjectDetailTaskComplete(
                                              item.SEQ,
                                              (res) => {
                                                if (res.code !== 0) return;
                                                userProjectApplicationHeroesDetail(
                                                  id,
                                                  (res) => {
                                                    if (res.code !== 0) return;
                                                    setData(res.response);
                                                    setTab(res.response.STATUS);
                                                  }
                                                );
                                                closeModal();
                                              }
                                            );
                                          },
                                          cancelText: "돌아가기",
                                          cancelCallback: () => {
                                            closeModal();
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.COMPLETED}
                                      readOnly
                                    />
                                    <span className="box purple"></span>
                                  </label>
                                </div>
                              )}
                              {item.STATUS === "SUGGEST" &&
                                item.WRITER === userInfo.SEQ && (
                                  <span className="badge badge-sm deepblue">
                                    보낸 요청
                                  </span>
                                )}
                              {item.STATUS === "SUGGEST" &&
                                item.WRITER !== userInfo.SEQ && (
                                  <span className="badge badge-sm deepblue">
                                    받은 요청
                                  </span>
                                )}
                            </>
                          )}
                        </div>
                        <div className="text-left tit">{item.NAME}</div>
                        <div className="text-left">{item.WRITER_NAME}</div>
                        <div className="text-right">
                          {dateFormatType2NoTime(item.CREATED_AT)}
                        </div>
                        <div className="text-right">
                          {item.EXPECTED_COMPLETED_AT}
                        </div>
                        <div className="text-right">
                          {item.COMPLETED ? item.COMPLETED_AT : "-"}
                        </div>
                        {!item.DELETED &&
                        data.STATUS !== "COMPLETE" &&
                        !data.COMPLETE_BEFORE ? (
                          <>
                            {item.STATUS === "PROGRESS" && (
                              <div className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-line"
                                  onClick={() => {
                                    openModal({
                                      type: "taskModal",
                                      contents: {
                                        type: "SUGGEST",
                                        seq: item.SEQ,
                                        taskData: {
                                          text: item.NAME,
                                          date: item.EXPECTED_COMPLETED_AT,
                                        },
                                        callback: () => {
                                          userProjectDetailTaskList(
                                            id,
                                            (res) => {
                                              setTaskList(
                                                res.response.viewList
                                              );
                                            }
                                          );
                                        },
                                      },
                                    });
                                  }}
                                >
                                  수정 요청
                                </button>
                              </div>
                            )}
                            {item.STATUS === "SUGGEST" &&
                              item.WRITER !== userInfo.SEQ && (
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-blue"
                                    onClick={() => {
                                      openModal({
                                        type: "taskModal",
                                        contents: {
                                          type: "ACCREJ",
                                          seq: item.SEQ,
                                          taskData: {
                                            text: item.NAME,
                                            date: item.EXPECTED_COMPLETED_AT,
                                          },
                                          callback: () => {
                                            userProjectDetailTaskList(
                                              id,
                                              (res) => {
                                                setTaskList(
                                                  res.response.viewList
                                                );
                                              }
                                            );
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    수락/거절
                                  </button>
                                </div>
                              )}
                            {item.STATUS === "SUGGEST" &&
                              item.WRITER === userInfo.SEQ && (
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-line grey"
                                  >
                                    요청 중...
                                  </button>
                                </div>
                              )}
                            {item.STATUS === "CANCEL" && (
                              <div className="text-right">-</div>
                            )}
                            {item.STATUS === "COMPLETE" && (
                              <div className="text-right">-</div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="text-right">-</div>
                          </>
                        )}
                      </li>
                    ))}
                </ul>
                <div className="history-list--bottom">
                  {data.STATUS !== "COMPLETE" && !data.COMPLETE_BEFORE && (
                    <button
                      type="button"
                      className="btn btn-sm btn-line btn-add"
                      onClick={() => {
                        openModal({
                          type: "taskModal",
                          contents: {
                            type: "APPLY",
                            seq: id,
                            callback: () => {
                              userProjectDetailTaskList(id, (res) => {
                                setTaskList(res.response.viewList);
                              });
                            },
                          },
                        });
                      }}
                    >
                      추가
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="state-guide">
                  <div className="icon type-check"></div>
                  <div className="text">
                    클라이언트가 프로젝트를 최종 확인 중이에요.
                  </div>
                  <ul className="info-list">
                    <li className="info-list--item">
                      <label className="label">프로젝트 총 진행 기간</label>
                      {dateFormatType2NoTime(data?.START_AT)}~
                      {dateFormatType2NoTime(data?.CLOSE_DATE)}
                    </li>
                    <li className="info-list--item">
                      <label className="label">수행한 TASK</label>
                      {completeTaskResultData?.completed || "-"}개
                    </li>
                    <li className="info-list--item">
                      <label className="label">TASK 검증 결과</label>
                      적합 {completeTaskResultData?.["적합"] ||
                        "-"}개/부적합{" "}
                      {completeTaskResultData?.["부적합"] || "-"}개
                    </li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-lg btn-black type2"
                    onClick={() => {
                      navigate("/aiproject");
                    }}
                  >
                    다른 프로젝트 살펴보기
                  </button>
                </div>
              </>
            )}
          </>
        );
      case "COMPLETE":
        return (
          <div className="state-guide">
            <div className="icon type-check"></div>
            <div className="text">
              프로젝트가 정상적으로 완료되었어요.
              {completeData && (
                <p className="sub type2">
                  프로젝트 총 진행 기간 |{" "}
                  {dateFormatType2NoTime(completeData.START_AT)}~
                  {dateFormatType2NoTime(completeData.COMPLETE_AT)}
                  <br />
                  수행한 TASK | {completeData?.taskInfo.TASK}개<br />
                  TASK 검증 결과 | 적합 {
                    completeData.taskInfo.PASS
                  }개/부적합 {completeData.taskInfo.NO_PASS}개
                </p>
              )}
            </div>
            <div className="review-input">
              <div className="rate">
                <button
                  type="button"
                  className={`btn btn-star ${
                    Number(reviewScore) >= 1 ? "active" : ""
                  }`}
                  onClick={() => setReviewScore(1)}
                ></button>
                <button
                  type="button"
                  className={`btn btn-star ${
                    Number(reviewScore) >= 2 ? "active" : ""
                  }`}
                  onClick={() => setReviewScore(2)}
                ></button>
                <button
                  type="button"
                  className={`btn btn-star ${
                    Number(reviewScore) >= 3 ? "active" : ""
                  }`}
                  onClick={() => setReviewScore(3)}
                ></button>
                <button
                  type="button"
                  className={`btn btn-star ${
                    Number(reviewScore) >= 4 ? "active" : ""
                  }`}
                  onClick={() => setReviewScore(4)}
                ></button>
                <button
                  type="button"
                  className={`btn btn-star ${
                    Number(reviewScore) >= 5 ? "active" : ""
                  }`}
                  onClick={() => setReviewScore(5)}
                ></button>
              </div>
              <textarea
                rows={3}
                disabled={!isReviewPossibleWrite}
                className="input-textarea"
                placeholder="여러분의 소중한 의견은 AI HEROES 개선에 큰 힘이 됩니다."
                value={reviewInput}
                onChange={(e) => setReviewInput(e.target.value)}
              ></textarea>
            </div>
            <button
              type="button"
              className="btn btn-lg btn-black type2"
              disabled={!isReviewPossibleWrite}
              onClick={() => {
                projectReviewWrite(
                  id,
                  {
                    score: reviewScore,
                    review: reviewInput,
                  },
                  (res) => {
                    if (!isReviewPossibleWrite) {
                      openModal({
                        type: "messageModal",
                        contents: {
                          title: "리뷰 등록 실패",
                          message: "이미 리뷰를 작성하셨습니다.",
                          confirmText: "확인",
                          confirmCallback: () => {
                            setReviewInput("");
                            setReviewScore(5);
                            closeModal();
                          },
                        },
                      });
                      return;
                    }
                    if (res.code !== 0) {
                      openModal({
                        type: "messageModal",
                        contents: {
                          title: "리뷰 등록 실패",
                          message:
                            "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
                          confirmText: "확인",
                          confirmCallback: () => {
                            setReviewInput("");
                            setReviewScore(5);
                            closeModal();
                          },
                        },
                      });
                    } else {
                      openModal({
                        type: "messageModal",
                        contents: {
                          title: "리뷰 등록 완료",
                          message: "소중한 의견 감사드려요!",
                          confirmText: "확인",
                          confirmCallback: () => {
                            setReviewInput("");
                            setReviewScore(5);
                            closeModal();
                            navigate("/mypage/aiprojecth");
                          },
                        },
                      });
                    }
                  }
                );
              }}
            >
              {isReviewPossibleWrite
                ? "더 나은 서비스를 위한 피드백 남기기"
                : "피드백을 남겨주셨어요."}
            </button>
          </div>
        );
      case "COLSE":
        return <></>;
      case "MODEL":
        return (
          <>
            {Boolean(taskResList.length) ? (
              <ul className="history-list type2">
                <li className="history-list--item top">
                  <div className="text-left">적합성</div>
                  <div className="text-left">검증 항목</div>
                  <div className="text-left">결과 / 목표(%)</div>
                  <div className="text-right">검증 일시</div>
                  <div className="text-right">비고</div>
                  <div className="text-right"></div>
                </li>

                <>
                  {taskResList.map((item) => (
                    <React.Fragment key={`task-res-list-item-${item.SEQ}`}>
                      <li className="history-list--item">
                        <div className="text-left">
                          {item.SUITABILITY === "-" && (
                            <span className={`badge badge-sm d-grey`}>
                              검증 전
                            </span>
                          )}
                          {item.SUITABILITY === "적합" && (
                            <span className={`badge badge-sm deeppurple`}>
                              적합
                            </span>
                          )}
                          {item.SUITABILITY === "부적합" && (
                            <span className={`badge badge-sm danger`}>
                              부적합
                            </span>
                          )}
                        </div>
                        <div className="text-left tit">{item.NAME}</div>
                        <div className="text-left">
                          <strong
                            style={
                              item.SUITABILITY === "부적합"
                                ? { color: "#DF2E47" }
                                : {}
                            }
                          >
                            {item.RESULT_PERCENT || 0} /{" "}
                            {item.TARGET_PERCENT || 0}
                          </strong>
                        </div>
                        <div className="text-right">
                          {dateFormatType2(item.UPDATED_AT)}
                        </div>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-list"
                            onClick={() => {
                              openModal({
                                type: "whiteModal",
                                contents: {
                                  title: "평가 외 확인사항",
                                  message: item.MEMO,
                                  confirmCallback: () => {
                                    closeModal();
                                  },
                                  closedBtn: false,
                                },
                              });
                            }}
                          ></button>
                        </div>
                        <div className="text-right">
                          {item.RESULT_REPORT_LINK && (
                            <button
                              type="button"
                              className="btn btn-down btn-sm btn-prupleline"
                              onClick={() => {
                                window.open(item.RESULT_REPORT_LINK);
                              }}
                            >
                              결과 레포트
                            </button>
                          )}
                        </div>
                      </li>
                      <li className="history-list--item entry">
                        {taskResInputMod && taskResInputMod === item.SEQ ? (
                          <div className="row">
                            <label className="label">인퍼런스 코드</label>
                            <input
                              type="text"
                              className="input-text"
                              placeholder="경로(Google 링크)를 입력해 주세요."
                              value={taskResInout}
                              onChange={(e) => setTaskResInput(e.target.value)}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-black type2"
                              disabled={!taskResInout}
                              onClick={() => {
                                projectTaskResultInferenceCodeWrite(
                                  item.SEQ,
                                  { inferenceCodeLink: taskResInout },
                                  (res) => {
                                    if (res.code !== 0) return;
                                    projectTaskResultListView(id, (res) => {
                                      setTaskResList(res.response);
                                      setTaskList(null);
                                      setTaskResInput("");
                                      setTaskResInputMod(null);
                                    });
                                  }
                                );
                              }}
                            >
                              저장
                            </button>
                          </div>
                        ) : (
                          <div className="row">
                            <label className="label">인퍼런스 코드</label>
                            <a
                              href={item.INFERENCE_CODE_LINK}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.INFERENCE_CODE_LINK}
                            </a>
                            <button
                              type="button"
                              className="btn btn-sm btn-black type2"
                              onClick={() => {
                                setTaskResInput(item.INFERENCE_CODE_LINK);
                                setTaskResInputMod(item.SEQ);
                              }}
                            >
                              수정
                            </button>
                          </div>
                        )}
                        <div className="row">
                          <label className="label">테스트 데이터</label>
                          <a
                            href={item.TEST_DATE_LINK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.TEST_DATE_LINK}
                          </a>
                        </div>
                        <div className="row">
                          <label className="label">결과 데이터</label>
                          <a
                            href={item.RESULT_REPORT_LINK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.RESULT_REPORT_LINK}
                          </a>
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </>
              </ul>
            ) : (
              <>
                <div className="container-none">모델 검증 전이에요.</div>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className="mypage-con"
      style={
        isMobile
          ? STATUS.indexOf(data?.STATUS) > 1
            ? { paddingTop: 96 }
            : { paddingTop: 13 }
          : {}
      }
    >
      {data && (
        <>
          {!isMobile && (
            <button
              type="button"
              className="btn top-back mb-20"
              onClick={() => navigate(-1)}
              style={
                STATUS.indexOf(data.STATUS) <= 1 && isMobile
                  ? { marginTop: 20 }
                  : {}
              }
            >
              리스트로 돌아가기
            </button>
          )}
          {isMobile && tab === "PROGRESS" && (
            <div className="state-tip" style={isMobile ? { marginTop: 0 } : {}}>
              <p>
                요구사항 TASK의{" "}
                <strong>최초 세팅은 매니저가 계약서를 기반으로 진행</strong>해
                드려요.
              </p>
              <p>
                상호 간의 충분한 논의를 통해{" "}
                <strong>요구사항 TASK와 일정을 변경</strong>할 수 있어요.
              </p>
            </div>
          )}
          <div className="mypage-tit text">{data.PROJECT_NAME}</div>
          <div className="mypage-state">
            <div className="left">
              {!isMobile && (
                <div className="tab-btn">
                  <button
                    type="button"
                    className={`btn ${tabV2 === "INFO" ? "active" : ""}`}
                    onClick={() => setTabV2("INFO")}
                  >
                    기본 정보
                  </button>
                  <div className="tooltip guide top">
                    <button
                      type="button"
                      className={`btn ${tabV2 === "MESSAGE" ? "active" : ""} ${
                        messageIsNew ? "new" : ""
                      }`}
                      onClick={() => {
                        if (STATUS.indexOf(data.STATUS) > 4) {
                          setTabV2("MESSAGE");
                        }
                      }}
                    >
                      메시지
                    </button>
                    {isMessageOpenFirst && (
                      <div className="tooltip-message">
                        클라이언트와 자유롭게 이야기를 나눠보세요!
                      </div>
                    )}
                  </div>
                </div>
              )}
              {tabV2 === "INFO" ? (
                <div className={`tab-con ${isMobile ? "tab-con-m" : ""}`}>
                  <ul
                    className={`info-list ${tabV2MobileHide ? "hide" : "show"}`}
                  >
                    <li className="info-list--item">
                      <label className="label">예산</label>
                      <div className="text">
                        {data.PAY_NEGOTIABLE ? (
                          <span className="badge badge-sm skyblue">
                            제안 가능
                          </span>
                        ) : (
                          <>
                            {priceFormat(data.BUDGET_MIN)}~
                            {priceFormat(data.BUDGET_MAX)}만 원<br />
                            <span
                              className="badge badge-sm skyblue"
                              style={{ display: "inline-block", marginTop: 8 }}
                            >
                              {data.PAY_METHOD === "SALARY"
                                ? "월급"
                                : data.PAY_METHOD === "WAGE"
                                ? "주급"
                                : "프로젝트 완료 후"}
                            </span>
                          </>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">기간</label>
                      <div className="text">
                        {data.PERIOD_NEGOTIABLE ? (
                          <span
                            className="badge badge-sm skyblue"
                            style={{ display: "inline-block" }}
                          >
                            협의 가능
                          </span>
                        ) : (
                          <>
                            {data.PROJECT_PERIOD % 4 === 0
                              ? data.PROJECT_PERIOD / 4
                              : data.PROJECT_PERIOD}
                            {data.PROJECT_PERIOD % 4 === 0 ? "개월" : "주"}
                          </>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">데이터 보유 현황</label>
                      <div className="text">
                        {data.HAVE_DATA ? "데이터 있음" : "데이터 없음"}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">진행 방식</label>
                      <div className="text">
                        {data.WORK_METHOD === "BOTH"
                          ? "상주, 원격"
                          : data.WORK_METHOD === "RESIDENT"
                          ? "상주"
                          : "원격"}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">의뢰 마감일</label>
                      <div className="text">{data.CLOSE_DATE}</div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">산업 분야</label>
                      <div className="text badge-flex-box">
                        {data.AI_INDUSTRY.EDUCATION && (
                          <span className="badge m5 badge-md primary2">
                            교육
                          </span>
                        )}
                        {data.AI_INDUSTRY.SERVICE && (
                          <span className="badge m5 badge-md primary2">
                            서비스
                          </span>
                        )}
                        {data.AI_INDUSTRY.MANUFACTURING && (
                          <span className="badge m5 badge-md primary2">
                            제조, 화학, 에너지
                          </span>
                        )}
                        {data.AI_INDUSTRY.VEHICLE && (
                          <span className="badge m5 badge-md primary2">
                            자동차
                          </span>
                        )}
                        {data.AI_INDUSTRY.SALES && (
                          <span className="badge m5 badge-md primary2">
                            판매, 유통
                          </span>
                        )}
                        {data.AI_INDUSTRY.MEDIA && (
                          <span className="badge m5 badge-md primary2">
                            미디어, 엔터테인먼트
                          </span>
                        )}
                        {data.AI_INDUSTRY.TOUR && (
                          <span className="badge m5 badge-md primary2">
                            관광, 스포츠, 레저
                          </span>
                        )}
                        {data.AI_INDUSTRY.CONSTRUCT && (
                          <span className="badge m5 badge-md primary2">
                            부동산, 건설, 건축
                          </span>
                        )}
                        {data.AI_INDUSTRY.FINANCE && (
                          <span className="badge m5 badge-md primary2">
                            금융
                          </span>
                        )}
                        {data.AI_INDUSTRY.MEDICAL && (
                          <span className="badge m5 badge-md primary2">
                            의료, 제약, 바이오, 헬스케어
                          </span>
                        )}
                        {data.AI_INDUSTRY.IT && (
                          <span className="badge m5 badge-md primary2">
                            IT, 통신
                          </span>
                        )}
                        {data.AI_INDUSTRY.GOVERNMENT && (
                          <span className="badge m5 badge-md primary2">
                            정부, 기관
                          </span>
                        )}
                        {data.AI_INDUSTRY.FOOD && (
                          <span className="badge m5 badge-md primary2">
                            식품
                          </span>
                        )}
                        {data.AI_INDUSTRY.AGRICULTURE && (
                          <span className="badge m5 badge-md primary2">
                            농업
                          </span>
                        )}
                        {data.AI_INDUSTRY.GAME && (
                          <span className="badge m5 badge-md primary2">
                            게임
                          </span>
                        )}
                        {data.AI_INDUSTRY.ETC && (
                          <span className="badge m5 badge-md primary2">
                            기타
                          </span>
                        )}
                      </div>
                    </li>
                    <li className="info-list--item">
                      <label className="label">기술 분야</label>
                      <div className="text badge-flex-box">
                        {data.AI_SKILL.VIDEO && (
                          <span className="badge m5 badge-md primary2">
                            동영상
                          </span>
                        )}
                        {data.AI_SKILL.IMAGE && (
                          <span className="badge m5 badge-md primary2">
                            이미지
                          </span>
                        )}
                        {data.AI_SKILL.SOUND && (
                          <span className="badge m5 badge-md primary2">
                            음성
                          </span>
                        )}
                        {data.AI_SKILL.TEXT && (
                          <span className="badge m5 badge-md primary2">
                            텍스트
                          </span>
                        )}
                        {data.AI_SKILL.BIG_DATA && (
                          <span className="badge m5 badge-md primary2">
                            빅데이터
                          </span>
                        )}
                        {data.AI_SKILL.FUSION && (
                          <span className="badge m5 badge-md primary2">
                            융합
                          </span>
                        )}
                        {data.AI_SKILL.ETC && (
                          <span className="badge m5 badge-md primary2">
                            기타
                          </span>
                        )}
                      </div>
                    </li>
                    {manager && (
                      <li className="info-list--item">
                        <label className="label">담당 매니저</label>
                        <div className="manager">
                          {manager.PROFILE_IMG && (
                            <div className="img-wrap">
                              <img src={manager.PROFILE_IMG} alt="" />
                            </div>
                          )}
                          <div className="txt-wrap">
                            <div className="name">{manager.NAME}</div>
                            <div className="text">{manager.PHONE}</div>
                            <div className="text">{manager.USERNAME}</div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  {isMobile && (
                    <button
                      type="button"
                      className={`btn m-btn ${
                        tabV2MobileHide ? "hide" : "show"
                      }`}
                      onClick={() => {
                        setTabV2MobileHide(!tabV2MobileHide);
                      }}
                    >
                      {tabV2MobileHide ? "더 보기" : "접기"}
                    </button>
                  )}
                </div>
              ) : (
                <div
                  className={`tab-con ${isMobile ? "tab-con-m" : ""} message`}
                >
                  {isMobile && (
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => setTabV2("INFO")}
                    ></button>
                  )}
                  <div className="message-wrap" ref={messageListRef}>
                    {isMobile && <div className="message-tit">메시지</div>}
                    <div className="message-guide">
                      ※ 원할한 프로젝트 운영을 위해
                      <br />
                      담당 매니저가 메시지 내역을 확인할 수 있어요.
                    </div>
                    {/* <button type="button" className="btn btn-history">이전 메시지 보기</button> */}
                    {Boolean(messageList.length) &&
                      messageList.map((item) => (
                        <div
                          className={`${
                            item.SEND_USER === userInfo.SEQ
                              ? "message-right"
                              : "message-left"
                          }`}
                          key={`message-list-item-${item.SEQ}`}
                        >
                          <div className="box">{item.CONTENTS}</div>
                          {item.SEND_USER === userInfo.SEQ ? (
                            <div className="date">
                              {item.CHECKED ? (
                                <span className="state read">읽음</span>
                              ) : (
                                <span className="state">안 읽음</span>
                              )}
                              {dateFormatType2(item.CREATED_AT)}
                            </div>
                          ) : (
                            <div className="date">
                              {dateFormatType2(item.CREATED_AT)}
                              {item.CHECKED ? (
                                <span className="state read">읽음</span>
                              ) : (
                                <span className="state">안 읽음</span>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="message-input">
                    <textarea
                      type="text"
                      className="input-text"
                      placeholder="메시지를 입력하세요."
                      value={messageInputText}
                      onChange={(e) => {
                        setMessageInputText(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.nativeEvent.isComposing) {
                          return;
                        }
                        if (e.key === "Enter" && e.shiftKey) {
                          return;
                        } else if (e.key === "Enter") {
                          messageSend();
                          setMessageInputText("");
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-black btn-sm type2"
                      onClick={() => {
                        messageSend();
                        setMessageInputText("");
                      }}
                    >
                      전송
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="right">
              {STATUS.indexOf(data.STATUS) > 1 && (
                <div className="state">
                  <div
                    className={`state-6 ${
                      STATUS.indexOf(data.STATUS) > 1 ? "active" : ""
                    }`}
                    onClick={() => {
                      if (STATUS.indexOf(data.STATUS) > 1) {
                        setTab("MEETING");
                      }
                    }}
                  >
                    미팅
                  </div>
                  <div
                    className={`state-7 ${
                      STATUS.indexOf(data.STATUS) > 3 ? "active" : ""
                    }`}
                    onClick={() => {
                      if (STATUS.indexOf(data.STATUS) > 3) {
                        setTab("CONTRACT");
                      }
                    }}
                  >
                    계약
                  </div>
                  <div
                    className={`state-3 ${
                      STATUS.indexOf(data.STATUS) > 4 ? "active" : ""
                    }`}
                    onClick={() => {
                      if (STATUS.indexOf(data.STATUS) > 4) {
                        setTab("PROGRESS");
                      }
                    }}
                  >
                    프로젝트 진행
                  </div>
                  <div
                    className={`state-4 ${
                      STATUS.indexOf(data.STATUS) > 5 ? "active" : ""
                    }`}
                    onClick={() => {
                      if (STATUS.indexOf(data.STATUS) > 5) {
                        setTab("COMPLETE");
                      }
                    }}
                  >
                    완료
                  </div>
                  <div
                    className={`state-5 ${
                      STATUS.indexOf(data.STATUS) > 4 ? "active" : ""
                    }`}
                    onClick={() => {
                      if (STATUS.indexOf(data.STATUS) > 4) {
                        setTab("MODEL");
                      }
                    }}
                  >
                    모델 검증
                  </div>
                </div>
              )}
              {stateComPonent()}
            </div>
          </div>
          {isMobile && STATUS.indexOf(data.STATUS) >= 5 && (
            <button
              type="button"
              className="btn floating-btn"
              onClick={() => setTabV2("MESSAGE")}
            ></button>
          )}
        </>
      )}
    </div>
  );
};

export default AiprojectHDetail;
