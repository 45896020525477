import React from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { modalSelector } from "store/modalSlice";
import MessageModal from "./MessageModal";
import ChangePasswordModal from "./ChangePasswordModal";
import LoginModal from "./LoginModal";
import EventPopupModal from "./EventPopupModal";
import ReportModal from "./ReportModal";
import OfferModal from "./OfferModal";
import ImageViewModal from "./ImageViewModal";
import PolicyModal from "./PolicyModal";
import TaskModal from "./TaskModal";
import ConsultModal from "./ConsultModal";
import ProjectHistoryModal from "./ProjectHistoryModal";
import ProjectSuggestionModal from "./ProjectSuggestionModal";
import WhiteModal from "./WhiteModal";
import AlarmModal from "./AlarmModal";
import ConfirmModal from "./ConfirmModal";
import PartnerContactModal from "./PartnerContactModal";
import AiProjectAddInfoModal from "./AiProjectAddInfoModal";

const MODAL_COMPONENTS = {
  messageModal: MessageModal,
  changePasswordModal: ChangePasswordModal,
  loginModal: LoginModal,
  eventPopupModal: EventPopupModal,
  reportModal: ReportModal,
  offerModal: OfferModal,
  projectSuggestionModal: ProjectSuggestionModal,
  imageViewModal: ImageViewModal,
  policyModal: PolicyModal,
  taskModal: TaskModal,
  consultModal: ConsultModal,
  projectHistoryModal: ProjectHistoryModal,
  whiteModal: WhiteModal,
  alarmMobileModal: AlarmModal,
  confirmModal: ConfirmModal,
  partnerContactModal: PartnerContactModal,
  aiProjectAddInfoModal: AiProjectAddInfoModal,
};

/**
 * 중첩 모달 관리하기 위해 redux 사용
 */
function ModalContainer() {
  const modalList = useSelector(modalSelector);

  const renderModal = modalList.map(({ type, contents }, i) => {
    if (!type) return;

    const Modal = MODAL_COMPONENTS[type];
    return <Modal key={`type_${i}`} {...contents} />;
  });
  const modalElement = document.getElementById("modal");
  return createPortal(<>{renderModal}</>, modalElement);
}

export default ModalContainer;
